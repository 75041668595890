import React, { ReactNode, useContext } from 'react'
import _ from 'underscore'
import util, { getTenantId } from './util'
import Table, { TableRowData } from './TableNG'
import { AccountInfo, BucketState, Flow, FundMove, FundsFlow, PlannedFundMove, PlannedItemHandle } from './api'
import { Button, Dialog, Link, useTheme } from '@mui/material'
import planBalanceAccumulator from './planBalanceAccumulator'
import { closePlannedItem } from './api-actions'
import { DialogsContext } from './Dialogs'


interface PlannedItemsTableProps {
    showSourceAccountsColumn: boolean,
    projections: PlannedFundMove[],
    overallBalances: Record<string, number>,
    accounts: AccountInfo[],
    blockUI: () => void,
    markDirtyAndReload: () => void,
    // showPlannedItem:(h:PlannedItemHandle)=>void, 
    isFlowOut: (flow: FundsFlow) => boolean,
    isFlowInOrOut: (flow: FundsFlow) => boolean
}

export default (props: PlannedItemsTableProps) => {
    const allProjections = props.projections

    const dialogs = useContext(DialogsContext);

    const theme = useTheme()
    const { showSourceAccountsColumn, isFlowInOrOut, isFlowOut, markDirtyAndReload, blockUI, overallBalances, accounts } = props

    let flowIsForThisAccount = isFlowInOrOut


    function accountName(accountId: string) {
        var account = _.find(accounts, function (account) {
            return account.id === accountId;
        })

        return account ? account.name : undefined;
    }

    var totalsAccumulator = planBalanceAccumulator(overallBalances, isFlowInOrOut, isFlowOut)

    let sourceColumnHeader = showSourceAccountsColumn ? ["Fund"] : []
    let header = ["When", "Status", "Description"].concat(sourceColumnHeader).concat(["To/From", "Planned Amount", "Remaining", "Balance", "Actual vs Plan", ""])

    let dataRowSets = _.map(allProjections ?? [], function (projection) {
        var item = projection.planned;
        var affectedFlows = _.filter(item.flows, flowIsForThisAccount);
        return _.map(affectedFlows, function (flow) {

            let twoWeeksAgo = new Date().getTime() - (1000 * 60 * 60 * 24 * 7 * 2)
            let whenClasses = "";
            if (item.when < twoWeeksAgo) {
                whenClasses += " old-unclosed-planned-item"
            }

            let formattedWhen = <span className={whenClasses}>{util.formatLongDateTime(item.when)}</span>

            var values = totalsAccumulator.next(flow, projection)

            var plannedAmount = values.plannedAmount;
            var actualAmount = values.actualAmount;
            var runningAmount = values.adjustmentAmount;
            var total = values.total;

            var actualAmountLabel;
            if (projection.actualization) {
                actualAmountLabel = util.formatAmount(actualAmount, item.currency)
            } else {
                actualAmountLabel = "";
            }

            var SAME = "same", OVER = "over", UNDER = "under";

            function compare(expected: number, actual: number) {
                if (expected == actual) {
                    return SAME
                } else if (expected < 0) {
                    return (actual < expected) ? OVER : UNDER
                } else {
                    return (actual > expected) ? OVER : UNDER
                }
            }

            var comparison = compare(plannedAmount, actualAmount)

            var label;
            if (comparison == SAME) {
                label = "Identical"
            } else if (comparison == OVER) {
                label = "Over"
            } else {
                label = "Under"
            }

            var differenceToActual;
            if (projection.actualization) {
                var difference = Math.abs(plannedAmount - actualAmount)
                if (difference === 0) {
                    differenceToActual = label;
                } else {
                    differenceToActual = util.formatAmount(difference, item.currency) + " " + label + ' (' + actualAmountLabel + ')';
                }
            } else {
                differenceToActual = "";
            }

            var actualToPlanLabel = <span className={'difference-' + comparison.toLowerCase()}>{differenceToActual}</span>

            var statusLabel = projection.actualization ? "Open" : "Planned"

            function doActualize(projection: PlannedFundMove) {
                dialogs.doActualize(projection, ()=>{
                    blockUI()
                    markDirtyAndReload()
                })
            }

            var actions: Record<string, () => void> = {}

            if (projection.actualization) {
                actions["close"] = function () {
                    console.log("Close was clicked on ", projection)
                    blockUI()
                    closePlannedItem(projection.id, markDirtyAndReload)
                }
            } else {
                actions["actualize"] = () => doActualize(projection)
            }

            let actionLinks = _.map(actions, (action, name) => {
                let doAction = () => {
                    console.log("Need to do action ", name, action)
                    action()
                }
                return <span key={`action-${name}`} className="action-link" onClick={doAction}>{name}</span>
            })

            var otherAccountId = isFlowOut(flow) ? flow.toFundId : flow.fromFundId;
            var thisAccountId = isFlowOut(flow) ? flow.fromFundId : flow.toFundId;

            //markDirtyAndReload
            const labelLink = <DialogsContext.Consumer>{dialogs =>
                <Link color={theme.palette.text.primary} onClick={e => item.plannedItemId && dialogs.showFlowById(item.plannedItemId.sequenceId, markDirtyAndReload)}>{item.memo}</Link>
            }</DialogsContext.Consumer>



            const sourceCell: ReactNode[] = showSourceAccountsColumn ? [<div>{accountName(thisAccountId)}</div>] : []
            const a: ReactNode[] = [
                formattedWhen,
                statusLabel,
                labelLink,
            ]
            const b: ReactNode[] = [
                accountName(otherAccountId),
                util.formattedColorAmount(plannedAmount, item.currency),
                util.formattedColorAmount(runningAmount, item.currency),
                util.formattedColorAmount(total, item.currency),
                actualToPlanLabel,
                actionLinks
            ]
            return a.concat(sourceCell).concat(b)
        });
    });


    function makeBalancesRow(balanceMap: Record<string, number>) {
        return _.map(balanceMap, function (qty, currency) {
            console.log("considering ", currency, qty)
            return [
                "", "", "", "", "",
                "Total " + currency,
                <span key="whatever">{util.formattedColorAmount(qty, currency)}</span>,
                ""
            ]
        });
    }
    let topBalanceRow: TableRowData[] = makeBalancesRow(overallBalances)
    let bottomBalanceRow: TableRowData = makeBalancesRow(totalsAccumulator.balances())

    let dataRows: TableRowData[] = _.flatten(dataRowSets, true)

    console.log("dataRows are ", dataRows)



    let allRows: TableRowData[] = topBalanceRow.concat(dataRows).concat([bottomBalanceRow])

    return <Table
        header={header}
        rows={allRows} />
}