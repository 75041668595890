import { Button, ButtonProps, CircularProgress } from "@mui/material"
import { useState } from "react"

export default (props:ButtonProps & {clickAction:()=>Promise<any>}) => {
    const {clickAction} = props
    const [isBusy, setBusy] = useState(false)

    const doClick:React.MouseEventHandler<HTMLButtonElement> = (e)=>{
        console.log("Doing action for button ", props.children)
        props.onClick && props.onClick(e)
        setBusy(true)
        clickAction().finally(()=>setBusy(false))
    }

    return (<>
        {isBusy ? <CircularProgress/> : <Button {... props} onClick={doClick}/>}
    </>)
}
