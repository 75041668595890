import React from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css" 
import { Quarter } from './api-actions'

interface QuarterSelectProps{
    value:Quarter
    disabled?:boolean|undefined
    onChange:(v:Quarter)=>void
}
export default class QuarterSelect extends React.Component<QuarterSelectProps, {}> {
    constructor(props:QuarterSelectProps) {
        super(props);
        console.log("QuarterSelect props are ", props)
    }

    render(){
        let {value} = this.props

        let disabled = (typeof this.props.disabled == "undefined" ? false : this.props.disabled)

        
        let handleChange = (date:Date)=>{
            console.log("user said ", date)
            let month = date.getMonth() + 1
            let quarter;
            if(month>=1 && month<4) quarter = 1
            if(month>=4 && month<7) quarter = 2
            if(month>=7 && month<10) quarter = 3
            if(month>=10 && month<=12) quarter = 4
            
            console.log("Month", date.getMonth())
            console.log("Date", date.getDate())
            if(quarter){
                let q = date.getFullYear() + "-" + quarter
                let selection:Quarter = {
                    year:date.getFullYear(),
                    quarter:quarter
                }
                console.log("quarter", selection)
    
                this.props.onChange(selection)
            }
            
        }

        let str = value.year + "/" + (((value.quarter-1)*3) + 1) + "/1"
        console.log("Value is ", str)
        return <div className="quarter-select">
            <DatePicker
                selected={new Date(str)}
                onChange={handleChange}
                dateFormat="yyyy, QQQ"
                showQuarterYearPicker/>
        </div>
    }
}


