import React, { useEffect, useState } from 'react'
import http from './cj/HttpClient'
import { distinct, formattedTotals, getTenantId } from './util'
import { AccountInfo, ContextualizedPendingTransfer, ItemInfo, PendingTransfer, PendingTransferDto, PhysicalAccountDto, PhysicalAccountInfoDto, Statement } from './api'
import { Alert, Autocomplete, Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { balanceAccumulator } from './PhysicalBalanceAccumulator'
import TransactionsList, { TransactionsListColumn } from './TransactionsList'
import StatementStatsPrintout from './StatementStatsPrintout'
import { getPendingTransfers } from './api-actions'

interface StatementScreenProps {
    statementId: string,
    source: PhysicalAccountDto,
    items: ItemInfo[],
    accounts: AccountInfo[],
    sources: PhysicalAccountInfoDto[],
    onComplete: () => void
    selections?: string[],
    setSelections?: (selections: string[]) => void
    handleUpdate: () => void
}

export default (props: StatementScreenProps) => {
    const { statementId, source, items, accounts, sources, onComplete, handleUpdate: propogateUpdate } = props
    const [statement, setStatement] = React.useState<Statement>()
    const [error, setError] = React.useState<string>()
    const [isSaving, setSaving] = React.useState(false)
    const [pendingTransfers, setPendingTransfers] = useState<ContextualizedPendingTransfer[]>()

    useEffect(()=>getPendingTransfers(source.id, setPendingTransfers), [])

    const selections = statement?.itemIds ?? []

    const itemIdsWithCategory = buildCompleteCategoryMap(statement?.itemIds ?? [], items, statement?.itemIdsWithCategory)
    const categories = distinct(Object.values(itemIdsWithCategory))

    React.useEffect(() => {
        http({
            url: `/api/tenants/${getTenantId()}/statements/${statementId}`,
            method: "GET",
            options: { async: false },
            onResponse: function onResponse(response) {
                if (response.status !== 200) {
                    setError(`${response.status}:\n${response.body}`)
                } else {
                    setStatement(JSON.parse(response.body))
                }
            }
        });
    }, [])

    const handleUpdate = (statement: Statement) => {
        console.log("Transient state is now ", JSON.stringify(statement))
        setStatement(statement)
    }
    const doUpdate = (whenDone?: () => void) => {
        if (statement) {
            setSaving(true)
            http({
                url: `/api/tenants/${getTenantId()}/statements/${statementId}`,
                method: "POST",
                data: JSON.stringify(statement, null, 4),
                options: { async: true },
                onResponse: function onResponse(response) {
                    setSaving(false)
                    if (response.status !== 200) {
                        setError(`${response.status}:\n${response.body}`)
                    } else {
                        whenDone && whenDone()
                    }
                }
            });
        }
    }

    const apply = () => doUpdate()
    const save = () => doUpdate(onComplete)

    const handleNameChange = (name: string) => {
        if (statement) {
            handleUpdate({
                ...statement,
                name: name
            })
        }
    }

    const handleNotesChange = (notes: string) => {
        if (statement) {
            handleUpdate({
                ...statement,
                notes: notes
            })
        }
    }


    const setSelections = (itemIds: string[]) => {
        if (statement) {
            handleUpdate({
                ...statement,
                itemIds: itemIds,
                itemIdsWithCategory: buildCompleteCategoryMap(itemIds, items, itemIdsWithCategory)
            })
        }
    }


    const setSelectionCategory = (itemId: string, category: string) => {
        if (statement) {
            console.log("Item", itemId, "given category", category)
            handleUpdate({
                ...statement,
                itemIdsWithCategory: {
                    ...itemIdsWithCategory,
                    [itemId]: category
                }
            })
        }
    }

    if (!error && !statement) {
        return <CircularProgress />
    } else {
        const columns: TransactionsListColumn[] = [
            {
                name: "",
                control: (e, selected) => {
                    if (!selected) {
                        return <></>
                    } else {
                        const itemId = e.item.id
                        const category = categoryForItem(e, itemIdsWithCategory)

                        return <Autocomplete
                            disablePortal
                            freeSolo
                            value={category}
                            options={categories}
                            onChange={(e, value) => setSelectionCategory(itemId, value ?? "unknown")}
                            sx={{ width: 300 }}
                            renderInput={(params) =>
                                <TextField  {...params}
                                    value={category}
                                    onChange={e => setSelectionCategory(itemId, e.target.value)}
                                    label="Category" />}
                        />
                    }

                }
            }
        ]

        const selectedItems = selections.map(id => {
            let item = items.find(i => i.item.id == id)
            if (!item) {
                throw `Can't find ${id}`
            }
            return item!!
        })

        const selectedAmounts = balanceAccumulator().addAll(selectedItems)
        const selectedPositiveAmounts = balanceAccumulator().addAll(selectedItems.filter(i => i.item.amount > 0))
        const selectedNegativeAmounts = balanceAccumulator().addAll(selectedItems.filter(i => i.item.amount < 0))

        const totalsByCategory: Record<string, Record<string, number>> = {}
        const countsByCategory: Record<string, Record<string, number>> = {}

        categories.forEach(category => {
            const itemsInCategory = selectedItems.filter(i => itemIdsWithCategory[i.item.id] == category)
            const balances = balanceAccumulator().addAll(itemsInCategory)
            totalsByCategory[category] = balances.totals()
            countsByCategory[category] = balances.counts()
        })


        return <>
            {error && <Alert color="error">{error}</Alert>}
            {(!statement || !pendingTransfers) ? <CircularProgress/> : <Stack spacing={3}>
                <Stack direction="row-reverse" spacing={3} style={{ background: "white", position: "sticky", top: "0px", zIndex: "100" }}>
                    <Box><Button variant="contained" disabled={isSaving} onClick={save}>Save</Button></Box>
                    <Box><Button variant="contained" disabled={isSaving} onClick={apply}>Apply</Button></Box>
                    <Box><Button variant="outlined" disabled={isSaving} onClick={onComplete}>Cancel</Button></Box>
                    <div style={{ padding: "5px", marginRight: "20px" }}>
                        {selections.length} Selections
                    </div>
                    <Stack direction="row" spacing={3}>
                        {formattedTotals(selectedAmounts.totals())}
                        {formattedTotals(selectedPositiveAmounts.totals())}
                        {formattedTotals(selectedNegativeAmounts.totals())}
                        <StatementStatsPrintout stats={{
                            totals: selectedAmounts.totals(),
                            totalsIn: selectedPositiveAmounts.totals(),
                            totalsOut: selectedNegativeAmounts.totals(),
                            totalsByCategory: totalsByCategory,
                            countsByCategory: countsByCategory,
                        }} />
                    </Stack>
                    {isSaving && <CircularProgress />}
                </Stack>

                <TextField
                    label="Statement Name"
                    value={statement.name}
                    onChange={e => handleNameChange(e.target.value)} />

                <TextField
                    label="Notes"
                    value={statement.notes ?? ""}
                    multiline={true}
                    onChange={e => handleNotesChange(e.target.value)} />

                <TransactionsList
                    source={source}
                    isSelectable={(i, s) => {
                        const otherMappings = s.find(s => s.id != statement.id)
                        return otherMappings ? false : true
                    }}
                    pending={pendingTransfers}
                    extraColumns={columns}
                    totals={balanceAccumulator().addAll(items).totals()}
                    items={items}
                    accounts={accounts}
                    sources={sources}
                    selections={selections}
                    setSelections={setSelections}
                    handleUpdate={propogateUpdate}
                />
            </Stack>}
        </>
    }

}


const categoryForItem = (e: ItemInfo, itemIdsWithCategory: Record<string, string> | undefined): string => (itemIdsWithCategory ?? {})[e.item.id] ?? "uncategorized"
const buildCompleteCategoryMap = (itemIds: string[], items: ItemInfo[], foo: Record<string, string> | undefined): Record<string, string> => {

    const existingCategories = (foo ?? {})


    const itemIdsWithCategory: Record<string, string> = {}

    itemIds.forEach(itemId => {
        const item = items.find(i => i.item.id == itemId)
        if (item) {
            itemIdsWithCategory[itemId] = categoryForItem(item, existingCategories)
        }
    })

    return itemIdsWithCategory
}
