import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material"
import { AccountInfo, Flow, FullMoveDetails, FullPlannedMove, FundMove, FundMoveToPhysicalItemMapping, MappingOption, PhysicalItem, PhysicalItemHandle, PlannedItemHandle } from "./api"
import React, { useEffect, useState } from "react"
import http from './cj/HttpClient'
import util, { describeFullPlannedMoveState, describeState, formattedColorCurrencyAmount, getTenantId, initialCap, take } from "./util"
import Table from "./Table2"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import PhysicalItemsPanel from "./PhysicalItemsPanel"
import { DialogsContext } from "./Dialogs"
import data from "./data"
import MoveDetailsPanel from "./MoveDetailsPanel"
import { PlannedMovePanel, moveUrl, plannedMoveUrl, toMappingOption } from "./MovePage"
import { Link, useNavigate } from "react-router-dom"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { deletePlanItem, getFullPlannedMove } from "./api-actions"

export const AccountingEntryPanel = (props:{option: MappingOption, hidePlanned?:boolean})=>{
    const { option, hidePlanned } = props

    const [d, _] = React.useState(data())
    const getAccount = d.account
    const move = option.move ?? option.planned?.planned
    const plannedItemHandle = option.planned?.planned?.plannedItemId

    const reload = () => {
        window.location.reload()
    }

    const doDeletePlanItem = () => {

        if (plannedItemHandle) {
            var userConfirmedDelete = window.confirm("Are you sure you want to delete this?  This cannot be undone!");
            if (userConfirmedDelete) {
                deletePlanItem(plannedItemHandle)
                    .then(reload)
                    .catch(alert)
            }
        }
    }

    const currency = option?.move?.currency ?? option?.planned?.planned?.currency ?? "??"
    const totalMapped = option.move?.physicalMappings?.map(pm => pm.amount).reduce((a, b) => a + b, 0)

    return (<>
            <DialogsContext.Consumer>{dialogs=>
                    <Stack style={{}}>


                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <Stack spacing={3}>
                                    <Typography variant="h6">Actual<IconButton onClick={() => option.move && dialogs.showMove({
                                        value:option.move,
                                        onChange:reload
                                    })}><EditIcon /></IconButton></Typography>
                                    {option.move && <>
                                        <MoveDetailsPanel move={option.move} getAccount={getAccount} />
                                        {/* <Button variant="outlined" onClick={() => setShowEdit(true)}>Edit Flow</Button> */}
                                    </>}
                                </Stack>
                            </Grid>

                            {(!hidePlanned && plannedItemHandle) && <Grid item sm={6} xs={12}>
                                <Stack spacing={3}>
                                    <DialogsContext.Consumer>{dialogs =>
                                        <Typography variant="h6">Planned
                                            <IconButton onClick={() => plannedItemHandle && dialogs.showFlowById(plannedItemHandle?.sequenceId, () => { })}><EditIcon /></IconButton>
                                            <IconButton onClick={doDeletePlanItem}><DeleteIcon /></IconButton>
                                        </Typography>
                                    }</DialogsContext.Consumer>

                                    {option.planned?.planned && <MoveDetailsPanel move={option.planned?.planned} getAccount={getAccount} />}
                                </Stack>
                            </Grid>}

                            <Grid item xs={12}>
                                <Stack spacing={3}>
                                    <Typography variant="h6">Physical Mappings</Typography>
                                    {(!totalMapped || totalMapped == 0) ? <Typography>No Physical Mappings</Typography>: <>
                                        {totalMapped && formattedColorCurrencyAmount({currency:currency, amount:totalMapped})}
                                        {move && <PhysicalItemsPanel move={move} items={option.items} />}
                                    </>}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
            }</DialogsContext.Consumer>
    </>
    )
}


export const MappingOptionDialog = (props: { option: MappingOption, onClose: () => void }) => {
    const { option, onClose } = props

    const navigate = useNavigate()
    const linkUrl = (option.planned?.id) ? plannedMoveUrl(option.planned.id) : (option.move?.id) ? moveUrl(option.move.id) :  ""
    return (<>
        <Dialog
            open={true}
            onClose={onClose}
            fullScreen={true}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle><Link to={linkUrl}>{initialCap(describeState(option))} Flow</Link><IconButton onClick={()=>navigate(linkUrl)}><OpenInNewIcon/></IconButton><IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton></DialogTitle>
            <AccountingEntryPanel option={option}/>
        </Dialog>
    </>
    )
}


export const PlannedMovePanelDialog = (props: { move: FullPlannedMove, onClose: () => void }) => {
    const { move, onClose } = props

    const navigate = useNavigate()
    const linkUrl = plannedMoveUrl(move.planned.id)
    return (<>
        <Dialog
            open={true}
            onClose={onClose}
            fullScreen={true}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle><Link to={linkUrl}>{initialCap(describeFullPlannedMoveState(move))} Flow</Link><IconButton onClick={()=>navigate(linkUrl)}><OpenInNewIcon/></IconButton><IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton></DialogTitle>
            <DialogContent>
                <PlannedMovePanel move={move}/>
            </DialogContent>
        </Dialog>
    </>
    )
}




export const PlannedItemDialog = (props:{handle:PlannedItemHandle, onClose: () => void})=>{
    const {handle, onClose} = props
    const [move, setMove] = useState<FullPlannedMove>()

    useEffect(()=>{
        getFullPlannedMove(handle).then(setMove)
    }, [handle])
    
    return (<>

        {JSON.stringify(handle)}
        {move && <PlannedMovePanelDialog move={move} onClose={onClose}/>}
    </>)
}

export default MappingOptionDialog