import React, { ReactNode } from 'react'
import { Tab, Tabs } from '@mui/material'

export default (props: { onChange: (name: string) => void, tabs: string[], activeTab: string, tabContent: ReactNode | string | undefined }) => {

    let { tabs, activeTab, tabContent, onChange } = props

    let names = tabs
    const activeIdx: number = names.indexOf(activeTab) ?? 0

    return <div className="tabs-ng">
        <Tabs value={activeIdx} onChange={(e, v) => onChange(names[v])} aria-label="basic tabs example">
            {names.map(name => <Tab key={name} label={name} />)}
        </Tabs>
        <div className="tab-content">
            {tabContent}
        </div>
    </div>
}
