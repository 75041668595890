import { useContext, useEffect, useState } from 'react'
import _ from 'underscore'
import util, { describeSchedule, getTenantId } from './util'
import Table, { TableRowData } from './TableNG'
import { AccountInfo, Flow, } from './api'
import { Breadcrumbs, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import { getAccountsPromise, getPlanItem } from './api-actions'
import { DialogsContext } from './Dialogs'
import EditIcon from '@mui/icons-material/Edit';
import { Link, useParams } from 'react-router-dom'
import MarkdownContent from './MarkdownContent'
import { urlForPlanConfigPage } from './PlanConfigScreen'


export const urlForPlanElementPage = (planItemId:string) => `/app/${getTenantId()}/plan/plan/${planItemId}`

export default ()=>{
    const {planItemId} = useParams()
    
    const [flow, setFlow] = useState<Flow>()
    const [accounts, setAccounts] = useState<AccountInfo[]>()

    const refresh = ()=>{
        getPlanItem(planItemId!!, setFlow)
        getAccountsPromise().then(setAccounts)
    }

    useEffect(refresh, [])

    const getAccount = (id:string) => accounts?.find(a=>a.id == id)


    const toFrom = flow && getAccount(flow.toFromAccount)

    const otherSide = flow?.accountOrTemplate ? getAccount(flow?.accountOrTemplate) : undefined

    const [amount, fromAccount, toAccount] = (flow && flow.amount < 0) ? [flow.amount * -1, otherSide, toFrom] : [flow?.amount, toFrom, otherSide]

    const templateRows:TableRowData[] = flow?.template ? Object.keys(flow.template.split).map(accountId=>{
        
        const currency = flow?.currency
        const amt = flow.template?.split[accountId]

        return [(amt && currency) ? util.formattedColorAmount(amt, currency) : "??", toFrom?.name, <div>{getAccount(accountId)?.name}</div>]
    }) : []

    const moreRows:TableRowData[] = otherSide ? [
        [(flow) ? util.formattedColorAmount(flow.amount, flow.currency) : "??", fromAccount?.name, toAccount?.name]
    ] :[]

    const rows = moreRows.concat(templateRows)

    const dialogs = useContext(DialogsContext)
    

    const doEdit = ()=>{
        flow && dialogs.showFlow({
            value:flow,
            onChange:refresh
        })
    }

    return (
        
        <Stack spacing={2} style={{marginTop:"20px"}}>
            <Breadcrumbs>
                <Link to={urlForPlanConfigPage()}>All Plan Elements</Link>
                <Typography>{planItemId}</Typography>
            </Breadcrumbs>
            <Typography variant="h5">Plan Element <IconButton style={{float:"right"}} onClick={doEdit}><EditIcon/></IconButton></Typography>
            {!(flow && accounts) && <CircularProgress/>}
            {flow && <Stack spacing={2}>
                <Typography variant="body1">Name: {flow.name}</Typography>
                <Typography variant="body1">When: {describeSchedule(flow)}</Typography>
                <Typography variant="body1">Total: { amount && util.formattedColorAmount(amount, flow.currency)}</Typography>
                
                <Typography variant="body1">Flows</Typography>
                <Table
                    header={["Amount", "From", "To"]}
                    rows={rows}
                />

                <Typography variant="body1">Notes:</Typography>
                <Typography variant="body2"><MarkdownContent>{flow.notes}</MarkdownContent></Typography>
            </Stack>}
        </Stack>
    )
}
