import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MonthDate, PDate } from './api'
import moment, { Moment } from 'moment'

function toNumber(v: string) {
    return parseInt(v, 10)
}

// Keep the momentjs stuff as an implementation detail
function parseMomentDate(m: Moment): PDate | undefined {
    if (m.isValid()) {
        return { year: m.year(), month: m.month() + 1, day: m.date() }
    }
}

export function dateToMomentInCurrentTimeZone(d: PDate): Moment {
    const m = moment()

    m.year(d.year)
    m.month(d.month - 1)
    m.date(d.day)
    m.hour(0)
    m.minute(0)
    m.second(0)
    m.millisecond(0)
    console.log(d)
    console.log(`Moment created from ${dateToString(d)}: ${m.toISOString()}/${m.milliseconds}`)
    return m
}

export function today(): PDate {
    return parseMomentDate(moment())!!
}
export function dateAtMillisInCurrentTimeZone(timestamp: number): PDate {
    return parseMomentDate(moment(timestamp))!!
}

export function monthDayToString(date: MonthDate) {
    return date.month + "-" + date.day;
}
export function parseMonthDay(text: string): MonthDate {
    var parts = text.split("-").map(toNumber)
    return { month: parts[0], day: parts[1] }
}

export function parseDate(text: string): PDate {
    var parts = text.split("-").map(toNumber)
    return { year: parts[0], month: parts[1], day: parts[2] }
}

export function dateToString(date: PDate) {
    return date.year + "-" + date.month + "-" + date.day;
}

export const now = (): number => new Date().getTime()

export const PDatePicker = (props: { value: PDate | undefined, disabled?: boolean, label?: string, onChange: (v: PDate | undefined) => void }) => {
    const { value, disabled, label, onChange } = props
    const handleUpdate = (m: Moment | undefined) => {

        const d = m && parseMomentDate(m)
        console.log("Moment changed: ", m?.toISOString(), d, m)
        onChange(d)
    }
    console.log("Moment showing", value)
    const momentValue = value && dateToMomentInCurrentTimeZone(value)
    console.log("Moment showing: ", value, momentValue)

    return (
        <DatePicker
            label={label}
            disabled={disabled}
            onChange={newValue => handleUpdate(newValue ? newValue as Moment : undefined)}
            value={momentValue} />
    )
}



export interface YearMonthDay {
    year: number
    month: number
    day: number
}
export const ymd = (m: Moment): YearMonthDay => {
    return {
        year: m.year(),
        month: m.month() + 1,
        day: m.date(),
    }
}
export const sameYMD = (m: YearMonthDay, m2: YearMonthDay): boolean => {
    const result = m.year == m2.year && m.month == m2.month && m.day == m2.day

    // console.log("sameYMD? ", result, m, m2)

    return result
}

export const sameDay = (m: Moment, m2: Moment) => sameYMD(ymd(m), ymd(m2))
export interface Quarter {
    label: string
    start: Moment
    end: Moment
}
export const calcQuarters = (m: Moment): Quarter[] => {
    const startOfYear = moment(m).startOf('year');
    return [
        {
            label: "Q1",
            start: moment(startOfYear),
            end: moment(startOfYear).add(3, "month"),
        },
        {
            label: "Q2",
            start: moment(startOfYear).add(3, "month"),
            end: moment(startOfYear).add(6, "month"),
        },
        {
            label: "Q3",
            start: moment(startOfYear).add(6, "month"),
            end: moment(startOfYear).add(9, "month"),
        },
        {
            label: "Q4",
            start: moment(startOfYear).add(9, "month"),
            end: moment(startOfYear).add(12, "month"),
        },
    ]
}


export function formatDate(date: PDate) {
    function pad2(i: number) {
        var str = i.toString()
        if (str.length < 2) {
            return "0" + str
        } else {
            return str
        }
    }
    return pad2(date.year) + "-" + pad2(date.month) + "-" + pad2(date.day);
}


export function formatDateIfDefined(timestamp: PDate) {
    return timestamp ? formatDate(timestamp) : ""
}
