import React from 'react'
import _ from 'underscore'
import http, { HttpMethodName } from './cj/HttpClient'
import { formattedTotals, getTenantId, isDebt } from './util'
import PhysicalAccountTransactions from './PhysicalAccountTransactions'
import { AccountInfo, PhysicalAccountDto, PhysicalAccountInfoDto } from './api'
import { Box, Button, CircularProgress, Stack, } from '@mui/material'
import PhysicalAccountDialog from './PhysicalAccountDialog'
import { balanceAccumulator } from './PhysicalBalanceAccumulator'
import Table from './TableNG'
import { getAllPhysical } from './api-actions'
import FloatingActionBar from './FloatingActionBar'



interface AccountDialogSession {
    existing: PhysicalAccountInfoDto | undefined
}
export default (props: {}) => {
    const [isLoading, setLoading] = React.useState(true)
    const [isReloading, setReloading] = React.useState(false)
    const [sources, setSources] = React.useState<PhysicalAccountDto[]>([])
    const [accounts, setAccounts] = React.useState<AccountInfo[]>()
    const [accountBeingEdited, setAccountBeingEdited] = React.useState<AccountDialogSession>()

    const startLoad = () => {
        getAllPhysical(
            (sources)=>{
                http({
                    url: `/api/tenants/${getTenantId()}/accounts`,
                    method: "GET",
                    onResponse: (response) => {
                        if (response.status === 200) {
                            setAccounts(JSON.parse(response.body))
                            setSources(sources)
                        } else {
                            alert("Error loading!");
                        }
                        setLoading(false)
                        setReloading(false)
                    }
                });
            }
        )
    }
    const reload = () => {
        setReloading(true)
        startLoad()
    }
    const loadStuff = () => {
        setLoading(true)
        startLoad()
    }

    React.useEffect(loadStuff, [])

    function newPhysicalAccountClicked() {
        setAccountBeingEdited({
            existing: undefined
        })
    }

    if (isLoading) {
        return <div style={{ textAlign: 'center' }}><img style={{ marginTop: '40%' }} className="throbber" src="/loader.gif"></img></div>
    } else {

        let totals = balanceAccumulator()
        let debtBalances = balanceAccumulator()
        let assetBalances = balanceAccumulator()

        sources.forEach(source => {
            const items = source.items
            if (isDebt(source)) {
                debtBalances = debtBalances.addAll(items)
            } else {
                assetBalances = assetBalances.addAll(items)
            }
            totals = totals.addAll(items)
        })

        var physicalAccountWidgets = _.map(_.sortBy(sources, "name"), function (source, sourceIdx) {
            return <div key={sourceIdx}>
                <PhysicalAccountTransactions source={source} accounts={accounts ?? []} sources={sources} onChange={reload} />
            </div>
        });
        return <div>
            <FloatingActionBar 
                topOffset="40px"
                onRefresh={reload} 
                isRefreshing={isReloading}
                />
            <Table
                header={["class", "balances"]}
                rows={[
                    ["Net", formattedTotals(totals.totals())],
                    ["Cash", formattedTotals(assetBalances.totals())],
                    ["Debt", formattedTotals(debtBalances.totals())],
                ]} />
            {isReloading && <Box style={{ position: "sticky", top: "0px", zIndex: "200", margin: "20px", background: "white" }}><CircularProgress /></Box>}
            {accountBeingEdited && <PhysicalAccountDialog existing={accountBeingEdited.existing} onComplete={(change) => { setAccountBeingEdited(undefined); change && reload() }} />}
            <Stack direction="row">
                
            </Stack>
            {physicalAccountWidgets}
        </div>
    }
}