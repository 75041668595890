import { Foo } from "./FlowsMapper"
import { AccountInfo, FundMove, PhysicalItem, PlannedFundMove } from "./api"

export interface MoveSelection {
    planned: PlannedFundMove | undefined
    move: FundMove | undefined
}

export default (props: { foreignAccountId: string | undefined, item: PhysicalItem, accounts: AccountInfo[], onUnexpectedSelected: () => void, onSelection: (m: MoveSelection) => void }) => {

    return <Foo
        foreignAccountId={props.foreignAccountId ?? "generic-income-expense"}
        item={props.item}
        accounts={props.accounts}
        showUnexpectedOption={true}
        onUnexpectedSelected={props.onUnexpectedSelected}
        onMappingsChange={option => props.onSelection({
            planned: option.planned,
            move: option.move
        })}
    />
}
