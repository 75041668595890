import http from './cj/HttpClient'
import { getTenantId } from './util';
import { Dialog, DialogContent, DialogTitle, MenuItem, TextField, IconButton, Stack, Button, Typography } from '@mui/material';
import { useState } from 'react';
import LabeledSelect from './LabeledSelect';
import CloseIcon from '@mui/icons-material/Close';

type AccountKind = "Account" | "Alien" | "Stub"

export const Editor = function (props: { onComplete: () => void }) {

	const [name, setName] = useState("")
	const [category, setCategory] = useState("")
	const [purpose, setPurpose] = useState("")
	const [kind, setKind] = useState<AccountKind>("Account")

	const foo = {
		category: category,
		name: name,
		purpose: purpose,
		kind: kind
	}

	console.log("Foo", foo)

	const onClick = function () {

		http({
			url: `/api/tenants/${getTenantId()}/accounts`,
			method: "POST",
			data: JSON.stringify(foo),
			onResponse: function (response) {
				if (response.status == 201) {
					window.location.reload();
				} else {
					alert("Error!  Server said:" + response.body);
				}
			}
		});
	}

	const canSubmit = [name, kind].every(value => {
		return value.trim().length > 0
	})

	return (<>
		<Stack spacing={3} style={{marginTop:"10px"}}>
			<TextField label="Name" value={name} onChange={e => setName(e.target.value)}></TextField>
			<TextField label="purpose" value={purpose} onChange={e => setPurpose(e.target.value)}></TextField>
			<TextField label="category" value={category} onChange={e => setCategory(e.target.value)}></TextField>
			<LabeledSelect labelId="kind" label="kind" value={kind} onChange={(e) => setKind(e.target.value as AccountKind)}>
				<MenuItem value="Account">Account</MenuItem>
				<MenuItem value="Alien">Alien</MenuItem>
				<MenuItem value="Stub">Stub</MenuItem>
			</LabeledSelect>
			<Button disabled={!canSubmit} variant="contained" onClick={onClick}>Create</Button>
		</Stack>
	</>)
}


export default (props: { onClose?: () => void }) => {
	const { } = props

	const onClose = props.onClose ?? (() => { })
	return (
		<Dialog
			open={true}
			onClose={() => onClose()}
			// fullScreen={true}
			fullWidth={true}
			maxWidth={"md"}
		>
			<DialogTitle>Add Account<IconButton
				aria-label="close"
				onClick={() => onClose()}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			</DialogTitle>
			<DialogContent>
				<Editor onComplete={onClose} />
			</DialogContent>
		</Dialog>)
}