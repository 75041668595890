import React, { ReactNode, useEffect, useState } from 'react'
import util, { formattedTotals, getTenantId } from './util'
import { AccountInfo, ContextualizedPendingTransfer, PendingTransfer, PendingTransferDto, PhysicalAccountDto, PhysicalAccountInfoDto, PhysicalAccountItemsPatch } from './api'
import { Button, CircularProgress, IconButton } from '@mui/material'
import PhysicalAccountDialog from './PhysicalAccountDialog'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PhysicalTransactionDialog from './PhysicalTransactionDialog'
import TabsNg from './TabsNg'
import { balanceAccumulator } from './PhysicalBalanceAccumulator'
import TransactionsList from './TransactionsList'
import StatementsScreen from './StatementsScreen'
import UploadPanel from './UploadPanel'
import http from './cj/HttpClient'
import { Problem } from './ErrorAlert'
import ErrorDialog from './ErrorDialog'
import { getPendingTransfers } from './api-actions'
import { AccordionExpandToggle } from './UncategorizedScreenEntry'
import { Link } from 'react-router-dom'
import { urlForPhysicalAccount } from './PhysicalAccountPage'


export default (props: { showDetails?:boolean, source: PhysicalAccountDto, accounts: AccountInfo[], sources: PhysicalAccountInfoDto[], onChange: () => void }) => {
    let { source, sources, accounts, onChange } = props;

    const [detailsVisible, setdetailsVisible] = React.useState(props.showDetails ?? false)
    const [isEditing, setEditing] = React.useState(false)
    const [showPhysicalTransactionDialog, setshowPhysicalTransactionDialog] = React.useState(false)
    const [selections, setSelections] = React.useState<string[]>([])
    const [pendingTransfers, setPendingTransfers] = useState<ContextualizedPendingTransfer[]>()

    useEffect(()=>getPendingTransfers(source.id, setPendingTransfers), [])

    // console.log("Sorting ", source.items.length, "items in", source.name)
    var items = source.items.sort((a, b) => a.item.whenPosted - b.item.whenPosted);;


    const totalAmounts = balanceAccumulator().addAll(items)
    var totals: Record<string, number> = totalAmounts.totals()
    var totalsText = formattedTotals(totals)


    const selectedItems = selections.map(id => {
        let item = items.find(i => i.item.id == id)
        if (!item) {
            throw `Can't find ${id}`
        }
        return item!!
    }
    )
    const selectedAmounts = balanceAccumulator().addAll(selectedItems)

    const [tab, setTab] = React.useState("Transactions")
    const tabContent: Record<string, ReactNode> = {
        "Transactions": <>
            {!pendingTransfers && <CircularProgress/>}
            {pendingTransfers && <TransactionsList
                source={source}
                totals={totals}
                pending={pendingTransfers}
                items={items}
                accounts={accounts}
                sources={sources}
                selections={selections}
                setSelections={setSelections}
                handleUpdate={onChange}
            />}
        </>,
        "Statements": <>
            <StatementsScreen
                source={source}
                items={items}
                accounts={accounts}
                sources={sources}
                handleUpdate={onChange} />
        </>
    }

    const [problem, setProblem] = React.useState<Problem>()

    const deleteSelections = () => {
        const patch: PhysicalAccountItemsPatch = {
            itemIdsToDelete: selections
        }
        if (window.confirm("Do you really want to delete these " + patch.itemIdsToDelete.length + " items?")) {
            http({
                url: `/api/tenants/${getTenantId()}/physicalAccounts/` + source.id + "/items",
                data: JSON.stringify(patch),
                method: "PATCH",
                onResponse: function onResponse(response) {
                    if (response.status !== 200) {
                        console.log(response)
                        setProblem({
                            description: "There was an " + response.status + " error ",
                            details: response.body
                        })
                    } else {
                        setSelections([])
                        onChange()
                    }
                }
            });
        }

    }

    const [uploadVisible, setShowUpload] = React.useState(false)
    return (<>
        {problem && <ErrorDialog problem={problem} onClose={() => setProblem(undefined)} />}
        {showPhysicalTransactionDialog && <PhysicalTransactionDialog sourceId={source.id} onComplete={() => { setshowPhysicalTransactionDialog(false); onChange() }} />}
        {isEditing && <PhysicalAccountDialog existing={source} onComplete={(a) => { setEditing(false); a && onChange() }} />}

        <h1 style={{
            position: "sticky",
            top: "0px",
            background: "white",
            zIndex: 100
        }}>
            <span style={{ cursor: "pointer" }}> <Link to={urlForPhysicalAccount(source.id)}>{source.name}</Link></span>
            <span style={{ float: "right" }}>
                {totalsText}
                {!props.showDetails && <AccordionExpandToggle isShowing={detailsVisible} onToggle={visible=>setdetailsVisible(visible)}/>}
            </span>
            {selections.length > 0 && <span style={{ float: "right", marginRight: "20px" }}>
                <Button onClick={deleteSelections}>delete</Button>
                ({selections.length} Selections {util.formattedTotals(selectedAmounts.totals())})
            </span>}
            <IconButton onClick={e => { e.preventDefault(); setEditing(true) }}><EditIcon /></IconButton>
            <IconButton onClick={e => { e.preventDefault(); setshowPhysicalTransactionDialog(true) }}><AddIcon /></IconButton>
            <IconButton onClick={e => { e.preventDefault(); setShowUpload(true); setdetailsVisible(true); }}><UploadFileIcon /></IconButton>
        </h1>
        <div>
            {detailsVisible ? <>
                {uploadVisible ? <UploadPanel source={source} onUploadCompleted={onChange} /> : ""}
                <TabsNg
                    tabs={["Transactions", "Statements"]}
                    activeTab={tab}
                    tabContent={
                        tabContent[tab]
                    }
                    onChange={setTab} />
            </> : ""}
        </div>
    </>)

}
