import React, { ReactNode, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Link, Stack } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

interface RenderedTab {
    name: string
    content: ReactNode
}
const RoutedTabsInner = (props: { prefix: string, propertyName: string, tabs: Record<string, () => ReactNode> }) => {
    const { prefix, propertyName, tabs } = props
    const navigate = useNavigate()
    const params = useParams()
    const tabNames = Object.keys(tabs)
    const activeTab = params[propertyName] ?? tabNames[0]
    const [renderedTab, setRenderedTab] = useState<RenderedTab>()
    const tabIdx = tabNames.indexOf(activeTab)

    if (activeTab != renderedTab?.name) {
        const tabCreator = tabs[activeTab]
        console.log("RoutedTabsInner: tabCreator", tabCreator)
        setRenderedTab({
            name: activeTab,
            content: tabCreator ? tabCreator() : <></>
        })
    }

    let doTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const newTab = tabNames[newValue]
        navigate(`${prefix}${newTab}`)
    }

    console.log("RoutedTabsInner: propertyName", propertyName, "params", params)
    console.log("RoutedTabsInner: activeTab", activeTab)
    console.log("RoutedTabsInner: tabIdx", tabIdx)

    console.log(propertyName, "Index of ", activeTab, "is", tabIdx, "in", tabNames, JSON.stringify(params))
    return (
        <Stack spacing={2}>
            <Tabs
                value={tabIdx}
                onChange={doTabChange}>
                {tabNames.map(name => <Tab key={name} label={name} />)}
            </Tabs>
            <Box style={{ margin: "15px" }}>
                {renderedTab?.content}
            </Box>
        </Stack>
    )
}

export default (props: { propertyName: string, tabs: Record<string, () => ReactNode> }) => {
    const { propertyName, tabs } = props
    return (
        <Routes >
            <Route index element={<RoutedTabsInner prefix="" {...props} />} />
            <Route path={`:${propertyName}/*`} element={<RoutedTabsInner prefix="../" {...props} />} />
        </Routes>
    )
}