import { ReactNode, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AccountInfo, ContextualizedPendingTransfer, ItemInfo, PhysicalAccountInfoDto, PhysicalItemHandle } from "./api"
import { formatDate, formatTimeIfNotMidnight, formattedColorCurrencyAmount, getTenantId } from "./util"
import { getAccountsPromise, getPendingTransfer, getPhysicalAccountInfosPromise, getPhysicalItem } from "./api-actions"
import { Box, Breadcrumbs, Button, CircularProgress, Stack, Typography } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { PhysicalItemDescriptionView } from "./UncategorizedScreenEntryCollapsedItemView"
import { AccountLink } from "./Links"
import MarkdownContent from "./MarkdownContent"
import PendingTransferPanel from "./PendingTransferPanel"
import { urlForAllPhysicalAccounts, urlForPhysicalAccount } from "./PhysicalAccountPage"
import { DialogsContext, DialogsState } from "./Dialogs"
import { ContextualPhysicalItem } from "./ItemEditorDialog"


export const physicalItemUrl = (handle: PhysicalItemHandle) => `/app/${getTenantId()}/external/accounts/${handle.accountId}/items/${handle.itemId}`

const InfoSection = (props: { name: string, children?: ReactNode }) => {
    const { name, children } = props
    return (<>
        <Grid xs={12} md={6} xl={4}>
            <Typography variant={"h6"}>{name}</Typography>
            <Stack spacing={1} style={{ marginLeft: "10px", marginTop: "20px" }}>
                {children}
            </Stack>
        </Grid>

    </>)
}

export default () => {
    const { accountId, itemId } = useParams()
    const [busy, setBusy] = useState(false)
    const [physicalItem, setPhysicalItem] = useState<ItemInfo>()
    const [accounts, setAccounts] = useState<AccountInfo[]>()
    const [physAccounts, setPhysAccounts] = useState<PhysicalAccountInfoDto[]>()
    const [pendingTransfer, setPendingTransfer] = useState<ContextualizedPendingTransfer>()


    const n = useNavigate()
    const handle: PhysicalItemHandle = {
        accountId: accountId!!,
        itemId: itemId!!,
    }

    const reload = ()=>{
        setBusy(true)
        getPhysicalItem(handle)
            .then(setPhysicalItem)
            .then(getAccountsPromise)
            .then(setAccounts)
            .then(getPhysicalAccountInfosPromise)
            .then(setPhysAccounts)
            .finally(() => setBusy(false))
    }

    useEffect(reload, [])

    const pendingTransferId = physicalItem?.item.pendingTransferId
    useEffect(() => {

        pendingTransferId && getPendingTransfer(pendingTransferId).then(setPendingTransfer)

    }, [pendingTransferId])

    const accountForId = (id: string) => accounts?.find(a => a.id == id)
    const physAccountForId = (id: string) => physAccounts?.find(a => a.id == id)

    const item = physicalItem?.item

    const breadCrumbs = <>
        <Breadcrumbs>
            <Link to={urlForAllPhysicalAccounts()}>External Accounts</Link>
            <Link to={urlForPhysicalAccount(accountId!!)}>{physAccountForId(accountId!!)?.name ?? accountId}</Link>
            <Typography color="text.primary">{item && item.id}</Typography>
        </Breadcrumbs>
    </>

    let handleMapItemClicked = (dialogs:DialogsState) => {
        if(accountId && physicalItem){
            const i:ContextualPhysicalItem = {foreignAccountId:accountId, item:physicalItem.item}
            dialogs.showItemMapper({value:i, onChange:reload})
        }
    }

    let details: ReactNode;
    if (!busy && physicalItem && item) {

        const timeLabel = formatTimeIfNotMidnight(item.whenPosted)

        details = <>

            <InfoSection name="Details">
                <Box>Account:  {physAccounts?.find(a => a.id == physicalItem.handle.accountId)?.name}</Box>
                <Box>Amount: {formattedColorCurrencyAmount({ amount: item.amount, currency: item.currency })}</Box>
                <Box>Date: {formatDate(item.whenPosted)}</Box>
                {timeLabel.length > 0 && <Box>Time: {timeLabel}</Box>}
            </InfoSection>

            <InfoSection name="Description">
                <PhysicalItemDescriptionView handle={physicalItem.handle} item={item} />
            </InfoSection>

            <InfoSection name="Mapping">
                {physicalItem.item.mappingMemo && <Box><MarkdownContent>{physicalItem.item.mappingMemo}</MarkdownContent></Box>}
                {!physicalItem.item.mappings && <>
                    <Typography>Not Mapped</Typography>
                    <DialogsContext.Consumer>{dialogs => 
                        <Button onClick={()=>handleMapItemClicked(dialogs)}>Map</Button>
                    }</DialogsContext.Consumer>
                </>}
                {physicalItem.item.mappings?.allocations?.map((allocation, idx) => {
                    const adjustment = physicalItem.item
                    var move = physicalItem.moves.find(move => move.id == allocation.fundMoveId)!!
                    let accountLinks = move.flows.map(flow =>
                        <>
                            <AccountLink account={accountForId(flow.fromFundId)} />
                            -&gt;
                            <AccountLink account={accountForId(flow.toFundId)} />
                        </>
                    );
                    return <span key={idx}>{move.memo}<br />{accountLinks}</span>
                })}
            </InfoSection>


            {physicalItem.item.pendingTransferId && <InfoSection name="Pending Transfer">
                <Typography>Pending Transfer {physicalItem.item.pendingTransferId}</Typography>
                {!(pendingTransfer && physAccounts) ? <CircularProgress /> : <>
                    <PendingTransferPanel contextualized={pendingTransfer} physAccounts={physAccounts} />
                </>}
            </InfoSection>}

        </>
    } else {
        details = <CircularProgress />
    }



    return (<>
        <Typography>
            <Grid container spacing={3} >
                <Grid xs={12} >
                    {breadCrumbs}
                </Grid>
                <Grid xs={12} >
                    <Typography variant={"h5"}>Physical Item</Typography>
                </Grid>
                {details}
            </Grid>
        </Typography>

    </>)
}