import React, { useState } from 'react'
import _ from 'underscore'
import util from './util'
import PlanChart from './PlanChart'
import { AccountInfo, BalancesByTick, BucketState, FundsFlow, PlannedFundMove } from './api'
import { CircularProgress, Dialog } from '@mui/material'
import PlannedItemsTable from './PlannedItemsTable'
import { fetchChartData, getAccountProjections } from './api-actions'
import AnalysisWindowControls, { AnalysisWindow, WindowOption, resolveWindowOption } from './AnalysisWindowControls'

export default (props: { account: AccountInfo, accounts: AccountInfo[], accountState: BucketState, lastUpdate: number, onUpdate: () => void }) => {
    const { account, accounts, accountState, lastUpdate, onUpdate } = props
    const [chartData, setChartData] = React.useState<BalancesByTick>()
    const [allProjections, setallProjections] = React.useState<PlannedFundMove[]>()
    const [analysisWindow, setAnalysisWindow] = useState<AnalysisWindow>(resolveWindowOption(WindowOption.NEXT_12_MONTHS))
    const [isLoading, setLoading] = React.useState(false) 
    const [uiIsBlocked, setBlockUI] = React.useState<string>()  // TEST

    const loadProjections = ():Promise<any> => {
        return getAccountProjections(account.id)
            .then(r=>{
                setallProjections(r)
            })
            .catch(alert)
    }

    const refresh = ():Promise<any> => {
        setLoading(true)
        return Promise.all([
            loadProjections(),
            fetchChartData({from:analysisWindow.from, to:analysisWindow.to, accountId:account.id}).then(setChartData)
        ])
            .then(()=>setLoading(false))
            .catch(alert)
    }

    React.useEffect(()=>{refresh()}, [lastUpdate, analysisWindow])

    let markDirtyAndReload = () => refresh().then(onUpdate)

    function isFlowInOrOut(flow: FundsFlow) {
        return flow.fromFundId == account.id || flow.toFundId == account.id;
    }

    function isFlowOut(flow: FundsFlow) {
        return (flow.fromFundId === account.id)
    }


    return (<div className="account-projections-screen">
        {uiIsBlocked && <Dialog open={true} fullScreen={true} />}
        <AnalysisWindowControls onChange={v=>{console.log("Window update", v); setAnalysisWindow(v)}} window={analysisWindow} doUpdate={refresh} isFetching={isLoading} />
        {isLoading &&<CircularProgress/>}
        <PlanChart
            focusAccountId={account.id}
            showDetails={true}
            data={chartData} />
        <PlannedItemsTable
            showSourceAccountsColumn={false}
            blockUI={()=>{}}
            markDirtyAndReload={markDirtyAndReload}
            overallBalances={accountState.balances}
            projections={allProjections ?? []}
            isFlowInOrOut={isFlowInOrOut}
            isFlowOut={isFlowOut}
            accounts={accounts} />
    </div>)

}