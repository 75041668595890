import React from 'react'
import http from './cj/HttpClient'
import Select from '@mui/material/Select';
import { getTenantId } from './util';
import { FormControl, InputLabel, MenuItem } from '@mui/material';

export default (props: { label?: string, value: string | undefined, onChange: (newValue: string) => void }) => {
    const { label, value, onChange } = props
    const [currencies, setCurrencies] = React.useState<string[]>()
    const [selection, setSelection] = React.useState(value)


    React.useEffect(() => {
        http({
            url: `/api/tenants/${getTenantId()}/currencies`,
            method: "GET",
            onResponse: (response) => {
                if (response.status === 200) {
                    setCurrencies(JSON.parse(response.body))
                } else {
                    alert("Error loading!");
                }
            }
        },
            { async: false });
    }, [])


    let handleSelect = (newValue: string) => {
        setSelection(newValue)
        onChange(newValue)
    }

    return (
        <FormControl fullWidth>
            {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
            <Select
                labelId="demo-simple-select-label"
                label={label}
                value={selection}
                onChange={e => handleSelect(e.target.value as string)}>
                {currencies?.map((currency) => <MenuItem value={currency}>{currency}</MenuItem>)}
            </Select>
        </FormControl>
    )
}


