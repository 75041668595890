
import { Route, Routes, useNavigate, useParams, Link } from "react-router-dom";
import { AccountInfo, Flow } from "./api";
import React from "react";
import { getPlanItem } from "./api-actions";
import { getTenantId } from "./util";

export const accountUrl = (accountId:string, tenantId?:string) => `/app/${tenantId || getTenantId()}/accounts/${accountId}`

export const AccountLink = (props: { account: AccountInfo | undefined, target?: string }) => {
    const { tenantId } = useParams()
    const { account, target } = props

    const targetProp = target && { target: target }
    if (account) {
        return (
            <Link to={accountUrl(account.id, tenantId)} {...targetProp}>{account.name}</Link>
        )
    } else {
        return <span>???</span>
    }
}

export const PlannedFlowLink = (props: { flow?: Flow | undefined, sequenceId?: string | undefined, target?: string }) => {
    const { tenantId } = useParams()
    const { sequenceId, target } = props
    const [flow, setFlow] = React.useState(props.flow)

    React.useEffect(() => {
        if (sequenceId && !flow) {
            getPlanItem(sequenceId, setFlow)
        }
    }, [])

    const targetProp = target && { target: target }
    if (flow) {
        return (
            <Link to={`/app/${tenantId}/accounts/${flow.accountOrTemplate}`} {...targetProp}>{flow.name}</Link>
        )
    } else {
        return <span>???</span>
    }
}