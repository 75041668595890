import { StatementStats } from "./api"
import Table, { TableRowData } from './TableNG'
import { formattedTotals, formattedTotalsAndCounts } from "./util"

export default (props: { stats: StatementStats }) => {
    const { stats } = props
    console.log("Printing stats from ", stats)
    return (<Table
        header={["", ""]}
        rows={Object.keys(stats.totalsByCategory).map(category =>
            [category, formattedTotalsAndCounts(stats.totalsByCategory[category], stats.countsByCategory[category])]
        ).concat([["Totals", formattedTotals(stats.totals)]])} />)
}



