import React from "react";

export default (callback: (isCancelled: () => boolean) => void, interval: number, immediate: boolean) => {
  const ref = React.useRef<(isCancelled: () => boolean) => void>();

  // keep reference to callback without restarting the interval
  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  React.useEffect(() => {
    // when this flag is set, closure is stale
    let cancelled = false;

    // wrap callback to pass isCancelled getter as an argument
    const fn = () => {
      const c = ref.current
      c && c(() => cancelled);
    };

    // set interval and run immediately if requested
    const id = setInterval(fn, interval);
    if (immediate) fn();

    // define cleanup logic that runs
    // when component is unmounting
    // or when or interval or immediate have changed
    return () => {
      cancelled = true;
      clearInterval(id);
    };
  }, [interval, immediate]);
};