import $ from 'jquery'
import _ from 'underscore'
import http from './cj/HttpClient'
import { getTenantId } from './util';
import { AccountInfo, TemplateInfoListItem } from './api';

export interface Data {
    accountsPromise: () => Promise<AccountInfo[]>,
    accounts: (maybeCallback?: ((v: AccountInfo[]) => void) | undefined) => AccountInfo[]
    account: (accountId: string) => AccountInfo | undefined
    templatesPromise: () => Promise<TemplateInfoListItem[]>
    templates: (maybeCallback?: ((v: TemplateInfoListItem[]) => void) | undefined) => TemplateInfoListItem[]
}


function getJson<T>(url: string, maybeCallback?: (data: T) => void): T {
    var result: T;

    http({
        url: url,
        method: "GET",
        onResponse: function (response) {
            if (response.status == 200) {
                result = JSON.parse(response.body)
                if (maybeCallback) {
                    maybeCallback(result)
                }
            } else {
                alert("Error!  Server said:" + response.body);
            }
        }
    },
        { async: (typeof maybeCallback !== "undefined") });
    return result!!;
}

export function data(): Data {

    var caches: Record<string, any> = {};

    function cachedOrGet<T>(resource: string, url: string): (maybeCallback?: (v: T) => void) => T {
        return function (maybeCallback?: (v: T) => void): T {
            var cached: T = caches[resource];
            if (cached) {
                if (maybeCallback) maybeCallback(cached)
                return cached;
            } else {
                if (maybeCallback) {
                    return getJson<T>(url, (data) => {
                        caches[resource] = data;
                        maybeCallback(data)
                    });
                } else {
                    var data: T = getJson(url);
                    caches[resource] = data;
                    return data;
                }
            }
        }
    }
    function cachedOrGetPromise<T>(resource: string, url: string): () => Promise<T> {
        return function (): Promise<T> {
            return new Promise((resolveSuccess, resolveFailed)=>{

                var cached: T = caches[resource];
                if (cached) {
                    resolveSuccess(cached)
                } else {
                    http({
                        url: url,
                        method: "GET",
                        onResponse: function (response) {
                            if (response.status == 200) {
                                resolveSuccess(JSON.parse(response.body))
                            } else {
                                resolveFailed("Error!  Server said:" + response.body);
                            }
                        }
                    })
                }
            })
        }
    }

    let accountsPromise = cachedOrGetPromise<AccountInfo[]>("accounts", `/api/tenants/${getTenantId()}/accounts`)
    let accounts = cachedOrGet<AccountInfo[]>("accounts", `/api/tenants/${getTenantId()}/accounts`)

    let templatesPromise = cachedOrGetPromise<TemplateInfoListItem[]>("templates", `/api/tenants/${getTenantId()}/templates`)
    let templates = cachedOrGet<TemplateInfoListItem[]>("templates", `/api/tenants/${getTenantId()}/templates`)

    let account = (accountId: string): AccountInfo | undefined => {
        return accounts()?.find((account) => account.id == accountId)
    }


    return {
        accountsPromise: accountsPromise,
        accounts: accounts,
        account: account,
        templates: templates,
        templatesPromise: templatesPromise,
    }
}
export default data