import React, { CSSProperties, ReactNode, useState } from 'react'
import http from './cj/HttpClient'
import util, { ReactThing, formatDate, formattedTotals, getTenantId, isPartOfTransfer } from './util'
import Table, { TableRowData } from './TableNG'
import { AccountInfo, ContextualizedPendingTransfer, ItemInfo, PendingTransfer, PendingTransferDto, PhysicalAccountDto, PhysicalAccountInfoDto, PhysicalItemHandle, Statement, StatementWithStats } from './api'
import { Box, Button, Checkbox, Chip, Divider, Stack, Typography, useMediaQuery, } from '@mui/material'
import { balanceAccumulator } from './PhysicalBalanceAccumulator'
import { Problem } from './ErrorAlert'
import ErrorDialog from './ErrorDialog'
import { deleteMapping, deletePendingTransfer, getStatements } from './api-actions'
import { AccountLink } from './Links'
import MarkdownContent from './MarkdownContent'
import { DialogsContext, DialogsState } from './Dialogs'
import { ContextualPhysicalItem } from './ItemEditorDialog'
import { Link } from 'react-router-dom'
import { physicalItemUrl } from './PhysicalItemPage'
import { PhysicalItemDescriptionView } from './UncategorizedScreenEntryCollapsedItemView'
import { AttributeFilters, ItemFiltersView, attributeMatchesFilter, minusAttributeFilter, pendingMatchesFilter, plusAttributeFilter } from './ItemFIlters'

export interface TransactionsListColumn {
    name: string
    control: (item: ItemInfo, selected: boolean) => ReactNode
}

const toCacheKey = (h: PhysicalItemHandle): string => `${h.accountId}____${h.itemId}`

interface StatementsContext {
    statements: Statement[]
    getStatementById: (statementId: string) => Statement | undefined
    getForItem: (handle: PhysicalItemHandle) => Statement[]
}

const makeStatementsContext = (statements: StatementWithStats[]): StatementsContext => {
    const r: Record<string, string[]> = {}

    statements.forEach(ss => {
        ss.statement.itemIds.forEach(id => {
            const key = toCacheKey({
                accountId: ss.statement.accountId,
                itemId: id,
            })
            r[key] = (r[key] ?? []).concat([ss.statement.id])
        })
    })

    const getStatementById = (id: string): Statement | undefined => statements.find(s => s.statement.id == id)?.statement

    const getForItem = (handle: PhysicalItemHandle): Statement[] => {
        const sids = r[toCacheKey(handle)] ?? []
        return sids.map(id => getStatementById(id)!!)
    }

    return {
        statements: statements.map(ss => ss.statement),
        getStatementById: getStatementById,
        getForItem: getForItem,
    }
}

export interface TransactionsListProps {
    source: PhysicalAccountDto,
    totals: Record<string, number>,
    items: ItemInfo[],
    accounts: AccountInfo[],
    pending: ContextualizedPendingTransfer[],
    sources: PhysicalAccountInfoDto[],
    extraColumns?: TransactionsListColumn[],
    isSelectable?: (i: ItemInfo, statements: Statement[]) => boolean,
    selections?: string[],
    setSelections?: (selections: string[]) => void,
    handleUpdate: () => void
}
export default (props: TransactionsListProps) => {
    const { source, pending, items, totals, accounts, sources, isSelectable, handleUpdate } = props
    const extraColumns = props.extraColumns ?? []
    const selections = props.selections ?? []
    const setSelections = props.setSelections ?? ((s: string[]) => { })
    const isLarge = useMediaQuery('(min-width:800px)');

    const [statements, setStatements] = React.useState<StatementsContext>()
    const [problem, setProblem] = React.useState<Problem>()

    const actuallyPending = pending.filter(p =>  p.pending.actualTransferId == undefined)

    const [filters, setFilters] = useState<AttributeFilters>({})

    const addAttributeFilter = (key:string, value:string)=>{
        setFilters(plusAttributeFilter(filters, key, value))
    }
    const removeAttributeFilter = (key:string, value:string)=>{
        setFilters(minusAttributeFilter(filters, key, value))
    }
    

    const handleMappingDeletion = (problem: Problem | undefined) => {
        setProblem(problem)
        loadStatements()
        handleUpdate()
    }

    const loadStatements = () => {

        getStatements().then(statements => setStatements(makeStatementsContext(statements)))
        // .catch(setError)
    }

    React.useEffect(loadStatements, [])

    function accountForId(accountId: string): AccountInfo | undefined {
        return accounts.filter(item => item.id === accountId)[0];
    }
    function sourceForId(sourceId: string): PhysicalAccountInfoDto | undefined {
        return sources.filter(source => source.id === sourceId)[0];
    }

    
    var runningTotals = balanceAccumulator()
    const rows: TableRowData[] = items.filter(i=>attributeMatchesFilter(i, filters)).map((itemInfo, idx,) => {

        var adjustment = itemInfo.item;
        var formattedWhen = <div key={`foo-${idx}`} style={{ minWidth: "110px" }}>{util.formatLongDateTime(adjustment.whenPosted)}</div>
        runningTotals = runningTotals.add(itemInfo)
        let runningTotalsText = formattedTotals(runningTotals.totals())

        let mapping: ReactNode[] | string | undefined;
        if (isPartOfTransfer(adjustment)) {
            mapping = ["transfer " + (adjustment.amount > 0 ? "from" : "to") + " " + sourceForId(adjustment.otherAccount!!)?.name + " (via transaction '" + adjustment.otherAccount + "/" + adjustment.otherSideOfTransfer + "')"].join(" ");
        } else if (adjustment.mappings) {
            mapping = adjustment.mappings.allocations?.map((allocation, idx) => {
                var move = itemInfo.moves.find(move => move.id == allocation.fundMoveId)!!
                let accountLinks = move.flows.map(flow =>
                    <>
                        <AccountLink account={accountForId(flow.fromFundId)} />
                        -&gt;
                        <AccountLink account={accountForId(flow.toFundId)} />
                    </>
                );
                return <span key={idx}>{move.memo}<br />{accountLinks} {adjustment.pendingTransferId && <><br/>Pending Transfer {adjustment.pendingTransferId}</>}</span>
            });
        } else if (adjustment.nonMaterialResolution) {
            mapping = ["Not Material" + (adjustment.nonMaterialResolution.reason ? (":" + adjustment.nonMaterialResolution.reason) : "")].join(" ");
        } else if (adjustment.pendingTransferId) {
            mapping = [<>Safe Spend {adjustment.pendingTransferId}</>]
        } else {
            mapping = "not-mapped";
        }

        interface DuplicatePhysicalItem{
            originalId:string|undefined
            duplicateRef:string
        }

        const handleFlagDuplicate = ()=>{
            const ref = itemInfo.item.ref
            if(ref){
                const otherId = window.prompt("Enter ID of other transaction")
                if(otherId){
                    if(window.confirm(`Mark ref ${ref} as a duplicate of ${otherId}?`)){
                        console.log("Do it!")
                        const dup:DuplicatePhysicalItem = {
                            originalId:otherId,
                            duplicateRef:ref,
                        }
                        http({
                            url: `/api/tenants/${getTenantId()}/physicalAccounts/` + source.id + "/duplicates",
                            method: "POST",
                            data: JSON.stringify(dup),
                            options: { async: true },
                            onResponse: function onResponse(response) {
                                if (response.status !== 200) {
                                    console.log(response)
                                    setProblem({
                                        description: "There was an " + response.status + " error ",
                                        details: response.body
                                    })
                                } else {
                                    handleUpdate()
                                }
                            }
                        });
                    }
                }
    
            }
        }

        let deleteLink: ReactNode;
        let unmapLink: ReactNode;
        let mapLink: ReactNode;
        let markDuplicate: ReactNode;


        if (mapping == "not-mapped") {
            let handleMapItemClicked = (dialogs:DialogsState) => {
                const i:ContextualPhysicalItem = {foreignAccountId:source.id, item:itemInfo.item}
                dialogs.showItemMapper({value:i, onChange:handleUpdate})
            }
            let handleDeleteClicked = () => {
                console.log("Need to delete", itemInfo, source)
                let deleteWasConfirmed = window.confirm("Are you sure you want to delete this?")

                if (deleteWasConfirmed) {
                    http({
                        url: `/api/tenants/${getTenantId()}/physicalAccounts/` + source.id + "/items/" + itemInfo.item.id,
                        method: "DELETE",
                        options: { async: false },
                        onResponse: function onResponse(response) {
                            if (response.status !== 200) {
                                console.log(response)
                                setProblem({
                                    description: "There was an " + response.status + " error ",
                                    details: response.body
                                })
                            } else {
                                handleUpdate()
                            }
                        }
                    });
                }
            }
            unmapLink = ""
            deleteLink = <Button onClick={handleDeleteClicked}>delete</Button>
            mapLink = <DialogsContext.Consumer>{dialogs => 
                <Button onClick={()=>handleMapItemClicked(dialogs)}>Map</Button>
            }</DialogsContext.Consumer>
            markDuplicate = <Button onClick={handleFlagDuplicate}>Flag-Duplicate</Button>
        } else {
            const handleDelete = () => {
                if (window.confirm("Are you sure you want to delete this?")) {
                    deleteMapping(itemInfo.item, source, handleMappingDeletion)
                }
            }

            unmapLink = <Button onClick={handleDelete}>Unmap</Button>
            deleteLink = ""
            mapLink = ""
            markDuplicate = ""
        }


        const itemStatements = statements?.getForItem(itemInfo.handle) ?? []

        const itemCategories = itemStatements?.flatMap(s => {
            const itemIdsWithCategory = s.itemIdsWithCategory ?? {}
            return Object.keys(itemIdsWithCategory).filter(category => {
                const foo = itemIdsWithCategory[category] ?? []
                return foo.indexOf(adjustment.id) > -1
            })
        })
        const selected = selections.indexOf(adjustment.id) > -1
        const extras = extraColumns.map(i => i.control(itemInfo, selected))
        const baseColumns = [
            <Checkbox
                checked={selected}
                disabled={isSelectable && !isSelectable(itemInfo, itemStatements ?? [])}
                onChange={e => {
                    const id = adjustment.id
                    const minusThis = selections.filter(it => it != id)

                    if (e.target.checked) {
                        setSelections(minusThis.concat([id]))
                    } else {
                        setSelections(minusThis)
                    }
                }}
            />,
            formattedWhen,
            <Link to={physicalItemUrl(itemInfo.handle)}>{adjustment.id}</Link>,
            <PhysicalItemDescriptionView 
                handle={itemInfo.handle} 
                item={itemInfo.item} 
                onAttributeSelect={addAttributeFilter}
                />,
            util.formattedColorAmount(adjustment.amount, adjustment.currency),
            runningTotalsText,
            <div style={{ minWidth: "110px" }}>
                <div>{itemStatements?.map(s => s?.name)?.join(",") ?? ""}</div>
                <div>{itemCategories?.join(",") ?? "..."}</div>
            </div>,
            <>
                <Stack>
                    <Box>{adjustment.mappingMemo}</Box>
                    <Box>{mapping}</Box>
                </Stack>
            </>,
            <Stack>{mapLink}{deleteLink}{markDuplicate}{unmapLink}</Stack>]

        return baseColumns.concat(extras)
    });

    const totalsRow: TableRowData[] = Object.keys(totals).map(currency => {
        const qty = totals[currency]
        return ["", "", "", "Total " + currency, util.formattedColorAmount(qty, currency)]
    });

    const pendingRows: TableRowData[] = actuallyPending.filter(p=>pendingMatchesFilter(p, filters)).map(pending => {
        const handleDelete = ()=>{
            if(window.confirm("Are you sure you want to delete this?")){
                deletePendingTransfer(pending.pending.id, handleUpdate)
            }
        }
        let columns = [
            "PENDING", 
            formatDate(pending.pending.whenPosted), 
            pending.pending.id, 
            <MarkdownContent>{pending.pending.memo ?? ""}</MarkdownContent>, 
            util.formattedColorAmount(pending.pending.amount, pending.pending.currency),
             "Total ??", 
             "N/A", 
             "N/A", 
             <><Button onClick={handleDelete}>Delete</Button></>]
        
        return columns.concat(extraColumns.map(i => i.name))
    })

    const header = ["", "When", "ID", "Description", "Amount", "Total", "Statement", "Mapping", ""].concat(extraColumns.map(i => i.name))


    const RowCard = (props: { row: TableRowData }) => {
        const { row } = props
        const get = (name: string): ReactNode => {
            const idx = header.indexOf(name)
            return row[idx]
        }
        const lableStyle: CSSProperties = { fontWeight: "bold", fontSize: "1em" }
        const headerLabelStyle: CSSProperties = { fontWeight: "bold", fontSize: "1.2em" }
        return <Stack spacing={1} style={{ margin: "10px" }}>
            <Stack direction="row" spacing={2}>
                {get("")} {/*The select box*/}
            </Stack>
            <Stack direction="row-reverse" spacing={2}>
                <Typography style={headerLabelStyle}>{get("Amount")}</Typography>
                <Typography style={headerLabelStyle}>{get("When")}</Typography>
            </Stack>
            <Stack>
                <Typography style={lableStyle}>Description</Typography>
                {get("Description")}
            </Stack>
            {/* <Stack>
                <Typography style={lableStyle}>ID</Typography>
                {get("ID")} 
            </Stack> */}
            <Stack>
                <Typography style={lableStyle}>Mapping</Typography>
                {get("Mapping")}
            </Stack>
        </Stack>
    }

    const makeCompactTable = () => {
        const columnsToKeep = ["Details", "Amount", "Total"]
        const columnIdxsToKeep = columnsToKeep.map(name => header.indexOf(name))
        const collumnsToCollapse = ["ID", "When", "Description", "Amount", "Total", "Statement", "Mapping"]//header.filter(name=>columnsToKeep.indexOf(name)== -1)

        return <Table
            header={columnsToKeep}
            rows={rows.concat(pendingRows).concat(totalsRow).reverse().map(row => {
                const foo: TableRowData = columnIdxsToKeep.map(idx => (idx == -1) ? <RowCard row={row} /> : row[idx])
                return foo
            })} />
    }

    return (
        <>
            {problem && <ErrorDialog problem={problem} onClose={() => setProblem(undefined)} />}
            <ItemFiltersView filters={filters} onChange={setFilters}/>
            {isLarge && <Table
                header={header}
                rows={rows.concat(pendingRows).concat(totalsRow).reverse()} />}
            {!isLarge && makeCompactTable()}
        </>)

}