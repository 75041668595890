import { CSSProperties, ReactNode } from "react";
import marked from 'marked'

export default (props: { children: string | undefined , style?:CSSProperties}) => {
    const { children, style } = props

    const renderMarkdown = (marked as any);

    if (children) {
        return (<div style={style} dangerouslySetInnerHTML={
            { __html: renderMarkdown(children) }} />)
    } else {
        return <div />
    }
}