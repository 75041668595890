import _ from 'underscore'
import util, { formatDate, formatTime, formatTimeIfNotMidnight } from './util'
import { Box, Stack, Typography } from '@mui/material'
import {  PhysicalItem, PhysicalItemHandle } from './api'
import Table from './TableNG'
import { Link } from 'react-router-dom'
import { physicalItemUrl } from './PhysicalItemPage'
import MarkdownContent from './MarkdownContent'


export const PhysicalItemDescriptionView = (props:{handle:PhysicalItemHandle, item:PhysicalItem, onAddFilter?:(key:string, value:string)=>void, onAttributeSelect?:(key:string, value:string)=>void})=>{
    const {handle, item, onAttributeSelect} = props

    const normalStructuredMemoString = Object.keys(item.structuredMemo).map(k => `${k}: ${item.structuredMemo[k]}`).join("\n")
    const isStandardStructuredMemo = (normalStructuredMemoString===item.memo)

    let name:string;
    let details:Record<string, string>

    if(isStandardStructuredMemo){
        name = (item.structuredMemo["Name"]  ?? "") + (item.structuredMemo["name"] ?? "")
        details = {
            ... item.structuredMemo,
        }
        delete details.name
        delete details.Name
    }else{
        name = item.memo
        details = item.structuredMemo
    }

    // console.log("item", item)
    
    
    return (<>
        <Stack spacing={1}>
            <Link to={physicalItemUrl(handle)}>{name}</Link>
            {<Table
                header={["key", "value"]}
                rows={Object.keys(details).map(key => {
                    const value = details[key]

                    const formattedValue = onAttributeSelect ? <span style={{cursor:"pointer"}} onClick={()=>onAttributeSelect(key, value)}>{value}</span> : value

                    return [key, formattedValue]
                }).concat([["Ref", item.ref]])}
            />}

            {item.mappingMemo && <Stack spacing={1}>
                <Typography>Mapping Memo</Typography>
                <Box style={{border:"1px solid grey", padding:"5px"}}><MarkdownContent>{item.mappingMemo}</MarkdownContent></Box>
            </Stack>}
        </Stack>
    </>)
}

export default (props:{handle:PhysicalItemHandle, item:PhysicalItem, onAttributeSelect?:(key:string, value:string)=>void})=>{
    const {handle, item, onAttributeSelect} = props
    const timeLabel = formatTime(item.whenPosted)
    
    return (<Stack direction="row" spacing={4}>
    <Stack style={{ minWidth: "90px" }}>
        <Box><Link to={physicalItemUrl(handle)}>{formatDate(item.whenPosted)}</Link></Box>
        {timeLabel.length > 0 && <Box>{formatTimeIfNotMidnight(item.whenPosted)}</Box>}
    </Stack>
    <Box style={{ minWidth: "90px" }}><Link to={physicalItemUrl(handle)}>{util.formattedColorAmount(item.amount, item.currency)}</Link></Box>
    <Box>
        <PhysicalItemDescriptionView 
            handle={handle} 
            item={item}
            onAttributeSelect={onAttributeSelect}/>
    </Box>
</Stack>)
}