import { IconButton, TextField, TextFieldProps, TextFieldVariants } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';

export default (props: { value: string, onSearch: (newValue: string) => void } & TextFieldProps) => {
    const { value, onSearch } = props
    const isDefined = value.trim().length > 0

    return <TextField
        {...props}
        value={value}
        onChange={e => onSearch(e.target.value)}
        InputProps={{
            endAdornment: isDefined ? (
                <IconButton size="small" onClick={() => onSearch("")}>
                    <ClearIcon />
                </IconButton>
            ) : undefined
        }}
    />
}