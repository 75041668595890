
export interface CurrencyHandler {
    parse: (raw: string) => number
    normalized: (n: number) => string
    humanNumber: (n: number) => number
}

const filterStr = (str: string, predicate: (char: string, idx: number) => boolean): string => {
    let result = ""
    for (var i = 0; i < str.length; i++) {
        const char = str.charAt(i)
        if (predicate(char, i)) {
            result = result + char
        }
    }
    return result
}
function isNumber(char: string): boolean {
    return /^\d$/.test(char);
}

const filterCharsOrPeriod = (str: string) => filterStr(str, (c, idx) => isNumber(c) || (c == ".") || (idx == 0 && c == "-"))

const usdHumanNumber = (v: number) => (v / 100)
const usdHandler: CurrencyHandler = {
    parse: (str: string) => {
        const v = parseFloat(filterCharsOrPeriod(str))
        return Math.round(v * 100)
    },
    humanNumber: usdHumanNumber,
    normalized: (v: number) => {
        const str = filterCharsOrPeriod(usdHumanNumber(v).toFixed(2).toLocaleString())

        console.log(`Normalized ${v} as ${str}`)

        return str
    }
}

const simpleHandler: CurrencyHandler = {
    parse: (str: string) => {
        return parseInt(filterCharsOrPeriod(str))
    },
    humanNumber: (n: number) => n,
    normalized: (v: number) => {
        return v.toLocaleString().replaceAll(",", "")
    }
}

const currencyHandlers: Record<string, CurrencyHandler> = {
    "USD": usdHandler
}


export default (currency: string) => currencyHandlers[currency] ?? simpleHandler