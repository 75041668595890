import React, { ReactNode } from 'react'

export type TableCellData = ReactNode | string | undefined
export type TableRowData = {
    key: string | number,
    cells: TableCellData[]
}

export default (props: { header: string[], rows: TableRowData[] }) => {
    const { header, rows } = props

    const makeHeader = (columnNames: string[]) => {
        return <tr key="header" className="row-ng">{columnNames.map((columnName, idx) => <th key={idx}>{columnName}</th>)}</tr>
    }

    return (
        <table className="table-ng">
            <thead>
                {header ? makeHeader(header) : ""}
            </thead>
            <tbody>
                {rows.map(row => {
                    const rowKey = `row-${row.key}`
                    const values = row.cells
                    return (<tr key={rowKey} className="row-ng">
                        {values.map((value, idx) => <td key={`${rowKey}-${idx}`}>{value}</td>)}
                    </tr>)
                })}
            </tbody>
        </table>);
}
