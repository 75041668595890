import { Flow } from './api'
import { Box, Dialog, DialogTitle, Stack, useMediaQuery, useTheme } from '@mui/material'
import FlowProjectionEditor from './FlowProjectionEditor'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { doDeleteWithConfirmation } from './api-actions';
import { useNavigate } from 'react-router-dom';

export default (props: { flow: Flow, onDelete: (f: Flow) => void, onComplete: (f?: Flow | undefined) => void }) => {
  const { flow, onDelete, onComplete } = props
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate()

  const doClose = () => {
    onComplete(undefined)
  }

  const doComplete = (f: Flow | undefined) => {
    onComplete(f)
  }

  const doDelete = () => {
    flow.id && doDeleteWithConfirmation(flow.id, () => onDelete(flow))
  }

  return <Dialog
    open={true}
    onClose={doClose}
    fullScreen={fullScreen}
    fullWidth={true}
    maxWidth={"md"}
  >
    <DialogTitle>Flow Projection
      <Box style={{
        position: 'absolute',
        right: 8,
        top: 8,
      }}>

        <IconButton
          onClick={doDelete}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}>
          <DeleteIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={doClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
    <Stack style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
      <FlowProjectionEditor flow={flow} onComplete={doComplete} />
    </Stack>
  </Dialog>
}

