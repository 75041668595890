import React from 'react'
import CurrencySelect from './CurrencySelect'
import InstantSelect from './InstantSelect';
import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import http from './cj/HttpClient'
import { getTenantId } from './util';
import CurrencyAmountSelector from './CurrencyAmountSelector';

export interface PhysicalItemRequest {
    ref: string,
    whenPosted: number,
    memo: string,
    amount: number,
    currency: string
}
export default (props: { sourceId: string, onChange: (change: PhysicalItemRequest) => void }) => {

    const { sourceId, onChange } = props
    const [amount, setAmount] = React.useState(0)
    const [currency, setCurrency] = React.useState("USD")
    const [whenPosted, setWhenPosted] = React.useState(new Date().getTime())
    const [memo, setMemo] = React.useState("")
    const [ref, setRef] = React.useState("")


    let createTransaction = (transaction: PhysicalItemRequest) => {
        console.log("Creating ", transaction)
        http({
            url: `/api/tenants/${getTenantId()}/physicalAccounts/` + sourceId + "/items",
            method: "POST",
            data: JSON.stringify(transaction),
            onResponse: function (response) {
                if (http.is2xxResponse(response)) {
                    onChange(transaction)
                } else {
                    alert("Error!  Server said:" + response.body);
                }
            }
        });
    }

    let doCreate = () => {
        createTransaction({
            ref: ref,
            whenPosted: whenPosted,
            memo: memo,
            amount: amount,
            currency: currency
        })
    }

    return <>
        <Stack spacing={2}>
            <Box>
                <CurrencyAmountSelector
                    value={{ amount: amount, currency: currency }}
                    onChange={(v) => {
                        setAmount(v.amount)
                        setCurrency(v.currency)
                    }}
                />
                {/* <TextField type="number" label="amount" value={amount} onChange={e=>setAmount(parseInt(e.target.value, 10))} />
                <CurrencySelect value={currency} onChange={setCurrency} /> */}
            </Box>
            <Box>
                When (unix timestamp):
                <InstantSelect
                    value={whenPosted}
                    onChange={v => v && setWhenPosted(v)} />
            </Box>
            <TextField label="Reference" value={ref} type="text" onChange={e => setRef(e.target.value)} />
            <TextField label="Memo" multiline={true} value={memo} onChange={e => setMemo(e.target.value)} />
            <Stack direction="row-reverse"><Button variant="contained" onClick={doCreate}>Create</Button></Stack>
        </Stack>
    </>
}

