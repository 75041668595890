import $ from 'jquery';

export type HttpMethodName = "GET"|"POST"|"PUT"|"DELETE"|"PATCH"
export interface RequestOptions{
    async:boolean
}
export interface Request {
    url:string
    method:HttpMethodName
    data?:string
    headers?:Record<string, string>
    options?:RequestOptions
    onResponse:(response:Response)=>void
}
export interface Response {
    status: number,
    body: string,
    headers: Record<string, string>
}
export default function HttpClient(request:Request, opts?:any) {
    var defaults = { ajax: $.ajax, async: "true" },
        options = $.extend(defaults, opts);

    function parseHeaders(headerString:string):Record<string, string> {
        var headers:Record<string, string>= {},
            headerStrings = headerString.split("\n");

        $.each(headerStrings, function (index, header) {
            if (header !== "") {
                var headerTuple = header.split(": ");
                headers[headerTuple[0]] = headerTuple[1];
            }
        });

        return headers;
    }

    options.ajax({
            type: request.method,
            url: request.url,
            processData: false,
            data: request.data,
            dataType: "text",
            headers: request.headers,
            async: options.async,
            cache: options.cache,
            success: function (data:string, status:string, xhr:XMLHttpRequest) {
                var response = {
                    status: xhr.status,
                    body: xhr.responseText,
                    headers: parseHeaders(xhr.getAllResponseHeaders())
                };
                return request.onResponse(response);
            },
            error: function (xhr:XMLHttpRequest, status:string, err:string) {
                var response = {
                    status: xhr.status,
                    body: xhr.responseText,
                    headers: parseHeaders(xhr.getAllResponseHeaders())
                };
                return request.onResponse(response);
            }
        });
}

HttpClient.is2xxResponse = (response:Response|XMLHttpRequest) => response.status >= 200 && response.status < 300
