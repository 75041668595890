import { Box, Button, CircularProgress, Fab, IconButton, Menu, MenuItem, Stack } from "@mui/material"
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/AddCircle';
import AddIcon2 from '@mui/icons-material/Add';
import { CSSProperties, ReactNode, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getTenantId } from "./util"
import { DialogsContext, DialogsState } from "./Dialogs"
import { makeNewFlowTemplate } from "./PlanConfigScreen";
import { Refresh } from "@mui/icons-material";

export interface ActionsContext {
    accountId?: string
}
export default (props: { noFloat?:boolean, extraActions?: Record<string, () => void>, topOffset?: string, onRefresh?: () => void, handleChange?: () => void, isRefreshing?: boolean, children?: ReactNode, context?: ActionsContext }) => {
    const navigate = useNavigate()
    const { noFloat, topOffset, extraActions, context, onRefresh, isRefreshing, children } = props

    const dialogs = useContext(DialogsContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    function doMakeMove() {
        dialogs.showMove({
            value:undefined, 
            onChange:()=>onRefresh && onRefresh()
        })
    }

    const handleChange = () => {
        if (props.handleChange) {
            props.handleChange()
        } else if (onRefresh) {
            onRefresh()
        }
    }

    const actions = (dialogs: DialogsState): Record<string, () => void> => ({
        ...extraActions,
        "New Safe Spend": () => navigate(`/app/${getTenantId()}/spend`),
        "Plan Something": () => dialogs.showFlow({ value: makeNewFlowTemplate(context?.accountId), onChange: handleChange }),
        "Move Funds": doMakeMove,
        "Add Account": () => dialogs.showNewAccount(handleChange),
        "Add Ext. Acct": () => dialogs.newPhysicalAccount(handleChange),
        "Make Template": () => dialogs.newTemplate(handleChange),
    })

    const fabStyles:CSSProperties = {
        marginTop: "10px",
    }

    return (<>
        <DialogsContext.Consumer>{dialogs =>
            <div style={{
                position: "sticky",
                top: (topOffset || "0px"),
                float: (noFloat ? "none" : "right"),
                // display : (noFloat ? "inline-block" : "block"),
                // textAlign : (noFloat ? "right" : "initial"),
                zIndex: "101",
                margin: "10px",
            }}>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {mapRecord(actions(dialogs), (key, value) => <MenuItem onClick={() => { handleClose(); value() }}>{key}</MenuItem>)}
                </Menu>
                <Stack spacing={1} direction="row" justifyContent={"flex-end"}>

                    {children && <div style={{
                            background: "white",
                            border: "2px solid grey",
                            borderRadius: "5px",
                            padding: "10px",}}>{children}</div>}
                    <Fab color="primary" aria-label="add" onClick={handleClick} style={fabStyles}>
                        <AddIcon2 />
                    </Fab>
                    {onRefresh && (isRefreshing ? <CircularProgress style={{ marginLeft:"18px", }}/> : <Fab color="secondary" aria-label="add" onClick={onRefresh} style={fabStyles}><Refresh/></Fab>)}
                    
                    {/* <IconButton color="primary" onClick={handleClick}><AddIcon /></IconButton> */}
                    {/* {onRefresh && (isRefreshing ? <CircularProgress size="20px" style={{margin:"10px", marginLeft:"18px", }}/> : <IconButton color="primary" onClick={onRefresh}><RefreshIcon /></IconButton>)} */}
                </Stack>
            </div>
            
        }</DialogsContext.Consumer>
    </>)
}

const mapRecord = <V, T>(records: Record<string, V>, fn: (key: string, value: V) => T): T[] => {
    return Object.keys(records).map(key => fn(key, records[key]))
}