import { Box, Breadcrumbs } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { AccountInfo, PhysicalAccountDto } from "./api";
import { getAccountsPromise, getAllPhysicalPromise } from "./api-actions";
import PhysicalAccountTransactions from "./PhysicalAccountTransactions";
import FloatingActionBar from "./FloatingActionBar";
import { getTenantId } from "./util";

export const urlForAllPhysicalAccounts = () => `/app/${getTenantId()}/external/accounts`
export const urlForPhysicalAccount = (id: string) => `/app/${getTenantId()}/external/accounts/${id}`

export default () => {
    const { accountId } = useParams()
    const [busy, setBusy] = useState(false)
    const [accounts, setAccounts] = useState<AccountInfo[]>()
    const [sources, setPhysAccounts] = useState<PhysicalAccountDto[]>()

    const source = sources?.find(a => a.id == accountId)

    const reload = () => {
        setBusy(true)
        getAllPhysicalPromise()
            .then(setPhysAccounts)
            .then(getAccountsPromise)
            .then(setAccounts)
            .finally(() => setBusy(false))
    }

    useEffect(reload, [])

    const physAccountForId = (id: string) => sources?.find(a => a.id == id)

    return (<>
        <Box>
            <Breadcrumbs>
                <Link to={urlForAllPhysicalAccounts()}>External Accounts</Link>
                <Link to={urlForPhysicalAccount(accountId!!)}>{physAccountForId(accountId!!)?.name ?? accountId}</Link>
            </Breadcrumbs>
            <FloatingActionBar isRefreshing={busy} onRefresh={reload} />
        </Box>
        {(source && accounts && sources) && <>
            <PhysicalAccountTransactions
                showDetails={true}
                source={source}
                accounts={accounts}
                sources={sources}
                onChange={reload} />
        </>}

    </>)
}