import './App.css';
import http from './cj/HttpClient'
// import navContext, { useNavigate } from "./NavContext"
import MainView from "./MainView"
import LoginScreen from "./LoginScreen"
import LoadingThrobber from "./LoadingThrobber"
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import React, { useState, ReactDOM, useEffect } from 'react'
import { FullPhysicalItemDetails, Organization, PhysicalItem, PhysicalItemHandle, TenantInfo, UserInfoDto } from './api';
import { Button, CircularProgress, Typography } from '@mui/material';
import ErrorAlert, { Problem } from './ErrorAlert';
import { Link, NavigateFunction, Route, Routes, useNavigate, useParams } from "react-router-dom";
import SpendView from './SpendView';
import useInterval from './useInterval';
import { getPhysicalAccountInfos, getPhysicalItem } from './api-actions';
import PhysicalItemPanel from './PhysicalItemPanel';
import { getTenantId } from './util';
import PhysicalItemPage from './PhysicalItemPage';
import MovePage, { PlannedMovePage } from './MovePage';


const OrgSelector = (props: {}) => {
  const [organizations, setOrganizations] = React.useState<TenantInfo[]>()
  const [error, setError] = React.useState<Problem>()

  const navigate = useNavigate()

  const getOrganizations = () => {
    setError(undefined)
    http({
      url: "/api/tenants",
      method: "GET",
      options: { async: false },
      onResponse: ((response: any) => {
        if (response.status === 200) {
          const organizations: TenantInfo[] = JSON.parse(response.body)
          setOrganizations(organizations)
        } else {
          setError({
            description: "There was a problem fetching tenants",
            details: response.body
          })
        }
      })
    });
  }


  React.useEffect(getOrganizations, [])

  return (
    <div>
      <h1>Organizations:</h1>
      <LoadingThrobber isLoading={!organizations}>
        {organizations?.map(t => {
          return <Button key={t.tenantId} onClick={() => navigate("/app/" + t.tenantId)}>{t.organization?.name ?? t.tenantId}</Button>
        })}
      </LoadingThrobber>

    </div>
  )
}

export default () => {
  const [isLoggedIn, setLoggedIn] = React.useState<boolean>();
  const [hasRendered, setHasRendered] = React.useState(false)
  const [isLoading, setLoading] = React.useState(true)
  const [user, setUser] = React.useState<UserInfoDto>();
  const [error, setError] = React.useState<Problem>()
  const [tick, setTick] = React.useState(new Date().getTime())


  console.log(`rendering user`, user)
  // setTimeout(checkLoginStatus, 5000)

  const getLoggedInUser = (onResult: (status: number, u: UserInfoDto | undefined, error: string | undefined) => void) => {
    http({
      url: "/api/me",
      method: "GET",
      options: { async: true },
      onResponse: ((response) => {
        if (response.status === 200) {
          onResult(response.status, JSON.parse(response.body), undefined)
        } else {
          onResult(response.status, undefined, response.body)
        }
      })
    });
  }

  const checkLoginStatus = () => {
    getLoggedInUser((status, u, error) => {
      if (status === 200) {
        setUser(u)
        setLoggedIn(true)
        setHasRendered(true)
      } else if (status === 401) {
        setLoggedIn(false)
      } else {
        setError({
          description: `There was an error.  Check your network connection?  Server said ${status}.`,
          details: error ?? ""
        })
      }
      setLoading(false)
    })
  }

  React.useEffect(checkLoginStatus, [])


  // React.useEffect(() => {
  //   const id = setInterval(() => {
  //     getLoggedInUser((status, u, error) => {
  //       console.log("was user", user, "but now", u)
  //       if(isLoggedIn && !u){
  //         console.log("User dropped: was user", user, "but now", u)
  //         setLoggedIn(false)
  //       }
  //     })
  //   }, 15000);
  //   return () => clearInterval(id);  
  // }, []);

  useInterval(isCancelled => {
    if (!isCancelled()) {
      getLoggedInUser((status, u, error) => {
        console.log("was user", user, "but now", u)
        if (isLoggedIn && !u) {
          console.log("User dropped: was user", user, "but now", u)
          setLoggedIn(false)
        }
      })
    }
  }, 5000, false)

  let display: "none" | "initial";

  if (hasRendered) {
    display = isLoggedIn ? "initial" : "none"
  } else {
    display = "none"
  }

  return <LoadingThrobber isLoading={isLoading}>
    {isLoggedIn == false && <div className="login-form-holder">
      <Box style={{margin:"40px"}} >
        <Box>
          <img src='/icon-1024-1024.png' width="100" height="100" style={{display:"inline-block", verticalAlign:"middle"}}></img>
          <Typography variant="h1"  style={{display:"inline-block", verticalAlign:"middle", fontWeight:"bold", fontFamily:"sans-serif"}}>AIM</Typography>
        </Box>
        <Typography variant="h4">Spend with Purpose</Typography>
      </Box>
      <Box>
        <Alert color="info" style={{display:"inline-block", maxWidth:"80%", width:"500px"}}>
          AIM is in closed beta.  Email support@appfoo.io to request access.
        </Alert>
      </Box>
      <LoginScreen onComplete={checkLoginStatus} />
      </div>}
    {((isLoggedIn || hasRendered) && user) &&
      <div style={{ display: display }}>
        <Routes >
          <Route index element={<OrgSelector />} />
          <Route path="app" element={<OrgSelector />} />
          <Route path="app/:tenantId/Spend" element={<SpendView user={user} />} />
          <Route path="app/:tenantId/*" element={<MainView user={user} />} />
          <Route path="app/plaid-oauth-return" element={<>
            <Stack spacing={3} style={{textAlign:"center", margin:"40px"}}>
              <Typography variant="h3">Plaid Sent You Back to AIM</Typography>
              <Box><Typography>Maybe go <Link style={{textDecoration:"underline"}} to="/">here</Link> next?</Typography></Box>
            </Stack>
          </>}/>
{/* 
          <Route path="app/:tenantId/moves/planned/:sequenceId/:instanceId*" element={<PlannedMovePage/>} />
          <Route path="app/:tenantId/moves/:moveId/*" element={<MovePage/>} /> */}
        </Routes>
      </div>}
    {error && <Stack direction="row" style={{ padding: "40px" }}><ErrorAlert error={error} /></Stack>}
  </LoadingThrobber>
}
