import _ from 'underscore'
import { FullPhysicalItemDetails, FundMove, FundsFlow, PhysicalItem } from './api';

export const totalJustFlows = (flows: FundsFlow[]):number => {
    var total = 0;
    _.each(flows, function (flow) {
        total += flow.amount;
    });
    return total;
}

export function totalFlows(move: FundMove):number {
    return totalJustFlows(move.flows)
}

export function totalPhysicalMappings(move: FundMove) {
    var total = 0;
    _.each(move.physicalMappings, function (m) {
        total += m.amount;
    });
    return total;
}

export const totalPhysicalMappingsForFullPhysicalItemDetails = (items: FullPhysicalItemDetails[]):number => {
    return totalPhysicalMappingsForPhysicalItem(items.map(i=>i.item))
}

export const totalPhysicalMappingsForPhysicalItem = (items: PhysicalItem[]):number => {
    var total = 0;
    _.each(items, function (m) {
        total += m.amount;
    });
    return total;
}
