import { useEffect, useState } from "react"
import { AccountInfo, BalancesByTick, Flow, HistoricalPlanItemAnalysis, Organization } from "./api"
import { Alert, Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { AccountRecurringMonthlyBudgetAnalysisTable, BudgetView } from "./PlanConfigScreen"
import { fetchChartData, fetchPlannedStats, getAccountsPromise, getFlowsPromise, getTenantOrganizationPromise } from "./api-actions"
import AnalysisWindowControls, { AnalysisWindow, WindowOption, resolveWindowOption } from "./AnalysisWindowControls"
import FloatingActionBar from "./FloatingActionBar"
import util, { combineTotals, entityName, getTenantId } from "./util"
import PlanChart from "./PlanChart"
import dataCache from './data'
import _, { all } from "underscore"
import { KIND_STUB } from "./accounts"
import { AccountRecurringMonthlyBudgetAnalysis, FlowFilter, FundChange, analyzeMonthlyBudgetForAccount, makeIntraEntityFilter, summarize } from "./budget"
import Table from './TableNG'

export default () => {
    const tenantId = getTenantId()
    const [now, setNow] = useState(new Date().getTime())
    const [organization, setOrganization] = useState<Organization>()
    const [flows, setFlows] = useState<Flow[]>()
    const [allAccounts, setAccounts] = useState<AccountInfo[]>([])
    const [analysisWindow, setAnalysisWindow] = useState<AnalysisWindow>(resolveWindowOption(WindowOption.NEXT_12_MONTHS))
    const [data, setData] = useState(dataCache())
    const [chartData, setChartData] = useState<BalancesByTick>()
    const [isLoading, setLoading] = useState(false)

    const getAccount = (id:string) => allAccounts.find(a=>a.id === id)

    // if(getAccount("a51cace2-03f8-4040-8c3b-abaea54c3e03")) throw "a51cace2-03f8-4040-8c3b-abaea54c3e03 is " + JSON.stringify(getAccount("a51cace2-03f8-4040-8c3b-abaea54c3e03"), null, 4)

    const accounts = allAccounts?.filter(a => !a.name.startsWith("_") && a.kind !== KIND_STUB)

    const filter:FlowFilter = makeIntraEntityFilter(organization, allAccounts)

    function getData(): Promise<any> {
        setLoading(true)

        return Promise.all([
            getTenantOrganizationPromise(tenantId).then(setOrganization),
            getFlowsPromise(tenantId).then(setFlows),
            getAccountsPromise().then(setAccounts),
            fetchChartData({ to: analysisWindow.to, from: analysisWindow.from }).then(setChartData),
        ])
            .then(() => setLoading(false))
            .catch(alert)
    }
    interface AccountAndAnalysis {
        account: AccountInfo
        analysis: AccountRecurringMonthlyBudgetAnalysis
    }
    const analyses: AccountAndAnalysis[] | undefined = (flows && accounts && organization) && _.sortBy(accounts, a => `${a.kind}-${a.category}-${a.name}`).map(a => {
        const analysis = analyzeMonthlyBudgetForAccount(a.id, flows, now, filter)
        return { account: a, analysis }
    })

    const totals = analyses && summarize(analyses.map(i=>i.analysis))

    function refresh() {
        getData()
    }

    useEffect(refresh, [analysisWindow.to])

    return (<>
        <FloatingActionBar isRefreshing={isLoading} onRefresh={refresh}/>
        <Stack spacing={3}>
            <Box>
                <Stack spacing={3}>
                    <Typography variant="h2">Budget</Typography>
                    <Typography>This is an analysis of active, recurring flows.  One-time and unplanned flows are excluded.  Apart from major one-time planned expenses, this should be directionally-accurate over the long-run. </Typography>
                </Stack>
            </Box>
            {/* <Typography variant="h3">Overview</Typography>
            
            {totals && <Table
                header={["What", "Amount"]}
                rows={[
                    ["Income", util.formattedTotals(totals.incomes)],
                    ["Expenses", util.formattedTotals(totals.expenses)],
                    ["Net", util.formattedTotals(totals.net)],
                ]}
            />} */}

            <Typography variant="h3">Overview</Typography>
            <AnalysisWindowControls onChange={setAnalysisWindow} window={analysisWindow} doUpdate={refresh} isFetching={isLoading} />
            <Box style={{ background: "white" }}>
                <PlanChart
                    focusAccountId={undefined}
                    standardData={data}
                    showDetails={true}
                    data={chartData} />
            </Box>

            {totals && <Box>
                Net: {util.formattedTotals(totals.net)} - Income: {util.formattedTotals(totals.incomes)} - Expenses: {util.formattedTotals(totals.expenses)}
                </Box>}
            {(flows && organization) ?
                <BudgetView
                    accounts={accounts}
                    filter={filter}
                    flows={flows}
                /> : <CircularProgress />}

            <Typography variant="h3">Details</Typography>
            {(analyses) ? analyses.map(({ account, analysis }) => {

                return (<>
                    {(analysis.changes.length > 0) && <><Typography variant="h4">{account.name}</Typography>
                        
                        <AccountRecurringMonthlyBudgetAnalysisTable analysis={analysis} />
                        
                    </>}
                </>)
            }) : <CircularProgress />}
        </Stack>
    </>)
}