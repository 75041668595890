import React from 'react'
import http from './cj/HttpClient'
import { getTenantId, } from './util'
import { AccountInfo, Flow, BoxedFrequency, PlannedFundMove, FundsFlow, } from './api'
import LoadingThrobber from './LoadingThrobber'
import PlannedItemsTable from './PlannedItemsTable'
import { Stack } from '@mui/material'
import MovesScreen from './MovesScreen'
import RoutedSubNavigation from './RoutedSubNavigation'
import PlanConfigScreen from './PlanConfigScreen'
import PlanReview from './PlanReview'
import { Route, Routes } from 'react-router-dom'
import MovePage, { PlannedMovePage } from './MovePage'
import PlanItemPage from './PlanItemPage'
import BudgetPage from './BudgetPage'


export const FullPlanTransactionsScreen = (props: {}) => {
    const balances: Record<string, number> = {}
    const [allProjections, setallProjections] = React.useState<PlannedFundMove[]>()
    const [accounts, setAccounts] = React.useState<AccountInfo[]>([])

    const getData = (onSuccess?: () => void) => {
        http({
            url: `/api/tenants/${getTenantId()}/projections`,
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    setallProjections(JSON.parse(response.body))
                    if (onSuccess) onSuccess()
                } else {
                    alert("Error!  Server said:" + response.body);
                }
            }
        });
    }

    function fetchAccounts() {
        http({
            url: `/api/tenants/${getTenantId()}/accounts`,
            method: "GET",
            onResponse: function (response) {
                // console.log("done fetching");
                if (response.status === 200) {
                    setAccounts(JSON.parse(response.body))
                } else {
                    throw "Error retrieving "
                }
            }
        });
    }

    const isAccount = (a: AccountInfo) => a.kind.toLowerCase() !== "account"

    const isAccountId = (id: string): boolean => {
        const account = accounts.find(a => a.id == id)
        return account ? isAccount(account) : false
    }

    const isFlowInOrOut = (flow: FundsFlow): boolean => {
        return isAccountId(flow.toFundId) || isAccountId(flow.fromFundId)
    }

    const isFlowOut = (flow: FundsFlow): boolean => {
        return isAccountId(flow.toFundId)
    }

    const markDirtyAndReload = () => {
        getData()
    }

    React.useEffect(getData, [])
    React.useEffect(fetchAccounts, [])

    if (!accounts || !allProjections) {
        return <LoadingThrobber isLoading={true} />
    } else {
        return (<Stack style={{ marginTop: "20px" }}>
            <PlannedItemsTable
                showSourceAccountsColumn={true}
                blockUI={() => { }}
                markDirtyAndReload={markDirtyAndReload}
                overallBalances={balances}
                projections={allProjections ?? []}
                isFlowInOrOut={isFlowInOrOut}
                isFlowOut={isFlowOut}
                accounts={accounts} />
        </Stack>
        )
    }
}

export default (props: {}) => {
    return <RoutedSubNavigation propertyName='subtab'
        tabs={{
            "moves": () => <MovesScreen />,
            "future": () => <FullPlanTransactionsScreen />,
            "plan": () => <Routes>
                <Route index element={<PlanConfigScreen/>} />
                <Route path=":planItemId/*" element={<PlanItemPage/>} />
            </Routes>,
            "budget": () => <BudgetPage />,
            "budget performance": () => <PlanReview />,
        }}

        extraRoutes={<>
            <Route path="elements/planned/:sequenceId/:instanceId*" element={<PlannedMovePage/>} />
            <Route path="elements/realized/:moveId/*" element={<MovePage/>} />
        </>}
    />
}