import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { AccountInfo, Flow, PhysicalAccountInfoDto, Recommendation } from './api'
import MappingSpecViewer from './MappingSpecViewer'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export interface RecommendationWidgetProps {
	recommendation: Recommendation
	getAccount: (id: string) => AccountInfo | undefined,
	recommendationAction?: (() => void) | undefined
	flowProjections?: Flow[]
	physAccounts: PhysicalAccountInfoDto[]
}
export default (props: RecommendationWidgetProps) => {
	const { recommendation, getAccount, recommendationAction, flowProjections, physAccounts } = props
	const [isWorking, setWorking] = React.useState(false)



	if (!recommendationAction) {
		return <></>
	} else {
		let doRecommendationAction = () => {
			setWorking(true)
			recommendationAction()
		}

		return <Grid container spacing={3} direction="row">
			<Grid xs={12} sm={6}>
				<MappingSpecViewer
					disabled={false}
					physAccounts={physAccounts}
					mapping={recommendation.mapping}
					currency='??'
					getAccount={getAccount}
					handleChange={() => console.log(`Something changed on ${JSON.stringify(recommendation)}`)} />
			</Grid>
			{<Grid xs={12} sm={6}>
				<Button variant="contained" disabled={isWorking} onClick={doRecommendationAction}>Accept Recommendation</Button>
			</Grid>}
		</Grid>
	}

}