import { Alert, Box, Link, Stack, } from "@mui/material"
import { FundMove, FullPhysicalItemDetails, } from "./api"
import React from "react"
import util, { take } from "./util"
import PhysicalItemPanel from "./PhysicalItemPanel"

export default (props: { move: FundMove, items: FullPhysicalItemDetails[] | undefined }) => {
    const { move } = props
    const items = props.items ?? []
    const [doLimit, setDoLimit] = React.useState(true)
    const limit = 5
    const maxItemsToShow = doLimit ? limit : move.physicalMappings.length


    return (
        <Stack>

            {move?.physicalMappings && take(move?.physicalMappings, maxItemsToShow).map(phys => {
                    
                const details = items.find(i => i.accountId == phys.physicalAccountId && i.item.id == phys.physicalItemId)

                const info = details ? <PhysicalItemPanel item={details} /> : <Box>Stuff was missing, but here's what I can give you: {util.formatAmount(phys.amount, move.currency)} {phys.physicalItemId}</Box>

                return (<>
                    {(details && details.item.amount != phys.amount) && <Alert color="warning">Partial mapping: {util.formatAmount(phys.amount, move.currency)}/{util.formatAmount(details.item.amount, move.currency)}</Alert>}  {info}
                </>)

            })}
            {(move?.physicalMappings && move.physicalMappings.length > limit) && <Link onClick={() => setDoLimit(!doLimit)}>{move.physicalMappings.length - maxItemsToShow} other items</Link>}
        </Stack>
    )
}
