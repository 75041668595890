import React, { CSSProperties, ReactNode } from 'react'
import RecommendationWidget from './recommendationWidget'
import { AccountInfo, Flow, ItemInfo, PhysicalAccountInfoDto, PhysicalItem, Recommendation } from './api'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from 'react-router-dom';

export const AccordionExpandToggle = (props:{isShowing:boolean, onToggle:(showing:boolean)=>void, style?:CSSProperties}) => {
    const {isShowing, onToggle, style} = props
    return (<>

        <IconButton size='large' style={{... style, margin: "0px", padding: "0px", marginLeft: "10px", marginBottom: "10px"}} onClick={()=>onToggle(!isShowing)}>
                {isShowing ? <KeyboardArrowUpIcon fontSize='large' /> : <KeyboardArrowDownIcon fontSize='large' />}</IconButton>
    </>)
}

interface UncategorizedScreenEntryProps {
    id: string,
    label: ReactNode | string,
    detailLink:string,
    detailFunction: (notUsed: any | undefined, callback: () => void) => ReactNode | string | undefined
    recommendation?: Recommendation
    recommendationAction?: () => void
    getAccount: (id:string)=>AccountInfo|undefined, 
    flowProjections?: Flow[]
    physAccounts:PhysicalAccountInfoDto[]
}

export default (props: UncategorizedScreenEntryProps) => {
    const { id, label, detailLink, detailFunction, recommendation, recommendationAction, getAccount, flowProjections, physAccounts} = props

    const [isShowing, setShowing] = React.useState(true)
    const [isEditorShowing, setisEditorShowing] = React.useState(false)


    function toggleVisible() {
        setisEditorShowing(!isEditorShowing)
    }

    function editingDidComplete() {
        setShowing(false)
    }

    if (isShowing) {
        return <div id={id} className="item " >
            <AccordionExpandToggle
                isShowing={isEditorShowing}
                onToggle={toggleVisible}
                style={{float: "right" }}
                />
            <Stack spacing={2}>
                <Typography>{label}</Typography>
                {recommendation && <div className="recommendation"><RecommendationWidget
                    physAccounts={physAccounts}
                    recommendation={recommendation}
                    recommendationAction={recommendationAction}
                    getAccount={getAccount}
                    flowProjections={flowProjections} />
                </div>}
                {isEditorShowing && <div className="addWidget" >{isEditorShowing ? detailFunction(null, editingDidComplete) : ""}</div>}
            </Stack>
        </div>
    } else {
        return <></>
    }

}
