import OFXGeneralSettingsPage, { OfxEditorResult } from './OFXGeneralSettingsPage'
import PlaidGeneralSettingsPage, { PlaidEditingResult } from './PlaidGeneralSettingsPage'
import AccountMappingPage from './AccountMappingPage'
import { AccountSyncConfig, ConnectionConfigDto, ConnectionSpecificationDto } from '../api'
import { ReactNode, useState } from 'react'
import WizardReact from '../WizardReact'

export default function (props: { type: string, existingConnection: ConnectionConfigDto | undefined, onComplete: (v: ConnectionConfigDto) => void }) {
  const { type, existingConnection, onComplete } = props
  console.log("type", type, "existingConnection", existingConnection)

  const [name, setName] = useState(existingConnection?.name ?? "")
  const [specification, setSpecification] = useState<ConnectionSpecificationDto | undefined>()
  const [accountConfigs, setAccountConfigs] = useState<AccountSyncConfig[] | undefined>(existingConnection?.accounts)

  const setPlaidResult = (r: PlaidEditingResult) => {
    setName(r.name)
    setSpecification(r.newConnectionInfo)
  }
  const setOfxResult = (r: OfxEditorResult) => {
    setName(r.name)
    setSpecification(r.newConnectionInfo)
  }

  const pages: Record<string, () => ReactNode> = {
    "General Settings": () => { return type === "plaid" ? <PlaidGeneralSettingsPage connection={existingConnection} onChange={setPlaidResult} /> : <OFXGeneralSettingsPage connection={existingConnection} onChange={setOfxResult} /> },
    "Account Mappings": () => <>
      <AccountMappingPage
        maybeExistingConnection={existingConnection}
        existing={accountConfigs ?? []}
        newConnectionInfo={specification} onChange={setAccountConfigs} />
    </>
  }

  const doSubmit = () => {
    console.log(" accountConfigs", accountConfigs)
    console.log(" specification", specification)
    console.log(" existingConnection?.remote", existingConnection?.remote)
    const specToUse = specification ?? existingConnection?.remote
    console.log(" specToUse", specToUse)
    if (specToUse && accountConfigs) {
      onComplete({
        name: name,
        id: existingConnection?.id ?? "whatever-this-is-new",
        remote: specification,
        accounts: accountConfigs
      })
    }
  }

  return (<>
    <WizardReact
      pages={pages}
      onSubmit={doSubmit}
    />
  </>)
};
