import { useEffect, useState } from 'react'
import $ from 'jquery'
import _ from 'underscore'
import util from './util'
import Wizard from './WizardNg'
import PreflightPanel from './PreflightPanel'
import MainPanel from './MainPanel'
import { AccountInfo, FundMove, MoveChangeResult } from './api'
import { getAccountsPromise } from './api-actions'
import { CircularProgress } from '@mui/material'

interface MoveEditorProps {
    move:FundMove | undefined
    onComplete:(m:FundMove)=>void
    onDelete:()=>void
}

interface MoveEditorState {
    move:FundMove
    affordability?:MoveChangeResult
}
export default (props:MoveEditorProps) => {
    const [accounts, setAccounts] = useState<AccountInfo[]>()

    useEffect(()=>{
        getAccountsPromise().then(result=>{
            var accounts:AccountInfo[] = [];
            var accountsByKind = util.groupArray(result, "kind");
    
            $.each(accountsByKind, function(kind, accountsForKind){
                _.each(accountsForKind, function(account){
                    accounts.push(account);
                });
            });
            setAccounts(accounts)
        }).catch(()=> alert("Error loading!"))
    }, [])
    if(accounts){
        return <MoveEditorInner accounts={accounts} {... props}/>
    }else{
        return <CircularProgress/>
    }
}

const defaultEmptyMove = (accounts:AccountInfo[]):FundMove => {

    let defaultToAccount = _.find(accounts, (a)=>a.kind == "Account")
    let defaultEmptyMove:FundMove = {
        currency:"USD", 
        when:(new Date().getTime()), 
        memo:"", 
        amount:33, 
        flows:[{
            amount:100, 
            fromFundId:"generic-income-expense", 
            toFundId:defaultToAccount?defaultToAccount.id:"generic-income-expense"}], 
        physicalMappings:[],
        id: undefined,
        plannedItemId: undefined,
        warningDismissals: undefined,
        whenEnds: undefined
    }

    return defaultEmptyMove
}

const MoveEditorInner = (props:{move:FundMove | undefined, accounts:AccountInfo[], onComplete:(m:FundMove)=>void, onDelete:()=>void})=>{
    
    const {accounts} = props
    const [state, setState] = useState<MoveEditorState>({
        move:props.move || defaultEmptyMove(props.accounts)
    })


    console.log("MoveWizard state", state)

    let handleMoveChange = (move:FundMove)=>{
        setState({
            ... state,
            move:move
        })
    }

    let handlePreflight = (affordability:MoveChangeResult)=>{
        setState({
            ... state,
            affordability:affordability
        })
    }
    
    let editorPanel = ()=><MainPanel move={state.move} onChange={handleMoveChange} onDelete={props.onDelete} accounts={accounts}/>
    let preflight = ()=><PreflightPanel move={state.move} onChange={handlePreflight}/>

    let onComplete = ()=>{
        let move = state.move
        console.log("Wizard complete: move is ", move)
        props.onComplete(move)
    }

    return <Wizard 
            // className="move-editor"
            panels={[editorPanel, preflight]}
            onComplete={onComplete}/>
    
}

    
