import React from 'react'
import { Frequency, Monthly, Once, PDate, Weekly, Yearly, dateIsAfter } from './api'
import { MenuItem, Select, Stack, TextField } from '@mui/material';
import { PDatePicker, dateAtMillisInCurrentTimeZone, monthDayToString, parseMonthDay, today } from './DateAndTime';

function toNumber(v: string) {
    return parseInt(v, 10)
}

function trim(o: string) {
    return o.trim()
}

const OnceWidget = (props: { spec: Once, disabled?: boolean, onChange: (v: Once) => void }) => {
    const { spec, disabled, onChange } = props

    return <PDatePicker
        label="Once on"
        disabled={disabled}
        value={spec.type === "once" ? spec.when : undefined}
        onChange={newValue => {
            onChange({
                type: "once",
                when: newValue || spec.when
            })
        }} />

}

interface RecurringWidgetState {
    spec: Frequency
    pattern: string
}
const RecurringWidget = (props: { spec: Frequency, disabled?: boolean, onChange: (v: Frequency | undefined) => void }) => {


    const [state, setTheState] = React.useState<RecurringWidgetState>(calcInitialState(props.spec))



    function calcInitialState(spec: Frequency): RecurringWidgetState {
        let pattern;

        if (spec.type === "monthly") {
            const monthly = spec as Monthly
            pattern = monthly.daysOfMonth.join(",")
        } else if (spec.type === "yearly") {
            const yearly = spec as Yearly
            if (yearly.dates) {
                pattern = yearly.dates.map(monthDayToString).join(",")
            }
        } else if (spec.type === "weekly") {
            const weekly = spec as Weekly
            pattern = weekly.daysOfWeek.join(",")
        }

        return {
            spec: props.spec,
            pattern: pattern ?? ""
        }
    }

    function setState(state: RecurringWidgetState) {
        setTheState(state)
        console.log("State changed", state)
        props.onChange(state.spec)
    }


    let { spec, pattern } = state
    let isDisabled = props.disabled

    var calendarUnitLabels: Record<string, string> = {
        monthly: "day(s)",
        yearly: "date(s)",
        weekly: "day(s) "
    }

    let calendarSelectOptions: Record<string, string> = { monthly: "Month(s)", yearly: "Year(s)", weekly: "Week(s)" }

    let patternLabel = calendarUnitLabels[spec.type]
    let skipUnits = (spec as any).skip ?? 1
    let first: PDate | undefined = (spec as any).first
    let last: PDate | undefined = (spec as any).last

    console.log("first/last is", first, last, spec)

    let setSpecState = (spec: Frequency, isValid: boolean) => {
        console.log("spec updated: ", spec)

        setTheState({
            ...state,
            spec: spec
        })
        props.onChange(isValid ? spec : undefined)
    }

    let patternPropertiesForType = (type: string, pattern: string) => {
        var patternEntries = pattern.split(",").map(trim).filter(function (v) { return v.length > 0; })
        if (type === "monthly") {
            return { daysOfMonth: patternEntries.map(toNumber) }
        } else if (type === "yearly") {
            return { dates: (patternEntries.length > 0) ? patternEntries.map(parseMonthDay) : null }
        } else if (type === "weekly") {
            return { daysOfWeek: patternEntries }
        }
    }

    let handleSkipUnitsChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setSpecState(
            {
                ...state.spec,
                skip: parseInt(event.target.value, 10)
            } as Frequency,
            true
        )
    }

    let handleCalendarUnitChange = (type: string) => {
        let spec = state.spec
        // let type = event.target.value
        const foo: any = {
            type: type,
            first: (spec as any).first,
            last: (spec as any).last,
            ...patternPropertiesForType(type, state.pattern)
        }
        setSpecState(foo as Frequency, true)
    }

    let handleStartChange = (newValue: PDate | undefined) => {
        console.log("spec new first value:", newValue)
        setSpecState(
            {
                ...state.spec,
                first: (newValue ?? first)
            } as Frequency,
            (newValue && last && dateIsAfter(last, newValue)) ? true : false
        )
    }

    let handleEndChange = (newValue: PDate | undefined) => {
        console.log("spec new last value:", newValue)

        setSpecState(
            {
                ...state.spec,
                last: (newValue ?? last)
            } as Frequency,
            (newValue && first && dateIsAfter(newValue, first)) ? true : false
        )
    }

    let handlePatternChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        let spec = state.spec
        let pattern = event.target.value
        setState({
            ...state,
            pattern: pattern,
            spec: {
                ...state.spec,
                ...patternPropertiesForType(spec.type, pattern)
            }
        })
    }

    return <div className="recurring-widget">
        <Stack direction="row">
            <TextField label="Recurring every" disabled={isDisabled} className="skipUnits" type="number" value={skipUnits} onChange={handleSkipUnitsChange} />
            <Select value={spec.type} disabled={isDisabled} className="calendar-unit" onChange={e => handleCalendarUnitChange(e.target.value)}>
                {Object.keys(calendarSelectOptions).map(type => <MenuItem value={type} >{calendarSelectOptions[type]}</MenuItem>)}
            </Select>
        </Stack>

        <div>
            <PDatePicker
                label="From"
                disabled={isDisabled}
                value={first}
                onChange={handleStartChange} />

            <PDatePicker
                label="To"
                disabled={isDisabled}
                value={last}
                onChange={handleEndChange} />
        </div>
        <div>
            <TextField
                label={`on ${patternLabel}`}
                disabled={isDisabled}
                className="pattern"
                placeholder="comma-separated list of days of week, month, year, etc" value={pattern} onChange={handlePatternChange} />
        </div>
    </div>

}

const defaultOnce: Once = {
    type: "once",
    when: today()
}

const defaultMonthly: Monthly = {
    type: "monthly",
    first: today(),
    last: dateAtMillisInCurrentTimeZone(2208992400000),
    skip: 1,
    daysOfMonth: [1]
}
export default (props: { spec: Frequency, onChange: (n: Frequency | undefined) => void }) => {
    const [once, setOnce] = React.useState(props.spec.type == "once" ? (props.spec as Once) : defaultOnce)
    const [repeat, setRepeat] = React.useState(props.spec.type != "once" ? props.spec : defaultMonthly)
    const [mode, setMode] = React.useState<"once" | "recurring">(props.spec.type == "once" ? "once" : "recurring")

    let doChange = (v: Frequency | undefined) => {
        console.log("frequency is:", v)
        props.onChange(v)
    }

    let handleModeSelect: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        let mode = event.target.value
        console.log("Mode changed to ", mode)
        setMode(mode as any)

        if (mode == "once") {
            doChange(once)
        } else {
            doChange(repeat)
        }
    }


    let applyChange = (newStuff: Frequency | undefined) => {
        if (newStuff) {

            if (newStuff.type == "once") {
                setOnce(newStuff as Once)
            } else {
                setRepeat(newStuff)
            }
        }
        doChange(newStuff)
    }

    return <div className="frequency-widget">
        <div>
            <div><input type="radio" name="repetition" className="once" value="once" checked={mode === "once"} onChange={handleModeSelect} /> <OnceWidget disabled={mode != "once"} spec={once} onChange={applyChange} /> </div>
            <div><input type="radio" name="repetition" className="recurring" value="recurring" checked={mode === "recurring"} onChange={handleModeSelect} /> <RecurringWidget disabled={mode != "recurring"} spec={repeat} onChange={applyChange} />
            </div>
        </div>
    </div>
}



