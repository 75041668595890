import React, { ReactNode } from "react";
import { AccountInfo, ConnectionsListItem, Flow, FullMoveDetails, FundMove, ItemInfo, MappingOption, PhysicalAccountInfoDto, PlannedFundMove, PlannedItemHandle, Template } from "./api";
import MoveEditorDialog from "./MoveEditorDialog";
import { Problem } from "./ErrorAlert";
import ErrorDialog from "./ErrorDialog";
import FlowProjectionEditorDialog from "./FlowProjectionEditorDialog";
import { getPlanItem } from "./api-actions";
import { AccountEditorDialog } from "./AccountEditor";
import AccountingEntryDialog, { PlannedItemDialog } from "./AccountingEntryDialog";
import ItemEditorDialog, { ContextualPhysicalItem } from "./ItemEditorDialog";
import ConnectionEditorDialog, { ConnectionEditingStuff } from "./ConnectionEditorDialog";
import NewAccountDialog from "./NewAccountDialog";
import PhysicalAccountDialog from "./PhysicalAccountDialog";
import NewTemplateDialog from "./NewTemplateDialog";
import ActualizationDialog from "./ActualizationDialog";

export interface Listener<T> {
    value: T
    onChange: (newVal: T) => void
}

export interface DialogsState {
    move?: FundMove,
    newTemplate:(onSuccess:(a:Template)=>void)=>void,
    newPhysicalAccount:(onSuccess:(a:PhysicalAccountInfoDto)=>void)=>void,
    showNewAccount: (onSuccess:()=>void) => void,
    showAccount: (l: Listener<AccountInfo>) => void,
    showMove: (l: Listener<FundMove|undefined>) => void,
    showActualization: (planned: PlannedFundMove, onChange: (realized: FundMove) => void) => void,
    error?: Problem
    showError: (l: Listener<Problem>) => void
    flow?: Flow
    showFlow: (l: Listener<Flow>) => void
    showFlowById: (flowId: string, onChange: (n: Flow) => void) => void
    showFullMove: (m: FullMoveDetails, onChange: () => void) => void
    showPlannedItem: (m: PlannedItemHandle, onChange: () => void) => void
    showItemMapper: (l: Listener<ContextualPhysicalItem>) => void
    showConnection: (l: Listener<ConnectionEditingStuff>) => void
    doActualize:(projection: PlannedFundMove, onChange:()=>void) => void
}

export const DialogsContext = React.createContext<DialogsState>({
    newTemplate: () => { },
    newPhysicalAccount: () => { },
    showNewAccount: () => { },
    showAccount: () => { },
    showMove: () => { },
    showActualization: () => { },
    showError: () => { },
    showFlow: () => { },
    showFlowById: () => { },
    showFullMove: () => { },
    showPlannedItem: ()=>{},
    showItemMapper: () => { },
    showConnection: () => { },
    doActualize:()=>{},
});

interface PendingActualization {
    planned:PlannedFundMove
    actual:FundMove|undefined
}
export default (props: { children?: ReactNode | string | undefined }) => {
    const { children } = props

    const [template, setTemplate] = React.useState<Listener<Template|undefined>>()
    const [physicalAccount, setPhysicalAccount] = React.useState<Listener<PhysicalAccountInfoDto|undefined>>()
    const [move, setMove] = React.useState<Listener<FundMove|undefined>>()
    const [actualization, setActualization] = React.useState<Listener<PendingActualization>>()

    const [error, setError] = React.useState<Listener<Problem>>()
    const [flow, setFlow] = React.useState<Listener<Flow>>()
    const [newAccount, setNewAccount] = React.useState<Listener<string>>()
    const [account, setAccount] = React.useState<Listener<AccountInfo>>()
    const [fullMove, setFullMove] = React.useState<Listener<MappingOption>>()
    const [itemMapped, setItemMapped] = React.useState<Listener<ContextualPhysicalItem>>()
    const [connection, setConnection] = React.useState<Listener<ConnectionEditingStuff>>()
    const [plannedItem, setPlannedItem] = React.useState<Listener<PlannedItemHandle>>()

    console.log("Rendering dialogs with ", move)

    const state: DialogsState = {
        doActualize:(projection: PlannedFundMove, onChange:()=>void)=>{
            var item = projection.planned;
            console.log("Edit link was clicked for ", item)
            const copy = { ...item, id: undefined }
            setMove({
                value:copy,
                onChange:onChange
            })
        },
        showPlannedItem:(handle, onChange)=>{
            setPlannedItem({value:handle, onChange:onChange})
        },
        newTemplate:(listener)=>{
            setTemplate({
                value:undefined,
                onChange:(v)=>{
                    v && listener(v);
                }
            })
        },
        newPhysicalAccount:(listener)=>{
            setPhysicalAccount({
                value:undefined,
                onChange:(v)=> v && listener(v),
            })
        },
        move: move?.value,
        showNewAccount: (listener)=>{
            setNewAccount({
                value:"fsdfds",
                onChange:listener
            })
        },
        showAccount: setAccount,
        showMove: setMove,
        showActualization: (planned: PlannedFundMove, onChange: (realized: FundMove) => void) => {
            setActualization({
                value: {
                    planned, 
                    actual:undefined,
                },
                onChange: (s)=>{s?.actual && onChange(s.actual)}
            })
        },
        error: error?.value,
        showError: setError,
        flow: flow?.value,
        showFlow: setFlow,
        showFlowById: (flowId: string, onChange: (n: Flow) => void) => {
            getPlanItem(flowId, (flow) => {
                setFlow({
                    value: flow,
                    onChange: onChange
                })
            })
        },
        showFullMove(m, onChange: () => void) {
            setFullMove({
                value: {
                    planned: m.planned?.plannedFundMove,
                    move: m.actual,
                    items: m.physicalItems,
                },
                onChange: onChange
            })
        },
        showItemMapper: setItemMapped,
        showConnection: setConnection,
    }
    return <>
        <DialogsContext.Provider value={state}>
            {plannedItem && <PlannedItemDialog handle={plannedItem.value} onClose={()=>{
                plannedItem?.onChange(plannedItem.value)
                setPlannedItem(undefined)
            }}/>}
            {template && <NewTemplateDialog onClose={()=>setTemplate(undefined)} onComplete={t=>{
                template?.onChange(t)
                setTemplate(undefined)
            }} />}
            {physicalAccount && <PhysicalAccountDialog existing={physicalAccount.value} onComplete={(change) => { 
                physicalAccount?.onChange(change)
                setPhysicalAccount(undefined)
            }} />}
           
            {newAccount?.value && <NewAccountDialog onClose={()=>setNewAccount(undefined)}/>}

            {connection?.value && <ConnectionEditorDialog
                connectionId={connection.value.existing?.id}
                connectionName="External Connection"
                type={connection.value.type}
                connection={connection.value.existing}
                onSave={()=>{
                    connection.onChange(connection.value)
                    setConnection(undefined)
                }}
                onClose={()=>setConnection(undefined)}
            />}
            {itemMapped && <ItemEditorDialog
                item={itemMapped.value}
                onSuccess={()=>{
                    itemMapped.onChange(itemMapped.value)
                    setItemMapped(undefined)
                }}
                handleChange={()=>{
                    itemMapped.onChange(itemMapped.value)
                    setItemMapped(undefined)
                }}
                onClose={()=>setItemMapped(undefined)}
                />}
            {fullMove && <AccountingEntryDialog option={fullMove.value} onClose={() => {
                const l = fullMove
                setFullMove(undefined)
                l.onChange(l.value) // hack - this isn't a new value
            }} />}
            {account && <AccountEditorDialog
                account={account.value}
                onClose={() => setAccount(undefined)}
                onUpdated={update => {
                    account.onChange(update)
                }}
            />}
            {flow && <FlowProjectionEditorDialog
                flow={flow.value}
                onDelete={v => {
                    setFlow(undefined)
                    flow.onChange(v)
                }}
                onComplete={(v: Flow | undefined) => {
                    setFlow(undefined)
                    if (v) {
                        flow.onChange(v)
                    }
                }} />}

            {move && <MoveEditorDialog move={move.value} onClose={(newValue) => {
                if (newValue) {
                    move.onChange(newValue)
                }
                setMove(undefined)
            }} />}

            {actualization && <ActualizationDialog
                planned={actualization.value.planned}
                onSuccess={m=>{                    
                    actualization.onChange({ ... actualization.value, actual:m})
                    setActualization(undefined)
                }}
                onClose={()=>setActualization(undefined)} />}
            {children}
            {error && <ErrorDialog problem={error.value} onClose={() => setError(undefined)} />}
        </DialogsContext.Provider>

    </>
}