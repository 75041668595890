import { Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material"
import { AccountInfo, Flow, PlannedFundMove } from "./api"
import { AccountLink } from "./Links"
import util from "./util"
import { DialogsContext } from "./Dialogs"
import React from "react"
import { getPlanedItem } from "./api-actions"

export const PlannedFundMoveViewer = (props: { plannedMove: PlannedFundMove, getAccount: (id: string) => AccountInfo | undefined})=>{
    const { getAccount } = props
    const [plannedMove, setPlannedMove] = React.useState(props.plannedMove)
    const move = plannedMove.planned
    const planSequenceId = move.plannedItemId?.sequenceId

    return (<Stack>
        <Typography variant="h5" component="div">Unrealized Planned Move </Typography>
        <Typography>{util.formatLongDateTime(move.when)}</Typography>
        <Typography>{move.memo}</Typography>
        {move.flows.map(flow => {
            const from = getAccount(flow.fromFundId)
            const to = getAccount(flow.toFundId)
            return <>
                <Typography>{flow.amount && util.formattedColorAmount(flow.amount, move.currency)}</Typography>
                <Typography>From: <AccountLink target="_blank" account={from} /></Typography>
                <Typography>To: <AccountLink target="_blank" account={to} /></Typography>
            </>
        })}
    </Stack>)
}

export default (props: { plannedMove: PlannedFundMove, getAccount: (id: string) => AccountInfo | undefined, handleChange: () => void }) => {
    const { getAccount, handleChange } = props
    const [plannedMove, setPlannedMove] = React.useState(props.plannedMove)
    const move = plannedMove.planned
    const planSequenceId = move.plannedItemId?.sequenceId
    console.log(JSON.stringify(move))

    const respondToChange = () => {
        if (move.plannedItemId) {
            getPlanedItem(move.plannedItemId, setPlannedMove)
            handleChange()
        }
    }
    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <PlannedFundMoveViewer plannedMove={plannedMove} getAccount={getAccount}/>
            </CardContent>
            <CardActions>
                <DialogsContext.Consumer>{dialogs => <>
                    <Button onClick={() => planSequenceId && dialogs.showFlowById(planSequenceId, respondToChange)}>Edit Plan</Button>
                    {(plannedMove && !plannedMove?.actualization) && <Button onClick={() => dialogs.doActualize(plannedMove, respondToChange)}>Actualize</Button>}                    
                </>}</DialogsContext.Consumer>

            </CardActions>
        </Card>
    )
}
