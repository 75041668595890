import { Box, Chip, Stack } from "@mui/material"
import { ContextualizedPendingTransfer, ItemInfo } from "./api"


export type AttributeFilters = Record<string, string>

export const plusAttributeFilter = (filters:AttributeFilters, key:string, value:string):AttributeFilters=>{
    return {
        ... filters,
        [key]:value,
    }
}
export const minusAttributeFilter = (filters:AttributeFilters, key:string, value:string):AttributeFilters=>{
    const copy:Record<string, string> = {
    }

    Object.keys(filters).forEach(k=>{
        if(k!=key){
            copy[k] = filters[k]
        }
    })
    
    return copy
}


export const pendingMatchesFilter = (cpt:ContextualizedPendingTransfer, filters:AttributeFilters):boolean => {
    const filterKeys = Object.keys(filters)
    return filterKeys.length==0
}

export const attributeMatchesFilter = (i:ItemInfo, filters:AttributeFilters):boolean=>{
    const structuredMemo = i.item.structuredMemo
    const filterKeys = Object.keys(filters)
    if(filterKeys.length>0){
        if(structuredMemo){
            let numMatches = 0
            const keysOnItem = Object.keys(structuredMemo)
            filterKeys.forEach(k=>{
                const expectedValue = filters[k]
                const actualValue = structuredMemo[k]
                if(actualValue === expectedValue){
                    numMatches += 1
                }
            })
            
            const matches = numMatches == filterKeys.length
            if(matches){
                console.log("Matches? ", numMatches == filterKeys.length, numMatches, filterKeys.length, i.item.memo)
            }
            return matches
        }else{
            return false
        }
    }else{
        return true
    }
}


export const ItemFiltersView = (props:{filters:AttributeFilters, onChange:(newFilters:AttributeFilters)=>void})=>{
    const {filters, onChange} = props

    return (<>
    {Object.keys(filters).length > 0 && <Box style={{
                    position:"fixed", 
                    bottom:20, 
                    left:20,
                    background: "white",
                    border: "1px solid grey",
                    padding: "10px",
                    borderRadius: "10px",
                    opacity: 1,
                    zIndex: 2,
                }}>
                <Stack direction="row" spacing={2}>
                    {Object.keys(filters).map(key=>{
                        const value = filters[key]
                        return <Chip label={`${key}=${value}`} onDelete={()=>onChange(minusAttributeFilter(filters, key, value))}/>
                    })}
                </Stack>
            </Box>}
    </>)
}