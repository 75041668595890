import React, { CSSProperties, useEffect, useState } from 'react'
import _ from 'underscore'
import util, { formatDateTimeRange, getTenantId, useJsonUrlParam, useUrlParam } from './util'
import LoadingThrobber from './LoadingThrobber'
import { data as dataThing } from './data'
import { AnalysisTag, Flow, FullMoveDetails, FullMoveDetailsPage, FundMove, MappingOption, PhysicalAccountInfoDto, WarningDismissal } from './api'
import PhysicalItemPanel from './PhysicalItemPanel'
import { Alert, Box, Button, ButtonProps, ButtonTypeMap, Chip, CircularProgress, Divider, ExtendButtonBaseTypeMap, IconButton, Link, Stack, Typography } from '@mui/material'
import { MovesSearchParameters, closePlannedItem, closePlannedItemPromise, createOrUpdateMove, fetchFullMoveDetailsPage, getFullMove, getMove, getPhysicalAccountInfos, getPlanItem, getPlanedItem, reOpenPlanedItem, reOpenPlanedItemPromise } from './api-actions'
import { DialogsContext } from './Dialogs'
import InstantSelect from './InstantSelect'
import CloseIcon from '@mui/icons-material/Close';
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { AccountLink } from './Links'
import MarkdownContent from './MarkdownContent'
import PendingTransferPanel from './PendingTransferPanel'
import FloatingActionBar from './FloatingActionBar'
import { AccountingEntryPanel } from './AccountingEntryDialog'
import MovePage, { PlannedMovePage } from './MovePage'
import { OverrideProps } from '@mui/material/OverridableComponent'
import BusyButton from './BusyButton'

const AnalysisTagNote = (props: { value: AnalysisTag, move: FundMove | undefined, onDismissal: () => void }) => {
    const { value: tag, move, onDismissal } = props

    const defaultColors: CSSProperties = {
        border: " 1px solid darkgrey",
        background: "lightgrey",
    }

    const levelColors: Record<string, CSSProperties> = {
        "warn": {
            border: " 1px solid darkorange",
            background: "lightyellow",
        }
    }

    const doDismiss = () => {
        var userConfirmed = window.confirm("Are you sure you want to ignore this?  Think twice!");
        if (userConfirmed && move && tag.tagId) {
            var rationale = window.prompt("Provide a rationale");
            if (rationale) {
                const dismissal: WarningDismissal = {
                    tagId: tag.tagId,
                    warningText: tag.note,
                    rationale: rationale,
                    whenDismissed: new Date().getTime()
                }
                const existingDismissals = move.warningDismissals ?? []
                const update: FundMove = {
                    ...move,
                    warningDismissals: [...existingDismissals, dismissal]
                }
                createOrUpdateMove(update, onDismissal)
            }
        }
    }

    const unDismiss = () => {

        var userConfirmed = window.confirm("Are you sure you want to undismiss this?");
        if (userConfirmed && move) {
            const existingDismissals = move.warningDismissals ?? []
            const update: FundMove = {
                ...move,
                warningDismissals: existingDismissals.filter(d => d.tagId != tag.tagId)
            }
            createOrUpdateMove(update, onDismissal)
        }
    }
    const dismissal = (move?.warningDismissals ?? []).find(d => d.tagId == tag.tagId)
    const isDismissed = dismissal ? true : false

    // console.log("Dismissed?", move?.memo, isDismissed, move?.warningDismissals)

    return (
        <Stack
            spacing={2}
            style={{
                ...defaultColors,
                ...levelColors[tag.level],
                padding: "5px",
                margin: "5px",
            }}>
            {dismissal && <Box>
                <Typography>DISMISSED</Typography>
                <Typography>{dismissal.rationale}</Typography>
                <Button onClick={unDismiss}>Undismiss</Button>
                <Divider />
            </Box>}
            <Box>
                {(tag.tagId && move && !dismissal) && <IconButton size='small' style={{ float: "right" }} onClick={doDismiss}><CloseIcon /></IconButton>}
                {tag.level.toUpperCase()}: {tag.note}
            </Box>
        </Stack>
    )
}

export default (props:{})=>{
    return (<>
            <Routes>
                <Route index element={<MovesScreenProper/>} />
            </Routes>
    </>)
}
export const MovesScreenProper = (props: {}) => {

    const [now, setNow] = useState(new Date().getTime())

    const [isLoading, setLoading] = React.useState(false)
    const [data, setData] = React.useState(dataThing())
    const [pages, setPages] = React.useState<FullMoveDetailsPage[]>([])

    const [onlyFlagged, setOnlyFlagged] = useJsonUrlParam("onlyFlagged", true)
    const [onlyInstantaneous, setOnlyInstantaneous] =  useJsonUrlParam("onlyInstantaneous", false)
    const [timeThreshold, setTimeThreshold] =  useJsonUrlParam("timeThreshold", now)
    const [doRestrictTime, setRestrictTime] =  useJsonUrlParam("restrictTime", false)
    const [planSequenceId, setPlanSequenceId] = useUrlParam("planSequenceId")

    const [physAccounts, setPhysAccounts] = useState<PhysicalAccountInfoDto[]>()

    const {tenantId} = useParams()

    const navigate = useNavigate()


    const [planSequence, setPlanSequence] = React.useState<Flow>()

    useEffect(()=>getPhysicalAccountInfos(setPhysAccounts), [])

    React.useEffect(() => {
        if (planSequenceId) {
            getPlanItem(planSequenceId, setPlanSequence)
        } else {
            setPlanSequence(undefined)
        }
    }, [planSequenceId])


    function refreshEverything() {
        // for each loaded page, re-fetch it
        setLoading(true)
        const numPagesToLoad = pages.length
        const updatedPages: FullMoveDetailsPage[] = []
        const fetchRemaining = (fromPageNum: number) => {
            console.log("Fetch Remaining from ", fromPageNum)
            if (fromPageNum <= numPagesToLoad) {
                fetchFullMoveDetailsPage(fromPageNum, searchParams(), (page) => {
                    if (page) {
                        updatedPages.push(page);
                        fetchRemaining(fromPageNum + 1);
                    }
                })
            } else {
                console.log("All done fetching pages")
                setLoading(false)
                setPages(updatedPages)
            }
        }

        setLoading(true)
        fetchRemaining(1)
    }

    const searchParams = (): MovesSearchParameters => {
        return {
            planSequenceId: planSequenceId,
            onlyInstantaneous: onlyInstantaneous,
            onlyFlagged: onlyFlagged,
            before: doRestrictTime ? timeThreshold : undefined
        }
    }

    function getNextPage() {
        let pageNum = pages.length + 1
        setLoading(true)

        fetchFullMoveDetailsPage(pageNum, searchParams(), (page) => {
            if (page && page.items.length > 0) {
                console.log("Got page", pageNum)
                setPages([...pages].concat([page]))
            } else {
                console.log("No more pages")
            }
            setLoading(false)
        })
    }

    let doSearch = () => {
        setPages([])
        setLoading(true)

        fetchFullMoveDetailsPage(1, searchParams(), (page) => {
            if (page) {
                setPages([page])
            }
            setLoading(false)
        })
    }

    React.useEffect(doSearch, [onlyInstantaneous, onlyFlagged, timeThreshold, doRestrictTime, planSequenceId])

    let loadMore = () => {
        getNextPage()
    }

    function flatMap<T, R>(list: T[], fn: (i: T) => R[]): R[] {
        var flatMap = _.compose(_.flatten, _.map);
        return flatMap(list, fn)
    }

    let rows = flatMap(pages, (page) => flatMap(page.items, (item) => {
        let move = item.actual
        let currency = item.currency
        let plannedId = item.planned?.plannedFundMove.id
        let theFlows = move?.flows ?? item.planned?.plannedFundMove?.planned?.flows ?? []

        let cellsForFlows = theFlows.map((flow, idx) => {
            const sourceFund = data.account(flow.fromFundId)
            const destFund = data.account(flow.toFundId)
            let [fromEntityId, toEntityId] = item.entityIds[idx]
            // console.log("got", fromEntityId, toEntityId, " from", item.entityIds)
            let values = [
                (idx + 1) + "/" + theFlows.length,
                util.formattedColorAmount(flow.amount, currency),
                sourceFund && <AccountLink account={sourceFund} />,
                destFund && <AccountLink account={destFund} />,
                fromEntityId,
                toEntityId,
                <Stack direction="row">
                    {
                        (plannedId && item.planned?.isComplete) && <BusyButton clickAction={() => reOpenPlanedItemPromise(plannedId!!).then(refreshEverything)}>open</BusyButton>}
                    <DialogsContext.Consumer>{dialogs =>
                        <Stack spacing={2}>
                            {move && <Button onClick={() => move && dialogs.showMove({
                                value: move,
                                onChange: (n) => {
                                    console.log("dialogs: hey, it changed to ", n?.memo)
                                    refreshEverything()
                                }
                            })}>Edit</Button>}
                            {plannedId && <Button onClick={() => {
                                dialogs.showFlowById(
                                    plannedId?.sequenceId!!,
                                    (n) => {
                                        console.log("dialogs: hey, flow changed to ", n)
                                        refreshEverything()
                                    }
                                )
                            }}>Plan</Button>
                            }
                            {plannedId && <Button onClick={() => { setPlanSequenceId(plannedId?.sequenceId) }}>Filter to Plan</Button>}
                            {plannedId && <Button onClick={()=>navigate(`/app/${tenantId}/spend?plannedSequenceId=${plannedId?.sequenceId}&plannedInstanceId=${plannedId?.instanceId}`)}>Safe Spend</Button>}
                            {(move && item.planned?.isComplete == false) && <BusyButton clickAction={() => closePlannedItemPromise(plannedId!!).then(refreshEverything)}>Close</BusyButton>}
                            {(!move && item.planned?.isComplete == false) && <Button onClick={() => item.planned && dialogs.showActualization(item.planned.plannedFundMove, refreshEverything)}>Actualize</Button>}
                        </Stack>
                    }</DialogsContext.Consumer>

                </Stack>
            ]
            return _.map(values, (value) => <td>{value}</td>)
        })

        const planState = (): string => {
            if (item.planned) {
                return item.planned.isComplete ? "Closed" : "Open"
            } else {
                return ""
            }
        }

        const planMisalignment = item.planned ? (Math.abs(item.total) - Math.abs(item.planned?.plannedTotal ?? 0)) : 0
        const cashMisalignment = move ? Math.abs(item.total) - Math.abs(item.totalPhysical ?? 0) : 0

        const isPerfectlyAligned = move && (planMisalignment + cashMisalignment) == 0


        let firstRowPrefix = [
            <div>
                {formatDateTimeRange(item.when, item.whenEnds)}
            </div>,
            <div>
                <DialogsContext.Consumer>
                    {(dialogs) => move && <Link onClick={() => move && dialogs.showFullMove(item, refreshEverything)}>{move.id}</Link>}
                </DialogsContext.Consumer>
                {(!move && plannedId) &&
                    <DialogsContext.Consumer>
                        {(dialogs) => plannedId && <Link onClick={() => plannedId && dialogs.showPlannedItem(plannedId, refreshEverything)}>{plannedId.instanceId} {plannedId.sequenceId.substring(0, 4)}</Link>}
                    </DialogsContext.Consumer>
                }
                {_.map(item.analysis, (tag, idx) => <AnalysisTagNote key={`${idx}-${tag.tagId}`} value={tag} move={move} onDismissal={refreshEverything} />)}
            </div>,
            <MarkdownContent>{move?.memo ?? item.planned?.plannedFundMove.planned.memo}</MarkdownContent>,
            <div>
                {move && <>
                    {isPerfectlyAligned && <Alert color='success'>Exact</Alert>}
                    {!isPerfectlyAligned && <Stack spacing={1}>
                        {item.planned && <Box>To Plan: {util.formattedColorAmount(planMisalignment, currency)}</Box>}
                        <Box>To Cash: {util.formattedColorAmount(cashMisalignment, currency)}</Box>
                    </Stack>
                    }
                </>}

            </div>,
            <div>{item.planned ? util.formattedColorAmount(item.planned.plannedTotal, currency) : "Unplanned"}</div>,
            <div>{planState()}</div>,
            (move && move.physicalMappings.length > 0) ? util.formattedColorAmount(item.totalPhysical, currency) : "-",
            <>
                {item.physicalItems.map(i => {
                    const mapping = item.actual?.physicalMappings?.find(pm=>pm.physicalItemId == i.item.id && pm.physicalAccountId == i.accountId)
                    return (<>
                        {(Math.abs(mapping?.amount ?? 0) != Math.abs(i.item.amount)) && <Alert color="warning">
                            Fractionally Mapped: {mapping ? util.formattedColorAmount(mapping.amount, currency) : "??"}/{util.formattedColorAmount(i.item.amount, currency)}
                        </Alert>}
                        <PhysicalItemPanel item={i} />
                    </>)
                })}
                {item.pendingPhysicalItems.map(i => <PendingTransferPanel transfer={i} physAccounts={physAccounts??[]}/>)}
            </>,
            util.formattedColorAmount(item.total, currency),
        ]
        const rowId = move?.id || JSON.stringify(item.planned?.plannedFundMove?.id)
        let firstRow = <tr key={rowId + "-first"} className="row-ng" style={{ "verticalAlign": "top" }}>
            {firstRowPrefix.map(value => <td rowSpan={cellsForFlows.length}>{value}</td>)}
            {cellsForFlows[0]}
        </tr>
        let otherRows = _.tail(cellsForFlows).map((cells, idx) => {
            return <tr key={rowId + "-" + idx} className="row-ng">{cells}</tr>
        })
        return [firstRow].concat(otherRows)
    }))

    console.log("onlyFlagged", onlyFlagged)
    let header = ["When", "ID", "Memo", "Alignment", "Plan Amt", "Plan Open?", "Ext. Amt", "External", "Total", "Flow Num", "Flow Amt", "From", "To", "From Entity", "To Entity"]
    return <div className="moves-screen">
        <FloatingActionBar 
            topOffset="40px"
            isRefreshing={isLoading} 
            onRefresh={refreshEverything}>
            
            {(planSequenceId) && <Chip label={planSequence?.name ?? "..."} onDelete={() => setPlanSequenceId(undefined)} />}
            <div><input type="checkbox" checked={onlyFlagged} onChange={() => setOnlyFlagged(!onlyFlagged)} />Flagged Items Only</div>
            <div><input type="checkbox" checked={onlyInstantaneous} onChange={() => setOnlyInstantaneous(!onlyInstantaneous)} />Instantaneous Items Only</div>
            <div style={{margin:"15px"}} >
                <input type="checkbox" checked={doRestrictTime} onChange={() => setRestrictTime(!doRestrictTime)} />
                <InstantSelect label="Before" disabled={!doRestrictTime} value={timeThreshold} onChange={v => setTimeThreshold(v || now)} />
            </div>
        
        </FloatingActionBar>
        <LoadingThrobber isLoading={pages.length == 0}>
            <table className="table-ng">
                <tbody>
                    <tr className="row-ng header-row">{_.map(header, (v) => <th>{v}</th>)}</tr>
                    {rows}
                </tbody>
            </table>
            <button disabled={isLoading} onClick={loadMore} >More</button>
        </LoadingThrobber>
    </div>

}
