import { ReactNode, useState } from 'react'
import { Button, Stack } from '@mui/material';

interface WizardProps {
    panels:(()=>ReactNode)[]
    onComplete:(doIt:()=>void)=>void
}

interface WizardState {
    currentPanelIdx:number
    currentPanel:ReactNode
    isWorking:boolean
}

export default (props:WizardProps)=> {
    const { panels, onComplete } = props
    const [state, setState] = useState<WizardState>({
        currentPanelIdx: 0,
        currentPanel: props.panels[0](),
        isWorking: false
    })

    const { currentPanelIdx, currentPanel, isWorking } = state

    const moveBackward = () => {
        if (currentPanelIdx == 0) {

        } else {
            const newIdx = currentPanelIdx - 1


            const panel = panels[newIdx]()

            console.log("next panel is", panel)

            setState({
                ... state,
                currentPanelIdx: newIdx,
                currentPanel: panel
            })
        }

    }

    const moveForward = () => {
        console.log("moving on from panel ", currentPanelIdx, panels.length - 1, currentPanelIdx === panels.length - 1)
        if (currentPanelIdx === panels.length - 1) {
            console.log("Wizard props are ", props)
            setState({
                ... state,
                isWorking: true
            })

            onComplete(() => {
                setState({
                    ... state,
                    isWorking: false
                })
            })
        } else {
            const newIdx = currentPanelIdx + 1


            const panel = panels[newIdx]()

            console.log("next panel is", panel)

            setState({
                ... state,
                currentPanelIdx: newIdx,
                currentPanel: panel
            })
        }
    }

    const nextName = currentPanelIdx === panels.length - 1 ? "Finish" : "Next"
    return <div className="wizard">
        <div className="wizard-content">
            {currentPanel}
        </div>
        <Stack direction="row-reverse" spacing={2}>
            <Button variant="contained" disabled={isWorking} onClick={moveForward}>{nextName}</Button>
            <Button variant="outlined" disabled={isWorking || currentPanelIdx == 0} onClick={moveBackward}>Previous</Button>
        </Stack>
    </div>
    
}