import React, { CSSProperties, ReactNode } from "react"
import _ from 'underscore'
import http from './cj/HttpClient'
import util, { getTenantId, isDebtType } from './util'
import PlanChart from './PlanChart'
import accountsUtils from './accounts'
import AnalysisWindowControls, {AnalysisWindow} from './AnalysisWindowControls'
import { Button, Typography, } from '@mui/material'
import { AccountBalances, AccountInfo, BalancesByTick, PhysicalAccountSummaryDto, } from './api'
import LoadingThrobber from './LoadingThrobber'
import { Link, useNavigate } from "react-router-dom";
import { fetchChartData, getAccounts, getPhysicalAccountSummaries } from "./api-actions"
import FloatingActionBar from "./FloatingActionBar"
import { DialogsContext } from "./Dialogs"
import MarkdownContent from "./MarkdownContent"

const SectionTitleRow = (props: { sectionKey: string, label: string }) => {
    const { sectionKey, label } = props

    return (
        <tr id={sectionKey} key={`title-${sectionKey}`} className="overview-tab-subsection-header">
            <td style={{ paddingTop: "30px", background: "white" }}>
                <Typography variant="h4">
                    {label}
                </Typography>
            </td>
        </tr>
    )
}

export default (props: { accounts: AccountInfo[], showMainScreen: () => void }) => {
    const now = new Date().getTime()
    const navigate = useNavigate()

    const [accounts, setAccounts] = React.useState(props.accounts)
    const [isExpandedByAccountId, setisExpandedByAccountId] = React.useState<Record<string, boolean>>({})
    const [chartData, setchartData] = React.useState<BalancesByTick>()
    const [chartDataLastUpdated, setchartDataLastUpdated] = React.useState<number>()
    const [showChartDetails, setshowChartDetails] = React.useState(false)
    const [showFutureBalances, setshowFutureBalances] = React.useState(true)
    const [physicalSummaries, setPhysicalSummaries] = React.useState<PhysicalAccountSummaryDto[]>()

    const [analysisWindow, setanalysisWindowt] = React.useState<AnalysisWindow>({
        from: now,
        to: now + util.monthsInMillis(18)
    })
    const [isFetchingChartData, setisFetchingChartData] = React.useState(false)
    const [isFetchingAccounts, setisFetchingAccounts] = React.useState(false)

    console.log("isFetchingChartData?", isFetchingChartData)


    const summaries = physicalSummaries && summarizePhysicalAccounts(physicalSummaries)
    const debtBalances = summaries?.debtBalances



    function refreshChartData() {
        setisFetchingChartData(true)

        fetchChartData({to:analysisWindow.to, from:analysisWindow.from}).then(r=>{
            setchartData(r)
            setchartDataLastUpdated(new Date().getTime())
        }).catch(err=>{
            alert(err);
        }).finally(()=>{
            setisFetchingChartData(false)
        })
    }

    const refresh = () => {
        refreshChartData()
        setisFetchingAccounts(true)
        getAccounts(a => {
            setisFetchingAccounts(false)
            setAccounts(a)
        })
        getPhysicalAccountSummaries(setPhysicalSummaries)
    }

    React.useEffect(refresh, [])

    if (!chartData) {
        return <LoadingThrobber isLoading={true}></LoadingThrobber>
    }


    var accountsByKind = util.groupArray(accounts, "kind");

    let accountsById: Record<string, AccountInfo> = {}
    _.each(accounts, (account) => accountsById[account.id] = account)
    const aggregateBalancesForAccountsOfKind = (kind: string) => {
        return _.reduce(_.filter(accounts, (account) => accountsById[account.id].kind === kind), function (balances, nextAccount) {
            let updatedBalances: Record<string, number> = { ...balances }
            _.each(nextAccount.balances, (quantity, currency) => {
                let currentBalance = updatedBalances[currency]
                let updatedAmount = (currentBalance ? currentBalance : 0) + quantity
                updatedBalances[currency] = updatedAmount;
            })

            return updatedBalances

        }, {})
    }

    const renderBalancesTable = (balances: Record<string, number>) => {
        return <div className="balances-table" style={{
            margin: "5px",
            fontStyle: "italic",
            maxWidth: "500px",
            display: "table-cell",
            padding: "5px",
        }}>
            {util.formattedTotals(balances)}
        </div>
    }

    let kindsInOrder = ["Account", "Alien", "Stub"]
    let accountEntries = kindsInOrder.map(kind => {
        const accounts = accountsByKind[kind]
        var accountsByCategory = util.groupArray(accounts, "category");
        function collapse(accounts: AccountInfo[], categoryName: string) {

            if (categoryName == "null") {
                return accounts;
            } else {

                var balances: Record<string, number> = {};
                _.each(accounts, function (account) {
                    _.each(account.balances, function (balance, currency) {
                        const b = balances[currency] ?? 0
                        balances[currency] = b + balance;
                    });
                });


                return [{ name: categoryName, balances: balances, accounts: accounts }];
            }

        }

        interface TreeNode {
            id: string | undefined
            name: string;
            balances: Record<string, number>
            account: AccountInfo | undefined
            accounts: TreeNode[] | undefined
        }

        const toTreeNode = (entry: any): TreeNode => {
            const o = (entry as any)
            if (o.accounts) {
                return {
                    id: undefined,
                    name: o.name,
                    balances: o.balances,
                    account: undefined,
                    accounts: o.accounts.map(toTreeNode)
                }
            } else {
                const a = entry as AccountInfo
                return {
                    id: a.id,
                    name: a.name,
                    balances: a.balances,
                    account: a,
                    accounts: undefined
                }
            }
        }

        var pseudos = _.sortBy(_.flatten(_.map(accountsByCategory, collapse)), "name").map(toTreeNode)

        function renderRow(account: TreeNode, chartData: BalancesByTick, extraClasses?: string, parent?: AccountInfo): ReactNode {
            var balancesHtml = renderBalancesTable(account.balances)

            var negativeChildBalances: Record<string, number> = {};
            _.each(account.accounts ?? [], function (child) {
                _.each(child.balances, function (balance, currency) {
                    if (balance < 0) {
                        var existing = negativeChildBalances[currency];
                        negativeChildBalances[currency] = (existing ? existing : 0) + balance;
                    }
                });
            });

            var childBalancesHtml = renderBalancesTable(negativeChildBalances);

            let allBalancesHtml;
            if (balancesHtml !== childBalancesHtml) {
                allBalancesHtml = <>
                    {balancesHtml} {childBalancesHtml}
                </>
            } else {
                allBalancesHtml = <>{balancesHtml} {renderBalancesTable({})}</>
            }


            var displayName = account.name && account.name.length > 0 ? account.name : "unnamed";
            if (account.accounts) {
                displayName = displayName + " ..."
            }

            let finalBalancesView;
            let lastChartRecord = _.last(Object.values(chartData))
            if (lastChartRecord && showFutureBalances) {
                let lastAccountsBalances = lastChartRecord
                let finalBalancesForAccount: AccountBalances = _.find(lastAccountsBalances.balances, (record) => record.accountId == account.id) || { accountId: "", balances: {} }
                let finalBalancesForChildren = account.accounts?.map(function (child) {
                    let finalRecordForChild: AccountBalances = _.find(lastAccountsBalances.balances, (record) => record.accountId == child.id) || { accountId: "", balances: {} }
                    return finalRecordForChild
                }) ?? [];

                let aggregateFinalBalances = finalBalancesForAccount.balances

                _.each(finalBalancesForChildren, (childRecord) => {
                    Object.keys(childRecord.balances).forEach((currency) => {
                        const amount = childRecord.balances[currency]
                        aggregateFinalBalances[currency] = amount + (aggregateFinalBalances[currency] || 0)
                    })
                })

                finalBalancesView = renderBalancesTable(aggregateFinalBalances)

            }
            
            const AccountLink = (props:{name:string})=>{
                const {name} = props

                if (account.accounts) {
                    return <a onClick={e=>{
                        if(account.accounts){
                            const update = { ...isExpandedByAccountId }
                            _.map(account.accounts, function (child) {
                                let childAccountId = child.id
                                if (childAccountId) {
                                    let currentlyExpanded = update[childAccountId]
                                    update[childAccountId] = !currentlyExpanded
                                }
                            });
        
                            setisExpandedByAccountId(update)
                        }
                        e.preventDefault();
                    }} href="#">{name}</a>
                } else if (account.id) {
                    return <Link to={account.id}>{name}</Link>
                    
                }else{
                    return <></>
                }

            }

            const props: CSSProperties = parent ? { borderLeft: "5px solid grey" } : {}

            var section = <tr key={`account-${account.id}`}>
                <td style={props}><AccountLink name={displayName}/></td>
                <td>{allBalancesHtml}</td>
                <td><div ><span>{finalBalancesView}</span></div></td>
                <td><div >{<MarkdownContent>{account.account?.purpose ?? ""}</MarkdownContent>}</div></td>
            </tr>

            var children = (account.accounts ?? []).flatMap(account => {
                if (account.id && isExpandedByAccountId[account.id]) {
                    return renderRow(account, chartData, "child-account", account.account)
                } else {
                    return <></>
                }
            });

            return <>
                {section}
                {children}
            </>
        }
        const balances = aggregateBalancesForAccountsOfKind(kind)
        const sectionKey = kind
        return <>
            <SectionTitleRow 
                label={accountsUtils.accountKindDescription(kind)} 
                sectionKey={sectionKey} />

            <tr key={`column-labels-${sectionKey}`}>
                <td className="label"> </td>
                <td className="balances-table label">Now</td>
                <td className="direction label">{new Date(analysisWindow.to).toLocaleDateString()}</td>
                <td className="label">Purpose</td>
            </tr>

            <tr key={`totals-${sectionKey}`}>
                <td>Totals</td>
                <td>{renderBalancesTable(balances)}</td>
                <td></td>
                <td></td>
            </tr>

            {pseudos.map(account => {
                return renderRow(account, chartData)
            })}
            {(pseudos.length % 2 != 0) && <tr></tr>}
        </>
    });

    const fundsTotals = aggregateBalancesForAccountsOfKind("Account")
    const aliensLiabilities = aggregateBalancesForAccountsOfKind("Alien")
    const allLiabilities = add(aliensLiabilities, debtBalances ?? {})

    const isRefreshing = (isFetchingAccounts || isFetchingChartData)
    if (accounts && accounts.length > 0 && chartData) {
        return (<div >
            <PlanChart focusAccountId={undefined} showDetails={showChartDetails} data={chartData} lastUpdated={chartDataLastUpdated} />
            <FloatingActionBar isRefreshing={isRefreshing} onRefresh={refresh} />
            <AnalysisWindowControls disableStartChanges={true} onChange={setanalysisWindowt} window={analysisWindow} doUpdate={refreshChartData} isFetching={isFetchingChartData} />

            <div><input type="checkbox" checked={showChartDetails} onChange={() => setshowChartDetails(!showChartDetails)} />Show Individual Accounts</div>
            <div><input type="checkbox" checked={showFutureBalances} onChange={() => setshowFutureBalances(!showFutureBalances)} />Show Future Balances</div>

            <table className="tree-table" style={{ fontSize: "12pt" }}>
                <tbody>
                    <SectionTitleRow label={"Summary"} sectionKey={"summary"} />

                    <tr key={`net-funds`}>
                        <td><a className={'account-link '} href="#Account">Assets - Funds</a></td>
                        <td>{renderBalancesTable(fundsTotals)}</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr key={`net-funds`}>
                        <td><a className={'account-link '} href="#Alien">Liabilities - Aliens</a></td>
                        <td>{renderBalancesTable(aliensLiabilities)}</td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr key={`debt-overall`}>
                        <td><Link to={"../external/accounts"}>Liabilities - Debts</Link></td>
                        <td>{renderBalancesTable(debtBalances ?? {})}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr key={`net-overall`}>
                        <td>Net</td>
                        <td>{renderBalancesTable(add(fundsTotals, allLiabilities))}</td>
                        <td></td>
                        <td></td>
                    </tr>

                    {_.flatten(accountEntries, true)}
                </tbody>
            </table>

        </div>)
    } else {
        return <div style={{ textAlign: 'center' }}><img style={{ marginTop: '40%' }} className="throbber" src="/loader.gif"></img></div>
    }
}


const add = (additions: Record<string, number>, existing: Record<string, number>): Record<string, number> => {
    const result: Record<string, number> = { ...existing }


    Object.keys(additions).forEach(currency => {
        const existing = result[currency] ?? 0
        const v = additions[currency] ?? 0
        result[currency] = existing + v
    })
    return result
}

const del = (additions: Record<string, number>, existing: Record<string, number>): Record<string, number> => {
    const result: Record<string, number> = { ...existing }


    Object.keys(additions).forEach(currency => {
        const existing = result[currency] ?? 0
        const v = additions[currency] ?? 0
        result[currency] = existing - v
    })
    return result
}


const negate = (balances: Record<string, number>): Record<string, number> => {
    const result: Record<string, number> = {}


    Object.keys(balances).forEach(currency => {
        const existing = balances[currency] ?? 0
        result[currency] = existing * -1
    })
    return result
}
interface PhysicalAccountSummaries {
    totals: Record<string, number>
    debtBalances: Record<string, number>
    assetBalances: Record<string, number>
}

const summarizePhysicalAccounts = (physicalSummaries: PhysicalAccountSummaryDto[]): PhysicalAccountSummaries => {

    let totals: Record<string, number> = {}
    let debtBalances: Record<string, number> = {}
    let assetBalances: Record<string, number> = {}


    physicalSummaries?.forEach(source => {
        const balances = source.balances
        if (isDebtType(source.config?.type)) {
            debtBalances = add(balances, debtBalances)
        } else {
            assetBalances = add(balances, assetBalances)
        }
        totals = add(balances, totals)
    })

    return {
        totals: totals,
        debtBalances: debtBalances,
        assetBalances: assetBalances,
    }

}