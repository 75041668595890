import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { PhysicalAccountSummaryDto } from "./api"

export default (props: { label: string, accounts: PhysicalAccountSummaryDto[], value: string | undefined, disabled?: boolean, onChange: (selection: PhysicalAccountSummaryDto | undefined) => void }) => {
    const { value, label, accounts, disabled, onChange } = props


    let makeLabel = (account: PhysicalAccountSummaryDto) => {
        return account.name
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        onChange(accounts.find(a => a.id == event.target.value));
    }

    return (<>
        <FormControl fullWidth>
            <InputLabel >{label}</InputLabel>
            <Select label={label}
                value={value ?? ""}
                disabled={disabled === true}
                onChange={handleChange}
            >
                {/* <MenuItem value=""><em>None</em></MenuItem> */}
                {accounts.map(account => <MenuItem value={account.id}>{makeLabel(account)}</MenuItem>)}
            </Select>
        </FormControl>
    </>)
}