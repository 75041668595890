import _ from 'underscore'
import { Bucket, MappingSpec, PhysicalItem } from '../api'
import { Button, Stack } from '@mui/material'
import Table from '../TableNG'
import { splitsToMapping } from '../mappingLogic'
import { CurrencyAmount } from '../CurrencyAmountField'

export default (props: { item: PhysicalItem, aliens: Bucket[], onMappingsChange: (mappingFactory:(allocatedAmount:CurrencyAmount)=>MappingSpec) => void }) => {

    const { item, aliens, onMappingsChange } = props

    const handleSelection = (selection: string) => {

        const accountId = (selection && selection.length > 0) ? selection : undefined
        console.log("Account ID is ", accountId)

        if (accountId) {
            onMappingsChange(amount=>{
                const mapping = splitsToMapping({[accountId]:amount.amount}, amount)
                
                console.log("Splits generated ", amount, "for account", accountId, "to", mapping)
                return mapping
            })
        }
    }

    return <Stack>
        <Table
            header={["Category", "Name", "Purpose", "",]}
            rows={_.map(_.sortBy(aliens, a => `${a.category ?? "AAAA"} -> ${a.name}`), (alien) => {
                return [alien.category, alien.name, alien.purpose, <Button variant="contained" onClick={() => handleSelection(alien.id)}>Select</Button>,]
            })}
        />
    </Stack>
}