import http, { HttpMethodName, Response } from './cj/HttpClient'
import { AccountInfo, BalancesByTick, Bucket, BucketState, CheckpointInfo, ConnectionConfigDto, ConnectionsListItem, ContextualizedPendingTransfer, Flow, FullMoveDetails, FullMoveDetailsPage, FullPhysicalItemDetails, FullPlannedMove, FundMove, FundMoveToPhysicalItemMapping, FundsFlow, HistoricalPlanItemAnalysis, ItemInfo, MappingSpec, MonthlyRatesForCurrency, MoveChangeResult, Organization, PhysicalAccountDto, PhysicalAccountInfoDto, PhysicalAccountSummaryDto, PhysicalItem, PhysicalItemHandle, PhysicalItemPatch, PlannedFundMove, PlannedItemHandle, ProfitAndLossReport, Recommendation, SpendDto, StatementWithStats, Template, TemplateInfoListItem, UnplannedAnalysis, UtilityChargeDto, asTransfer } from './api'
import { getPromise, getTenantId, toQueryString } from './util';
import { Problem } from './ErrorAlert';
import { AnalysisWindow } from './AnalysisWindowControls';

export const getPendingTransfers = (accountId:string, onResult:(transfers:ContextualizedPendingTransfer[])=>void) => {
    http({
        url: `/api/tenants/${getTenantId()}/spend`,
        method: "GET",
        onResponse: (response) => {
            const transfers:ContextualizedPendingTransfer[] = JSON.parse(response.body)
            // TODO: Move this filter into the server-side
            onResult(transfers.filter(it => it.pending.physicalAccountId == accountId))
        }
    })
}
export const getFlowsPromise = (tenantId:string):Promise<Flow[]> => {
    return new Promise((resolveSuccess, resolveFailed )=> {
        getFlows(tenantId, resolveSuccess, resolveFailed)
    })
}

export const getFlows = (tenantId:string, setFlows:(flows:Flow[])=>void, setError:(error:Response)=>void) => {
    http({
        url: `/api/tenants/${tenantId}/plan/elements`,
        method: "GET",
        onResponse: function (response) {
            console.log("done fetching");
            if (response.status === 200) {
                setFlows(JSON.parse(response.body));
            } else {
                setError(response)
            }
        }
    });
}

export const getAccountsPromise = ():Promise<AccountInfo[]> => {
    return new Promise((resolveSuccess, resolveFailed )=> {
        getAccounts(resolveSuccess, resolveFailed)
    })
}

export const getAccounts = (onResult: (connections: AccountInfo[]) => void, setError?:(error:Response)=>void) => {
    http({
        url: `/api/tenants/${getTenantId()}/accounts`,
        method: "GET",
        onResponse: (response) => {
            if(response.status == 200){
                onResult(JSON.parse(response.body))
            }else{
                setError && setError(response)
            }
        }
    })
}


export const getConnections = (onResult: (connections: ConnectionsListItem[]) => void) => {
    http({
        url: `/api/tenants/${getTenantId()}/connections`,
        method: "GET",
        onResponse: (response) => {
            if (response.status === 200) {
                onResult(JSON.parse(response.body))
            }
        }
    });
}

export const getStatements = () => getPromise<StatementWithStats[]>(`/api/tenants/${getTenantId()}/statements`)

export const deleteMapping = (item: PhysicalItem, source: PhysicalAccountDto, onResult: (problem: Problem | undefined) => void) => {
    deleteMappingFromHandle({ accountId: source.id, itemId: item.id }, onResult)
}

export const deleteMappingFromMapping = (item: FundMoveToPhysicalItemMapping, onResult: (problem: Problem | undefined) => void) => {
    deleteMappingFromHandle({ accountId: item.physicalAccountId, itemId: item.physicalItemId }, onResult)
}

export const deleteMappingFromHandle = (item: PhysicalItemHandle, onResult: (problem: Problem | undefined) => void) => {
    http({
        url: `/api/tenants/${getTenantId()}/mappings/${item.accountId}/${item.itemId}`,
        method: "DELETE",
        onResponse: function onResponse(response) {
            if (response.status !== 200) {
                console.log(response)
                onResult({
                    description: `There was an ${response.status} error`,
                    details: response.body
                })
            } else {
                onResult(undefined)
            }
        }
    });
}




export const reOpenPlanedItem = (plannedItemId: PlannedItemHandle, onResponse: () => void) => reOpenPlanedItemPromise(plannedItemId).then(onResponse)
export const reOpenPlanedItemPromise = (plannedItemId: PlannedItemHandle):Promise<void> => {

    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/plan/elements/${plannedItemId.sequenceId}/instances/${plannedItemId.instanceId}`,
            method: "PATCH",
            data: JSON.stringify({ isComplete: false }),
            onResponse: function (response) {
                if(response.status==200){
                    resolveSuccess()
                }else{
                    resolveFailed()
                }
            }
        });
    })
}

export const getPlanedItem = (plannedItemId: PlannedItemHandle, onResponse: (n: PlannedFundMove) => void) => {
    http({
        url: `/api/tenants/${getTenantId()}/plan/elements/${plannedItemId.sequenceId}/instances/${plannedItemId.instanceId}`,
        method: "GET",
        onResponse: function (response) {
            onResponse(JSON.parse(response.body))
        }
    });
}

export const getPlanedItemPromise = (plannedItemId: PlannedItemHandle):Promise<PlannedFundMove> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/plan/elements/${plannedItemId.sequenceId}/instances/${plannedItemId.instanceId}`,
            method: "GET",
            onResponse: function (response) {
                if(response.status == 200){
                    resolveSuccess(JSON.parse(response.body))
                }else{
                    resolveFailed(`server said ${response.status}: ${response.body}`)
                }
            }
        });
    })
}

export const getPlanItem = (sequenceId: string, onResult: (f: Flow) => void) => {
    http({
        url: `/api/tenants/${getTenantId()}/plan/elements/${sequenceId}`,
        method: "GET",
        onResponse: (response) => {
            if (response.status == 200) {
                onResult(JSON.parse(response.body))
            } else {
                alert("Error!  Server said:" + response.body);
            }
        }
    });
}

export const closePlannedItem = (plannedItemHandle: PlannedItemHandle, onComplete: () => void) => {
    closePlannedItemPromise(plannedItemHandle).finally(onComplete)
}
export const closePlannedItemPromise = (plannedItemHandle: PlannedItemHandle):Promise<void> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/plan/elements/${plannedItemHandle.sequenceId}/instances/${plannedItemHandle.instanceId}`,
            method: "PATCH",
            data: JSON.stringify({ isComplete: true }),
            onResponse: function (response) {
                if(response.status==200){
                    resolveSuccess()
                }else{
                    resolveFailed()
                }
            }
        });
    });
}


export const createOrUpdateMove = (move: FundMove, onSuccess: (m: FundMove) => void) => {
    var url, method: HttpMethodName;
    if (move.id) {
        url = `/api/tenants/${getTenantId()}/moves/` + move.id;
        method = "PUT";
    } else {
        url = `/api/tenants/${getTenantId()}/moves`;
        method = "POST";
    }
    http({
        url: url,
        method: method,
        data: JSON.stringify(move),
        onResponse: function (response) {
            if (response.status == 200) {
                var updatedMove = JSON.parse(response.body);
                onSuccess(updatedMove)
            } else {
                alert("Error!  Server said:" + response.body);
            }
        }
    });
}

export const deleteMove = (move: FundMove, onSuccess: (m: FundMove) => void) => {
    http({
        url: `/api/tenants/${getTenantId()}/moves/` + move.id,
        method: "DELETE",
        onResponse: function (response) {
            if (response.status == 200) {
                onSuccess(move)
            } else {
                alert("Error!  Server said:" + response.body);
            }
        }
    });
}


export const getMovePromise = (moveId: string):Promise<FundMove> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/moves/` + moveId,
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}

export const getMove = (moveId: string, onSuccess: (m: FundMove) => void) => {
    getMovePromise(moveId).then(onSuccess).catch(alert)
}


export const getFullPlannedMove = (handle:PlannedItemHandle):Promise<FullPlannedMove> => {
   
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/full-planned-moves/${handle.sequenceId}/${handle.instanceId}`,
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}

export const getFullMove = (moveId: string):Promise<FullMoveDetails> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/full-moves/` + moveId,
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}

export interface MovesSearchParameters {
    onlyFlagged: boolean
    onlyInstantaneous: boolean
    before: number | undefined
    planSequenceId: string | undefined
}

export const fetchFullMoveDetailsPage = (pageNum: number, params: MovesSearchParameters, onResult: (page: FullMoveDetailsPage | undefined) => void) => {
    const { onlyFlagged, onlyInstantaneous, before, planSequenceId } = params
    console.log("Getting page", pageNum)

    const beforeParams = before ? { before: before } : {}

    http({
        url: `/api/tenants/${getTenantId()}/details?${toQueryString({
            page: pageNum,
            onlyFlagged: onlyFlagged,
            planSequenceId: planSequenceId,
            onlyInstantaneous: onlyInstantaneous,
            ...beforeParams
        })}`,
        method: "GET",
        onResponse: (response) => {
            if (response.status === 200) {
                const page: FullMoveDetailsPage = JSON.parse(response.body)
                console.log("Got page", pageNum, " (" + page.items.length + " items)")
                onResult(page)
            } else {
                alert(`Error ${response.status} loading page: ${response.body}`);
                onResult(undefined)
            }
        }
    });
}

export function fetchPages(params:MovesSearchParameters, untilCondition:(pages:FullMoveDetailsPage[])=>boolean, onResult:(pages:FullMoveDetailsPage[])=>void) {
    const updatedPages: FullMoveDetailsPage[] = []
    const fetchRemaining = (fromPageNum: number) => {
        console.log("Fetch Remaining from ", fromPageNum)
        if (!untilCondition(updatedPages)) {
            fetchFullMoveDetailsPage(fromPageNum, params, (page) => {
                if (page && page.items.length > 0) {
                    updatedPages.push(page);
                    fetchRemaining(fromPageNum + 1);
                }else{
                    console.log("Ran out of pages to fetch")
                    onResult(updatedPages)
                }
            })
        } else {
            console.log("All done fetching pages")
            onResult(updatedPages)
        }
    }
    fetchRemaining(1)
}


export const doDeleteWithConfirmation = (planElementId: string, onDeleted: () => void) => {

    var userConfirmedDelete = window.confirm("Are you sure you want to delete this?  This cannot be undone!");
    if (userConfirmedDelete) {
        http({
            url: `/api/tenants/${getTenantId()}/plan/elements/` + planElementId,
            method: "DELETE",
            onResponse: function (response) {
                if (response.status == 200) {
                    onDeleted()
                } else {
                    alert("Error!  Server said:" + response.body);
                }
            }
        });
    }

}

export const patchPhysicalItem = (patch:PhysicalItemPatch, handle:PhysicalItemHandle):Promise<ItemInfo> => {

    return new Promise((resolveSucess, resolveFailed)=>{

        http({
            url: `/api/tenants/${getTenantId()}/physicalAccounts/${handle.accountId}/items/${handle.itemId} `,
            method: "PATCH",
            data: JSON.stringify(patch),
            onResponse: function (response) {
                console.log("done fetching");
                if (response.status === 200) {
                    resolveSucess(JSON.parse(response.body));
                } else {
                    resolveFailed(`Error - server said ${response.status}: ${response.body}`)
                }
            }
        });
    })
}

export const getPhysicalItem = (handle:PhysicalItemHandle):Promise<ItemInfo> =>{
    return new Promise((resolveSucess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/physicalAccounts/${handle.accountId}/items/${handle.itemId}`,
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSucess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        },
        { async: true });
    })
}

export const getPhysicalAccountInfosSync = (): PhysicalAccountInfoDto[] => {
    let results: PhysicalAccountInfoDto[] = []
    getPhysicalAccountInfos(r => results = r, false)
    return results
}


export const getPhysicalAccountInfosPromise = ():Promise<PhysicalAccountInfoDto[]> => {
    return new Promise((resolveSucess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/physicalAccounts`,
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSucess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        },
        { async: true });
    })
}

// TODO: remove this
export const getPhysicalAccountInfos = (onSuccess: (results: PhysicalAccountInfoDto[]) => void, async: boolean = true) => {
    http({
        url: `/api/tenants/${getTenantId()}/physicalAccounts`,
        method: "GET",
        onResponse: function (response) {
            if (response.status == 200) {
                onSuccess(JSON.parse(response.body))
            } else {
                alert("Error!  Server said:" + response.body);
            }
        }
    }
        ,
        { async: async });
}

export const getPhysicalAccountSummaries = (onSuccess: (results: PhysicalAccountSummaryDto[]) => void, async: boolean = true) => {
    http({
        url: `/api/tenants/${getTenantId()}/physicalAccounts?details=true`,
        method: "GET",
        onResponse: function (response) {
            if (response.status == 200) {
                onSuccess(JSON.parse(response.body))
            } else {
                alert("Error!  Server said:" + response.body);
            }
        }
    }
        ,
        { async: async });
}

export const saveAccountInfo = (account: AccountInfo, onUpdated: (account: AccountInfo | undefined, error: string | undefined) => void) => {

    http({
        url: `/api/tenants/${getTenantId()}/accounts/` + account.id,
        method: "POST",
        data: JSON.stringify(account),
        onResponse: (response) => {
            if (response.status == 200) {
                onUpdated(account, undefined)
            } else {
                onUpdated(undefined, response.body)
            }
        }
    });
}

export const addSpend = (spend: SpendDto, onUpdated: (account: FundMove | undefined, error: string | undefined) => void) => {

    http({
        url: `/api/tenants/${getTenantId()}/spend`,
        method: "POST",
        data: JSON.stringify(spend, null, 4),
        onResponse: (response) => {
            if (response.status == 200) {
                onUpdated(JSON.parse(response.body), undefined)
            } else {
                onUpdated(undefined, `Error Code ${response.status}: ${response.body}`)
            }
        }
    });
}

export const getTenantOrganizationPromise = (tenantId:string):Promise<Organization> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${tenantId}/organization`,
            method: "GET",
            options: { async: false },
            onResponse: ((response) => {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed(response)
                }
            })
        });
    })
}

export const getTenantOrganization = (tenantId:string, onResult:(o:Organization)=>void, onError:(r:Response)=>void) => {
    getTenantOrganizationPromise(tenantId)
        .then(onResult)
        .catch(onError)
}

export const getAllPhysicalPromise = ():Promise<PhysicalAccountDto[]> => {
    return new Promise((success, failure)=>{
        getAllPhysical(success, failure)
    })
}
export const getAllPhysical =(onResult:(results:PhysicalAccountDto[])=>void, onError?:(xhr:Response)=>void) => {

    http({
        url: `/api/tenants/${getTenantId()}/uncategorized`,
        method: "GET",
        onResponse: function (response) {
            if (response.status === 200) {
                onResult(JSON.parse(response.body))
            } else {
                onError && onError(response)
            }
        }
    });
}

export const getPendingTransfer = (pendingTransferId:string):Promise<ContextualizedPendingTransfer> => {
    return new Promise<ContextualizedPendingTransfer>((resolve, fail)=>{

        http({
            url: `/api/tenants/${getTenantId()}/pending-transfers/${pendingTransferId}`,
            method: "GET",
            onResponse: function onResponse(response) {
                if (response.status == 200) {
                    console.log(response)
                    resolve(JSON.parse(response.body))
                } else {
                    fail(`${response.status}: ${response.body}`)
                }
            }
        });
    })
}

export const deletePendingTransfer = (pendingTransferId:string, onResult: (problem: Problem | undefined) => void) => {
    ///api/tenants/{tenant-id}/pending-transfers/{pendingTransferId}
    http({
        url: `/api/tenants/${getTenantId()}/pending-transfers/${pendingTransferId}`,
        method: "DELETE",
        onResponse: function onResponse(response) {
            if (response.status !== 200) {
                console.log(response)
                onResult({
                    description: `There was an ${response.status} error`,
                    details: response.body
                })
            } else {
                onResult(undefined)
            }
        }
    });
}


export interface ResultThing {
    otherAffectedItems: string[]
}

export const postPhysicalItemMappings = (selection:MappingSpec, handle:PhysicalItemHandle, onSuccess:(results:ResultThing)=>void, onProblem:(problem:Problem)=>void)=>{
    http({
        url: `/api/tenants/${getTenantId()}/mappings/` + handle.accountId + "/" + handle.itemId,
        method: "POST",
        data: JSON.stringify(selection),
        onResponse: function (response) {
            if (response.status === 200) {

                const transfer = asTransfer(selection)

                const otherSide = transfer?.otherSideOfTransfer
                onSuccess({
                    otherAffectedItems: otherSide ? [otherSide] : []
                });
            } else {
                onProblem({
                    description: `Server said ${response.status}`,
                    details: response.body
                })
            }
        }
    });
}

interface NewFundMoveArgs{
    memo:string, 
    when:number, 
    currency:string, 
    flows: FundsFlow[]
}
export const asNewFundMove = (args:NewFundMoveArgs):FundMove => {
    return {
        id: undefined,
        memo: args.memo,
        when: args.when,
        flows: args.flows,
        currency: args.currency,
        physicalMappings: [],
        plannedItemId: undefined,
        warningDismissals: undefined,
        whenEnds: undefined,
        fromFundId: undefined,
        toFundId: undefined,
        amount: undefined
    }
}



export const fetchCharges = ():Promise<UtilityChargeDto[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/utility-charges?unmapped`,
            method: "GET",
            onResponse: function (response) {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed(response)
                }
            }
        });
    })
}


export const fetchRecommendations = ():Promise<Recommendation[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{

        http({
            url: `/api/tenants/${getTenantId()}/recommendations/all-uncategorized`,
            method: "GET",
            onResponse: function (response) {
                if(response.status == 200){
                    resolveSuccess(JSON.parse(response.body))
                }else{
                    resolveFailed(response)
                }
            }
        });
    })
}

export const createNewTemplate = (template:Template):Promise<Template> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/templates`,
            method: "POST",
            data: JSON.stringify(template),
            onResponse: (response) => {
                const status = response.status
                if(status == 200 || status == 201){
                    resolveSuccess(JSON.parse(response.body))
                }else{
                    resolveFailed(response)
                }
            }
        })
    })
}

export const updateTemplate = (template:Template):Promise<undefined> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/templates/${template.id}`,
            method: "PUT",
            data: JSON.stringify(template),
            onResponse: (resp) => {
                if(resp.status == 200){
                    resolveSuccess(undefined)
                }else{
                    resolveFailed(resp)
                }
            }
        });
    })
}


export const getTemplate = (id:string):Promise<Template> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/templates/${id}`,
            method: "GET",
            onResponse: (response) => {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error fetching template!");
                }
            }
        });
    })
}

export const getTemplates = ():Promise<TemplateInfoListItem[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/templates`,
            method: "GET",
            onResponse: (response) => {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error loading!");
                }
            }
        });
    })
}

export const getMovePreflight = (move:FundMove):Promise<MoveChangeResult> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/moves/preflight`,
            method: "POST",
            data: JSON.stringify(move),
            onResponse: (response) => {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Server said: " + response.body)
                }
            }
        });
    })
}

export const getAccountState = (acccountId:string):Promise<BucketState> => {
    return new Promise((resolveSuccess, resolveFailed)=>{

        http({
            url: `/api/tenants/${getTenantId()}/accounts/` + acccountId + "/items",
            method: "GET",
            onResponse: function (response) {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed(response)
                }
            }
        });
    })
}

export const getVersions = ():Promise<CheckpointInfo[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/versions`,
            method: "GET",
            onResponse: (response) => {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}


export interface Quarter { 
    year: number, 
    quarter: number, 
}

export interface QuarterRange {
    from: Quarter
    to: Quarter
}

export const getProfitAndLoss = (range:QuarterRange):Promise<ProfitAndLossReport>=>{
    let quarterToParam = (quarter: Quarter) => quarter.year + "-" + quarter.quarter
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/profit-and-loss?from=` + quarterToParam(range.from) + "&to=" + quarterToParam(range.to),
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}




export const createOrUpdateConnection = (connectionId:string|undefined|null, newConfiguration:ConnectionConfigDto):Promise<string> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        var url = connectionId ? `/api/tenants/${getTenantId()}/connections/` + connectionId : `/api/tenants/${getTenantId()}/connections`
        var method: HttpMethodName = connectionId ? "PUT" : "POST"
    
        http({
            url: url,
            method: method,
            data: JSON.stringify(newConfiguration, null, 4),
            onResponse: function (response) {
                if (http.is2xxResponse(response)) {
                    resolveSuccess("Successfully saved connection configuration")
                } else {
                    resolveFailed("Somethis is wrong.  Server said:" + response.body)
                }
            }
        });
    })
}



export const getConnection = (id:string):Promise<ConnectionConfigDto>=>{
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/connections/${id}`,
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}


    
export const deletePlanItem = (plannedItemHandle:PlannedItemHandle):Promise<any> => {
    return new Promise((resolveSuccess, resovleFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/plan/elements/` + plannedItemHandle.sequenceId,
            method: "DELETE",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSuccess(true)
                } else {
                    resovleFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}

export const fetchUnplannedStats = (analysisWindow:AnalysisWindow):Promise<UnplannedAnalysis> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/plan/stats/unplanned?from=` + analysisWindow.from + "&to=" + analysisWindow.to,
            method: "GET",
            onResponse: (response: Response) => {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
    
}

export const fetchMonthlyRatesByEntity = (analysisWindow:AnalysisWindow):Promise<Record<string, MonthlyRatesForCurrency[]>> => {

    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/plan/stats/trajectory?from=` + analysisWindow.from + "&to=" + analysisWindow.to,
            method: "GET",
            onResponse: (response) => {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}

export const fetchPlannedStats = (analysisWindow:AnalysisWindow):Promise<Record<string, HistoricalPlanItemAnalysis>> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/plan/stats/planned?from=` + analysisWindow.from + "&to=" + analysisWindow.to,
            method: "GET",
            onResponse: (response) => {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    })
}



export const fetchChartData = (args:{from?:number, to: number, accountId?:string}):Promise<BalancesByTick> => {
    const {from, to, accountId} = args

    const renderOptionalArg = (name:string, value:any|undefined):string => (value ? `&${name}=${value}` : "")

    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/plan/stats/daily-projections?to=${to}&numDivisions=30${renderOptionalArg("accountId", accountId)}${renderOptionalArg("from", from)}`,
            method: "GET",
            onResponse: (response) => {
                console.log("fetchChartData [DONE]")
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    });
}


export const getAccountProjections = (accountId:string):Promise<PlannedFundMove[]> => {

    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/projections?account=` + accountId,
            method: "GET",
            onResponse: (response) => {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body);
                }
            }
        });
    });
}
