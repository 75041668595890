import { getTenantId } from './util';
import React from 'react';
import { Alert, Button, CircularProgress, MenuItem, Select } from '@mui/material';
import { PhysicalAccountDto } from './api';
import ErrorAlert, { Problem } from './ErrorAlert';

interface ColumnSlot {
    displayName: string
    queryName: string
}
export default (props: { source: PhysicalAccountDto, onUploadCompleted: () => void }) => {
    const { source, onUploadCompleted } = props
    const [isWorking, setWorking] = React.useState(false)
    const [availableColumns, setavailableColumns] = React.useState<string[]>()
    const [error, setError] = React.useState<Problem>()
    const [result, setResult] = React.useState<string>()

    const [mappings, setMappings] = React.useState<Record<string, string>>()
    const fields: ColumnSlot[] = [
        { displayName: "ID", queryName: "ref" },
        { displayName: "Date", queryName: "date" },
        { displayName: "Memo", queryName: "memo" },
        { displayName: "Amount", queryName: "amount" },
        { displayName: "Currency", queryName: "currency" },

    ]

    function doPreview(form: HTMLFormElement) {

        setWorking(true)
        const oData = new FormData(form);

        var oReq = new XMLHttpRequest();
        oReq.open("POST", `/api/tenants/${getTenantId()}/csv-preview`, true);
        oReq.onload = function (oEvent) {
            setWorking(false)
            if (oReq.status == 200) {
                const availableColumns: string[] = JSON.parse(oReq.responseText)
                setavailableColumns(availableColumns)

                const firstAvailable = availableColumns[0]
                const nameMatch = (n: string): string => (availableColumns.find(it => it.toLowerCase() == n.toLowerCase())) ?? firstAvailable

                const mappings: Record<string, string> = {};

                fields.forEach(f => {
                    const name = f.displayName
                    mappings[name] = nameMatch(name)
                });

                setMappings(mappings)

            } else {
                setError({
                    description: "Error " + oReq.status + " occurred when trying to upload your file.",
                    details: oReq.responseText
                })
            }
        };

        oReq.send(oData);
    }

    function doUpload(form: HTMLFormElement) {

        if (mappings) {

            setWorking(true)
            const oData = new FormData(form);

            var url = `/api/tenants/${getTenantId()}/uncategorized/${source.id}`

            fields.forEach((field, idx) => {
                var value = mappings[field.displayName]
                var separator = idx == 0 ? "?" : "&"
                url += (separator + field.queryName + "=" + value)
            });

            var oReq = new XMLHttpRequest();
            oReq.open("POST", url, true);
            oReq.onload = function (oEvent) {
                setWorking(false)
                if (oReq.status == 200) {
                    setResult(oReq.responseText);
                    setError(undefined)
                    onUploadCompleted()
                } else {
                    setResult(undefined)
                    setError({
                        description: "Error " + oReq.status + " occurred when trying to upload your file ",
                        details: oReq.responseText
                    });
                }
            };

            oReq.send(oData);
        }
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (ev) => {
        ev.preventDefault();
        const form = ev.target as HTMLFormElement
        if (mappings) {
            doUpload(form);
        } else {
            doPreview(form);
        }

    }

    console.log("Rending with mappings", JSON.stringify(mappings, null, 4))

    return (

        <div>
            {error && <ErrorAlert error={error} />}
            {result && <Alert color="success">{result}</Alert>}
            {!result && <form method="POST" action={`/api/tenants/${getTenantId()}/csv-preview`} encType="multipart/form-data" onSubmit={handleSubmit}>
                <input type="file" name="thefile" />

                {(availableColumns && mappings) && <UploadFieldSelectPanel availableColumns={availableColumns} mappings={mappings} onChange={setMappings} />}
                <input disabled={isWorking} type="submit"></input>
                {isWorking && <CircularProgress />}
            </form>}
        </div>
    )
}

const UploadFieldSelectPanel = (props: { availableColumns: string[], mappings: Record<string, string>, onChange: (mappings: Record<string, string>) => void }) => {
    const { availableColumns, mappings, onChange } = props
    return (
        <div>
            <div className="mapping-panel">
                <h3>Mapping</h3>
                <table>
                    <tr><th>Input</th><th>File Column</th></tr>
                    {Object.keys(mappings).map(key => {
                        return (<tr><td>{key}</td><td><Select value={mappings[key]} onChange={e => onChange({ ...mappings, [key]: e.target.value })}>
                            {availableColumns.map(n => {
                                return <MenuItem value={n}>{n}</MenuItem>
                            })}
                        </Select></td></tr>)
                    })}
                </table>
            </div>
        </div>
    )
}