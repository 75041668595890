import { useEffect, useState } from 'react'
import { getTenantId } from '../util'
import http from '../cj/HttpClient'
import { ConnectionConfigDto, PlaidConnectionSpecificationDto } from '../api'
import { Button, Stack, TextField, Typography } from '@mui/material'


export interface LinkTokenResponse {
    expiration: string
    link_token: string
    request_id: string
}

export const updatePlaidToken = (connectionId: string): Promise<LinkTokenResponse> => {
    return new Promise((resolveSuccess, resolveFailed) => {
        http({
            url: `/api/tenants/${getTenantId()}/connections/` + connectionId + "/plaid/updateToken",
            method: "GET",
            onResponse: function (response) {
                console.log("connectionId", connectionId)
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("token is null")
                }
            }
        })
    })
}

export const createPlaidToken = (): Promise<LinkTokenResponse> => {

    return new Promise((resolveSuccess, resolveFailed) => {
        http({
            url: `/api/tenants/${getTenantId()}/plaid/create-token`,
            method: "GET",
            onResponse: function (response) {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("token is null")
                }
            }
        })
    })
}

export interface PlaidInstitutionInfo {
    institution_id: string,
    name: string,
    url: string | undefined,
    logo: string | undefined
}
export interface PlaidPublicTokenInfo {
    itemId: string,
    institution: PlaidInstitutionInfo | undefined
}
export interface PlaidEditingResult {
    type: "plaid"
    name: string
    newConnectionInfo: PlaidConnectionSpecificationDto
}
export const exchangePublicCodeCodeForPlaidToken = (public_token: string): Promise<PlaidPublicTokenInfo> => {
    return new Promise((resolveSuccess, resolveFailed) => {
        http({
            url: `/api/tenants/${getTenantId()}/plaid/publicTokens/` + public_token,
            method: "GET",
            onResponse: function (response) {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed(response.body)
                }
            }
        })
    })
}


export default (props: { connection: ConnectionConfigDto | undefined, onChange: (n: PlaidEditingResult) => void }) => {
    const { connection, onChange } = props
    const remote = connection?.remote?.type == "plaid" ? connection.remote as PlaidConnectionSpecificationDto : undefined

    const [publicToken, setPublicToken] = useState<string>()
    const [name, setName] = useState(connection?.name ?? "")
    const [plaidInstitution, setPlaidInstitution] = useState<string>()
    const [plaidItemId, setPlaidItemId] = useState(remote?.item_id)

    const doReauth = () => {

        if (connection) {
            console.log("connection", connection)
            if(window.confirm("Are you sure?  Might not be able to undo this")){
                updatePlaidToken(connection.id)
                .then(tokenInfo => {
                    var plaid = (window as any).Plaid.create({
                        token: tokenInfo.link_token,
                        onSuccess: function (public_token: any, metadata: any) {
                            console.log("Got something back from plaid:", public_token)
                        }
                    })
                    plaid.open();
                })
                .catch(console.log)
            }
        }
    }

    const doSelect = () => {
        createPlaidToken().then((tokenInfo) => {
            console.log("token is ", tokenInfo)

            var plaid = (window as any).Plaid.create({
                token: tokenInfo.link_token,
                onSuccess: function (public_token: string, metadata: any) {
                    console.log("Got something back from plaid:", public_token)
                    setPublicToken(public_token)
                    exchangePublicCodeCodeForPlaidToken(public_token)
                        .then(accountsResponse => {
                            console.log("accountsResponse is ", accountsResponse)
                            setPlaidItemId(accountsResponse.itemId)
                            setPlaidInstitution(accountsResponse.institution ? accountsResponse.institution.name : "unknown")
                        })
                        .catch(alert)
                }
            })
            plaid.open();
        })
    }


    useEffect(() => {

        if (publicToken && plaidItemId) {
            onChange({
                type: "plaid",
                name: name,
                newConnectionInfo: {
                    type: "plaid",
                    item_id: plaidItemId,
                    public_token: publicToken
                }
            })
        }

    }, [name, plaidItemId, plaidInstitution])

    return (<div className="plaid-connection-editor">

        <Typography>
            <Stack spacing={1}>
                <div><TextField label="Connection Name" className="connection-name" type="text" value={name} onChange={e => setName(e.target.value)} placeholder="name" /></div>
                <div><span className="label">Connected Institution: </span><span className="plaid-institution">{plaidInstitution || "not-selected"}</span></div>
                <div>
                    <span>Plaid Item ID: {plaidItemId || "not-selected"}</span>
                    <Button onClick={doSelect} style={{display:connection ? "none" : "initial"}}>Connect with Plaid</Button> <Button onClick={doReauth} style={{display:connection ? "initial" : "none"}}>Re-Authenticate</Button>
                </div>
            </Stack>
        </Typography>
    </div>
    )

}
