import React from 'react'
import http from './cj/HttpClient'
import util, { getTenantId } from './util'
import Table from './TableNG'
import { Button, Link, Stack, Typography } from '@mui/material'
import LoadingThrobber from './LoadingThrobber'
import ConsensedLongText from './ConsensedLongText'
import { getVersions } from './api-actions'
import { CheckpointInfo } from './api'

export default (props: {}) => {
    const [isLoading, setLoading] = React.useState(false)
    const [checkpoints, setCheckpoints] = React.useState<CheckpointInfo[]>([])

    function fetchCheckpoints() {
        setLoading(true)
        getVersions()
            .then(setCheckpoints)
            .catch(err => alert(err))
            .finally(()=>setLoading(false))
    }

    React.useEffect(fetchCheckpoints, [])

    function foo(checkpoint: CheckpointInfo) {
        console.log("clicked", checkpoint)

        if (window.confirm("This will roll-back all data to " + util.formatLongDateTime(checkpoint.timestamp) + ".  Are you sure?")) {
            http({
                url: `/api/tenants/${getTenantId()}/versions/current`,
                method: "PUT",
                data: JSON.stringify(checkpoint.id),
                onResponse: function (response) {
                    if (response.status === 200) {
                        window.location.reload()
                    } else {
                        alert("Server said: " + response.body);
                    }
                }
            });
        }
    }

    let doEditCheckpoint = (checkpoint: CheckpointInfo) => {
        console.log("Edit", checkpoint)
        var name = prompt("Enter a name for the checkpoint")
        if (name) {
            http({
                url: `/api/tenants/${getTenantId()}/versions/` + checkpoint.id + "/metadata",
                method: "PUT",
                data: JSON.stringify({
                    name: name,
                    permanent: true
                }),
                onResponse: function (response) {
                    if (response.status === 200) {
                        window.location.reload()
                    } else {
                        alert("Server said: " + response.body);
                    }
                }
            });
        }

    }


    return (<Stack spacing={3} className="data-tab" style={{ maxWidth: "100%" }}>
        <Typography variant="h5">Exports</Typography>
        <a href={`/downloads/tenants/${getTenantId()}/general-ledger`}>Download General Ledger</a>
        <Typography variant="h5">Checkpoints</Typography>
        {isLoading && <>
            <LoadingThrobber isLoading={true} />
        </>}
        {!isLoading && <>
            <Table
                header={["When", "ID", "Who", "Previous", "Status", "Memo", "Change", "Actions", "Data"]}
                rows={checkpoints.map((checkpoint, idx) => {
                    let statusLabel = checkpoint.isCurrent ? "Current" : (checkpoint.permanent ? "Saved" : "Temporary")
                    let actionButtons = []
                    if (!checkpoint.isCurrent) {
                        actionButtons.push(<Button onClick={() => foo(checkpoint)}>Restore</Button>)
                    }
                    if (!checkpoint.permanent) {
                        actionButtons.push(<Button onClick={() => doEditCheckpoint(checkpoint)}>Save</Button>)
                    }
                    return [
                        checkpoint.timestamp ? (util.formatLongDateTime(checkpoint.timestamp)) : "",
                        checkpoint.id,
                        checkpoint.who ?? "",
                        checkpoint.previousVersion,
                        statusLabel,
                        checkpoint.name,
                        <ConsensedLongText style={{ whiteSpace: "pre-wrap" }}>{checkpoint.reason}</ConsensedLongText>,
                        actionButtons.map((button, idx) => <span key={idx}>{button}</span>),
                        <Link href={`/api/tenants/${getTenantId()}/versions/` + checkpoint.id}>Download</Link>

                    ]
                })} />
        </>}
    </Stack>)
}