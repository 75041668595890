import { Stack, Typography } from "@mui/material"
import { AccountInfo, FundMove } from "./api"
import React from "react"
import util from "./util"
import Table from "./Table2"
import { Link } from "react-router-dom"
import { urlForMove } from "./MovePage"

export default (props: { move: FundMove, getAccount: (accountId: string) => AccountInfo | undefined }) => {
    const { move, getAccount } = props

    const rows = move.flows.map((flow, idx) => {
        return {
            key: `move-${move.id}-flow-${idx}-${flow.amount}`,
            cells: [
                util.formatAmount(flow.amount, move.currency),
                getAccount(flow.fromFundId)?.name,
                getAccount(flow.toFundId)?.name
            ]
        }
    })
    return (
        <Stack spacing={2}>
            <Typography variant="body1"><Link to={urlForMove(move)}>{move.memo}</Link></Typography>
            <Typography variant="body1">When: {util.formatLongDateTime(move.when)}</Typography>
            <Table
                header={["Amount", "From", "To"]}
                rows={rows}
            />
        </Stack>
    )
}