import React from 'react'
import _ from 'underscore'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, useMediaQuery, useTheme } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import PhysicalTransactionPanel, { PhysicalItemRequest } from './PhysicalTransactionPanel'

export default (props: { sourceId: string, onComplete: (change: PhysicalItemRequest | undefined) => void }) => {
    const { sourceId, onComplete } = props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [name, setName] = React.useState("")
    const [error, setError] = React.useState<string>()

    const doClose = () => {
        onComplete(undefined)
    }

    return <Dialog
        open={true}
        onClose={doClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>Create Manual Transaction<IconButton
            aria-label="close"
            onClick={doClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton></DialogTitle>
        <DialogContent>
            <Box style={{ marginTop: "10px" }}>
                <PhysicalTransactionPanel sourceId={sourceId} onChange={onComplete} />
            </Box>
        </DialogContent>
        {/* <Stack style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
            <TextField label="Name" value={name} onChange={e => setName(e.target.value)} />
        </Stack>
        <DialogActions>
            <Button variant="contained" onClick={doSave}>Save</Button>
        </DialogActions> */}
        {error && <Stack direction="row-reverse" style={{ margin: "20px" }}><Alert color="error">{error}</Alert></Stack>}

    </Dialog>
}
