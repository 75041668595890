
import $ from 'jquery'
import _ from 'underscore'
import http from './cj/HttpClient'
import { getTenantId } from './util';
import { ExistingMoveRef, FundMove, FundsFlow, MapToMoves, NewMoveSpec, PhysicalItem, PlanItemRef, PlannedFundMove, PlannedItemHandle, TemplateInfoListItem, TransferOptionDto } from './api';
import { asNewFundMove } from './api-actions';
import { totalFlows } from './moveUtils';
import { CurrencyAmount } from './CurrencyAmountField';

export interface Mapper {

    isUniqueMatch: (option: TransferOptionDto, item: PhysicalItem) => boolean
    getTemplate: (item: PhysicalItem) => TemplateInfoListItem | undefined
}

export default (): Mapper => {
    var templates: TemplateInfoListItem[] = [];

    http({
        url: `/api/tenants/${getTenantId()}/templates`,
        method: "GET",
        options: { async: false },
        onResponse: function (response) {
            if (response.status === 200) {
                templates = JSON.parse(response.body);
            } else {
                alert("Error fetching templates");
            }
        }
    });

    function getTemplate(item: PhysicalItem): TemplateInfoListItem | undefined {
        return _.find(templates, function (templateInfo, idx) {
            return new RegExp(templateInfo.pattern).test(item.memo);
        });
    }

    return {
        isUniqueMatch: isUniqueMatch,
        getTemplate: getTemplate
    };
}

function padTo2Places(number: number) {
    var pad;

    if (number < 10 && number > -9) {
        pad = "0";
    } else {
        pad = "";
    }

    return pad + number;
}

function comparableDateString(val: any) {
    var when;

    if (typeof val === "object") {
        when = val;
    } else {
        when = new Date(val);
    }

    var hours, minutes;

    hours = when.getHours();
    minutes = when.getMinutes();

    var timeOfDayString;

    if (hours === 0 && minutes === 0) {
        timeOfDayString = "";
    } else {
        timeOfDayString = " " + padTo2Places(hours) + ":" + padTo2Places(minutes);
    }

    return (when.getYear() + 1900) + "-" +
        padTo2Places(when.getMonth() + 1) + "-" +
        padTo2Places(when.getDate()) + timeOfDayString;
}

function isUniqueMatch(option: TransferOptionDto, item: PhysicalItem) {
    var date = comparableDateString(option.item.whenPosted);
    var datesMatch = comparableDateString(item.whenPosted) == date;
    var textMatches = option.item.memo.indexOf(item.memo) != -1;
    return (datesMatch && textMatches);
}



export const splitsToMapping = (split: Record<string, number>, itemAmount:CurrencyAmount):MapToMoves => {
    const hackDefaultFundId = "generic-income-expense"
    const isExpense = itemAmount.amount<0
    const currency = itemAmount.currency
    
    const flows: FundsFlow[] = Object.keys(split).map(accountId => {
        const amount = split[accountId]
  
        const [fromId, toId] = isExpense ? [accountId, hackDefaultFundId] : [hackDefaultFundId, accountId]

        return {
            fromFundId: fromId,
            toFundId: toId,
            amount: Math.abs(amount),
        }
    })

    const total = flows.reduce((accum, next) => accum + next.amount, 0)



    const n: NewMoveSpec = {
        type: "new-move",
        newMove: asNewFundMove({
            memo: "",
            when: new Date().getTime(), // TODO: this needs to be defined? Or, is ~now ok?
            currency: currency,
            flows: flows,
        })
    }

    var mappings: MapToMoves = {
        type: "to-moves",
        memo: "",
        allocations: [{
            move: n,
            amount: itemAmount.amount,
        }],
    }

    return mappings
}


export const mapToExistingMove = (move:FundMove, itemAmount:CurrencyAmount):MapToMoves => {
    if(!move) throw "Move is missing ID: " + JSON.stringify(move)
    const ref:ExistingMoveRef = {
        type: "existing-move",
        existingId: move.id!!
    }
    const mappings:MapToMoves = {
        type: "to-moves",
        memo: "", 
        allocations: [
            {  
                move:ref,
                amount:itemAmount.amount,
            }
        ]
    }

    return mappings
}

export const mapToPlannedMove = (plannedId:PlannedItemHandle, itemAmount:CurrencyAmount):MapToMoves => {
    const ref:PlanItemRef = {
        type: "plan-item",
        handle: plannedId
    }

    const mappings:MapToMoves = {
        type: "to-moves",
        memo: "", 
        allocations: [
            {  
                move:ref,
                amount:itemAmount.amount,
            }
        ]
    }

    return mappings
}