
import { ReactNode, useEffect, useState } from 'react';
import http from './cj/HttpClient'
import $ from 'jquery'
import moment, { DurationInputArg1, DurationInputArg2, Moment } from 'moment-timezone';
import { AccountInfo, BoxedFrequency, Flow, Frequency, FullPlannedMove, MappingOption, MonthDate, Monthly, MoveReference, Once, Organization, PDate, PhysicalAccountDto, PhysicalConfigType, PhysicalItem, SplitPattern, Template, Weekly, Yearly } from './api';
import { calcQuarters, sameDay } from './DateAndTime';
import { CurrencyAmount } from './CurrencyAmountField';
import { KIND_ALIEN, KIND_STUB } from './accounts';

export type Primitive = string | number | boolean

export function distinct<T extends Primitive>(items: T[]): T[] {
    return distinctBy(items, i=>i)
}

export function distinctBy<T, K extends Primitive>(items: T[], keyFn:(i:T)=>K): T[] {
    const seen = new Set<K>()

    return items.filter(i => {
        const key = keyFn(i)
        if (seen.has(key)) {
            return false
        } else {
            seen.add(key)
            return true
        }
    })
}

export function getTenantId(): string {
    return window.location.pathname.split("/")[2]
}

function formatAmount(qty: number, currency: string) {
    if (currency === "USD") {
        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(qty / 100)
    } else if (currency.indexOf("Time") !== -1) {
        return (qty / 1000 / 60 / 60).toFixed(2) + " hours " + currency;
    } else {
        return qty + " (" + currency + ")";
    }
}

export const formattedColorCurrencyAmount = (ca:CurrencyAmount):ReactNode => formattedColorAmount(ca.amount, ca.currency)
let formattedColorAmount = (quantity: number, currency: string):ReactNode => {
    var classes = ((quantity < 0) ? "negative-amount" : ((quantity > 0) ? "positive-amount" : "zero-amount"));
    return <span className={classes}>{formatAmount(quantity, currency)}</span>
}
export type ReactThing = ReactNode | string | undefined
let joinReact = (arrayOfReactElements: ReactThing[], separatorReactElement: (key: any) => ReactThing): ReactThing[] => {
    let results = []
    for (var x = 0; x < arrayOfReactElements.length; x++) {
        if (results.length > 0) results.push(separatorReactElement(x))
        results.push(arrayOfReactElements[x])
    }
    return results
}

function get(url: string): string {
    var data: string;
    http({
        url: url,
        method: "GET",
        onResponse: function (response) {
            console.log("done fetching");
            if (response.status === 200) {
                data = response.body;
            } else {
                throw "Error retrieving " + url;
            }
        }
    }, { async: false });
    return data!!;
}

function fetch<T>(url: string): T {
    var data: T;
    http({
        url: url,
        method: "GET",
        onResponse: function (response) {
            console.log("done fetching");
            if (response.status === 200) {
                data = JSON.parse(response.body);
            } else {
                throw "Error retrieving " + url;
            }
        }
    }, { async: false });
    return data!!;
}

export const getPromise = <T,>(url: string): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        http({
            url: url,
            method: "GET",
            options: { async: false },
            onResponse: function onResponse(response) {
                if (response.status !== 200) {
                    reject(`${response.status}:\n${response.body}`)
                } else {
                    resolve(JSON.parse(response.body))
                }
            }
        });
    })
}

function groupArray<T>(array: T[], property: string): Record<string, T[]> {
    const groups: Record<string, T[]> = {};

    $.each(array, function (idx, item) {
        var groupName = (item as any)[property];
        var group = groups[groupName];
        if (!group) {
            group = [];
            groups[groupName] = group;
        }
        group.push(item);
    });

    return groups;
}

export function dateHack(val: any) {
    const when = moment(val).utc()//hack!!
    return when
}

export const formatPercent = (x:number) => (x * 100).toFixed(2)+"%"

export const formatColorPercent= (x:number) => {
    var classes = ((x < 0) ? "negative-amount" : ((x > 0) ? "positive-amount" : "zero-amount"));
    return <span className={classes}>{formatPercent(x)}</span>
}

export function formatDate(val: any) {
    return formatDateSane(dateHack(val))
}

export function formatTime(val: any) {
    return formatTimeSane(dateHack(val))
}
export function formatTimeIfNotMidnight(val: any) {
    return formatTimeIfNotMidnightSane(dateHack(val))
}

export function formatTimeIfNotMidnightSane(when: moment.Moment) {
    const hours = when.hour()
    const minutes = when.minute()

    return (hours === 0 && minutes === 0) ? "" : formatTimeSane(when)
}


function formatDateSane(when: moment.Moment) {
    return (when.year()) + "-" +
        padTo2Places(when.month() + 1) + "-" +
        padTo2Places(when.date());
}

function formatTimeSane(when: moment.Moment) {
    const hours = when.hour()
    const minutes = when.minute()

    return padTo2Places(hours) + ":" + padTo2Places(minutes);
}

function formatLongDateTimeSane(when: moment.Moment) {
    const hours = when.hour()
    const minutes = when.minute()

    var timeOfDayPart = formatTimeIfNotMidnightSane(when)

    const timeOfDayParts = timeOfDayPart.length == 0 ? [] : [timeOfDayPart]

    return [formatDateSane(when)].concat(timeOfDayParts).join(" ")
}

function formatLongDateTime(val: any) {
    return formatLongDateTimeSane(dateHack(val))
}

export function formatDateTimeRange(start: number, end: number | undefined) {
    if (!end) {
        return formatLongDateTimeSane(dateHack(start))
    } else {
        const s = dateHack(start)
        const e = dateHack(end)

        // console.log("got ", s.format(), e.format(), "aka", ymd(s), ymd(e), "from", start, end)

        const nextMonth = moment(s).add(1, "month")
        const startOfYear = moment(s).startOf('year');
        const startOfNextYear = moment(startOfYear).add(1, "year")

        // console.log("nextMonth ", nextMonth.format())
        // console.log("startOfYear ", startOfYear.format())
        // console.log("startOfNextYear ", startOfNextYear.format())

        const quarters = calcQuarters(s)

        // console.log("quarters", quarters.map(it => [ ymd(it.start),  ymd(it.end)]))

        // console.log("formatLongDateTimeRange", "start", start, "end", end)

        const quarter = quarters.find(q => sameDay(q.start, s) && sameDay(q.end, e))

        // console.log("quarter?", quarter)

        if (quarter) {
            return `${quarter.label} ${s.format('YYYY')}`
        } else if (sameDay(e, nextMonth)) {
            return `${s.format('MMMM YYYY')}`
        } else if (sameDay(startOfYear, s) && sameDay(startOfNextYear, e)) {
            return `${s.format('YYYY')}`
        } else {
            return `${formatLongDateTimeSane(s)} through ${formatLongDateTimeSane(e)}`
        }
    }
}

function padTo2Places(number: number) {
    var pad;

    if (number < 10 && number > -9) {
        pad = "0";
    } else {
        pad = "";
    }

    return pad + number;
}

function addToNumericAccumulator(amount: number, key: string, totals: Record<string, number>) {

    var total = totals[key];
    if (!total) {
        total = 0;
    }
    total += amount;
    totals[key] = total;

    return total;
}

function throttled(threshold: number, valueOfThis: any, action: any) {
    var latestArgs: any = undefined;
    var latestInvocation = 0

    function invokeNowOrLater() {
        let now = new Date().getTime()
        let elapsed = (now - latestInvocation)

        console.log("DBNC elapsed v threshold ", elapsed, threshold)
        if (elapsed > threshold) {
            console.log("DBNC executing")
            action.apply(valueOfThis, latestArgs)
        } else {
            console.log("DBNC Too soon ", elapsed, " vs ", threshold, " e ", !elapsed)
            setTimeout(invokeNowOrLater, threshold)
        }
    }

    return function () {
        let now = new Date().getTime()

        latestArgs = arguments;
        latestInvocation = now;

        invokeNowOrLater()
    }
}

let daysInMillis = (numDays: number) => numDays * 24 * 60 * 60 * 1000
let monthsInMillis = (numMonths: number) => daysInMillis(numMonths * (365 / 12))
let now = () => new Date().getTime()


export function sortedBy<T>(list:T[], fn:(i:T)=>string):T[] {
    return [... list].sort((a, b)=>{
        const ka = fn(a)
        const kb = fn(b)

        return ka.localeCompare(kb)
    })
}

export const formattedTotals = (totals: Record<string, number>) => {
    let foo = Object.keys(totals).map((currency) => {
        const qty = totals[currency]
        return <span key={`${currency}-${qty}`}>{formattedColorAmount(qty, currency)}</span>
    })

    return joinReact(foo, k => <span key={k}>","</span>)
}

export const formattedPercentages = (totals: Record<string, number>) => {
    let foo = Object.keys(totals).map((currency) => {
        const qty = totals[currency]
        return <span key={`${currency}-${qty}`}>{formatColorPercent(qty)} {currency}</span>
    })

    return joinReact(foo, k => <span key={k}>","</span>)
}


export const formattedPercentagesWithSigns = (totals: Record<string, number>) => {
    let foo = Object.keys(totals).map((currency) => {
        const qty = totals[currency]
        const sign = qty > 0 ? "+" : qty < 0 ? "" : ""
        return <span key={`${currency}-${qty}`}>{sign}{formatPercent(qty)} {currency}</span>
    })

    return joinReact(foo, k => <span key={k}>","</span>)
}


export const formattedTotalsAndCounts = (totals: Record<string, number>, counts: Record<string, number>) => {
    let foo = Object.keys(totals).map((currency) => {
        const qty = totals[currency]
        return <span key={`${currency}-${qty}`}>{formattedColorAmount(qty, currency)} ({counts[currency]})</span>
    })

    return joinReact(foo, k => <span key={k}>","</span>)
}


export const calcTotal = (template: Template): number => {
    var total = 0;
    Object.values(template.split).forEach((amt) => {
        total += amt;
    });
    return total
}


export const take = <T,>(items: T[] | undefined, max: number): T[] => {
    const orig = items ?? []
    if (orig.length > max) {
        const r: T[] = []
        for (let x = 0; x < max; x++) {
            r[x] = orig[x]
        }
        return r
    } else {
        return orig
    }
}

export const initialCap = (s: string) => s.substring(0, 1).toUpperCase() + s.substring(1)

export const describeFullPlannedMoveState = (m:FullPlannedMove):string => {
    let state: string = ""
    if (m.unActualization) {
        state = "planned"
    } else {
        state = "realized"
    }
    return state
}
export const describeState = (option: MappingOption): string => {
    let state: string = ""
    if (option.planned && option.move) {
        state = "realized"
    } else if (option.planned && !option.move) {
        state = "planned"
    } else if (!option.planned && option.move) {
        state = "unplanned"
    }
    return state
}
export function typeLabelForAccountKind(kind: string) {
    return kind == "Account" ? "Fund" : kind;
}

export const typeLabeForAccount = (account: AccountInfo) => typeLabelForAccountKind(account.kind)

export const isPartOfTransfer = (item: PhysicalItem) => item.otherSideOfTransfer || item.otherAccount

export const mapRecord = <K extends string, V, R,>(record: Record<K, V>, fn: (key: K, value: V) => R): R[] => {
    const result: R[] = []
    const keys = Object.keys(record) as K[]

    keys.forEach((key) => {
        const value = record[key]
        result.push(fn(key, value))
    })

    return result
}
export const mapRecordReverse = <K extends string, V, R,>(record: Record<K, V>, fn: (value: V, key: K,) => R): R[] => {
    const result: R[] = []
    const keys = Object.keys(record) as K[]

    keys.forEach((key) => {
        const value = record[key]
        result.push(fn(value, key))
    })

    return result
}


export const renderTitle = (title: string) => {
    if (title !== document.title) {
        console.log("Setting title to ", title)
        document.title = title
    }
}

export const isDebt = (source: PhysicalAccountDto) => {
    return isDebtType(source.config?.type)
}

export const isDebtType = (type: PhysicalConfigType | undefined) => {
    return type === "line-of-credit"
}



function monthNameForNumber(monthNum: number) {
    var names = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"];

    return names[monthNum - 1];
}

function monthDayOrdinal(dayNum: number) {
    var text = dayNum.toString();
    var lastChar = text.substring(text.length - 1);
    if (dayNum > 10 && dayNum < 20) {
        return text + "th";
    } if (lastChar == "1") {
        return text + "st";
    } else if (lastChar == "2") {
        return text + "nd";
    } else if (lastChar == "3") {
        return text + "rd";
    } else {
        return text + "th";
    }
}

export const lastInstanceTime = (freq:Frequency):PDate => {
    if (freq.type === "monthly") {
        const schedule = freq as Monthly
        return schedule.last
    } else if (freq.type === "weekly") {
        const schedule = freq as Weekly
        return schedule.last
    } else if (freq.type === "yearly") {
        const schedule = freq as Yearly
        return schedule.last
    } else if (freq.type === "once"){
        const schedule = freq as Once
        return schedule.when
    }else {
        throw `Not sure how to instances of type ${freq.type}`
    }
}

export const describeFrequencey = (freq: Frequency):string=> {

    var description = "";

    const addBoxInfo = (schedule:BoxedFrequency):string => {
        if(schedule.last){
            return description += ` (ending ${formatDate(schedule.last)})`
        }else{
            return description
        }
    }

    if (freq.type === "monthly") {
        const schedule = freq as Monthly
        if (schedule.skip == 1) {
            description = 'monthly';
        } else {
            description = 'every ' + schedule.skip + ' months';
        }

        description += " on the ";
        let daysOfMonth = schedule.daysOfMonth.length == 0 ? [schedule.first.day] : schedule.daysOfMonth
        daysOfMonth.forEach(day => description += monthDayOrdinal(day));
        
        description = addBoxInfo(schedule)
    } else if (freq.type === "weekly") {
        const schedule = freq as Weekly
        if (schedule.skip == 1) {
            description = 'every week';
        } else {
            description = 'every ' + schedule.skip + ' weeks';
        }

        description += " on";

        schedule.daysOfWeek.forEach(day => {
            description += (" " + day)
        });
        description = addBoxInfo(schedule)
    } else if (freq.type === "yearly") {
        const schedule = freq as Yearly
        if (schedule.skip == 1) {
            description = 'annually';
        } else if(schedule.skip == 2){
            description = 'bi-annually';
        }else{
            description = 'every ' + schedule.skip + ' years';
        }

        const describeDay = (day: MonthDate) => {
            return monthNameForNumber(day.month) + " " + monthDayOrdinal(day.day)
        }

        var daysDescription = ""
        if (schedule.dates && schedule.dates.length > 0) {
            daysDescription = schedule.dates.map(describeDay).join(", ")
        } else {
            daysDescription = describeDay(schedule.first)
        }

        description += " on " + daysDescription;
        description = addBoxInfo(schedule)
    }
    return description
}

export const describeSchedule = (flow: Flow) => describeFrequencey(flow.schedule)

export default {
    get: get,
    formatLongDateTime: formatLongDateTime,
    formatAmount: formatAmount,
    formattedColorAmount: formattedColorAmount,
    joinReact: joinReact,
    daysInMillis: daysInMillis,
    monthsInMillis: monthsInMillis,
    now: now,
    fetch: fetch,
    throttled: throttled,
    addToNumericAccumulator: addToNumericAccumulator,
    formattedTotals: formattedTotals,
    groupArray: groupArray
};



export const asMoveRef = (o:MappingOption):MoveReference => {
    return {
        planned: o.planned?.planned.plannedItemId,
        existing: o.move?.id
    }
}


export const withItemMoved = <T,>(arr2:T[], old_index:number, new_index:number):T[] => {
    const copy:(T|undefined)[] = [... arr2]

    if (new_index >= copy.length) {
        var k = new_index - copy.length + 1;
        while (k--) {
            copy.push(undefined);
        }
    }
    copy.splice(new_index, 0, copy.splice(old_index, 1)[0]);
    return copy as T[]
};



export const applyTemplate = (amount:number, split:SplitPattern):Record<string, number> => {
    const result:Record<string, number> = {}

    let remainder = amount
    Object.keys(split.split).forEach(accountId=>{
        const amt = split.split[accountId]
        const applied = Math.min(amt, remainder)
        result[accountId] = applied
        remainder = remainder - applied
    })

    if(remainder>0){
        if(!split.remainder){
            throw `There is a ${remainder} after processing ${amount}, but no remainder account is specified for split: ${split}`
        }else{
            result[split.remainder] = ((result[split.remainder] ?? 0) + remainder)
        }
    }

    return result;
}


//TC's Annual Eye Exam - yearly - 12/month ({"type":"yearly","skip":1,"first":{"year":2024,"month":5,"day":1},"last":{"year":2030,"month":1,"day":1},"dates":null}) - -$672.00
// ATT Bill - monthly - 12/month ({"type":"monthly","skip":1,"first":{"year":2023,"month":5,"day":9},"last":{"year":2030,"month":1,"day":1},"daysOfMonth":[]}) - -$4,163.16
export const averageTimesPerMonth = (s:Frequency):number => {
    switch(s.type){
        case "once": return 0
        case "weekly": {
            const w = s as Weekly
            const timesPerWeek = w.daysOfWeek.length == 0 ? 7 : w.daysOfWeek.length
            const weeksPerYear = (52/ w.skip) 
            const timesPerMonth = (weeksPerYear/12) * timesPerWeek
            return timesPerMonth
        }
        case "monthly": {
            const m = s as Monthly
            const timesPerMonth = m.daysOfMonth.length == 0 ? 1 : m.daysOfMonth.length
            const monthsPerYear = (12/ m.skip) 
            const timesPerYear = timesPerMonth * monthsPerYear
            return timesPerYear/12
        }
        case "yearly": {
            const y = s as Yearly
            const timesPeryear = (!y.dates || y.dates.length == 0) ? 1 : y.dates.length
            return  (timesPeryear/ y.skip) /12
        }
    }
}


export const combineTotals = (items:Record<string, number>[]):Record<string, number> => {
    const totals:Record<string, number> = {}

    items.forEach(item=>{
        Object.keys(item).forEach(key=>{
            totals[key] = (totals[key] ?? 0) + item[key]
        })
    })

    return totals
}

export  const entityName = (a:AccountInfo, organization:Organization|undefined):string => {

    if(a.kind == KIND_ALIEN){
        return a.name
    }else if(a.kind == KIND_STUB){
        return a.name
    }else{
        return organization?.name ?? "We"
    }
}


export const readUrlParams = (search:string):Record<string, string> => {
    const p = new URLSearchParams(search)
    const params:Record<string, string> = {}

    const e = p.entries()
    // console.log("e is ", e)
    let keepGoing = true
    while(keepGoing){
        const n = e.next()
        if(n.done){
            keepGoing = false
        }else{
            console.log("n is ", n)
            const [key, value] = n.value
            params[key] = value
        }
    }

    console.log(`Made`, params, `from ${search}`)
    return params
}

export const useUrlParam = (name:string):[string|undefined, (v:string|undefined)=>void]=>{
    const [params, setParam] = useUrlParams()

    console.log("params", params)
    const value = params[name]
    console.log(name, value, "params", params)
    return [value, (v)=>setParam(name, v)]
}

export const useJsonUrlParam = <T,>(name:string, defaultValue:T):[T, (v:T)=>void]=>{
    const [params, setParam] = useUrlParams()
    const jsonValue = params[name]
    const value:T = jsonValue !== undefined ? JSON.parse(jsonValue) : defaultValue
    return [value, (v)=> setParam(name, (v!== undefined) ? JSON.stringify(v) : undefined)]
}

export const toQueryString = (params: Record<string, any>): string => Object.keys(params).flatMap(key => {
    const value = params[key]
    return value ? [`${key}=${value ?? ''}`] : []
}).join("&")

export const useUrlParams = ():[Record<string, string>, (name:string, value:string|undefined)=>void]=>{

    const [params, setParams] = useState(readUrlParams(window.location.search))

    const setParam = (name:string, value:string|undefined)=>{
        const copy = {... params}
        if(value === undefined){
            delete copy[name];
        }else{
            copy[name] = value
        }
        setParams(copy)
    }

    useEffect(()=>{
        const expectedQuery = toQueryString(params)
        const actualQuery = toQueryString(readUrlParams(window.location.search))

        if(Object.keys(params).length != 0){
        // if(actualQuery != expectedQuery){
            console.log("updating query, expected", expectedQuery, "vs", actualQuery)
            window.history.pushState(
                undefined,
                '',
                window.location.pathname + "?" + expectedQuery
            )
        }
    }, [params])

    return [params, setParam]
}