import React, { ReactNode } from 'react'

export default (props: { tabs: Record<string, () => ReactNode> }) => {
    const [selectedTabName, setSelectedTab] = React.useState(Object.keys(props.tabs)[0])

    // this.state = {
    //     selectedTab: Object.keys(props.tabs)[0]
    // }
    // this.child = React.createRef();

    // currentTab() {
    //     return this.child.current
    // }

    // console.log("Tabs render() ", this.state)

    let { tabs } = props
    let tabNames = Object.keys(tabs)
    let tabLabels = tabNames.map((name, idx) => {
        let selectTab = () => {
            console.log("select tab ", name)
            setSelectedTab(name)
        }
        let extraClasses = (name === selectedTabName) ? " tab-tip-selected " : "";
        let classes = "tab-tip" + extraClasses
        return <span key={`tab-${name}-${idx}`} className={classes} onClick={selectTab}>{name}</span>
    });

    let selectedTab = tabs[selectedTabName]
    // let tabContent = React.cloneElement(
    //     selectedTab(),
    //     { ref: this.child }
    // )
    return <div className="tab-set">
        <div className="tab-set-header"><div className="tab-tips-holder">{tabLabels}</div></div>
        <div className="tab-content">{selectedTab()}</div>
    </div>
}