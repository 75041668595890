import { ItemInfo } from "./api"
import util from "./util"

export interface PhysicalBalanceAccumulator {
    addRaw: (items: ({ amount: number, currency: string })[]) => PhysicalBalanceAccumulator
    add: (p: ItemInfo) => PhysicalBalanceAccumulator
    addAll: (items: ItemInfo[]) => PhysicalBalanceAccumulator
    totals: () => Record<string, number>
    counts: () => Record<string, number>
}

export function balanceAccumulator(totals: Record<string, number> = {}, counts: Record<string, number> = {}): PhysicalBalanceAccumulator {

    // console.log("Balance accum is ", JSON.stringify(totals), JSON.stringify(counts))


    const addRaw = (items: ({ amount: number, currency: string })[]): PhysicalBalanceAccumulator => {
        const update = {
            ...totals
        }

        const countsUpdate = {
            ...counts,
        }

        items.forEach(i => {
            // console.log("Adding", i)
            const currency = i.currency
            update[currency] = (update[currency] ?? 0) + i.amount
            countsUpdate[currency] = (countsUpdate[currency] ?? 0) + 1
            // console.log("Adding", i, "makes", JSON.stringify(update), JSON.stringify(countsUpdate))
        })



        return balanceAccumulator(update, countsUpdate);

    }

    const addOne = (p: ItemInfo): PhysicalBalanceAccumulator => {
        return addAll([p])
    }

    const addAll = (items: ItemInfo[]): PhysicalBalanceAccumulator => {
        return addRaw(items.map(i => {
            return { currency: i.item.currency, amount: i.item.amount }
        }))
    }
    return {
        addRaw: addRaw,
        add: addOne,
        addAll: addAll,
        totals: () => totals,
        counts: () => counts,
    }
}
