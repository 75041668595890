import React, { ReactNode } from 'react'
import _ from "underscore"
import http, { Response } from "./cj/HttpClient"
import HelpScreen from './helpTab'
import AccountsScreen from './AccountsScreen'
import PlanScreen from './PlanScreen'
import DataTab from './dataTab'
import ReportsTab from './ReportsTab'
import ExternalScreen from './ExternalScreen'
import { Organization, UserInfoDto } from './api'

import { Link, Stack } from '@mui/material'
import TemplatesEditorTab from './TemplatesEditorTab'
import Dialogs from './Dialogs'

import { useParams } from "react-router-dom";
import RoutedTabs from './RoutedTabs'
import { renderTitle } from './util'
import { getTenantOrganization } from './api-actions'

export default (props: { user: UserInfoDto }) => {
    const { tenantId, sectionId } = useParams()
    const { user } = props
    let initialUpper = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
    const activeTab = sectionId ? initialUpper(sectionId) : "Accounts"
    const [organization, setOrganization] = React.useState<Organization>()
    const [error, setError] = React.useState<string>()

    React.useEffect(() => {
        getTenantOrganization(
            tenantId!!, 
            setOrganization, 
            response=>setError(response.body))
    }, [])

    const orgName = organization?.name ?? ""

    const changeName = () => {
        const newName = prompt("enter name", orgName)
        if (newName) {
            http({
                url: `/api/tenants/${tenantId}/organization`,
                method: "PUT",
                data: JSON.stringify({ ...organization, name: newName }),
                onResponse: () => { }
            })
        }
    }

    renderTitle(`Aim: ${orgName}`)

    console.log("Error is", error)

    return <Dialogs>
        <Stack>
            <div style={{ textAlign: "right", padding: "2px" }}>{user.userId} @ <Link style={{ cursor: "pointer" }} onClick={changeName}>{orgName}</Link></div>
            <RoutedTabs propertyName='sectionId' tabs={{
                "accounts": () => <AccountsScreen when={new Date()} />,
                "plan": () => <PlanScreen />,
                "external": () => <ExternalScreen />,
                "templates": () => <TemplatesEditorTab />,
                "reports": () => <ReportsTab />,
                "help": () => <HelpScreen />,
                "data": () => <DataTab />,
            }} />
        </Stack>

    </Dialogs>
}
