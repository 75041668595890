import React, { useRef } from 'react'
import $ from 'jquery'
import { AccountInfo, MappingSpec, PhysicalItem } from '../api'
import { Button, Stack } from '@mui/material'
import { splitsToMapping } from '../mappingLogic'
import { CurrencyAmount } from '../CurrencyAmountField'
import WeightsWidget, { applyWeights } from '../WeightsWidget'

export default (props: { item: PhysicalItem, accounts: AccountInfo[], onMappingsChange: (mappingFactory:(allocatedAmount:CurrencyAmount)=>MappingSpec) => void }) => {
    const { item, accounts, onMappingsChange } = props
    const [weights, setWeights] = React.useState<Record<string, number>>({})

    const doNext = () => {
        onMappingsChange(amount=>{
            const splits = applyWeights(amount, weights)
            const mapping = splitsToMapping(splits ?? {}, amount)
            console.log("Splits generated ", amount, " and", splits, "to ", mapping)
            return mapping
        })
    }
    
    console.log("Render() on the IncomeExpenseMappingWidget")

    return <Stack>
        <WeightsWidget
            weights={weights}
            accounts={accounts.filter(a=>a.kind=="Account")}
            onChange={setWeights}
        />
        <Button disabled={!weights || Object.values(weights).filter(v => v != 0).length == 0} onClick={doNext} variant="contained">Done</Button>
    </Stack>
}