import { Link } from "@mui/material"
import { FullPhysicalItemDetails } from "./api"
import util from "./util"
import React from "react"

export default (props: { item: FullPhysicalItemDetails }) => {
    const { item: physMap } = props
    const widthLimitChars = 30
    return (<div className="move-breakdown-physical-item">
        <div className="move-breakdown-physical-item-line">{util.formattedColorAmount(physMap.item.amount, physMap.item.currency)} on {util.formatLongDateTime(physMap.item.whenPosted)}</div>
        <div className="move-breakdown-physical-item-line">{physMap.item.id}</div>
        <div className="move-breakdown-physical-item-line">{physMap.accountName}</div>
        <div className="move-breakdown-physical-item-line"><TruncatedString max={widthLimitChars}>{physMap.item.memo}</TruncatedString></div>
        <div className="move-breakdown-physical-item-line"><TruncatedString max={widthLimitChars}>{physMap.item.mappings?.memo}</TruncatedString></div>
    </div>)
}

const TruncatedString = (props: { children: string | undefined, max: number }) => {
    const [showAll, setShowAll] = React.useState(false)
    const value = props.children ?? ""
    const valueToShow = showAll ? value : value.substring(0, props.max)
    const elipses = (!showAll && (valueToShow.length != value.length)) && <Link key="elipses" onClick={() => setShowAll(!showAll)}>...</Link>
    return <>{util.joinReact([valueToShow, elipses], (key) => <span key={key}> </span>)}</>
}