import { useParams } from "react-router-dom"
import { AccountInfo, PhysicalAccountInfoDto } from "./api"
import { CircularProgress } from "@mui/material"
import AccountViewer from "./AccountViewer"

export default (props: { accounts: AccountInfo[], physicalAccounts: PhysicalAccountInfoDto[], showMainScreen: () => void }) => {
    const { accounts, physicalAccounts, showMainScreen } = props
    const params = useParams()
    console.log("AccountPage params are ", params)
    const { accountId } = params
    let account = accounts.find(a => a.id == accountId)

    if (!account) {
        console.log("no such account ", accountId)
        return <CircularProgress />
    }


    const accountForId = (accountId: string): AccountInfo | undefined => {
        return accounts.find(a => a.id == accountId);
    }

    const physicalAccountForId = (accountId: string): PhysicalAccountInfoDto | undefined => {
        return physicalAccounts.find(a => a.id == accountId);
    }

    return (<div className="account-screen">
        Account {accountId}
        {<h2 className="account-header"><a href="#" className="all-accounts-link" onClick={showMainScreen}>Funds</a>/{account.category && <span>{account.category}/</span>}{account.name}</h2>}

        <AccountViewer
            account={account}
            accounts={accounts}
            accountForId={accountForId}
            physicalAccountForId={physicalAccountForId}
        />
    </div>)
}