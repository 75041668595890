import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup, Stack, TextField } from "@mui/material"
import { FundMove, FundsFlow, PlannedFundMove } from "./api"
import CloseIcon from '@mui/icons-material/Close';
import MoveEditor from "./MoveEditorNg";
import { createOrUpdateMove } from "./api-actions"
import { useState } from "react";
import { formattedColorCurrencyAmount } from "./util";
import { totalFlows, totalJustFlows } from "./moveUtils";
import CurrencyAmountField, { CurrencyAmount } from "./CurrencyAmountField";

const applyDifferentAmount = (amount:number, orig:FundMove):FundMove => {
    const defaultTotal = totalFlows(orig)
    if(defaultTotal == amount){
        return orig
    }else{
        const ratio = amount/defaultTotal
        const updatedFlows:FundsFlow[] = orig.flows.map((of, idx)=>{
            const origFlowAmount = of.amount
            const newFlowAmount = Math.floor(origFlowAmount * ratio)
            console.log(`Adjusting flow ${idx} from ${origFlowAmount} to ${newFlowAmount}`)
            return {
                ... of,
                amount:newFlowAmount
            }
        })

        const remainder = amount - totalJustFlows(updatedFlows)
        console.log(`Applying remainder ${remainder}`)

        updatedFlows[updatedFlows.length-1].amount += remainder

        const finalTotal = totalJustFlows(updatedFlows)
        if( finalTotal != amount){
            throw "Something is wrong - expected ${amount} but was ${finalTotal}"
        }
        return {
            ... orig,
            flows: updatedFlows
        }
    }
}

export default (props:{planned: PlannedFundMove, onSuccess: (realized: FundMove) => void, onClose:()=>void}) => {

    const { planned, onSuccess, onClose} = props

    const plannedMove:FundMove = {
         ...planned.planned,
        id: undefined,
    }
    const plannedAmount:CurrencyAmount = {amount:totalFlows(plannedMove), currency:plannedMove.currency}

    const [amount, setAmount] = useState(plannedAmount)
    const [confirmedMove, setConfirmedMove] = useState<FundMove>()
    const [mode, setMode] = useState("default")

    return (
        <Dialog
            open={true}
            onClose={onClose}
            // fullScreen={true}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle>Realize Move<IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            </DialogTitle>
            {confirmedMove ? (
                <DialogContent>
                    <MoveEditor 
                        move={confirmedMove} 
                        onComplete={move=>createOrUpdateMove(move, onSuccess)}
                        onDelete={onClose}
                        />
                </DialogContent>
            ) : <>
                <DialogContent>
                    <Stack>
                    
                        <RadioGroup
                            value={mode}
                            onChange={e => setMode(e.target.value)}
                            name="radio-buttons-group"
                        >
                            <Stack spacing={3}>
                                <FormControlLabel value={"default"} control={<Radio />} label={<>Planned Amount ({formattedColorCurrencyAmount(plannedAmount)})</>} />
                                <FormControlLabel value={"other"} control={<Radio />} label={<><CurrencyAmountField label="Other Amount" disabled={mode!="other"} value={amount} onChange={n=>setAmount({amount:n, currency:amount.currency})}/></>} />
                                
                            </Stack>
                        </RadioGroup>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={()=>setConfirmedMove(applyDifferentAmount(amount.amount, plannedMove))}>Next</Button>
                </DialogActions>
            </>}
        </Dialog>
    )
        
}