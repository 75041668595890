import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import _ from 'underscore'
import http, { Response } from '../cj/HttpClient'
import Table from '../Table2'
import InstantSelect from '../InstantSelect'
import { AccountListingInfo, AccountSyncConfig, ConnectionConfigDto, ConnectionSpecificationDto, PhysicalAccountInfoDto } from '../api'
import { getTenantId } from '../util'
import { getPhysicalAccountInfosPromise, getPhysicalAccountInfosSync } from '../api-actions'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Alert, CircularProgress } from '@mui/material'

const AccountDetailsPanel = (props: { account: AccountListingInfo | undefined }) => {

  const { account } = props
  var bankId, accountType, routingNumber, accountNumber;
  if (account?.specifics && account.specifics.accountDetails) {
    var accountDetails = account.specifics.accountDetails;
    bankId = accountDetails.bankId;
    accountNumber = accountDetails.accountNumber;
    routingNumber = accountDetails.routingNumber
    accountType = accountDetails.accountType;
    accountNumber = accountDetails.accountNumber;
  }


  var details: Record<string, any> = {
    "Description": account?.description,
    "BankId": bankId,
    "Routing Number": routingNumber,
    "Account Number": accountNumber,
    "Account Type": accountType
  }

  return <div className="account-details-panel">
    {Object.keys(details).map(name => {
      const value = details[name]
      if (value) {
        return [
          <div key={name + "-k"}>{name}</div>,
          <div key={name + "-v"}>{value}</div>]
      }
    })}
  </div>
}

const PhysicalMappingPanel = (props: { existingMapping: AccountSyncConfig /* ?? */, physicalAccounts: PhysicalAccountInfoDto[], onChange: (v: AccountSyncConfig) => void }) => {
  const { existingMapping, physicalAccounts, onChange } = props
  const [fromMode, setFromModeRaw] = React.useState("absolute")
  const [whenToStartRecordingAbsolute, setWhenToStartRecordingAbsolute,] = React.useState(existingMapping.whenToStartRecordingAbsolute || new Date().getTime())
  const [whenToStartRecordingRelative, setWhenToStartRecordingRelative] = React.useState(existingMapping.whenToStartRecordingRelative || 0)


  const notifyChange = (v: AccountSyncConfig) => {
    console.log("New mappings:", v)
    onChange(v)
  }


  const handleEnabledChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    let newValue = event.target.checked
    // console.log(newValue)
    notifyChange({
      ...existingMapping,
      isEnabled: newValue
    })
  }

  const handleWhenToStartRecordingAbsoluteChange = (newValue: number | undefined) => {
    if (newValue) {
      setWhenToStartRecordingAbsolute(newValue)
      notifyChange({
        ...existingMapping,
        whenToStartRecordingAbsolute: newValue,
        whenToStartRecordingRelative: undefined
      })
    }
  }

  const handleWhenToStartRecordingRelativeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = parseInt(event.target.value, 10)

    setWhenToStartRecordingRelative(newValue)
    notifyChange({
      ...existingMapping,
      whenToStartRecordingAbsolute: undefined,
      whenToStartRecordingRelative: newValue
    })
  }

  console.log(existingMapping.isEnabled, existingMapping.physicalAccountId, existingMapping)

  const setFromMode = (mode: string) => {
    setFromModeRaw(mode)

    const startTimes = {
      whenToStartRecordingAbsolute: mode === "absolute" ? whenToStartRecordingAbsolute : undefined,
      whenToStartRecordingRelative: mode === "relative" ? whenToStartRecordingRelative : undefined
    }


    notifyChange({
      ...existingMapping,
      ...startTimes
    })
  }

  const handlewhenToStopRecordingChange = (newValue: number | undefined) => {
    notifyChange({
      ...existingMapping,
      whenToStopRecording: newValue
    })
  }

  const handlePhysicalAccountChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    notifyChange({
      ...existingMapping,
      physicalAccountId: event.target.value
    })
  }

  return <div className="track-configuration-panel">
    <div className="controls-panel">
      <div className="is-enabled-panel">
        <input type="checkbox"
          className="is-enabled"
          checked={existingMapping.isEnabled}
          onChange={handleEnabledChange} />Enabled</div>
      Where to put transactions: <select className="physical-account" value={existingMapping.physicalAccountId} onChange={handlePhysicalAccountChange}>
        <option key="nuthin">-- select --</option>
        {
          physicalAccounts.map(function (physicalAccount) {
            return <option key={physicalAccount.id} value={physicalAccount.id}>{physicalAccount.name}</option>
          })
        }
      </select>
      Only record transactions that ocurred:
      <div>
        <div> From
          <form>
            <div><input type="radio" name="sinceType" className="since-date-button"
              checked={fromMode == "absolute"}
              onChange={() => setFromMode("absolute")} />
              A specific date:  <InstantSelect value={whenToStartRecordingAbsolute} onChange={handleWhenToStartRecordingAbsoluteChange} /></div>
            <div><input type="radio" name="sinceType" className="days-ago-button"
              checked={fromMode == "relative"}
              onChange={() => setFromMode("relative")} />
              <input className="days-ago" type="number" value={whenToStartRecordingRelative} onChange={handleWhenToStartRecordingRelativeChange} /> days ago</div>
          </form>
        </div>
        <div>
          Until <InstantSelect value={existingMapping.whenToStopRecording} onChange={handlewhenToStopRecordingChange} />
        </div>
      </div>
    </div>
  </div>
}

const AccountMappingPanel = (props: { existing: AccountSyncConfig[], accounts: AccountListingInfo[], physicalAccounts: PhysicalAccountInfoDto[], onChange: (updatedAccounts: AccountSyncConfig[]) => void }) => {
  const { accounts, existing, physicalAccounts, onChange } = props



  const genAccountNumber = (account: AccountListingInfo): string => account.specifics?.accountDetails?.accountNumber ?? "no account number"

  const foos: AccountSyncConfig[] = accounts.map((account) => {
    var accountNumber = genAccountNumber(account);
    var existingMapping: AccountSyncConfig | undefined;
    if (existing) {
      existingMapping = existing.find(a => {
        return a.accountNumber == accountNumber;
      });
    }

    const defaultValue: AccountSyncConfig = {
      isEnabled: false,
      accountNumber: accountNumber,
      whenToStartRecordingAbsolute: new Date().getTime(),
      physicalAccountId: "not-a-real-account-id",
      memo: "",
      whenToStartRecordingRelative: undefined,
      whenToStopRecording: undefined
    }

    return existingMapping ?? defaultValue
  })


  return <div className="accounts-listing">
    <Table
      header={["Notes", "Account Details", "Configuration"]}
      rows={
        foos.map((existingMapping, idx) => {
          const mapping = existingMapping
          const accountNumber = existingMapping.accountNumber
          const account = accounts.find((a) => genAccountNumber(a) == accountNumber)

          const handleAccountUpdate = (newVersion: AccountSyncConfig) => {
            const updatedAccounts = [...foos]
            updatedAccounts[idx] = newVersion
            onChange(updatedAccounts)
          }

          const handleMemoChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
            if (account) {
              handleAccountUpdate({
                ...mapping,
                memo: event.target.value
              })
            }
          }


          const handleMappingChange = (mapping: AccountSyncConfig) => {
            handleAccountUpdate(mapping)
          }


          return {
            key: `${existingMapping.physicalAccountId}-${idx}`,
            cells: [
              <textarea
                key={"memo-" + idx}
                className="memo"
                value={mapping.memo || ""}
                onChange={handleMemoChange}
                placeholder="Put notes here that help you understand what this account is for, why it is enabled/disabled or configured the way it is, etc." />,
              <AccountDetailsPanel account={account} />,
              <PhysicalMappingPanel
                // accountNumber={accountNumber}
                existingMapping={existingMapping}
                physicalAccounts={physicalAccounts}
                onChange={handleMappingChange} />,
            ]
          }
        })
      }
    />
  </div>

}

const listConnectionAccountsTemp = (newConnectionInfo: ConnectionSpecificationDto):Promise<AccountListingInfo[]>=>{
  return new Promise((resolveSuccess, resolveFailed)=>{
    http({
      url: `/api/tenants/${getTenantId()}/connections/listAccounts`,
      method: "POST",
      data: JSON.stringify(newConnectionInfo, null, 4),
      onResponse: (response: Response)=> {
        if (response.status === 200) {
          resolveSuccess(JSON.parse(response.body));
        } else {
          resolveFailed('Error.  Server said: "' + response.body + '"');
        }
      }
    })
  })
}

const listConnectionAccountsExisting = (maybeExistingConnectionId:string):Promise<AccountListingInfo[]>=>{
  return new Promise((resolveSuccess, resolveFailed)=>{

    http({
      url: `/api/tenants/${getTenantId()}/connections/` + maybeExistingConnectionId + "/listAccounts",
      method: "GET",
      onResponse: (response: Response)=> {
        if (response.status === 200) {
          resolveSuccess(JSON.parse(response.body));
        } else {
          resolveFailed('Error.  Server said: "' + response.body + '"');
        }
      }
    })
  })
}

export default (props:{maybeExistingConnection: ConnectionConfigDto|undefined, existing: AccountSyncConfig[], newConnectionInfo: ConnectionSpecificationDto | undefined, onChange:(updates: AccountSyncConfig[])=>void})=>{
  const {maybeExistingConnection, existing, newConnectionInfo, onChange} = props
  const [error, setError] = useState<string>()

  const [physicalAccounts, setPhysicalAccounts] = useState<PhysicalAccountInfoDto[]>()
  const [accounts, setAccounts] = useState<AccountListingInfo[]>()

  
  useEffect(()=>{
    getPhysicalAccountInfosPromise().then(setPhysicalAccounts)
  }, [])

  useEffect(()=>{
    const foo = newConnectionInfo ? listConnectionAccountsTemp(newConnectionInfo) : listConnectionAccountsExisting(maybeExistingConnection!!.id)
    foo.then(setAccounts).catch(setError)
  }, [newConnectionInfo])



  return (<>
    {error && <Alert color="error">{error}</Alert>}
    {(physicalAccounts && accounts) ? <AccountMappingPanel
        existing={existing}
        physicalAccounts={physicalAccounts}
        accounts={accounts}
        onChange={onChange} /> : <CircularProgress/>}
  </>)
   
}