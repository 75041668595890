import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import _ from 'underscore'
import http from './cj/HttpClient'
import InstantSelect from './InstantSelect'
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import LabeledSelect from './LabeledSelect'
import { enumKeys } from './typescript-tools'
import moment, { Moment } from 'moment'
import { dateHack } from './util'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export interface AnalysisWindow {
    from: number
    to: number
}

export enum WindowOption {
    CUSTOM = "Custom",
    LM = "Last Month",
    CONTEXTUAL_12_MONTHS = "12 month Context",
    NEXT_12_MONTHS = "Next 12 Months",
    LAST_12_MONTHS = "Last 12 Months",
    LAST_6_MONTHS = "Last 6 Months",
}

// function createEnumChecker<V, T extends Record<string, string>>(enumVariable: { e: Record<key in T, TEnumValue> }) {
// 	const enumValues = Object.values(enumVariable)
// 	return (value: string): value is TEnumValue => enumValues.includes(value)
// }


// export const enumerateEnum = <Y, T extends Record<string, Y>>(enumeration:T):Y[]=>{
//     return Object.values(enumeration)
// }

interface MomentWindow {
    from:Moment,
    to:Moment,
}

const toAnalysisWindow = (mw:MomentWindow):AnalysisWindow => {
    return {
        from:mw.from.unix() * 1000,
        to:mw.to.unix() * 1000,
    }
}
export const resolveWindowOption = (windowType:WindowOption):AnalysisWindow => {
    switch (windowType) {
        case WindowOption.LM:
            return previousMonth()
        case WindowOption.CONTEXTUAL_12_MONTHS:
            return toAnalysisWindow({
                from: moment().subtract(6, "M"),
                to: moment().add(6, "month"),
            })
        case WindowOption.NEXT_12_MONTHS:
            return toAnalysisWindow({
                from: moment(),
                to: moment().add(12, "month"),
            })
        case WindowOption.LAST_12_MONTHS:
            return toAnalysisWindow({
                from: moment().subtract(12, "M"),
                to: moment(),
            })
        case WindowOption.LAST_6_MONTHS:
            return toAnalysisWindow({
                from: moment().subtract(6, "M"),
                to: moment(),
            })

    }

    throw `Unsupported window type: ${windowType}`
}


export const startOfMonth = (m:Moment):Moment => {
    const d = moment(m)
    return dateHack(moment()
        .set("year", d.year())
        .set("month", d.month())
        .set("date", 1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0))
}

export const endOfMonth = (m:Moment):Moment => {
    const d = moment(m)
    return dateHack(moment()
        .set("year", d.year())
        .set("month", d.month())
        .add(1, "month")
        .set("date", 1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0)
        .subtract(1, "millisecond"))
}

export const month = (m:Moment): AnalysisWindow => {
    const bom = startOfMonth(m)
    const eom = endOfMonth(m)

    console.log(`Span is ${bom.format()} to ${eom.format()}`)

    return {
        from: bom.unix() * 1000,
        to: eom.unix() * 1000,
    }
}

export const previousMonth = (): AnalysisWindow => {
    return month(moment().subtract(1, "month"))
}

export const detectWindowType = (window:AnalysisWindow):WindowOption => {
    return Object.values(WindowOption).filter(o=>o!== WindowOption.CUSTOM).find(option => {
        console.log("Option: ", option, option == WindowOption.CUSTOM)
        const v = resolveWindowOption(option)
        return (v.from == window.from && v.to == window.to) 
    }) ?? WindowOption.CUSTOM
}

export default (props: { isFetching: boolean | undefined, onChange: (w: AnalysisWindow) => void, doUpdate: () => void, window: AnalysisWindow, disableStartChanges?: boolean }) => {

    const { isFetching, onChange, doUpdate, window, disableStartChanges } = props

    const [windowType, setWindowType] = useState<WindowOption>(detectWindowType(window))

    console.log("Window Value is ", window, "(" + windowType + ")")

    const handleFromChange = (value: number | undefined) => {
        console.log("Value is ", value)
        if (value) {
            onChange({
                ...window,
                from: value
            })
        }
    }

    const handleToChange = (value: number | undefined) => {
        console.log("Value is ", value)

        if (value) {
            onChange({
                ...window,
                to: value
            })
        }
    }

    const isCalendarMonth = (w:AnalysisWindow):boolean => {
        const wFrom = moment(window.from)
        const wTo = moment(window.to)

        console.log("Considering Window ", window, wFrom.format(), wTo.format())


        const expected = month(wFrom)

        console.log("Expected Window ", window, wFrom.format(), wTo.format())
    
        const bom = startOfMonth(moment(window.from))
        const eom = endOfMonth(moment(window.from))

        const sameStart = (bom.unix() * 1000) == window.from
        const sameEnd = (eom.unix() * 1000) == window.to

        console.log(`FFFFF Start ${sameStart} ${bom.format()} vs ${moment(window.from).format()}`)
        console.log(`FFFFF End ${sameEnd} ${eom.format()} vs ${moment(window.to).format()}`)

        return ( sameStart && sameEnd)
    }

    const goBack = ()=>{
        if(isCalendarMonth(window)){
            const prevBom = startOfMonth(moment(window.from).subtract(1, "month"))
            const prevEom = endOfMonth(prevBom)
            console.log(`FFFFF prev is ${prevBom.format()} to ${prevEom.format()}`)
            onChange({
                ... window,
                from:prevBom.unix() * 1000,
                to:prevEom.unix() * 1000,
            })
        }
    }

    const goForward = ()=>{
        if(isCalendarMonth(window)){
            const prevBom = startOfMonth(moment(window.from).add(1, "month"))
            const prevEom = endOfMonth(prevBom)
            console.log(`FFFFF prev is ${prevBom.format()} to ${prevEom.format()}`)
            onChange({
                ... window,
                from:prevBom.unix() * 1000,
                to:prevEom.unix() * 1000,
            })
        }
    }


    const canGoForwardAndBack = isCalendarMonth(window)

    const handleWindowChange = (t: WindowOption) => {
        setWindowType(t)
    }

    useEffect(() =>{
        if(windowType!="Custom"){
            onChange(resolveWindowOption(windowType))
        }
    }, [windowType])

    console.log("enum value is ", windowType)

    if (true) {

        return (<>
            <Stack
                direction="row"
                spacing={1}
                style={{
                    display: "inline-block",
                    border: "1px solid lightgray",
                    borderRadius: "5px",
                    padding: "15px",
                    margin: "15px",
                }}>
                <LabeledSelect
                    labelId="type-select"
                    fullWidth={false}
                    value={windowType}
                    label="Window Type"
                    onChange={e => handleWindowChange(e.target.value as WindowOption)}
                >
                    {enumKeys(WindowOption).map(k => {
                        const value = WindowOption[k]
                        return <MenuItem value={value}>{value}</MenuItem>
                    })}
                </LabeledSelect>
                
                <IconButton onClick={goBack} disabled={!canGoForwardAndBack}><NavigateBeforeIcon/></IconButton>
                <FormControl>
                    <InstantSelect
                        label="from"
                        disabled={disableStartChanges || windowType != WindowOption.CUSTOM}
                        value={window.from}
                        onChange={handleFromChange} />
                </FormControl>
                <FormControl>
                    <InstantSelect
                        disabled={windowType != WindowOption.CUSTOM}
                        label="to"
                        value={window.to}
                        onChange={handleToChange} />
                </FormControl>
                <IconButton onClick={goForward} disabled={!canGoForwardAndBack}><NavigateNextIcon/></IconButton>
                <Button
                    variant="outlined"
                    disabled={isFetching}
                    onClick={doUpdate}>Apply</Button>
            </Stack>
        </>
        )
    }
}





