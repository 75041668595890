import { IconButton, Radio, Stack } from "@mui/material"
import AccountSelect from "./AccountSelect"
import CurrencyAmountField from "./CurrencyAmountField"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AccountInfo, SplitPattern } from "./api";
import { withItemMoved } from "./util";

const firstUnused = (accounts: AccountInfo[], usedIds: string[]) => accounts.find(a => usedIds.indexOf(a.id) === -1)

export default (props: { value: SplitPattern, disabled: boolean, accounts: AccountInfo[], currency: string, onChange: (v: SplitPattern) => void }) => {
    const { value, disabled, accounts, currency, onChange } = props
    const keys = Object.keys(value.split)

    if (keys.length == 0) throw "This component only works with valid splits, but you gave one with zero entries: " + JSON.stringify(value)

    const handleAccountSelection = (idx: number, accountId: string) => {
        const currentAccountId = keys[idx]
        const currentAmount = value.split[currentAccountId]

        const newSplits: Record<string, number> = {}

        keys.forEach((key, n) => {
            if (n == idx) {
                newSplits[accountId] = currentAmount
            } else {
                newSplits[key] = value.split[key]
            }
        })

        onChange({
            ...value,
            split: newSplits
        })
    }
    const handleAccountAmount = (idx: number, amount: number) => {
        const accountId = keys[idx]

        const newSplits: Record<string, number> = { ...value.split }

        newSplits[accountId] = amount

        onChange({
            ...value,
            split: newSplits
        })
    }

    const handleRemainderUpdate = (idx: number) => {
        const accountId = keys[idx]

        onChange({
            ...value,
            remainder: accountId
        })
    }

    const addAnother = (idx: number) => {
        const nextAccount = firstUnused(accounts, keys)
        console.log("splits next is ", nextAccount)
        if (nextAccount) {

            const newSplits: Record<string, number> = {}

            keys.forEach((key, n) => {
                newSplits[key] = value.split[key]
                if (n == idx) {
                    newSplits[nextAccount.id] = 0
                }
            })

            onChange({
                ...value,
                split: newSplits
            })
        }
    }


    const moveUp = (idx: number)=>{

        if(idx>0){
            const newSplits: Record<string, number> = {}

            withItemMoved(keys, idx, idx-1).forEach(key=>{
                newSplits[key] = value.split[key]
            })

            onChange({
                ...value,
                split: newSplits
            })
        }
        

    }
    const moveDown = (idx: number)=>{

        if(idx<(keys.length-1)){
            const newSplits: Record<string, number> = {}

            withItemMoved(keys, idx, idx+1).forEach(key=>{
                newSplits[key] = value.split[key]
            })

            onChange({
                ...value,
                split: newSplits
            })
        }
    }
    const removeItem = (idx: number) => {
        const accountId = keys[idx]

        const newSplits: Record<string, number> = {}

        keys.forEach((key, n) => {
            if (n != idx) {
                newSplits[key] = value.split[key]
            }
        })

        onChange({
            ...value,
            split: newSplits
        })
    }
    console.log("Rendering splits value", JSON.stringify(value))

    const soloMode = keys.length == 1

    return (<Stack spacing={2}>
        {keys.map((key, idx) => {
            const amt = value.split[key]


            return <Stack direction="row" spacing={1} key={`${idx}-${key}`}>
                {!soloMode && <Radio checked={value.remainder == key} onChange={() => handleRemainderUpdate(idx)} />}
                <AccountSelect
                    label="account"
                    accounts={accounts}
                    selection={key}
                    disabled={disabled}
                    onChange={e => handleAccountSelection(idx, e.target.value)} />
                {keys.length > 1 && <CurrencyAmountField label="amount" value={{ amount: amt, currency: currency }} onChange={(v) => handleAccountAmount(idx, v)} />}
                
                <Stack>
                    <IconButton onClick={()=>moveUp(idx)}><KeyboardArrowUpIcon /></IconButton>
                    <IconButton onClick={()=>moveDown(idx)}><KeyboardArrowDownIcon /></IconButton>
                </Stack>

                <Stack>
                    <IconButton onClick={() => addAnother(idx)}><AddIcon /></IconButton>
                    {!soloMode && <IconButton onClick={() => removeItem(idx)}><RemoveIcon /></IconButton>}
                </Stack>
            </Stack>
        })}
    </Stack>
    )
}