import React from 'react'
import _ from 'underscore'
import dataCache from './data'
import ProfitAndLossReport from './reports/ProfitAndLossReport'
import BalanceSheetReport from './reports/BalanceSheetReport'
import RoutedTabs from './RoutedTabs'
import RoutedSubNavigation from './RoutedSubNavigation'

export default (props: {}) => {
    const [data, setData] = React.useState(dataCache())

    return <>
        <RoutedSubNavigation propertyName='subtab'
            tabs={{
                "balance-sheet": () => <BalanceSheetReport standardData={data} />,
                "profit-and-loss": () => <ProfitAndLossReport />
            }}
        />
    </>
}