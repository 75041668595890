
import {
    AdmonitionDirectiveDescriptor,
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    Button,
    ChangeAdmonitionType, ChangeCodeMirrorLanguage, CodeToggle, CreateLink, DiffSourceToggleWrapper, InsertAdmonition, InsertCodeBlock, InsertFrontmatter, InsertImage, InsertSandpack, InsertTable, InsertThematicBreak, ListsToggle, MDXEditor, MDXEditorProps, SandpackConfig, ShowSandpackInfo, UndoRedo,
    codeBlockPlugin,
    codeMirrorPlugin,
    // codeMirrorPlugin, 
    diffSourcePlugin, directivesPlugin, frontmatterPlugin, headingsPlugin, imagePlugin, linkDialogPlugin, linkPlugin, listsPlugin, quotePlugin, sandpackPlugin, tablePlugin, thematicBreakPlugin, toolbarPlugin
} from '@mdxeditor/editor'

import SaveIcon from '@mui/icons-material/Save';
import '@mdxeditor/editor/style.css'
import MarkdownContent from './MarkdownContent';
import { Box, FormLabel, Stack } from '@mui/material';

export default (props: { label?: string, value: string, editable: boolean, onChange: (newValue: string) => void }) => {
    const { label, value, editable, onChange } = props
    const defaultSnippetContent = `
    export default function App() {
      return (
        <div className="App">
          <h1>Hello CodeSandbox</h1>
          <h2>Start editing to see some magic happen!</h2>
        </div>
      );
    }
    `.trim()
    
    const simpleSandpackConfig: SandpackConfig = {
        defaultPreset: 'react',
        presets: [
          {
            label: 'React',
            name: 'react',
            meta: 'live react',
            sandpackTemplate: 'react',
            sandpackTheme: 'light',
            snippetFileName: '/App.js',
            snippetLanguage: 'jsx',
            initialSnippetContent: defaultSnippetContent
          },
        ]
      }
    const editor = (
        <>
            {!editable && <MarkdownContent>{value}</MarkdownContent>}
            {editable && <MDXEditor
                readOnly={!editable}
                onChange={onChange}
                markdown={value}
                plugins={[
                    linkPlugin(),
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
                    linkDialogPlugin(),
                    frontmatterPlugin(),
                    imagePlugin(),
                    diffSourcePlugin({ diffMarkdown: 'An older version', viewMode: 'rich-text' }),
                    tablePlugin(),
                    codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
                    // sandpackPlugin({ sandpackConfig: simpleSandpackConfig }),
                    codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS' } }),
                    thematicBreakPlugin(),
                    listsPlugin(),
                    toolbarPlugin({
                        toolbarContents: () => (<>
                            {editable && <>
                                <BlockTypeSelect />
                                <ListsToggle />
                                <BoldItalicUnderlineToggles />
                                <CreateLink />
                                <DiffSourceToggleWrapper>
                                </DiffSourceToggleWrapper>
                                <UndoRedo />
                                {/* <InsertAdmonition /> */}
                                <InsertCodeBlock />
                                {/* <InsertFrontmatter /> */}
                                <InsertImage />
                                <InsertTable />
                                {/* <InsertThematicBreak /> */}
                                <CodeToggle />
                                {/* <InsertSandpack/> */}
                                {/* <ChangeAdmonitionType/> */}
                                {/* <ChangeCodeMirrorLanguage/> */}
                                {/* <Button><SaveIcon/>Save</Button> */}
                                {/* <ShowSandpackInfo/> */}
                            </>
                            }
                        </>)
                    })
                ]}
            />
            }
        </>
    )

    if (label) {

        return (
            <Stack spacing={1}>
                <FormLabel>{label}</FormLabel>
                <Box style={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
                    {editor}
                </Box>
            </Stack>
        )

    } else {
        return editor
    }
}