import React, { useState } from 'react'
import AccountHistory from './AccountHistory'
import { AccountInfo, BucketState, PhysicalAccountInfoDto } from './api'
import ProjectionsScreen from './ProjectionsScreen'
import { Button, IconButton, Stack } from '@mui/material'
import { now } from './DateAndTime'
import LoadingThrobber from './LoadingThrobber'
import { DialogsContext } from './Dialogs'
import RoutedSubNavigation from './RoutedSubNavigation'
import { getAccountState, saveAccountInfo } from './api-actions'
import FloatingActionBar from './FloatingActionBar'
import MarkdownEditor from './MarkdownEditor'
import AccountPeoplePanel from './AccountPeoplePanel'
import MarkdownContent from './MarkdownContent'
import EditIcon from '@mui/icons-material/Edit';

export default (props: {
    account: AccountInfo, accounts: AccountInfo[],
    accountForId: (accountId: string) => AccountInfo | undefined,
    physicalAccountForId: (id: string) => PhysicalAccountInfoDto | undefined,
}) => {
    const { account, accounts, accountForId, physicalAccountForId } = props
    const [accountState, setaccountState] = React.useState<BucketState>()
    const [lastUpdate, setLastUpdate] = React.useState(now())
    const [notes, setNotes] = React.useState(account.notes)
    const [notesEditing, setNotesEditing] = React.useState(false)
    const [isRefreshing, setRefreshing] = useState(false)


    const refresh = ()=>{
        setRefreshing(true)
        getAccountState(account.id)
            .then(accountState=>{
                setaccountState(accountState)
                setLastUpdate(now())
            })
            .catch(err=> alert("Error loading!"))
            .finally(()=>setRefreshing(false))

    }

    React.useEffect(refresh, [])


    /**
     * "UTILITY" feature code ... needs to be ported to react
     */
    // if(account.kind==="Utility"){
    //     newTab.append('<button class="add-meter-reading-button">Add Meter Reading</button>');
    // }

    // newTab.find("button").button().click(function(){

    //     var account = util.fetch(accountUrl);


    //     var prevReadingLabel;
    //     var startingVal;

    //     if(account.latestMeterValue){
    //         prevReadingLabel = account.latestMeterValue;
    //         startingVal = account.latestMeterValue;
    //     }else{
    //         prevReadingLabel = "N/A";
    //         startingVal = "";
    //     }

    //     var val = startingVal;

    //     newTab.append('<div style="display:none;" id="meterReaderWizard"><div>Prev. Reading: ' + prevReadingLabel + '</div><div>New Reading:<input type="text" value="' + val + '"/><button class="moreButton">+</button><button class="lessButton">-</button></div><button class="addButton">Record</button></div>');

    //     var dialog = newTab.find("#meterReaderWizard").dialog({modal:true});
    //     var meterValueTextField = dialog.find("input:text");

    //     dialog.find(".moreButton").button().click(function(){
    //         val ++;
    //         //console.log(val);
    //         meterValueTextField.val(val);
    //     });


    //     dialog.find(".lessButton").button().click(function(){
    //         if(val>startingVal) {
    //             val --;
    //             //console.log(val);
    //             meterValueTextField.val(val);
    //         }
    //     });

    //     dialog.find(".addButton").button().click(function(){
    //         http({
    //             url:`/api/tenants/${getTenantId()}/accounts/` + account.id + "/meter-readings",
    //             method:"POST",
    //             data:JSON.stringify({
    //                 when:new Date().getTime(),
    //                 memo:"",
    //                 value:parseInt(meterValueTextField.val())}),
    //             onResponse:function(response){
    //                 if(response.status==200){
    //                     dialog.dialog("close");
    //                 }else{
    //                     alert("Error!  Server said:" + response.body);
    //                 }
    //             }
    //         });
    //     });
    // });

    const saveNotes = () => {
        const updatedAccount: AccountInfo = {
            ...account,
            notes: notes
        }
        saveAccountInfo(updatedAccount, (_, error) => {
            if (error) {
                alert(error);
            } else {
                setNotesEditing(false)
                markActualTabDirty()
            }
        })
    }
    function markActualTabDirty() {
        console.log("markActualTabDirty()")
        refresh()
    }

    const handleAccountInfoChange = (newInfo: AccountInfo) => {
        refresh()
    }

    let tabs = {
        "planned": () =>
            <ProjectionsScreen
                onUpdate={markActualTabDirty}
                account={account}
                accountState={accountState!!}
                accounts={accounts}
                lastUpdate={lastUpdate} />,
        "actual": () =>
            <AccountHistory
                onChange={markActualTabDirty}
                account={account}
                accountState={accountState}
                accountForId={accountForId}
                physicalAccountForId={physicalAccountForId} />,

        "Notes": () => <>
            {!notesEditing && <Button onClick={() => setNotesEditing(true)}>Edit</Button>}
            {notesEditing && <>
                <Button onClick={() => setNotesEditing(false)}>Cancel</Button>
                <Button variant="contained" disabled={notes == account.notes} onClick={saveNotes}>Save</Button>
            </>}
            <MarkdownEditor
                editable={notesEditing}
                value={notes ?? ""}
                onChange={setNotes} />
        </>,
        "People": () => <>
            <AccountPeoplePanel accountId={account.id}/>
        </>
    }

    const isReady = accountState ? true : false

    return <LoadingThrobber isLoading={!isReady}>
        {isReady && <Stack>
            <DialogsContext.Consumer>{dialogs => <>

                <div>
                    <MarkdownContent>{account.purpose.length>0 ? account.purpose : "_no description_"}</MarkdownContent>
                    <IconButton onClick={() => dialogs.showAccount({ value: account, onChange: handleAccountInfoChange })}><EditIcon/></IconButton>
                </div>

                <FloatingActionBar context={{accountId:account.id}} isRefreshing={isRefreshing} onRefresh={refresh}/>
                
            </>}</DialogsContext.Consumer>

            <RoutedSubNavigation propertyName="accountSubTab" tabs={tabs} />
        </Stack>}
    </LoadingThrobber>

}