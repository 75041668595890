import { useEffect, useState } from 'react'
import http from './cj/HttpClient'
import { getTenantId } from './util';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { ConnectionsListItem } from './api';
import { getConnections } from './api-actions';
import ConnectionCard from './ConnectionCard';
import LoadingThrobber from './LoadingThrobber';
import { DialogsContext } from './Dialogs';

export default (props: {}) => {

    const [connections, setConnections] = useState<ConnectionsListItem[]>([])
    const [isBusy, setBusy] = useState(false);

    const reload = () => getConnections(setConnections)
    
    useEffect(reload, [])

    let doScanAll = () => {
        setBusy(true)

        http({
            url: `/api/tenants/${getTenantId()}/connections/scan`,
            method: "POST",
            onResponse: function () {
                setBusy(false)
                reload()
            }
        });
    }

    return <DialogsContext.Consumer>{dialogs => <><div>
        <Stack direction="row" spacing={2} style={{ margin: "20px" }}>
            <Button variant="outlined" onClick={doScanAll}>Scan All</Button>
            <Button variant="outlined" onClick={()=>dialogs.showConnection({
                value:{type:"ofx", existing:undefined},
                onChange:()=>{}
            })}>New Raw OFX Connection</Button>
            <Button variant="outlined" onClick={()=>dialogs.showConnection({
                value:{type:"plaid", existing:undefined},
                onChange:()=>{}
            })}>New Plaid Connection</Button>
        </Stack>
        <Stack spacing={4}>
            {connections.map((c, idx) => <ConnectionCard item={c} key={idx} />)}
        </Stack>
        {isBusy && <Dialog open={true}>
            <DialogContent>
                <LoadingThrobber isLoading={true}/>
                <DialogContentText>Scanning...</DialogContentText>
            </DialogContent>
        </Dialog>}
    </div>
    </>}</DialogsContext.Consumer>

}
