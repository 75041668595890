import React from 'react'
import _ from 'underscore'
import http from './cj/HttpClient'
import util, { getTenantId } from './util'
import cookies from './cookies'
import base64 from './dankogai/base64'
import { Alert, Button, Stack, TextField, Typography } from '@mui/material'

export default (props: { onComplete: () => void }) => {
    const { onComplete } = props
    const [isWorking, setWorking] = React.useState(false)
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [error, setError] = React.useState<string>()

    function encodeCredentials(user: string, password: string) {
        var result = base64.encode(user + ":" + password);
        return result;
    }

    let doLogin = () => {
        setError(undefined)
        setWorking(true)

        let user = username

        http({
            url: "/sessions",
            method: "POST",
            headers: { "Authorization": "Basic " + encodeCredentials(user, password) },
            data: JSON.stringify({}),
            onResponse: (response) => {
                var tokenHolder;
                setWorking(false)
                if (response.status === 200) {
                    tokenHolder = JSON.parse(response.body);
                    console.log("path is ", "/")
                    document.cookie = `Authorization=${tokenHolder.token}; path=/`
                    onComplete()
                } else {
                    setError("Invalid Credentials")
                }
            }
        });
    }

    return <div style={{
        display: "inline-block",
        border: "1px solid grey",
        borderRadius: "5px",
        padding: "20px",
        verticalAlign: "middle",
        textAlign: "center",
        marginTop: "10%",
    }}>
        <Stack spacing={2}>
            <Typography variant='h4'>Login</Typography>
            <TextField
                label="username"
                disabled={isWorking}
                value={username}
                onChange={e => setUsername(e.target.value)}
                size="medium"
                type="text" />
            <TextField
                disabled={isWorking}
                value={password}
                onChange={e => setPassword(e.target.value)}
                size="medium"
                type="password"
                label="password" />
            <Button disabled={isWorking} onClick={doLogin} variant='contained'>Login</Button>
            {error && <Alert color='error'>{error}</Alert>}
        </Stack>
    </div>

}
