import React, { ReactNode } from 'react'

export type TableRowData = (ReactNode | string | undefined)[]


export const Table = (props: { header: string[], rows: TableRowData[] }) => {
    const { header, rows } = props

    const makeRow = (values: (ReactNode | string | undefined)[], idx: number) => {
        const rowKey = `row-${idx}`
        return <tr key={rowKey} className="row-ng">{values.map((value, idx) => <td key={`${rowKey}-${idx}`}>{value}</td>)}</tr>
    }

    const makeHeader = (columnNames: string[]) => {
        return <tr key="header" className="row-ng">{columnNames.map((columnName, idx) => <th key={idx}>{columnName}</th>)}</tr>
    }

    return (
        <table className="table-ng">
            <thead>
                {header ? makeHeader(header) : ""}
            </thead>
            <tbody>
                {rows.map(makeRow)}
            </tbody>
        </table>);
}

export default Table

