import React from 'react'
import http from './cj/HttpClient'
import { distinct, getTenantId } from './util'
import { AccountInfo } from './api'
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Stack, TextField } from '@mui/material'
import LabeledSelect from './LabeledSelect'
import CloseIcon from '@mui/icons-material/Close';
import { getAccounts, saveAccountInfo } from './api-actions'
import MarkdownEditor from './MarkdownEditor'

interface AccountEditorProps {
    account: AccountInfo
    onUpdated: (a: AccountInfo) => void
}

export const AccountEditor = (props: AccountEditorProps) => {
    const { onUpdated } = props
    const [isWorking, setWorking] = React.useState(false)
    const [account, setAccount] = React.useState(props.account)
    const [accounts, setAccounts] = React.useState<AccountInfo[]>()

    React.useEffect(() => getAccounts(results => setAccounts(results)), [])

    const existingCategoriesForKind = distinct(accounts?.filter(it => it.kind == account.kind)?.map(a => a.category ?? "") ?? [])


    const labelsForKinds: Record<string, string> = {
        "Account": "Fund",
        "Alien": "Alien",
        "Stub": "Stub"
    }

    let doSave = () => {
        setWorking(true)
        saveAccountInfo(account, (_, error) => {
            if (error) {
                alert(error);
            } else {
                onUpdated(account)
            }
        })

    }

    let handleCategoryChange = (value: string) => {
        setAccount({
            ...account,
            category: value.trim().length == 0 ? undefined : value
        })
    }

    return <Stack spacing={2} style={{ paddingTop: "10px" }}>
        <TextField label="Name" type="text" value={account.name} onChange={e => setAccount({ ...account, name: e.target.value })} />
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            freeSolo
            options={existingCategoriesForKind}
            value={account.category ?? ""}
            onChange={(e, v) => handleCategoryChange(v ?? "")}
            renderInput={(params) => <TextField {...params} label="Category" type="text" />}
        />

        <LabeledSelect labelId="kind-select" label="Kind" value={account.kind} onChange={e => setAccount({ ...account, kind: e.target.value })}>
            {Object.keys(labelsForKinds).map(key => {
                return <MenuItem value={key}>{labelsForKinds[key]}</MenuItem>
            })}
        </LabeledSelect>
        <MarkdownEditor
            label="Purpose"
            value={account.purpose}
            onChange={e => setAccount({ ...account, purpose: e })} 
            editable={true}  
            />
        <Button variant="contained" disabled={isWorking} onClick={doSave}>Save</Button>
    </Stack>
}


export interface AccountEditorDialogProps extends AccountEditorProps {
    onClose: () => void
}
export const AccountEditorDialog = (props: AccountEditorDialogProps) => {
    const { onClose } = props

    const handleUpdate = (a: AccountInfo) => {
        props.onUpdated(a)
        onClose()
    }

    return <Dialog
        open={true}
        onClose={() => onClose()}
        // fullScreen={true}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>Account Info<IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
            <AccountEditor {...props} onUpdated={handleUpdate} />
        </DialogContent>
    </Dialog>
}