import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { TemplatesEditor } from './TemplatesEditor';
import { AccountInfo, Template } from './api';
import util, { calcTotal } from './util';


export const TemplateEditorDialog = (props: { template: Template, doClose: () => void, onComplete: (t: Template) => void }) => {
    const { onComplete, doClose } = props
    const [isClosing, setClosing] = React.useState(false)
    const [template, setTemplate] = React.useState(props.template)

    const handleClose = () => {
        setClosing(true)
        doClose()
    }

    const handleSave = () => {
        setClosing(true)
        onComplete(template)
    }
    const total = calcTotal(template)

    const currency: string = "USD"

    return <Dialog
        open={true}
        onClose={doClose}
        fullScreen={true}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>Template
            <IconButton
                aria-label="close"
                onClick={doClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <div>Total: <span className="total">{util.formattedColorAmount(total, currency)}</span></div>
            {isClosing && <CircularProgress />}
        </DialogTitle>
        <DialogContent>
            <Stack>
                <TemplatesEditor currency={currency} template={template} onChange={setTemplate} />
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button variant='outlined' disabled={isClosing} onClick={handleClose}>Cancel</Button>
            <Button variant='contained' disabled={isClosing} onClick={handleSave}>Save</Button>
        </DialogActions>
    </Dialog>
}
