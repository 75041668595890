
import { ReactNode } from 'react'
import { Link, Route, Routes, useParams } from "react-router-dom";

const Inner = (props: { prefix: string, propertyName: string, tabs: Record<string, () => ReactNode> }) => {
    const { prefix, propertyName, tabs } = props
    const params = useParams()
    const tabNames = Object.keys(tabs)
    const activeTab = params[propertyName] ?? tabNames[0]

    console.log("params are ", JSON.stringify(params))

    let tabLabels = tabNames.map((name, idx) => {
        let extraClasses = (name === activeTab) ? " tab-tip-selected " : "";
        let classes = "tab-tip" + extraClasses
        return <Link to={`${prefix}${name}`}><span key={`tab-${name}-${idx}`} className={classes} >{name}</span></Link>
    });

    let selectedTab = tabs[activeTab]

    return <div className="tab-set">
        <div className="tab-set-header"><div className="tab-tips-holder">{tabLabels}</div></div>
        <div className="tab-content">{selectedTab()}</div>
    </div>
}

export default (props: { propertyName: string, tabs: Record<string, () => ReactNode> , extraRoutes?:ReactNode}) => {
    const { propertyName, tabs, extraRoutes } = props
    const params = useParams()
    console.log("params are ", JSON.stringify(params))
    return (
        <Routes >
            {extraRoutes}
            <Route index element={<Inner prefix="" {...props} />} />
            <Route path={`:${propertyName}/*`} element={<Inner prefix="../" {...props} />} />
        </Routes>
    )
}