import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import http from './cj/HttpClient'
import { getTenantId } from './util'
import AccountRatiosWidget from './AccountRatiosWidget'
import AccountSelect from './AccountSelect'
import TemplateSelect from './TemplateSelect'
import CurrencyAmountField from './CurrencyAmountField'
import { Button, SelectChangeEvent, Stack } from '@mui/material'
import { AccountInfo, FundsFlow, Template, TemplateInfoListItem } from './api'

const flowsTotal = (flows: FundsFlow[]): number => flows.reduce((total, f) => total += f.amount, 0)

export default (props: { accounts: AccountInfo[], flows: FundsFlow[], currency: string, onChange: (flows: FundsFlow[]) => void }) => {
    const { accounts, flows, currency, onChange } = props
    const [fromAccountId, setfromAccountId] = useState("generic-income-expense")
    const [amountSelection, setamountSelection] = useState(flowsTotal(props.flows))
    const [templates, settemplates] = useState<TemplateInfoListItem[]>([])
    const [templateSelection, settemplateSelection] = useState<string>()
    const [ratios, setratios] = useState(generateRatiosFromFlows(props.flows))

    var totalAmount = amountSelection

    console.log("Creating a LiveSplitFlowsEditor")
    useEffect(() => {
        http(
            {
                url: `/api/tenants/${getTenantId()}/templates`,
                method: "GET",
                onResponse: function (response) {
                    if (response.status === 200) {
                        let templates = JSON.parse(response.body)
                        settemplates(templates);
                        console.log("templates", templates)
                        settemplateSelection(templates[0].id)

                    } else {
                        alert("Error loading!");
                    }
                }
            }
        );
    }, [])


    function flowsFromTemplate(template: Template, totalAmount: number, fromAccountId: string): FundsFlow[] {

        console.log("Applying template, totalAmount", template, totalAmount)

        let results: Record<string, number> = {}
        let remaining = totalAmount
        _.each(template.split, (amount, accountId) => {
            let toApply = Math.min(amount, remaining)
            results[accountId] = toApply
            remaining = remaining - toApply
        })

        if (remaining > 0) {
            let remainderAccount = template.remainder
            if (remainderAccount) {
                results[remainderAccount] = (results[remainderAccount] || 0) + remaining
            } else {
                alert("No remainder account")
            }
        }

        console.log("results are ", results)

        return _.map(results, (amount, accountId) => {
            return {
                fromFundId: fromAccountId,
                amount: amount,
                toFundId: accountId
            }
        })
    }
    function applyTemplate() {
        console.log("applyTemplate ", templateSelection);
        if (!templateSelection) return;

        http({
            url: `/api/tenants/${getTenantId()}/templates/` + templateSelection,
            method: "GET",
            onResponse: (response) => {
                if (response.status === 200) {
                    var template: Template = JSON.parse(response.body);

                    let flows = flowsFromTemplate(
                        template,
                        amountSelection,
                        fromAccountId)

                    let ratios = generateRatiosFromFlows(flows)

                    setratios(ratios)
                    onChange(flows)
                } else {
                    alert("Error loading!");
                }
            }
        }, { async: false });
    }

    function generateRatiosFromFlows(flows: FundsFlow[]): Record<string, number> {
        let totalFlows = 0
        // _.each(totalFlows, (f) => totalFlows += f.amount)

        let unitSize = Math.floor(totalFlows / 100) || 1
        // console.log("unit size is ", unitSize)
        let ratios: Record<string, number> = {}
        _.each(flows, (f) => {
            // console.log(" f.amount/unitSize", f.amount/unitSize)
            ratios[f.toFundId] = Math.floor(f.amount / unitSize)
        })
        console.log("generateRatiosFromFlows", flows, ratios)
        return ratios;
    }

    function regenerateFlows() {
        // let { fromAccountId, amountSelection } = state
        var totalAmount = amountSelection

        let totalAllocation = 0
        _.each(ratios, (allocation) => {
            totalAllocation += allocation
        })

        let newFlowsWithoutRemainder: FundsFlow[] = _.filter(_.map(ratios, (allocation, accountId) => {
            return {
                fromFundId: fromAccountId,
                amount: Math.floor((allocation / totalAllocation) * totalAmount),
                toFundId: accountId
            }
        }), (f) => f.amount > 0)

        let sumFlows = (flows: FundsFlow[]) => {
            let total = 0
            _.each(flows, (flow) => total += flow.amount)
            return total
        }
        let amountMappedSoFar = sumFlows(newFlowsWithoutRemainder)

        let remainder = totalAmount - amountMappedSoFar

        let newFlows = newFlowsWithoutRemainder

        if (remainder > 0 && newFlows.length > 0) {
            console.log("There is a remainder: ", remainder)
            let remainderFlow = newFlows[0]
            console.log("Adding remainder to flow ", remainderFlow)
            remainderFlow.amount += remainder
            console.log("Now it is ", remainderFlow)
        }

        let finalUpdatedAmount = sumFlows(newFlowsWithoutRemainder)
        if (newFlows.length > 0 && finalUpdatedAmount != totalAmount) {
            throw ("Only mapped " + finalUpdatedAmount + " of " + totalAmount)
        }

        console.log("Flows adjusted to new ratios", ratios, totalAllocation)
        console.log("Flows were ", flows)
        console.log("Flows are now", newFlows)
        onChange(newFlows)
    }


    console.log("Rendering with props", props)

    let handleTemplateSelection = (event: SelectChangeEvent<string>) => {
        let newValue = event.target.value
        console.log("Received newValue", newValue)
        settemplateSelection(newValue)
    }

    useEffect(regenerateFlows, [ratios, amountSelection])

    let handleAccountSelection = (event: SelectChangeEvent<string>) => {
        let selectedId = event.target.value
        console.log("Account is now ", selectedId)
        setfromAccountId(selectedId)
        regenerateFlows()
    }

    console.log("totalAmount, currency", totalAmount, currency)

    return <div>
        <Stack spacing={2}>
            <AccountSelect
                label="To/From"
                // className="from-select"
                accounts={accounts}
                selection={fromAccountId}
                onChange={handleAccountSelection} />
            <CurrencyAmountField
                label="Amount"
                value={{ currency: currency, amount: amountSelection }}
                onChange={setamountSelection} />
        </Stack>
        <div>
        </div>
        <div></div>
        <div>Use Template:
            <TemplateSelect
                // label="Us Template"
                // className="template-select"
                templates={templates}
                selection={templateSelection}
                disabled={false}
                onChange={handleTemplateSelection} />
            <Button onClick={applyTemplate}>Apply</Button>
        </div>
        <div className="splits-widget">
            <AccountRatiosWidget  splits={ratios} accounts={accounts} onChange={setratios} />
        </div>
    </div>
}

