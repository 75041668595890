import moment from "moment"
import { AccountInfo, Flow, Organization } from "./api"
import { applyTemplate, averageTimesPerMonth, combineTotals, entityName, lastInstanceTime } from "./util"
import { dateToMomentInCurrentTimeZone } from "./DateAndTime"

export interface FundChange {
    fromAccountId:string
    toAccountId:string
    amount:number
}
export const accountChangesForFlow = (flow:Flow):FundChange[] => {
    let results:FundChange[]
    if(flow.template){
        const a = applyTemplate(flow.amount, flow.template)
        results= Object.keys(a).map(accountId=>{
            const amount = a[accountId]
            const [fromAccountId, toAccountId, positiveAmount] = (amount<0) ? [accountId, flow.toFromAccount, amount * -1] : [flow.toFromAccount, accountId, amount]
            return  {
                amount:positiveAmount,
                fromAccountId,
                toAccountId,
            }
        })
    }else{
        const amount = flow.amount
        const [fromAccountId, toAccountId] = (amount<0) ? [flow.accountOrTemplate, flow.toFromAccount] : [flow.toFromAccount, flow.accountOrTemplate]
        results =  [{
            amount,
            fromAccountId,
            toAccountId,
        }]
    }

    results.forEach(c=>{
        if(c.fromAccountId == c.toAccountId){
            throw `Accounts are the same: ${c} for ${flow}`
        }
    })

    return results
}

export const howChangesAccount = (change:FundChange, accountId:string):number => {
    const amount = change.amount
    return (change.fromAccountId == accountId) ? (amount * -1) : (change.toAccountId == accountId) ? amount : 0
}
export interface AccountChange {
    flow:Flow
    change:FundChange
    amount:number
    averageTimesPerMonth:number
    amountPerInstance:number
}

export interface AccountRecurringMonthlyBudgetAnalysis {
    accountId:string
    changes:AccountChange[]
    expenses: Record<string, number>
    incomes: Record<string, number>
    net: Record<string, number>
}

export type FlowFilter =  (flow:Flow, change:FundChange, ) => boolean


const isIntraEntity = (flow:Flow, change:FundChange, organization:Organization, getAccount:(id:string) =>AccountInfo|undefined):boolean =>{
    const getAccountSafe = (id:string):AccountInfo => {
        const a = getAccount(id)
        if(!a){
            throw `No such account ${id}`
        }
        return a!!
    }
    const from =  entityName(getAccountSafe(change.fromAccountId), organization)
    const to = entityName(getAccountSafe(change.toAccountId), organization) 

    // console.log("isIntraEntity?", flow.name, change.fromAccountId, from, getAccountSafe(change.fromAccountId).name, change.toAccountId, to, getAccountSafe(change.toAccountId).name, flow)
   return from == to

}

export const makeIntraEntityFilter = (organization:Organization|undefined, allAccounts:AccountInfo[]):FlowFilter => {
    const getAccount = (id:string) => allAccounts.find(a=>a.id === id)

    return (flow, change)=> organization ? isIntraEntity(flow, change, organization, getAccount) : false
}


export const analyzeMonthlyBudgetForAccount = (accountId:string, allFlows:Flow[], now:number, filter:FlowFilter):AccountRecurringMonthlyBudgetAnalysis => {
    const n = moment(now)
    const flows = allFlows.filter(flow=> !dateToMomentInCurrentTimeZone(lastInstanceTime(flow.schedule)).isBefore(n))
    const flowApplications = flows ? analyzeHowFlowAffectsAccount(accountId, flows) : []

    const foo:AccountChange[] = flowApplications && flowApplications.flatMap(([flow, changes])=>{
        return changes.filter(change=>!filter(flow, change)).map(change=>{
            const atpm = averageTimesPerMonth(flow.schedule)
            const a = howChangesAccount(change, accountId)
            const total = a * atpm
            return {
                flow,
                change,
                amount:total,
                averageTimesPerMonth:atpm,
                amountPerInstance:a
            }
        })
    })
    
    const asFoo = (c:AccountChange):Record<string, number> =>{
        const r:Record<string, number> = {
            [c.flow.currency]:c.amount
        }
        return r
    }

    const negatives = combineTotals(foo.filter(c=>c.amount<0).map(asFoo))
    const incomes = combineTotals(foo.filter(c=>c.amount>=0).map(asFoo))
    const totals = combineTotals(foo.map(asFoo))

    return {
        net:totals,
        incomes,
        expenses:negatives,
        accountId,
        changes:foo
    }
}


const assertOne = <T,>(items:T[]):T =>{
    if(items.length!=1) throw `Expected 1 but was ${JSON.stringify(items)}`
    else return items[1]
}
export const analyzeHowFlowAffectsAccount = (accountId:string, flows:Flow[]):[Flow, FundChange[]][] =>  {
    return flows.map(flow=>{
        const changes = accountChangesForFlow(flow).filter(change=>{
            return (change.fromAccountId == accountId || change.toAccountId == accountId) && (change.amount != 0)
        })
        return [flow, changes]
    })
}

export interface SummaryAnalysis {
    expenses: Record<string, number>
    incomes: Record<string, number>
    net: Record<string, number>
}
export const summarize = (analyses:AccountRecurringMonthlyBudgetAnalysis[]):SummaryAnalysis => {
    return {
        expenses: combineTotals(analyses.map(analysis => analysis.expenses)),
        incomes: combineTotals(analyses.map(analysis => analysis.incomes)),
        net: combineTotals(analyses.map(analysis => analysis.net)),
    }
}