import React from 'react'
import $ from 'jquery'
import _ from 'underscore'
import http from '../cj/HttpClient'
import util, { getTenantId } from '../util'
import { MapAsTransfer, MappingSpec, PhysicalItem, TransferOptionDto } from '../api'
import { Mapper } from '../mappingLogic'
import { Button } from '@mui/material'
import Table, { TableRowData } from '../Table2'

export default (props: { foreignAccountId: string, item: PhysicalItem, mapper: Mapper, onMappingsChange: (newMappings: MappingSpec) => void }) => {

    let { item, mapper, onMappingsChange } = props
    const [selectedOption, setselectedOption] = React.useState<TransferOptionDto>()
    const [transferOptions, setTransferOptions] = React.useState<TransferOptionDto[]>()

    function fetchOptions() {
        http({
            url: `/api/tenants/${getTenantId()}/transferOptions/` + props.foreignAccountId + "/" + props.item.id,
            method: "GET",
            onResponse: function (response) {
                console.log("done fetching");
                if (response.status === 200) {
                    setTransferOptions(JSON.parse(response.body))
                } else {
                    throw "Error retrieving ";
                }
            }
        });
    }

    React.useEffect(fetchOptions, [])


    const handleChange = (selectedOption: TransferOptionDto) => {
        setselectedOption(selectedOption)
        const mapping:MapAsTransfer = {
            type: "transfer",
            memo: "",
            otherAccountId: selectedOption.accountId,
            otherSideOfTransfer: selectedOption.item.id
        }
        onMappingsChange(mapping)
    }

    if (transferOptions) {

        if (transferOptions && transferOptions.length > 0) {
            const rows: TableRowData[] = _.map(transferOptions, function (option) {
                var extraClass = mapper.isUniqueMatch(option, item) ? "suggested-transfer-option" : "";
                const classes = "transferOption " + extraClass
                return {
                    key: item.id,
                    cells: [
                        <div className={classes}>
                            {util.formatLongDateTime(option.item.whenPosted)}
                        </div>,
                        <div className={classes}>
                            {util.formattedColorAmount(option.item.amount, option.item.currency)}
                        </div>,
                        <div className={classes}>
                            {option.accountName}
                        </div>,
                        <div className={classes}>
                            {option.item.memo}
                        </div>,
                        <div className={classes}>
                            ({option.item.ref})
                        </div>,
                        <Button
                            variant="contained"
                            onClick={() => handleChange(option)}>
                            Select
                        </Button>,
                    ]
                }
            });
            return <Table
                header={["when", "amount", "account", "memo", "ref", ""]}
                rows={rows}
            />
        } else {
            return <span>No matching transactions</span>
        }

    } else {
        return <div>Searching for potential transfer matches...</div>
    }

}
