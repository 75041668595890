import { useEffect, useState } from "react"
import http from "./cj/HttpClient";
import { distinct, getTenantId } from "./util";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LabeledSelect from "./LabeledSelect";
import { CheckpointInfo } from "./api";
import { getVersions } from "./api-actions";

export const usePeople = ()=>{

    const [checkpoints, setCheckpoints] = useState<CheckpointInfo[]>([])

    useEffect(()=>{

        getVersions()
            .then(setCheckpoints)
            .catch(err => alert(err))
    }, [])

    return checkpoints && distinct(checkpoints.map(c=>c.who ?? "")).filter(i=>i.length>0 && i!="system")
     
}

export const getAccountRoles = (acccountId:string):Promise<AccountRole[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{

        http({
            url: `/api/tenants/${getTenantId()}/accounts/${acccountId}/roles`,
            method: "GET",
            onResponse: function (response) {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed(response)
                }
            }
        });
    })
}


export const setAccountRoles = (acccountId:string, roles:AccountRole[]):Promise<AccountRole[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{

        http({
            url: `/api/tenants/${getTenantId()}/accounts/${acccountId}/roles`,
            method: "POST",
            data: JSON.stringify(roles),
            onResponse: function (response) {
                if (response.status === 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed(response)
                }
            }
        });
    })
}
interface AccountRole{
    userAccountId:string
}
interface RoleEditingState {
    existing:AccountRole|undefined
}
export default (props:{accountId:string})=>{
    const {accountId} = props
    const [roles, setRoles] = useState<AccountRole[]>()
    const [roleUnderEdit, setRoleUnderEdit] = useState<RoleEditingState>()

    const refresh = ()=> {getAccountRoles(accountId).then(setRoles)}
    useEffect(refresh, [])

    const doSave = (newRole:AccountRole)=>{
        if(roles){
            const oldRolesMinusThis = roles.filter(r=>r.userAccountId != newRole.userAccountId)
            setRoles(undefined)
            setRoleUnderEdit(undefined)
            setAccountRoles(accountId, oldRolesMinusThis.concat([newRole]))
                .then(refresh)
        }
    }

    if(!roles){
        return <CircularProgress/>
    }else{
        return (<>
            {roleUnderEdit && <AccountRoleDialog 
                existing={roleUnderEdit.existing}
                existingRoles={roles}
                onSave={doSave}
                onClose={()=>setRoleUnderEdit(undefined)}/>}
            <Typography>
                <Stack>
                    <Box>{roles?.length} Roles <IconButton onClick={()=>setRoleUnderEdit({existing:undefined})}><AddIcon/></IconButton></Box>
                    
                    {roles?.map(role => {
                        return <Box>{role.userAccountId}</Box>
                    })}
                </Stack>
    
            </Typography>
        </>)
    }
}


export const AccountRoleDialog = (props: {existing?:AccountRole, existingRoles:AccountRole[], onSave:(r:AccountRole)=>void, onClose:()=>void}) => {
    const { existing, existingRoles, onSave, onClose } = props
    const [person, setPerson] = useState<string>()
    const people = usePeople()
    
    return <Dialog
        open={true}
        onClose={() => onClose()}
        // fullScreen={true}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>Account Role<IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
            <Stack spacing={3} style={{margin:"20px"}}>

                <LabeledSelect labelId={"person"} label={"Person"} value={person} onChange={e=>setPerson(e.target.value)}>
                    {people.filter(p=>existingRoles.map(r=>r.userAccountId).indexOf(p)==-1).map(p=>{
                        return <MenuItem value={p}>{p}</MenuItem>
                    })}
                </LabeledSelect>
                <Box style={{textAlign:'right'}}>
                    <Button disabled={!person} onClick={()=>person && onSave({userAccountId:person})}>Save</Button>
                </Box>
            </Stack>
        </DialogContent>
    </Dialog>
}