import React from 'react'
import $ from 'jquery'
import http from './cj/HttpClient'
import { getTenantId } from './util';
import { Button, Card, CardActions, CardContent, IconButton, Link, Typography } from '@mui/material';
import { ConnectionsListItem } from './api';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DialogsContext, DialogsState } from './Dialogs';

export default (props: { item: ConnectionsListItem }) => {
    const { item } = props
    let connectionId = item.id
    let connectionName = item.name

    let doDelete = () => {

        if (window.confirm("This will delete the '" + connectionName + "' connection and all its synchronization mappings.  Are you sure?")) {
            http({
                url: `/api/tenants/${getTenantId()}/connections/` + connectionId,
                method: "DELETE",
                onResponse: function (response) {
                    if (response.status === 200) {
                        window.location.reload()
                    } else {
                        alert("Server said: " + response.body);
                    }
                }
            });
        }
    }

    let doConfigure = (dialogs:DialogsState) => {

        http({
            url: `/api/tenants/${getTenantId()}/connections/` + connectionId,
            method: "GET",
            onResponse: function (response) {
                if (response.status === 200) {
                    var connection = JSON.parse(response.body) //ConnectionConfigDto

                    console.log("Connection is ", connection)
                    dialogs.showConnection({
                        value:{type:connection.remote.type, existing:connection},
                        onChange:()=>{}
                    })
                    // showConnectionDialog(connection.id, connectionName, connection.remote.type, connection);

                } else {
                    alert("Server said: " + response.body);
                }
            }
        });
    }

    let doScan = () => {

        var dialog = $("<div>Scanning...</div>");
        $("body").append(dialog);
        (dialog as any).dialog({
            modal: true,
            position: { my: "center", at: "top", of: window },
            closeOnEscape: false,
            beforeClose: function () {
                return false; // keep the window from closing
            }
        });

        http({
            url: `/api/tenants/${getTenantId()}/connections/` + connectionId + "/scan",
            method: "POST",
            onResponse: function () {
                window.location.reload();
            }
        });

    }

    return <DialogsContext.Consumer>{dialogs => <>
        <Card sx={{ minWidth: 275, maxWidth: 400 }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    {connectionName}
                    <IconButton className="trigger-scan-button" onClick={doScan} ><RefreshIcon /></IconButton>
                </Typography>
                <Typography variant="body2">
                    <Link target="_blank" href={`/api/tenants/${getTenantId()}/connections/${connectionId}/log`}>logs</Link>
                </Typography>
            </CardContent>
            <CardActions>
                <Button className="delete-button" onClick={doDelete}>Remove</Button>
                <Button className="configure-button" onClick={()=>doConfigure(dialogs)}>Configure</Button>
            </CardActions>
        </Card>
        
    </> }</DialogsContext.Consumer>
}
