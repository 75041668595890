import React, { ReactElement, ReactNode, useState } from 'react'
import _ from 'underscore'
import http from './cj/HttpClient'
import util, { getTenantId } from './util'
import UncategorizedScreenEntry from './UncategorizedScreenEntry'
import ItemEditor from './itemEditor'
import PhysicalTransactionPanel from './PhysicalTransactionPanel'
import { Box, Paper, Typography } from '@mui/material'
import { AccountInfo, Flow, ItemInfo, PhysicalAccountDto, PhysicalAccountInfoDto, PhysicalItemHandle, Recommendation, UtilityChargeDto } from './api'
import { Mapper } from './mappingLogic'
import LoadingThrobber from './LoadingThrobber'
import UploadPanel from './UploadPanel'
import { ResultThing, fetchCharges, fetchRecommendations, getAccountsPromise, getAllPhysicalPromise, getFlowsPromise, getPhysicalAccountInfos } from './api-actions'
import UncategorizedScreenEntryCollapsedItemView from './UncategorizedScreenEntryCollapsedItemView'
import UncategorizedScreenEntryCollapsedUtilityChargeView from './UncategorizedScreenEntryCollapsedUtilityChargeView'
import FloatingActionBar from './FloatingActionBar'
import { physicalItemUrl } from './PhysicalItemPage'
import { Link } from 'react-router-dom'
import { urlForPhysicalAccount } from './PhysicalAccountPage'
import { AttributeFilters, ItemFiltersView, attributeMatchesFilter, plusAttributeFilter } from './ItemFIlters'

interface SourceState {
    addPanelVisible?: boolean
    uploadVisible?: boolean
}
export default (props: { mapper: Mapper }): ReactElement => {

    let { mapper } = props

    const [recommendations, setrecommendations] = React.useState<Recommendation[]>([])
    const [sources, setSources] = React.useState<PhysicalAccountDto[]>([])
    const [sourceStates, setsourceStates] = React.useState<Record<string, SourceState>>({})
    const [completedItemIds, setcompletedItemIds] = React.useState<PhysicalItemHandle[]>([])
    const [charges, setcharges] = React.useState<UtilityChargeDto[]>([])
    const [accounts, setAccounts] = React.useState<AccountInfo[]>()
    const [isLoading, setisLoading] = React.useState(true)
    const [flowProjections, setflowProjections] = React.useState<Flow[]>()
    const [physAccounts, setPhysAccounts] = React.useState<PhysicalAccountInfoDto[]>()
    const [isRefreshing, setIsRefreshing] = React.useState(false)

    const [filters, setFilters] = useState<AttributeFilters>({})

    const getAccount = (id:string):AccountInfo|undefined => {
        if(!accounts){
            throw "I don't have accounts yet"
        }
        const match = accounts?.find(a => a.id == id)

        if(!match){
            throw `no such account ${id} in ${accounts.length} accounts: ${JSON.stringify(accounts.map(a=>a.id + " " + a.name))}`
        }

        return match
    }

    const refresh = () => {
        console.log("Refreshing...")
        setIsRefreshing(true)
        Promise.all([
            fetchRecommendations().then(setrecommendations),
            getAllPhysicalPromise().then(setSources),
            fetchCharges().then(setcharges),
            getFlowsPromise(getTenantId()).then(setflowProjections),
            getAccountsPromise().then(setAccounts),
            getPhysicalAccountInfos(setPhysAccounts),
        ])
        .then(()=> {
            setisLoading(false)
            setIsRefreshing(false)
            console.log("Done refreshing")
        })
        .catch(error=> alert("Error loading!"))
    }
    React.useEffect(refresh, [])


    function renderLoaded(accounts:AccountInfo[], physAccounts:PhysicalAccountInfoDto[]): ReactElement {
        console.log("completedItemIds are ", completedItemIds)


        console.log("SHowing the uncategorized tab");

        function markItemsCompleted(itemIds: PhysicalItemHandle[]) {
            setcompletedItemIds(completedItemIds.concat(itemIds))
        }

        
        var recommendationActions: Record<string, () => void> = {};
        recommendations.forEach(function (r) {
            let handleOnThisItem = r.item
            let itemMappingsUrl = `/api/tenants/${getTenantId()}/mappings/` + handleOnThisItem.accountId + "/" + handleOnThisItem.itemId

            let actionKey = JSON.stringify(r)
            
            const mappings = r.mapping

            recommendationActions[actionKey] = function submitMappings() {
                console.log("applying recommendation: ", r)
                http({
                    url: itemMappingsUrl,
                    method: "POST",
                    data: JSON.stringify(mappings),
                    onResponse: function (response) {
                        if (response.status === 200) {
                            markItemsCompleted([handleOnThisItem])
                            refresh()
                        } else {
                            alert("Somethis is wrong.  Server said:" + response.body);
                        }
                    }
                });
            }

        });

        var flatMap = _.compose(_.flatten, _.map);

        function getStateForSource(sourceStates: Record<string, SourceState>, sourceId: string): SourceState {
            let sourceState = sourceStates[sourceId]
            return sourceState ? sourceState : {}
        }

        const topHeaderHeight = 40
        const headerZIndex = 100 // needed to keep some MUI stuff from floating on top when in stick mode

        var uncategorizedWidgets = _.sortBy(sources, s=>s.name).map((source: PhysicalAccountDto, sourceIdx: number):ReactNode|undefined=> {
            let sourceState = getStateForSource(sourceStates, source.id)

            function functionshowUploadPanel() {

                var sourceState = getStateForSource(sourceStates, source.id)

                sourceState.uploadVisible = true

                setsourceStates({
                    ...sourceStates,
                    [source.id]: sourceState
                })
            }

            let showAddTransactionPanel = () => {
                var sourceState = getStateForSource(sourceStates, source.id)

                sourceState.addPanelVisible = true

                setsourceStates({
                    ...sourceStates,
                    [source.id]: sourceState
                })
            }
            var header = <div key={source.name + "-header"} style={{
                    position: "sticky",
                    top: topHeaderHeight,
                    background: "white",
                    borderTop: "2px solid grey",
                    padding:"5px",
                    zIndex:headerZIndex,
                }} >
                <div style={{
                        display: "inline-block",
                        fontSize: "18pt",
                        fontWeight: "bold",
                    }}><Link to={urlForPhysicalAccount(source.id)}>{source.name}</Link></div>
                <div className="physical-account-actions-panel">
                    <button className="add-transaction-button" onClick={showAddTransactionPanel}>Add</button>
                    <button className="show-upload-controls-button" onClick={functionshowUploadPanel}>Upload</button>
                </div>

                {sourceState.addPanelVisible &&
                    <Paper style={{ maxWidth: "500px", padding: "20px" }}>
                        <Typography variant="h4">Add Manual Transaction</Typography>
                        <PhysicalTransactionPanel sourceId={source.id} onChange={refresh} />
                    </Paper>}

                {sourceState.uploadVisible ? <UploadPanel source={source} onUploadCompleted={refresh} /> : ""}
                <div></div>
            </div>


            function isMapped(itemInfo: ItemInfo) {
                var item = itemInfo.item;
                return item.mappings || item.otherAccount || item.otherSideOfTransfer || item.otherSideOfTransfer || item.nonMaterialResolution;
            }

            var unMappedItems = _.filter(source.items, function (itemInfo) {

                let wasCompleted = _.find(completedItemIds, (handle) => (handle.itemId == itemInfo.item.id && handle.accountId == source.id))

                if (wasCompleted) {
                    console.log("Was completed? " + wasCompleted + " : ", wasCompleted, itemInfo)
                }
                const thisIsMapped = isMapped(itemInfo)

                return !thisIsMapped && !wasCompleted
            });

            let unmappedEntriesSorted = unMappedItems.sort((a, b) => a.item.whenPosted - b.item.whenPosted)

            let unmappedEntries = unmappedEntriesSorted.filter(i=>attributeMatchesFilter(i, filters)).map((itemInfo)=>{
                var item = itemInfo.item;

                var recommendationObject = _.find(recommendations, function (r) {
                    return r.item.itemId == item.id;
                });
                var recommendationAction = recommendationActions[JSON.stringify(recommendationObject)];
                function detailFunction(view: any, onSuccess: () => void) {

                    function hideOtherItemsAndCallOnSuccess(editingInfo: ResultThing) {
                        refresh()
                        onSuccess();
                    }

                    console.log("Returning item editor!")
                    return <ItemEditor 
                        foreignAccountId={source.id}
                        item={item}
                        accounts={accounts}
                        onSuccess={hideOtherItemsAndCallOnSuccess}
                        handleChange={refresh}
                        mapper={mapper} />
                }

                return <UncategorizedScreenEntry
                    detailLink={physicalItemUrl(itemInfo.handle)}
                    physAccounts={physAccounts}
                    id={item.id}
                    key={"uncategorized-item-" + item.id + "-" + source.id}
                    label={<UncategorizedScreenEntryCollapsedItemView handle={itemInfo.handle} item={item} onAttributeSelect={(key, value)=>setFilters(plusAttributeFilter(filters, key, value))}/>}
                    detailFunction={detailFunction}
                    recommendation={recommendationObject}
                    recommendationAction={recommendationAction}
                    getAccount={getAccount}
                    flowProjections={flowProjections} />

            });

            if(unmappedEntries.length==0){
                return undefined
            }else{
                return (<Box>
                {[header].concat(unmappedEntries)}
                </Box>)
            }
        }).flatMap(e => e ? [e] : [])

        var chargesByAccount = util.groupArray(charges, "accountId");

        let chargeThings: ReactNode[] = _.map(chargesByAccount, function (charges, accountId) {
            var account = $.grep(accounts, function (item) { return item.id === accountId })[0];

            let header: ReactNode[] = [<h1>{account.name}</h1>]
            let chargeItems: ReactNode[] = _.map(charges, function (charge, idx) {

                return <UncategorizedScreenEntry
                    detailLink={"/nonexistent"}
                    physAccounts={physAccounts}
                    id={charge.id}
                    key={charge.id}
                    getAccount={getAccount}
                    label={<UncategorizedScreenEntryCollapsedUtilityChargeView charge={charge}/>}
                    detailFunction={(notUsed, onSucces) => undefined}
                />

            });
            return header.concat(chargeItems)
        });

        return <div>
            <FloatingActionBar 
                isRefreshing={isRefreshing}
                 onRefresh={refresh}/>
            {uncategorizedWidgets.length == 0 ? <Box style={{textAlign:"center", margin:"100px"}}><Typography variant='h6' color={'GrayText'}>There are no unmapped items</Typography></Box> : uncategorizedWidgets}
            {_.flatten(chargeThings, true)}
            <ItemFiltersView filters={filters} onChange={setFilters}/>
        </div>
    }

    if (isLoading || !accounts || !physAccounts) {
        return <LoadingThrobber isLoading={true} />
    } else {
        return renderLoaded(accounts, physAccounts)
    }
}


