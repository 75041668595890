import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

ReactDOM.render(
    <LocalizationProvider dateAdapter={AdapterMoment}>
    <RouterProvider router={createBrowserRouter([
      { path: "/*", element: <App />},
    ])} />
    </LocalizationProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
