import React from 'react'
import $ from 'jquery'
import _ from 'underscore'
import http from './cj/HttpClient'
import util, { getTenantId } from './util'
import moment, { Moment } from 'moment'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
// import DatePicker from 'react-datepicker'
// import "react-datepicker/dist/react-datepicker.css"

export default (props: { label?: string, value: number | undefined, disabled?: boolean, onChange: (value: number | undefined) => void }) => {

    let { label, value } = props

    let disabled = (typeof props.disabled == "undefined" ? false : props.disabled)


    let handleChange = (d: Moment | null) => {

        let timestamp = d ? d.unix() * 1000 : undefined

        console.log("Value is ", timestamp, d)
        console.log(typeof d)
        props.onChange(timestamp)

    }

    let selection = value ? moment(value) : undefined

    return <DateTimePicker
            label={label}
            disabled={disabled}
            format="YYYY-MM-DD h:mm:ss a"
            value={(selection)}
            onChange={(date) => handleChange(date)} 
            sx={{
                minWidth:270,
            }}
            />
}


