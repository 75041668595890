import { ConnectionConfigDto, OfxConnectionSpecificationDto } from '../api';
import { useState } from 'react';
import { Button, TextField } from '@mui/material';

const undefinedIfEmpty = (value:string) =>(value && value.length > 0)  ? value : undefined
export interface OfxEditorResult{name:string, newConnectionInfo:OfxConnectionSpecificationDto|undefined}

export default (props:{connection:ConnectionConfigDto|undefined, onChange:(n:OfxEditorResult)=>void})=> {
  const {connection, onChange} = props
  const remote = connection?.remote?.type == "ofx" ? connection.remote as OfxConnectionSpecificationDto : undefined

  const [name, setName] = useState(connection?.name ?? "")
  const [url, seturl] = useState(remote?.url ?? "")
  const [organization, setorganization] = useState(remote?.organization ?? "")
  const [financialInstitutionId, setfinancialInstitutionId] = useState(remote?.financialInstitutionId ?? "")
  const [clientId, setclientId] = useState(remote?.clientId ?? "")
  const [username, setusername] = useState(remote?.username ?? "")
  const [password, setpassword] = useState("")
  const [connectionSpecIsUpdated, setconnectionSpecIsUpdated] = useState(false)
  const [isEditable, setIsEditable] = useState(connection ? false : true)

  const disabled = !isEditable

  let setEditable = (isEditable:boolean) => {
    setIsEditable(isEditable)
    setconnectionSpecIsUpdated(isEditable)
  }

  function read():OfxEditorResult {

    var spec:OfxConnectionSpecificationDto = {
      type: "ofx",
      url: url,
      financialInstitutionId: financialInstitutionId,
      username: username,
      password: password,
      organization: undefinedIfEmpty(organization),
      clientId: undefinedIfEmpty(clientId)
    }

    return {
      name: name,
      newConnectionInfo: connectionSpecIsUpdated ? spec : undefined
    };
  }

  const handleUpdate = ()=>{
    onChange(read())
  }

  return (<div>
    <div><TextField label="Connection Name" disabled={disabled} value={setName} onChange={e=>{setName(e.target.value); handleUpdate()}} className="connection-name" type="text" placeholder="name"/></div>
    <div className="ofx-connection-editor">
      <div><TextField label="OFX URL" className="url" disabled={disabled} value={url} onChange={e=>seturl(e.target.value)} type="text" placeholder="url"/></div>
      <div><TextField label='OFX "Organization"' className="organization" disabled={disabled} value={organization} onChange={e=>setorganization(e.target.value)} type="text" placeholder="organization"/></div>
      <div><TextField label='OFX "Financial Institution ID' className="financial-institution" disabled={disabled} value={financialInstitutionId} onChange={e=>setfinancialInstitutionId(e.target.value)} type="text" placeholder="financialInstitutionId"/></div>
      <div><TextField label='OFX Client ID' className="clientUUID" disabled={disabled} value={clientId} onChange={e=>setclientId(e.target.value)} type="text" placeholder="leave empty or enter a randomly-generated UUID"/></div>
      <div><TextField label='Username' className="username" disabled={disabled} value={username} onChange={e=>setusername(e.target.value)} type="text" placeholder="username"/></div>
      <div><TextField label='Password' className="password" disabled={disabled} value={password} onChange={e=>setpassword(e.target.value)} type="password" placeholder="password"/></div>
      <div><Button className="edit-button" disabled={disabled} onClick={() => setEditable(true)}>edit</Button></div>
    </div>
  </div>)
}


