import React from 'react'
import _ from 'underscore'
import http from '../cj/HttpClient'
import util, { getTenantId } from '../util'
import { Data } from '../data'
import Table, { TableRowData } from '../TableNG'
import InstantSelect from '../InstantSelect'

interface BalanceSheetReportProps{
    standardData:Data
}

interface BalanceSheetReportState{
    when:number
    data?:BalanceSheet
}

interface BalanceSheet{
   totalAssets:Record<string, number>
   totalLiabilities:Record<string, number>
   delta:Record<string, number>
   assets:Record<string, Record<string, number>>
   liabilities:Record<string, Record<string, number>>
   noteworthy:Record<string, Record<string, number>>
}


export const getBalanceSheet = (when:number):Promise<BalanceSheet> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        http({
            url: `/api/tenants/${getTenantId()}/balance-sheet?when=` + when,
            method: "GET",
            onResponse: function (response) {
                if (response.status == 200) {
                    resolveSuccess(JSON.parse(response.body))
                } else {
                    resolveFailed("Error!  Server said:" + response.body)
                }
            }
        });
    })
}

export default class BalanceSheetReport extends React.Component<BalanceSheetReportProps, BalanceSheetReportState> {
    constructor(props:BalanceSheetReportProps) {
        super(props);
        this.state = {
            when: new Date().getTime()
        }
        this.fetchData()
    }

    fetchData() {
        let getState = (() => this.state).bind(this)
        let setState = this.setState.bind(this)

        getBalanceSheet(this.state.when)
            .then(data => {
                setState({
                    ...getState(),
                    data: data
                })
            })
            .catch(alert)
    }

    render() {
        const { standardData } = this.props
        const { data, when } = this.state

        let currenciesInSection = (section:Record<string, Record<string, number>>) => _.uniq(_.flatten(_.map(_.flatten(_.values(section)), _.keys)))

        const currencies:string[] = data ? currenciesInSection(data.assets) : []
        console.log("currencies:", currencies)

        let sectionRows = (section:Record<string, Record<string, number>>):TableRowData => _.map(section ?? [], (balances, accountId) => {

            const amounts:TableRowData = _.map(currencies, (currency) => {
                return util.formattedColorAmount(balances[currency] || 0, currency)
            })
            const account = standardData.account(accountId)
            const foo:TableRowData = [account ? account.name : accountId]
            return foo.concat(amounts)
        })

        let handleWhenUpdated = (when:number) => {
            this.setState(
                { ... this.state, when: when },
                this.fetchData)
        }
        const assetsRow:TableRowData = (["Assets"] as TableRowData).concat(_.map(currencies, (currency) => data ? util.formattedColorAmount(data.totalAssets[currency], currency) : ""))
        const liabilitiesRow:TableRowData = (["Liabilities"] as TableRowData).concat(_.map(currencies, (currency) => data ? util.formattedColorAmount(data.totalLiabilities[currency], currency) : ""))
        const deltaRow:TableRowData = (["Delta"] as TableRowData).concat(_.map(currencies, (currency) => data ? util.formattedColorAmount(data.delta[currency], currency) : ""))

        return <div>
            <h2>Balance Sheet</h2>
            <InstantSelect value={when} onChange={v=>handleWhenUpdated(v||0)} />
            When: {new Date(when).toString()}
            <h3>Balance</h3>
            <Table header={["Total"].concat(currencies)}
                rows={[
                    assetsRow,
                    liabilitiesRow,
                    deltaRow,
                    ]} />
            <h3>Assets</h3>
            <Table header={["Account"].concat(currencies)}
                rows={[sectionRows(data ? data.assets : {})]} />

            <h3>Liabilities</h3>
            <Table header={["Account"].concat(currencies)}
                rows={[sectionRows(data ? data.liabilities : {})]} />

            <h3>Noteworthy</h3>
            <Table header={["Account"].concat(currencies)}
                rows={[sectionRows(data ? data.noteworthy : {})]} />
        </div>
    }
}