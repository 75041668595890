import $ from 'jquery'
import _ from 'underscore'
import util, { getTenantId } from './util'
import { FundsFlow, PlannedFundActualization, PlannedFundMove } from './api'

interface AccountBalancesAccumulator {
    next:(flow: FundsFlow, projection: PlannedFundMove)=>Foo
    balances:()=>Record<string, number>
}

interface Foo{
    plannedAmount: number,
    actualAmount: number,
    adjustmentAmount: number,
    total: number
}

export default (initialBalances: Record<string, number>, isFlowInOrOut: (f: FundsFlow) => boolean, isFlowOut: (f: FundsFlow) => boolean):AccountBalancesAccumulator => {

    var projectedTotals = { ...initialBalances }



    function totalFlowsForActualization(actualization: PlannedFundActualization | undefined) {
        var actualAmount = 0;
        if (actualization) {
            _.each(actualization.actuals, function (actualMove) {
                _.each(actualMove.flows, function (actualFlow) {
                    if (isFlowInOrOut(actualFlow)) {
                        var amount = isFlowOut(actualFlow) ? - actualFlow.amount : actualFlow.amount;
                        actualAmount = actualAmount + amount;
                    }
                });
            });
        }

        return actualAmount;
    }

    function next(flow: FundsFlow, projection: PlannedFundMove):Foo {

        var plannedAmount = isFlowOut(flow) ? - flow.amount : flow.amount;
        var actualAmount = totalFlowsForActualization(projection.actualization);


        var runningAmount;
        if (plannedAmount > 0) {
            if (actualAmount >= plannedAmount) {
                runningAmount = 0;
            } else {
                runningAmount = plannedAmount - actualAmount;
            }
        } else {
            if (actualAmount < plannedAmount) {
                runningAmount = 0;
            } else {
                runningAmount = plannedAmount - actualAmount;
            }
        }

        var total = util.addToNumericAccumulator(runningAmount, "USD", projectedTotals);
        return {
            plannedAmount: plannedAmount,
            actualAmount: actualAmount,
            adjustmentAmount: runningAmount,
            total: total
        };
    }

    return {
        next: next,
        balances: () => { return { ...projectedTotals } }
    }
}