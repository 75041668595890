import { Box, Button, Card, CardContent, CircularProgress, Divider, IconButton, Stack, Typography } from "@mui/material"
import { ContextualizedPendingTransfer, PendingTransfer, PhysicalAccountInfoDto } from "./api"
import util, { formatDate } from "./util"
import MarkdownContent from "./MarkdownContent"
import DeleteIcon from '@mui/icons-material/Delete';
import { deletePendingTransfer, getPendingTransfer } from "./api-actions"
import { DialogsContext, DialogsState } from "./Dialogs";
import { useEffect, useState } from "react";
import MoveDetailsPanel from "./MoveDetailsPanel";
import data from "./data";

export default (props: { contextualized?:ContextualizedPendingTransfer, transfer?: PendingTransfer, physAccounts: PhysicalAccountInfoDto[] }) => {
    const { physAccounts } = props
    const transfer = props.contextualized?.pending || props.transfer

    if(!transfer){
        throw "No transfer to describe"
    }
    
    const i = transfer
    const [contextualized, setContextualized] = useState(props.contextualized)

    const [d, setD] = useState(data())

    useEffect(()=>{
        if(!contextualized){
            getPendingTransfer(transfer.id).then(setContextualized)
        }
    }, [])

    const handleDeleteClicked = (dialogs:DialogsState)=>{

        let deleteWasConfirmed = window.confirm("Are you sure you want to delete this?")
        if(deleteWasConfirmed){
            deletePendingTransfer(transfer.id, (problem)=>{
                if(problem){
                    dialogs.showError({
                        value:problem,
                        onChange:()=>{}
                    })
                }
            })
        }
    }

    

    return (
        <DialogsContext.Consumer>{dialogs => <>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography fontSize={"1em"}>
                    <Stack spacing={1}>

                        <Typography variant='h6' fontSize={"1.2em"}>
                            Pending Transaction 
                            <IconButton style={{float:"right"}} onClick={()=>handleDeleteClicked(dialogs)}><DeleteIcon/></IconButton>
                        </Typography>
                        
                        <Box>Amount: {util.formattedColorAmount(i.amount, i.currency)}</Box>
                        <Box>When: {formatDate(i.whenPosted)}</Box>
                        <Box>Account: {physAccounts.find(a => a.id == i.physicalAccountId)?.name ?? "???"}</Box>
                        <Box>Who: {i.who}</Box>
                        {(i.ref && i.ref.length > 0) && <Box>Ref: {i.ref}</Box>}
                        <Divider />
                        <Box><MarkdownContent>{i.memo}</MarkdownContent></Box>
                        <Divider/>
                        {!contextualized ? <CircularProgress/> : contextualized.moves.map(m=>{
                            return (<>
                                <Box>{util.formattedColorAmount(m.amount, i.currency)} to </Box>
                                <MoveDetailsPanel move={m.move} getAccount={d.account}/>
                            </>)
                        })}
                    </Stack>
                </Typography>
            </CardContent>
        </Card>
    </>}</DialogsContext.Consumer>
    )
}