import { useEffect, useState } from "react"
import { TemplateEditorDialog } from "./TemplateEditorDialog"
import { AccountInfo, Template } from "./api"
import { createNewTemplate, getAccountsPromise } from "./api-actions"
import { CircularProgress } from "@mui/material"


export default (props:{onComplete:(t:Template)=>void, onClose:()=>void})=>{
    const {onComplete, onClose} = props
    const [isBusy, setBusy] = useState(false)

    const [accounts, setAccounts] = useState<AccountInfo[]>()

    useEffect(()=>{
        getAccountsPromise().then(setAccounts)
    }, [])

    if(isBusy || !accounts){
        return <CircularProgress/>
    }else{
        return (<>
            <TemplateEditorDialog
                template={{
                    id: "whatever",
                    name: "new template",
                    pattern: "",
                    kind: "",
                    remainder: accounts[0].id,
                    split: {},
                }}
                onComplete={(t) => {
                    setBusy(true)
                    createNewTemplate(t)
                        .then(onComplete)
                        .finally(()=>setBusy(false))
                }}
                doClose={() => onClose()} />
        </>)
    }

}

