import React, { ReactNode, useEffect} from 'react'
import util, { asMoveRef, describeSchedule, formatTime, formattedColorCurrencyAmount, getTenantId, take } from './util'

import { AccountInfo, Bucket, ContextualizedPendingTransfer, MapToMoves, MapToPendingTransfer, MappingOption, MappingSpec, PhysicalAccountInfoDto, PhysicalItem, PhysicalItemHandle, PlannedFundMove, PlannedItemHandle, asExistingMoveRef, asMapToMoves, asMapToPendingTransfer, asNewMoveSpec, asNonMaterial, asPlanItemRef, asTransfer } from './api'

import { Alert, Box, Button, CircularProgress, Stack, Typography } from '@mui/material'

import MappingSpecViewer, { ExistingMoveViewer, PlannedMoveViewer } from './MappingSpecViewer'
import { CurrencyAmount } from './CurrencyAmountField'
import { getPhysicalAccountInfos } from './api-actions'


const addToPrevious = (existing:MappingSpec, newMappings:MappingSpec):MappingSpec => {
    
    const existingMoves = asMapToMoves(existing)
    const newMoves = asMapToMoves(newMappings)
    if(existingMoves && newMoves){
        const combined:MapToMoves = {
            type: "to-moves",
            memo: "",
            allocations:existingMoves.allocations.concat(newMoves.allocations)
        }

        return combined
    }else{
        return newMappings
    }
}

export interface MappingEditorProps { 
    itemAmount: CurrencyAmount, 
    getAccount: (id: string) => AccountInfo | undefined, 
    onSubmit: (spec: MappingSpec) => Promise<any>, 
    makeEditor: (isAppending:boolean, handleMappingUpdate: (mappingFactory:(allocatedAmount:CurrencyAmount)=>MappingSpec) => void) => ReactNode, 
    handleChange: () => void 
}

interface SelectionThing {
    amount:number
    factory:(allocatedAmount:CurrencyAmount)=>MappingSpec
}

export default (props:MappingEditorProps)=>{
    const {itemAmount, getAccount, onSubmit, makeEditor, handleChange} = props
    const [selections, setSelections] = React.useState<SelectionThing[]>()
    const [showMapper, setShowMapper] = React.useState(false)
    const [isWorking, setWorking] = React.useState(false)
    const [physAccounts, setPhysAccounts] = React.useState<PhysicalAccountInfoDto[]>()

    useEffect(()=>getPhysicalAccountInfos(setPhysAccounts), [])

    console.log("There are " + selections?.length + " selections:", selections)

    const selection = selections && selections.reduce<MappingSpec|undefined>((accum, selection)=>{
        const selectionAmount = {amount:selection.amount, currency:itemAmount.currency}
        if(accum){
            return addToPrevious(accum, selection.factory(selectionAmount))
        }else{
            return selection.factory(selectionAmount)
        }
    }, undefined)

    console.log("Rendering MappingEditor with selection", selections)


    const handleApplyButtonPressed = ()=>{
        if(selection){
            setWorking(true)
            onSubmit(selection)
                .then(handleChange)
                .finally(()=> {
                    setWorking(false)
                })
        }
    }

    const handleAllocationDeletion = (idxToDelete:number)=>{
        const moves = selection && asMapToMoves(selection)

        if(moves){
            setSelections(selections.flatMap((a, nextIdx)=>{
                if(nextIdx == idxToDelete) return []
                else return [a]
            }))
        }
    }
    const handleAllocationAdjustment = (idx:number, amount:number)=>{
        const moves = selection && asMapToMoves(selection)
        
        if(moves){
            const update = [... selections]

            update[idx].amount = amount

            console.log("Adjusted allocations", update)
            setSelections(update)
        }
    }


    const handleMappingUpdate = (mappingFactory:(allocatedAmount:CurrencyAmount)=>MappingSpec) => {
        const allocationAmount = selections ? 0 : itemAmount.amount
        setSelections((selections ?? []).concat({amount:allocationAmount, factory:mappingFactory}))
        setShowMapper(false)
    }
    
    useEffect(()=>{
        const moves = selection && asMapToMoves(selection)
        
        if(moves && selections.length == 1){
            const update =  selections.map(a=>{
                return {
                    ... a,
                    amount:itemAmount.amount
                }
            })
            setSelections(update)
        }
    }, [itemAmount])

    const clearSelection = ()=>setSelections(undefined)

    const existingMoves = selection && asMapToMoves(selection)

    const hasMultiple = existingMoves ? existingMoves.allocations.length > 1 : false
    const totalAllocated = existingMoves && existingMoves.allocations.reduce((accum, n)=>accum+n.amount, 0)

    let submitErrors:ReactNode[] = []
    
    console.log("totalAllocated", totalAllocated)

    if(totalAllocated && (totalAllocated != itemAmount.amount)){
        submitErrors.push(<>Expected {formattedColorCurrencyAmount(itemAmount)} but total allocated is {formattedColorCurrencyAmount({amount:totalAllocated, currency:itemAmount.currency})}</>)
    }

    existingMoves?.allocations.forEach(allocation=>{
        const direction = (n:number) => {
            if(n>0) return "pos"
            if(n<0) return "neg"
            return "zero"
        }

        const expectedDir = direction(itemAmount.amount)
        const allocDir = direction(allocation.amount)
        if(allocation.amount!=0 && allocDir != expectedDir){
            submitErrors.push( `Expected ${expectedDir} but was ${allocDir}.`)
        }

        if(allocation.amount == 0){
            submitErrors.push( `Allocation amounts can't be zero.`)
        }
    })
    
    const selectionIsValid = submitErrors.length==0
    
    if(!physAccounts) return <CircularProgress/>
    
    return (
        <Stack spacing={3}>
            {(hasMultiple && totalAllocated) && <Box>Total {formattedColorCurrencyAmount({amount:totalAllocated, currency:itemAmount.currency})} Allocated</Box>}
                {selection && <MappingSpecViewer 
                    physAccounts={physAccounts}
                    disabled={isWorking}
                    currency={itemAmount.currency} 
                    getAccount={getAccount} 
                    mapping={selection} 
                    onDelete={handleAllocationDeletion}
                    onAmountChange={handleAllocationAdjustment}
                    handleChange={handleChange} />}
            {submitErrors.map(e=><Alert color="error">{e}</Alert>)}
            {(selection && !showMapper && existingMoves) && <Button disabled={isWorking} onClick={()=>setShowMapper(true)}>Add More</Button>}

            {(!selection || showMapper) && makeEditor(showMapper, handleMappingUpdate)}

            <div className="mapping-panel">
                <div>
                    {selection && <Stack direction="row-reverse" spacing={3}>
                        {isWorking && <CircularProgress/>}
                        {!isWorking && <Button disabled={!selectionIsValid} variant="contained" onClick={handleApplyButtonPressed}>Apply</Button>}
                        <Button onClick={clearSelection}>Cancel</Button>
                    </Stack>}
                </div>
            </div>
        </Stack>
    )
}