import React from 'react'
import _ from 'underscore'
import { AccountInfo } from './api'

interface AccountAllocationBarProps{
    account:AccountInfo
    percent:number
    doMore:()=>void
    doLess:()=>void
}
interface AccountAllocationBarState{

}
class AccountAllocationBar extends React.Component<AccountAllocationBarProps, AccountAllocationBarState> {
    constructor(props:AccountAllocationBarProps) {
        super(props);
    }

    render() {
        let { account, percent } = this.props

        return <div id={account.id} >
            <div className="barLabel">{account.name}</div>
            <div className="splits-bar-wrapper" >
                <div className="bar splits-bar" style={{ "width": percent + "px" }}></div>
            </div>
            <button className="moreButton" onClick={this.props.doMore}>+</button><button className="lessButton" onClick={this.props.doLess}>-</button>
        </div>
    }
}

interface AccountRatiosWidgetProps{
    accounts:AccountInfo[]
    splits:Record<string, number>
    onChange:(splits:Record<string, number>)=>void
}

interface AccountRatiosWidgetState {

}

export default class AccountRatiosWidget extends React.Component<AccountRatiosWidgetProps, AccountRatiosWidgetState> {
    constructor(props:AccountRatiosWidgetProps) {
        super(props);

    }

    render() {
        let { accounts, splits } = this.props
        var accountsByCategory = _.groupBy(_.sortBy(accounts, "category"), "category");
        console.log("SplitsWidget state", this.state, this.props)

        let categoryWidgets = _.map(accountsByCategory, (accounts, category) => {

            var categoryLabel = category === "null" ? "Uncategorized" : category;

            let accountRows = _.map(accounts, (account, idx) => {
                if (account.kind === "Alien") return;
                else {

                    let updateSplit = (newPercent:number) => {
                        let updatedSplits = {
                            ... this.props.splits
                        }
                        updatedSplits[account.id] = newPercent
                        console.log("UpdatedSplits ", this.props.splits, updatedSplits)
                        this.props.onChange(updatedSplits)
                    }

                    let totalAllocated = 0;
                    _.each(splits, (x) => totalAllocated += x)

                    let increment = totalAllocated < 1 ? 1 : totalAllocated / 10

                    let allocated = splits[account.id] || 0
                    let doMore = () => {
                        console.log("Do more for ", account.name, allocated, totalAllocated)
                        if (totalAllocated == 0 || allocated != totalAllocated) {
                            updateSplit(allocated + increment)
                        }
                    }
                    let doLess = () => {
                        console.log("Do less for ", account.name, allocated, totalAllocated)
                        updateSplit(Math.max(0, allocated - increment))
                    }

                    let percent = (100 * (allocated / totalAllocated)) || 0

                    console.log("percent is ", percent)
                    return <AccountAllocationBar
                        account={account}
                        percent={percent}
                        doLess={doLess}
                        doMore={doMore} />
                }
            });

            return <div className="category-section"><h3 className="category-section-name">{categoryLabel}</h3><div className="rows">{accountRows}</div></div>
        });


        return <div>{categoryWidgets}</div>
    }
}
// })

