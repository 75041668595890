import React, { ReactNode } from 'react'
import _ from 'underscore'
import util from './util'
import CurrencySelect from './CurrencySelect'
import InstantSelect from './InstantSelect'
import LiveSplitFlowsEditor from './LiveSplitFlowsEditor'
import StandardFlowsEditor from './StandardFlowsEditor'
import Tabs from './TabsNg'
import Table from './TableNG'
import { AccountInfo, FundMove, FundsFlow } from './api'
import { Box, Card, IconButton, Stack, TextField, Typography } from '@mui/material'
import { CSSProperties } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteMappingFromMapping, getMove } from './api-actions'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import MarkdownEditor from './MarkdownEditor'

export default (props: { accounts: AccountInfo[], move: FundMove, onChange: (newMove: FundMove) => void, onDelete: () => void }) => {
    const { accounts, onChange, onDelete } = props

    const [move, setMove] = React.useState(props.move)
    const [activeTabName, setactiveTabName] = React.useState("moves")
    const [isWorking, setisWorking] = React.useState(false)


    let updateMoveState = (newMove: FundMove) => {
        setMove(newMove)
        onChange(newMove)
    }

    let handleFlowsChange = (flows: FundsFlow[]) => {
        console.log("flows changed to ", flows)
        updateMoveState({
            ...move,
            flows: flows
        })
    }

    let showTab = setactiveTabName

    let tabs: Record<string, () => ReactNode> = {
        "moves": () => <StandardFlowsEditor currency={move.currency} accounts={accounts} flows={move.flows} onChange={handleFlowsChange} />,
        "income/expenses": () => <LiveSplitFlowsEditor accounts={accounts} flows={move.flows} currency={move.currency} onChange={handleFlowsChange} />
    }


    let flowsEditor = <Tabs
        activeTab={activeTabName}
        onChange={showTab}
        tabContent={tabs[activeTabName]()}
        tabs={_.keys(tabs)} />

    let handleTimeChange = (value: number | undefined) => {
        console.log("time changed to ", value)
        updateMoveState({
            ...move,
            when: value || (new Date().getTime())
        })
    }

    let handleEndChange = (value: number | undefined) => {
        console.log("end changed to ", value)
        updateMoveState({
            ...move,
            whenEnds: value
        })
    }

    let handleCurrencyChange = (value: string) => {
        console.log("Currency changed to ", value)
        updateMoveState({
            ...move,
            currency: value
        })
    }


    let handleMemoTextChange = (value: string) => {
        console.log("memo changed to ", value)

        updateMoveState({
            ...move,
            memo: value
        })
    }
    let handleMemoChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        handleMemoTextChange(event.target.value)
    }


    let totalAmount = 0;
    _.each(move.flows, (flow) => totalAmount += flow.amount)
    let splitReadouts = _.map(move.flows, (flow) => {
        let accountId = flow.toFundId
        let account = _.find(accounts, (a) => a.id == accountId)
        return [account ? account.name : "---", util.formatAmount(flow.amount, move.currency)]
    })

    let doDelete = () => {
        console.log("Doing delete")
        setisWorking(true)
        onDelete()
    }

    let totalPhysicalAmount = 0;
    let physicalReadouts = _.map(move.physicalMappings, (physicalMapping) => {
        totalPhysicalAmount += physicalMapping.amount

        const deleteMapping = () => {
            console.log("Delete pressed")
            const moveId = move.id
            if (moveId) {
                if (window.confirm("Delete physical mappping?")) {
                    deleteMappingFromMapping(physicalMapping, () => {
                        getMove(moveId, (m) => {
                            updateMoveState({
                                ...move,
                                physicalMappings: m.physicalMappings
                            })
                        })
                    })
                }
            }

        }

        return ["physicalMapping", util.formattedColorAmount(physicalMapping.amount, move.currency), <IconButton onClick={deleteMapping}><DeleteIcon /></IconButton>]
    })
    let small = 12
    let medium = 4
    let large = 4
    let view = <Stack spacing={3}>
        <div>{move.id ? <button disabled={isWorking} className="delete-button" onClick={doDelete}>Delete</button> : ""}</div>
        <Grid container spacing={2}>
            <Grid sm={small} md={medium} lg={large}>
                <TopCard title="General">
                    <Stack spacing={1}>
                        <InstantSelect label="when" value={move.when} onChange={handleTimeChange} />
                        <InstantSelect label="ends" value={move.whenEnds} onChange={handleEndChange} />
                        <CurrencySelect label="Currency" value={move.currency} onChange={handleCurrencyChange} />
                    </Stack>
                </TopCard>
            </Grid>
            <Grid sm={small} md={medium} lg={large}>
                <TopCard title="Preview">
                    <Table header={[]} rows={[["Total", util.formatAmount(totalAmount, move.currency)]].concat(splitReadouts)} />
                </TopCard>
            </Grid>
            <Grid sm={small} md={medium} lg={large}>
                <TopCard title="Physical Mappings">
                    <Table header={[]} rows={[["Total", util.formattedColorAmount(totalPhysicalAmount, move.currency)]].concat(physicalReadouts)} />
                </TopCard>
            </Grid>
        </Grid>
        <MarkdownEditor
            label="Memo"
            editable
            value={move.memo}
            onChange={handleMemoTextChange}
        />

        {flowsEditor}
    </Stack>
    return view
}

const TopCard = (props: { title: string, children?: ReactNode | string, style?: CSSProperties }) => {

    let styles: CSSProperties = {
        display: "inline-block",
        width: "100%",
        // height: "150px",
        height: "100%",
        verticalAlign: "top",
        overflowY: "auto",
    }

    return <Card style={styles}>
        <Stack spacing={2} style={{ margin: "10px" }}>
            <Typography variant="caption">{props.title}</Typography>

            <Box>
                {props.children}
            </Box>

        </Stack>
    </Card>
}