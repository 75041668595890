import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { FundMove } from "./api";
import CloseIcon from '@mui/icons-material/Close';
import MoveEditor from "./MoveEditorNg";
import { createOrUpdateMove, deleteMove } from "./api-actions";

export default (props: { move: FundMove|undefined, onClose: (newMove: FundMove | undefined) => void }) => {
    const { move, onClose } = props

    const handleSave = (move: FundMove) => createOrUpdateMove(move, onClose)
    const handleDelete = () => move && deleteMove(move, onClose)

    return (
        <Dialog
            open={true}
            onClose={() => onClose(undefined)}
            // fullScreen={true}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle>Move<IconButton
                aria-label="close"
                onClick={() => onClose(undefined)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            </DialogTitle>
            <DialogContent>
                <MoveEditor move={move} onComplete={handleSave} onDelete={handleDelete} />
            </DialogContent>
        </Dialog>)
}