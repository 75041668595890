import { CurrencyAmount } from "./CurrencyAmountField"
import { AccountInfo } from "./api"
import _ from 'underscore'
import { distinct, sortedBy } from "./util"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useState } from "react"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const total = (r:Record<string, number>):number => Object.values(r).reduce((a, b)=>a+b, 0)

export const applyWeights = (amount:CurrencyAmount, update:Record<string, number>):Record<string, number>=>{
    const application:Record<string, number> = {}
        
    console.log("Allocating", JSON.stringify(update), "to", amount.amount)
    Object.keys(update).forEach(key=>{
        const amountApplied = total(application)
        const amountRemaining = amount.amount - amountApplied
        const totalWeight = Object.values(update).reduce((a, b)=>a+b, 0)
        const weight = update[key] ?? 0
        const ratio = totalWeight==0 ? 0 : weight/totalWeight
        const a = Math.max(Math.round(amount.amount * ratio), amountRemaining)
        application[key] = a
        console.log("Allocating", a, "to", key, "using ratio", ratio)
    })

    const totalAppliedBeforeRemainder = total(application)
    console.log("totalAppliedBeforeRemainder", totalAppliedBeforeRemainder)
    
    const remainder = amount.amount - totalAppliedBeforeRemainder
    console.log("remainder", remainder)

    const firstKey = Object.keys(application)[0]

    application[firstKey] = application[firstKey] + remainder
        

    const finalTotalApplied = total(application)
    if(finalTotalApplied != amount.amount){
        throw `Somehow we didn't allocate everything ${finalTotalApplied} vs ${amount.amount}`
    }

    return application

}

export default (props:{accounts: AccountInfo[], weights:Record<string, number>, onChange:(splits: Record<string, number>)=>void})=>{
    const {weights, accounts, onChange} = props

    const categoryName = (a:AccountInfo) => a.category ?? "Uncategorized"
    const categories = distinct( accounts.map(categoryName)).sort()
    
    const applyUpdatedWeights = (update:Record<string, number>)=>{
        onChange(update)
        
    }
    
    return ( <Grid container spacing={2}>
        {
            categories.map((category)=>{
                const accountsForCategory = sortedBy(accounts.filter(a=>categoryName(a) == category), a=>a.name)
                // .toSorted((a, b) => (a.name > b.name) ? 1 : -1)
                return (<Grid xs={12} md={6} lg={4} >
                    <Typography style={{fontWeight:"bold"}}>{category}</Typography>
                    {accountsForCategory.map(a=>{
                        const total = Object.values(weights).reduce((a, b)=>a+b, 0)
                        const weight = weights[a.id] ?? 0
                        const ratio = total==0 ? 0 : weight/total

                        const doAdd = ()=>{
                            applyUpdatedWeights({... weights,
                                [a.id]: Math.max(0, weight + .1)
                            })
                        }
                        const doSubtract = ()=>{
                            applyUpdatedWeights({... weights,
                                [a.id]:  Math.max(0, weight - .1)
                            })
                        }

                        return (<Stack direction="row" spacing={2}>
                            <Box style={{width:"200px", textAlign:"right", overflow:"clip"}}><Typography style={{fontSize:"10px"}}>{a.name}</Typography></Box>
                            
                            <div style={{width:"100px", border:"1px solid grey", height:"20px"}}><div style={{width:`${ratio * 100}px`, background:"green", height:"100%"}}></div></div>
                            <Stack direction="row" spacing={0}>
                                <Box><button onClick={doAdd}>+</button></Box>
                                <Box><button onClick={doSubtract}>-</button></Box>
                            </Stack>
                        </Stack>)
                    })}
                </Grid>)
            })
        }
    </Grid>)
}