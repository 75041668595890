import { useEffect, useState } from 'react'
import { FundMove, MoveChangeResult } from './api'
import { CircularProgress } from '@mui/material'
import { getMovePreflight } from './api-actions'

export interface PreflightPanelProps {
    move:FundMove
    onChange:(affordability:MoveChangeResult)=>void
}
interface PreflightPanelState {
    loading:boolean
    error:string|undefined
    affordability?:MoveChangeResult
}

export default (props:PreflightPanelProps)=>{
    const {move, onChange} = props
    const [state, setState] = useState<PreflightPanelState>({
        loading: true,
        error: undefined
    })
    const { affordability, loading, error } = state
    
    console.log("preflighting move", move)

    useEffect(()=>{
        getMovePreflight(move).then(affordability=>{
            setState({
                ... state,
                loading: false,
                affordability: affordability
            })
            onChange(affordability)
        }).catch(error=>{
            console.log("Got error", error)
            setState({
                ... state,
                loading: false,
                error: error
            })
        })
    }, [])

    if (error) {
        return <div className="error-text">Error! {error}</div>
    } else if(affordability && !loading){
        let result = affordability
        return <div className="affordability-report">
            <div className="summary">{result.affordability.isAffordable ? "This is affordable." : "This is NOT affordable!"}</div>
            <div className="details">{result.affordability.analysis}</div>
        </div>
    }else{
        return <div><CircularProgress/> Analyzing ...</div>
    }

}
