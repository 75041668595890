import React from 'react'
import util from './util'
import CurrencySelect from './CurrencySelect'
import { Stack, TextField } from '@mui/material'
import CurrencyAmountField, { CurrencyAmount } from './CurrencyAmountField'

export default (props: { value: CurrencyAmount, onChange: (v: CurrencyAmount) => void }) => {
    const { value, onChange } = props

    const handleChange = (amt: number) => {
        const v: CurrencyAmount = { ...value, amount: amt }
        console.log("Value is ", util.formatAmount(v.amount, v.currency), v)
        onChange(v)
    }


    return (
        <Stack direction="row" spacing={1}>
            <CurrencyAmountField label="amount" value={value} onChange={e => handleChange(e)} />
            <CurrencySelect value={value.currency} onChange={v => onChange({ ...value, currency: v })} />
        </Stack>
    )
}