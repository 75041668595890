import _ from 'underscore'
import http from './cj/HttpClient'
import accountsUtils from './accounts'
import { getTenantId } from './util';
import { AccountInfo, Template } from './api';
import React from 'react';
import LoadingThrobber from './LoadingThrobber';
import { FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import CurrencyAmountField from './CurrencyAmountField';
import { getAccounts } from './api-actions';

export const TemplatesEditor = (props: { currency: string, template: Template, onChange: (t: Template) => void }) => {
    const { currency, template, onChange } = props
    const [list, setList] = React.useState<AccountInfo[]>()


    React.useEffect(() => getAccounts(results => setList(results)), [])

    if (!list) {
        return <LoadingThrobber isLoading={true} />
    }

    var accountsByKind = _.groupBy(list, "kind");

    const accountRows = Object.keys(accountsByKind).map(function (kind) {
        const accounts = accountsByKind[kind]
        var accountsOrderedByCategory = _.sortBy(accounts, function (accountInfo) {
            return accountInfo.kind + "----" + accountInfo.category + "----" + accountInfo.name
        });

        return <>
            <Stack spacing={2}>
                <Typography variant='h6'>{accountsUtils.accountKindDescription(kind)}</Typography>
                {accountsOrderedByCategory.map(function (accountInfo) {
                    var id = accountInfo.id;
                    var value = template.split[id] ?? 0

                    return (<Stack direction="row" spacing={3}>
                        <CurrencyAmountField
                            fullWidth
                            label={(accountInfo.category ? (accountInfo.category + "/") : "") + accountInfo.name}
                            value={{ currency: currency, amount: value }}
                            onChange={n => onChange({
                                ...template,
                                split: {
                                    ...template.split,
                                    [id]: n
                                }
                            })}
                        />
                        <FormControlLabel value={id} control={<Radio />} label="Remainder" />
                    </Stack>)
                })}
            </Stack>
        </>
    });

    return (<div className="template-editor">
        <Stack spacing={3} style={{ paddingTop: "20px", }}>
            <Typography variant='h5'>General</Typography>

            <TextField label="Name" fullWidth type="text" value={template.name} onChange={e => onChange({ ...template, name: e.target.value })} />
            <TextField label="Match Pattern" fullWidth type="text" value={template.pattern} onChange={e => onChange({ ...template, pattern: e.target.value })} />

            <Typography variant='h5'>Allocations</Typography>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={template.remainder}
                onChange={e => onChange({ ...template, remainder: e.target.value })}
                name="radio-buttons-group"
            >
                <Stack spacing={3}>
                    {accountRows}
                </Stack>
            </RadioGroup>
        </Stack>
    </div>)
}
