import React from 'react'
import _ from 'underscore'
import http from './cj/HttpClient'
import { getTenantId } from './util'
import { CircularProgress } from '@mui/material'
import { AccountInfo, PhysicalAccountInfoDto } from './api'
import { Route, Routes, useNavigate } from "react-router-dom";
import { getPhysicalAccountInfos } from './api-actions'
import AccountPage from './AccountPage'
import AccountsOverviewPage from './AccountsOverviewPage'

export default (props: { when: Date }) => {
    const [accounts, setAccounts] = React.useState<AccountInfo[]>()
    const [physicalAccounts, setPhysicalAccounts] = React.useState<PhysicalAccountInfoDto[]>()

    function fetchAccounts() {
        http({
            url: `/api/tenants/${getTenantId()}/accounts`,
            method: "GET",
            onResponse: function (response) {
                // console.log("done fetching");
                if (response.status === 200) {
                    setAccounts(JSON.parse(response.body))
                } else {
                    throw "Error retrieving "
                }
            }
        });
    }

    React.useEffect(fetchAccounts, [])
    React.useEffect(() => getPhysicalAccountInfos(setPhysicalAccounts), [])

    const navigate = useNavigate()

    const showMainScreen = () => {
        navigate(`.`)
    }

    if (!accounts || !physicalAccounts) {
        return <CircularProgress />
    } else {
        return (
            <Routes>
                <Route index element={<AccountsOverviewPage accounts={accounts} showMainScreen={showMainScreen} />} />
                <Route path=":accountId/*" element={<AccountPage accounts={accounts} physicalAccounts={physicalAccounts} showMainScreen={showMainScreen} />} />
            </Routes>
        )
    }

}