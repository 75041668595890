import { UtilityChargeDto } from "./api"
import util from "./util"

export default (props:{charge:UtilityChargeDto})=>{
    const {charge} = props
    const theCharge = charge.charge

    return <>
        {(util.formatLongDateTime(charge.when) + " " + (theCharge ? util.formatAmount(theCharge.qty, theCharge.units) : "") + " (" + (charge.meterReading - (charge?.previousReading ?? 0)) + " " + charge.meterUnits + ") " + charge.memo)}
    </>
}