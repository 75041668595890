import React, { useEffect, useState } from 'react'
import http, { HttpMethodName } from './cj/HttpClient'
import { getTenantId } from './util';
import ConnectionEditor from './connectionEditor/ConnectionEditor'
import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from '@mui/material';
import { ConnectionConfigDto, ConnectionsListItem } from './api';
import { createOrUpdateConnection, getConnection, getConnections } from './api-actions';
import CloseIcon from '@mui/icons-material/Close';


export interface ConnectionEditingStuff {
    type:string
    existing?: ConnectionsListItem
}

export default (props:{connectionId: string | undefined, connectionName: string, type: string, connection?: ConnectionsListItem, onSave:()=>void, onClose:()=>void})=>{
    const {connectionId, connectionName, type, connection, onSave, onClose} = props
    const [existing, setExisting] = useState<ConnectionConfigDto>()

    useEffect(()=>{
        connectionId && getConnection(connectionId).then(setExisting)
    }, [connectionId])
    
    const onComplete = (result:ConnectionConfigDto)=> {
        createOrUpdateConnection(connectionId, result)
            .then(()=>{
                onSave()
            })
            .catch(err=>{
                alert(err);
            })
    }
    
    return (<Dialog 
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={onClose} 
        open={true}>

        <DialogTitle>{connectionName} <IconButton style={{float:"right"}} onClick={onClose}><CloseIcon/></IconButton></DialogTitle>
        <DialogContent>
            {(!connectionId || (connectionId && existing)) ? <ConnectionEditor type={type} existingConnection={existing} onComplete={onComplete}/> :  <CircularProgress/>}
        </DialogContent>
    </Dialog>)
}

