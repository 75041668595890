import React, { ReactNode, useEffect, useState } from 'react'
import http from './cj/HttpClient'
import util, { formattedColorCurrencyAmount, getTenantId } from './util'
import Tabs from './Tabs'
import AlienMappingWidget from './itemEditor/AlienMappingWidget'
import TransferMappingWidget from './itemEditor/TransferMappingWidget'
import IncomeExpenseMappingWidget from './itemEditor/IncomeExpenseMappingWidget'
import NonMaterialMappingWidget from './itemEditor/NonMaterialMappingWidget'
import { AccountInfo, Bucket, ContextualizedPendingTransfer, MapToMoves, MapToPendingTransfer, MappingOption, MappingSpec, PhysicalAccountInfoDto, PhysicalItem, PhysicalItemHandle, PlannedFundMove, PlannedItemHandle, asExistingMoveRef, asMapToMoves, asMapToPendingTransfer, asNewMoveSpec, asNonMaterial, asPlanItemRef, asTransfer } from './api'
import { Mapper, mapToExistingMove, mapToPlannedMove } from './mappingLogic'
import FlowsMapper from './FlowsMapper'
import { Button, CircularProgress } from '@mui/material'
import ErrorDialog from './ErrorDialog'
import { Problem } from './ErrorAlert'
import { ResultThing, getMove, getPendingTransfers, getPhysicalAccountInfos, getPlanedItem, postPhysicalItemMappings, patchPhysicalItem } from './api-actions'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import MarkdownContent from './MarkdownContent'
import Table2 from './Table2'
import data from './data'
import MoveDetailsPanel from './MoveDetailsPanel'
import MarkdownEditor from './MarkdownEditor'
import MappingEditor from './MappingEditor'
import { CurrencyAmount } from './CurrencyAmountField'
import BusyButton from './BusyButton'

const withMemoHack = (spec:MappingSpec, memo:string):MappingSpec => {

    const movesMapping = asMapToMoves(spec)
    let finalSpec:MappingSpec;
    if(movesMapping){
        // have to combine the memos?  not sure, but I think so
        const consolidatedMemo = memo + movesMapping.memo 
        const f:MapToMoves = {
            ... movesMapping,
            memo:consolidatedMemo,
            allocations:movesMapping.allocations.map(alloc => {
                const newMoveSpec = asNewMoveSpec(alloc.move)
                
                if(newMoveSpec){
                    return {
                        ... alloc,
                        move:{
                            type: "new-move",
                            newMove: {
                                ... newMoveSpec.newMove,
                                memo:consolidatedMemo
                            },
                        }
                    }
                }else{
                    return alloc
                }
            })
        }
        finalSpec = f
    }else{
        finalSpec = spec
    }

    console.log("Spec with memo: ", finalSpec)
    return finalSpec
}


export const optionToMappingSpec = (option: MappingOption, item:CurrencyAmount):MappingSpec => {
    if(option.move){
        return mapToExistingMove(option.move, item)
    }else if(option.planned){
        return mapToPlannedMove(option.planned.id, item)
    }else{
        throw `Not sure how to translate this: ${JSON.stringify(option)}`
    }
}

const PendingSelector = (props:{pendingTransfers:ContextualizedPendingTransfer[], item:PhysicalItem, onMappingsChange: (newSelection: MappingSpec) => void })=>{
    const {pendingTransfers, item, onMappingsChange} = props

    const [d, setData] = useState(data())


    return (<>
        <Table2
            header={["when", "amount", "who", "memo", "move", ""]}
            rows={pendingTransfers.map(pending => {
                const selectSpend = ()=>{
                    console.log("They selected ", pending, "for", item)
                    const mapping:MapToPendingTransfer = {
                        type: "pending-transfer",
                        memo:"",
                        pendingTransferId: pending.pending.id
                    }
                    onMappingsChange(mapping)
                }
                return {
                    key:pending.pending.id,
                    cells:[
                        util.formatLongDateTime(pending.pending.whenPosted),
                        util.formattedColorAmount(pending.pending.amount, pending.pending.currency),
                        pending.pending.who,
                        <MarkdownContent>{pending.pending.memo}</MarkdownContent>,
                        <>{pending.moves.map(ma => <>{formattedColorCurrencyAmount({amount:ma.amount, currency:item.currency})} <MoveDetailsPanel move={ma.move} getAccount={d.account} /></>)}</>,
                        <Button variant="contained" onClick={selectSpend}>Select</Button>
                    ]
                }
            })}
        /></>)
}

export interface ItemEditorProps { 
    item: PhysicalItem,
    foreignAccountId: string,
    accounts: AccountInfo[], 
    mapper: Mapper, 
    onSuccess: (result: ResultThing) => void,
    handleChange: () => void ,
}



export default (props: ItemEditorProps) => {
    const {foreignAccountId, accounts, onSuccess, handleChange, mapper } = props

    const [item, setItem] = useState(props.item)
    const [memoText, setmemoText] = React.useState(item.mappingMemo ?? "")
    const [aliens, setaliens] = React.useState<Bucket[]>()
    const [problem, setProblem] = React.useState<Problem>()
    const [pendingTransfers, setPendingTransfers] = useState<ContextualizedPendingTransfer[]>()

    const handle:PhysicalItemHandle = {
        accountId: foreignAccountId,
        itemId: item.id
    }

    useEffect(()=>getPendingTransfers(foreignAccountId, setPendingTransfers), [])

    const fetchAliens = () => {
        http({
            url: `/api/tenants/${getTenantId()}/aliens`,
            method: "GET",
            onResponse: function (response) {
                console.log("done fetching");
                if (response.status === 200) {
                    setaliens(JSON.parse(response.body));
                } else {
                    throw "Error retrieving"
                }
            }
        });
    }

    React.useEffect(fetchAliens, [])

    console.log("We have aliens:", aliens)


    if(!pendingTransfers){
        return <CircularProgress/>
    }

    const unusedSpends = pendingTransfers.filter(it => !it.pending.actualTransferId)


    const makeEditor = (isAppending:boolean, handleMappingUpdate:(mappingFactory:(allocatedAmount:CurrencyAmount)=>MappingSpec)=>void)=>{

        const staticHandleMappingUpdate = (m:MappingSpec)=>{
            handleMappingUpdate(()=>m)
        }

        const stockTabs: Record<string, () => ReactNode> = {
            "Expected Flow": () => <FlowsMapper foreignAccountId={foreignAccountId} item={item} accounts={accounts} onMappingsChange={handleMappingUpdate} />,
            "Unexpected Flow": () => <IncomeExpenseMappingWidget item={item} accounts={accounts} onMappingsChange={handleMappingUpdate} />,
            "Somebody Else": () => <AlienMappingWidget item={item} aliens={aliens ?? []} onMappingsChange={handleMappingUpdate} />,
            "Transfer between External Accounts": () => <TransferMappingWidget foreignAccountId={foreignAccountId} item={item} mapper={mapper} onMappingsChange={staticHandleMappingUpdate} />
        }
        
        console.log("isAppending?", isAppending)
        const tabs: Record<string, () => ReactNode> = (pendingTransfers.length == 0 || isAppending) ? stockTabs : {
            "Spends": () => <PendingSelector pendingTransfers={unusedSpends} item={item} onMappingsChange={staticHandleMappingUpdate} />,
            ... stockTabs,
        }
    
        if (item.amount === 0) {
            tabs["Non-Material"] = () => <NonMaterialMappingWidget onMappingsChange={staticHandleMappingUpdate} />
        }
        
        return <Tabs tabs={tabs} />
    }

    const addButtonClicked = (selection:MappingSpec) => new Promise<ResultThing>((resolve, reject) => {
        const handle:PhysicalItemHandle = {
            accountId:foreignAccountId,
            itemId:item.id,
        }

        let spec:MappingSpec = selection
        
        let finalSpec:MappingSpec = withMemoHack(spec, memoText)
            
        postPhysicalItemMappings(
            finalSpec, 
            handle, 
            (result)=>{
                onSuccess(result)
                resolve(result)
            }, 
            (p:Problem)=>{
                setProblem(p)
                reject(p)
            }
        )
    })


    const saveMemo = ()=> patchPhysicalItem({mappingMemo: memoText}, handle).then(i=>setItem(i.item))

    return <div className="memo-section">

        {problem && <ErrorDialog problem={problem} onClose={() => setProblem(undefined)} />}
        <Grid container spacing={2}>
            <Grid md={12} xl={6} >
                <MarkdownEditor
                    label="memo"
                    editable={true}
                    value={memoText}
                    onChange={setmemoText} />
                {(memoText != (item.mappingMemo ?? "")) && <BusyButton clickAction={saveMemo}>save memo</BusyButton>}
            </Grid>
            <Grid md={12} xl={6}>
                <MappingEditor
                    itemAmount = {{amount:item.amount, currency:item.currency}}
                    getAccount = {id => accounts.find(a => a.id == id)}
                    onSubmit = {addButtonClicked}
                    makeEditor = {makeEditor}
                    handleChange = {handleChange}
                />
            </Grid>

        </Grid>
    </div>

}

