import _ from 'underscore'
import http from './cj/HttpClient'
import util, { getTenantId } from './util'
import Table, { TableRowData } from './Table2'
import { AccountInfo, BucketState, PhysicalAccountInfoDto, PlannedItemHandle } from './api'
import { getMove, getMovePromise, reOpenPlanedItem } from './api-actions'
import { DialogsContext, DialogsState } from './Dialogs'
import { ReactNode, useContext } from 'react'
import { moveUrl, urlForMovesPage } from './MovePage'
import { Link } from 'react-router-dom'

export default (props: {
    account: AccountInfo, accountState: BucketState | undefined, accountForId: (id: string) => AccountInfo | undefined,
    physicalAccountForId: (id: string) => PhysicalAccountInfoDto | undefined,
    onChange: () => void
}) => {
    const { account, accountState, accountForId, physicalAccountForId, onChange } = props
    const header = ["When", "Plan Status", "Plan Alignment", "Description", "External Txns", "To/From", "Amount", "Balance", ""]


    const dialogs = useContext(DialogsContext);

    const totals = {};
    const reversedHistory = accountState?.history ?? []
    const adjustmentRows: TableRowData[] = reversedHistory.map(function (adjustment, idx) {
        const when = new Date(adjustment.when);
        const formattedWhen = util.formatLongDateTime(when);

        let whenClasses = "when-column"

        const twentyFourHoursFromNow = new Date().getTime() + (1000 * 60 * 60 * 24)
        if (adjustment.when > twentyFourHoursFromNow) {
            whenClasses += " move-with-future-time"
        }

        const whenCell = <Link to={moveUrl(adjustment.itemId)}><span className={whenClasses}>{formattedWhen}</span></Link>
        

        const actions: Record<string, (e?: any) => void> = {};

        if (adjustment.planAlignment && adjustment.planAlignment.isComplete) {
            var plannedItemId = adjustment.planAlignment.plannedItemId;

            actions['re-open'] = () => reOpenPlanedItem(plannedItemId, onChange)
        }

        actions['edit'] = function (event) {
            console.log("adjustment", adjustment)

            getMovePromise(adjustment.itemId).then(move=>dialogs.showMove({value:move, onChange:onChange}))

        }

        const actionLinks = _.map(actions, (action, name) => {
            let doAction = () => {
                console.log("Need to do action ", name, action)
                action()
            }
            return <span className="action-link" onClick={doAction}>{name}</span>
        })
        
        // var actionLinks = ActionLinks(actions);


        const physicalAmounts: Record<string, number> = _.reduce(adjustment.physicalAlignment, function (totals, physical) {
            updateTotalsMap(physical.amount, physical.currency, totals)
            return totals
        }, {});

        const phoo = _.map(physicalAmounts, function (amount, currency) {
            return <div>{util.formatAmount(amount, currency)}</div>
        });

        const physicalDescriptions = _.map(adjustment.physicalAlignment, function (physical) {
            console.log("physical", physical)
            return <div>[{util.formatLongDateTime(physical.whenPosted)}/{physicalAccountForId(physical.handle.accountId)?.name}]  {physical.description}</div>
        });

        const physicalAlignmentText = physicalDescriptions.concat(phoo)

        const total = updateTotalsMap(adjustment.qty, adjustment.currency, totals);

        const otherAccount = accountForId(adjustment.otherAccountId);

        const planAlignmentDescription = adjustment.planAlignment ? adjustment.planAlignment.description : "";



        return {
            key: `${idx}-${adjustment.itemId}`,
            cells: [
                whenCell,
                adjustment.planAlignment ? (adjustment.planAlignment.isComplete ? "Complete" : "Open") : "Unplanned",
                planAlignmentDescription,
                adjustment.memo,
                physicalAlignmentText,
                otherAccount?.name ?? "",
                util.formattedColorAmount(adjustment.qty, adjustment.currency), //util.formatAmount(adjustment.qty, adjustment.currency), 
                util.formattedColorAmount(total, adjustment.currency),
                actionLinks
            ]
        }
    });

    const balanceRows: TableRowData[] = Object.keys(accountState?.balances ?? []).map(currency => {
        const qty = accountState?.balances[currency]
        return {
            key: `balances-for-${currency}`,
            cells: ["", "", "", "", "", "", "Total " + currency, qty && util.formatAmount(qty, currency), ""]
        }
    });

    function updateTotalsMap(amount: number, currency: string, totals: Record<string, number>) {

        var total = totals[currency];
        if (!total) {
            total = 0;
        }
        total += amount;
        totals[currency] = total;

        return total;
    }

    const rows = balanceRows.concat(adjustmentRows.reverse())
    return (<div className="account-history-screen">
        <a href={'/api/accounts/' + account.id + '/items.csv'}>CSV Download</a>
        <Table
            header={header}
            rows={rows}
        />
    </div>)
}
