import { Link, useNavigate, useParams } from "react-router-dom"
import { Flow, FullMoveDetails, FullPlannedMove, FundMove, MappingOption, PlannedItemHandle } from "./api"
import { getTenantId } from "./util"
import { useEffect, useState } from "react"
import { deletePlanItem, getFullMove, getFullPlannedMove, getPlanItem } from "./api-actions"
import { AccountingEntryPanel } from "./AccountingEntryDialog"
import { Box, Breadcrumbs, Divider, IconButton, Stack, Typography } from "@mui/material"
import { DialogsContext } from "./Dialogs"
import MoveDetailsPanel from "./MoveDetailsPanel"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import data from "./data"
import MarkdownContent from "./MarkdownContent"

export const toMappingOption = (m:FullMoveDetails):MappingOption => {
    return {
        planned: m.planned?.plannedFundMove,
        move: m.actual,
        items: m.physicalItems,
    }
}

export const urlForMove = (move:FundMove) => move.plannedItemId ? plannedMoveUrl(move.plannedItemId) : moveUrl(move.id!!)
export const moveUrl = (moveId:string) => `/app/${getTenantId()}/plan/elements/realized/${moveId}`
export const plannedMoveUrl = (handle:PlannedItemHandle) => `/app/${getTenantId()}/plan/elements/planned/${handle.sequenceId}/${handle.instanceId}`

export const urlForMovesPage = () => `/app/${getTenantId()}/plan`

export const PlannedMovePanel = (props: { move: FullPlannedMove }) => {
    const { move } = props
    const [d, _] = useState(data())
    const getAccount = d.account
    const plannedItemHandle = move.planned.id
    const navigate = useNavigate()
    const [flow, setFlow] = useState<Flow>()

    const doDeletePlanItem = () => {
        const userConfirmedDelete = window.confirm("Are you sure you want to delete this?  This cannot be undone!");
        if (userConfirmedDelete) {
            deletePlanItem(plannedItemHandle)
                .then(()=>navigate(urlForMovesPage()))
                .catch(alert)
        }
    }

    useEffect(()=>getPlanItem(plannedItemHandle.sequenceId, setFlow), [plannedItemHandle])

    return (<>
        <Stack spacing={3}>
                
            {(flow?.notes && flow.notes.trim().length > 0) &&< >
                <Typography variant="h6">Notes</Typography>
                <MarkdownContent >{flow?.notes}</MarkdownContent>
            </>}
            <DialogsContext.Consumer>{dialogs =>
                <Typography variant="h6">Planned
                    <IconButton onClick={() => dialogs.showFlowById(move.planned.id?.sequenceId, () => { })}><EditIcon /></IconButton>
                    <IconButton onClick={doDeletePlanItem}><DeleteIcon /></IconButton>
                </Typography>
            }</DialogsContext.Consumer>
            <MoveDetailsPanel move={move.planned.planned} getAccount={getAccount} />


        {/* {move.unActualization && <AccountingEntryPanel option={toMappingOption(move.unActualization)} hidePlanned={true} />} */}
        {move.actualizations && move.actualizations.map((m, idx) => (<>
            <Divider/>
            <Typography variant="h6">Actualization {idx +1} of {move.actualizations?.length}</Typography>
            <AccountingEntryPanel option={toMappingOption(m)} hidePlanned={true} />
        </> ))}
        </Stack>
    </>)
}

export const PlannedMovePage = (props:{})=>{
    const {sequenceId, instanceId} = useParams()
    const [move, setMove] = useState<FullPlannedMove>()

    const handle:PlannedItemHandle = {
        sequenceId:sequenceId!!,
        instanceId:instanceId!!,
    }

    useEffect(()=>{
        getFullPlannedMove(handle).then(setMove)
    }, [JSON.stringify(handle)])
    

    return (<Stack spacing={2}>
        <Breadcrumbs>
            <Link to={urlForMovesPage()}>All Planned Moves</Link>
            <Typography>{handle.sequenceId}</Typography>
            <Typography>{handle.instanceId}</Typography>
        </Breadcrumbs>
        {move  && <PlannedMovePanel move={move}/>}
    </Stack>)
}

export default (props:{})=>{
    const {moveId} = useParams()
    const [move, setMove] = useState<FullMoveDetails>()


    useEffect(()=>{
        getFullMove(moveId!!).then(setMove)
    }, [moveId])

    return (<>

        <Breadcrumbs>
            <Link to={urlForMovesPage()}>All Realized Moves</Link>
            <Typography>{moveId}</Typography>
        </Breadcrumbs>
        {move && <AccountingEntryPanel option={toMappingOption(move)}/>}
    </>)
}
