import React, { ReactNode, useEffect, useState } from 'react'
import $ from 'jquery'
import http from './cj/HttpClient'
import util, { asMoveRef, describeSchedule, formatTime, formattedColorCurrencyAmount, getTenantId, take } from './util'
import Tabs from './Tabs'
import AlienMappingWidget from './itemEditor/AlienMappingWidget'
import TransferMappingWidget from './itemEditor/TransferMappingWidget'
import IncomeExpenseMappingWidget from './itemEditor/IncomeExpenseMappingWidget'
import NonMaterialMappingWidget from './itemEditor/NonMaterialMappingWidget'
import { AccountInfo, Bucket, ContextualizedPendingTransfer, FundMove, MapToPendingTransfer, MappingOption, MappingSpec, MoveMappingAllocation, MoveSpec, PhysicalAccountInfoDto, PhysicalItem, PhysicalItemHandle, PlannedFundMove, PlannedItemHandle, asExistingMoveRef, asMapToMoves, asMapToPendingTransfer, asNewMoveSpec, asNonMaterial, asPlanItemRef, asTransfer } from './api'
import { Mapper, mapToExistingMove, mapToPlannedMove } from './mappingLogic'
import FlowsMapper from './FlowsMapper'
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, IconButton, Stack, TextField, Typography } from '@mui/material'
import ErrorDialog from './ErrorDialog'
import { Problem } from './ErrorAlert'
import PhysicalItemPanel from './PhysicalItemPanel'
import { AccountLink } from './Links'
import { DialogsContext } from './Dialogs'
import PlannedFundMovePanel from './PlannedFundMovePanel'
import FundMovePanel from './FundMovePanel'
import { ResultThing, getMove, getPendingTransfer, getPendingTransfers, getPhysicalAccountInfos, getPlanedItem, postPhysicalItemMappings } from './api-actions'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import MarkdownContent from './MarkdownContent'
import Table2 from './Table2'
import data from './data'
import MoveDetailsPanel from './MoveDetailsPanel'
import MarkdownEditor from './MarkdownEditor'
import PendingTransferPanel from './PendingTransferPanel'
import { all } from 'underscore'
import CurrencyAmountField, { CurrencyAmount } from './CurrencyAmountField'
import CloseIcon from '@mui/icons-material/Close';

const StandardWrapper = (props: { children: ReactNode, disabled: boolean, onDelete?: () => void }) => {
    const { onDelete, disabled } = props
    return (<>
        <Card>
            {(onDelete && !disabled) && <IconButton onClick={onDelete}><CloseIcon /></IconButton>}
            <CardContent>{props.children}</CardContent>
        </Card>
    </>)
}

const MoveMappingAllocationEditor = (props: { allocation: MoveMappingAllocation, currency: string, disabled: boolean, getAccount: (id: string) => AccountInfo | undefined, onDelete?: () => void, onAmountChange?: (amt: number) => void, handleChange: () => void }) => {
    const { allocation, currency, disabled, getAccount, onDelete, onAmountChange, handleChange } = props
    const moveSpec = allocation.move

    const existingMoveSpec = asExistingMoveRef(moveSpec)
    const planItemRef = asPlanItemRef(moveSpec)
    const newMoveSpec = asNewMoveSpec(moveSpec)

    let description: ReactNode;

    if (existingMoveSpec) {
        description = <ExistingMoveViewer moveId={existingMoveSpec.existingId} getAccount={getAccount} handleChange={handleChange} />
    } else if (planItemRef) {
        description = <PlannedMoveViewer plannedId={planItemRef.handle} getAccount={getAccount} handleChange={handleChange} />
    } else if (newMoveSpec) {
        const flows = newMoveSpec.newMove.flows
        const accountIds = flows.flatMap(f => [f.fromFundId, f.toFundId])
        const allAccounts = accountIds.map(id => {
            const a = getAccount(id)
            if (!a) {
                console.log("No such account: " + id)
                throw ("No such account: " + id)
            }
            return a!!
        })
        const nonStubAccounts = allAccounts.filter(it => it && it.kind != 'Stub')
        const firstAccount = nonStubAccounts[0]

        let flowsDescription: ReactNode;
        if (nonStubAccounts.length == 0) {
            flowsDescription = <Typography>Create new (external only?) move with {flows.length} flows:<ul>{take(flows, 10).map(flow => {
                return <li>{formattedColorCurrencyAmount({ amount: flow.amount, currency: currency })}  <AccountLink account={getAccount(flow.fromFundId)} /> -&gt; <AccountLink account={getAccount(flow.toFundId)} /></li>
            })}</ul></Typography>
        } else if (nonStubAccounts.length == 1 && firstAccount) {
            if (firstAccount.kind == 'Alien') {
                flowsDescription = <Typography>Assign to {firstAccount.name}</Typography>
            } else {
                flowsDescription = <Typography>Create new move affecting <AccountLink account={firstAccount} /></Typography>
            }
        } else {

            console.log("Here's one:", newMoveSpec, nonStubAccounts, allAccounts)

            flowsDescription = <Typography>Create new move affecting {nonStubAccounts.length} accounts:<ul>{take(nonStubAccounts, 10).map(a => {
                const flow = flows.find(f => f.fromFundId == a?.id || f.toFundId == a?.id)!!

                const direction = flow.fromFundId == a?.id ? "from" : "to"

                return <li>{formattedColorCurrencyAmount({ amount: flow.amount, currency: currency })} {direction} <AccountLink account={a} /></li>
            })}</ul></Typography>
        }

        description = <Stack>
            {flowsDescription}
            <Box>{newMoveSpec.newMove.memo}</Box>
        </Stack>
    } else {
        description = <Stack>
            <Typography>Not sure how to describe this</Typography>
            <pre>{JSON.stringify(moveSpec)}</pre>
        </Stack>
    }


    const amount = { amount: allocation.amount, currency: currency }

    console.log("Rendering allocation amount ", amount.amount, "for", allocation)
    return <><StandardWrapper disabled={disabled} onDelete={onDelete}>
        {onAmountChange ? <CurrencyAmountField label="amount" value={amount} onChange={onAmountChange} /> : <>{formattedColorCurrencyAmount(amount)} to </>}

        {description}</StandardWrapper></>
}

export const MapToPendingTransferPanel = (props: { disabled: boolean, mapping: MapToPendingTransfer, physAccounts: PhysicalAccountInfoDto[] }) => {
    const { disabled, mapping, physAccounts } = props
    const [pending, setPending] = useState<ContextualizedPendingTransfer>()
    const [error, setError] = useState<any>()

    useEffect(() => {
        getPendingTransfer(mapping.pendingTransferId)
            .then(setPending)
            .catch(setError)
    }, [])


    return (<>
        <StandardWrapper disabled={disabled}>
            <Typography>Map as pending transfer</Typography>
            <Box>{mapping.memo}</Box>
            {error && <Alert color="error">{error}</Alert>}
            {!pending ? <CircularProgress /> : <PendingTransferPanel contextualized={pending} physAccounts={physAccounts} />}
        </StandardWrapper></>)
}
export default (props: { mapping: MappingSpec, currency: string, disabled: boolean, physAccounts: PhysicalAccountInfoDto[], getAccount: (id: string) => AccountInfo | undefined, onDelete?: (idx: number) => void, onAmountChange?: (idx: number, newAmount: number) => void, handleChange: () => void }) => {
    const { mapping, currency, disabled, physAccounts, getAccount, onDelete, onAmountChange, handleChange } = props

    const pendingTransfer = mapping && asMapToPendingTransfer(mapping)
    const transfer = mapping && asTransfer(mapping)
    const toNonMaterial = mapping && asNonMaterial(mapping)
    const toMoves = mapping && asMapToMoves(mapping)

    if (transfer) {
        return <StandardWrapper disabled={disabled}>
            <Typography>Transfer between accounts</Typography>
            <Box>{transfer.memo}</Box>
        </StandardWrapper>
    } else if (toNonMaterial) {
        return <StandardWrapper disabled={disabled}>
            <Typography>Map as immaterial</Typography>
            <Box>{toNonMaterial.memo}</Box>
        </StandardWrapper>
    } else if (pendingTransfer) {
        return <MapToPendingTransferPanel
            disabled={disabled}
            mapping={pendingTransfer!!}
            physAccounts={physAccounts} />
    } else if (toMoves && toMoves.allocations.length > 0) {
        const hasMultiple = toMoves.allocations.length > 1
        return <><Stack spacing={3}>
            {toMoves.allocations.map((allocation, idx) => (
                <MoveMappingAllocationEditor
                    disabled={disabled}
                    allocation={allocation}
                    currency={currency}
                    getAccount={getAccount}
                    onDelete={hasMultiple ? () => onDelete && onDelete(idx) : undefined}
                    onAmountChange={hasMultiple ? n => onAmountChange && onAmountChange(idx, n) : undefined}
                    handleChange={handleChange} />
            ))}
            <Box>{toMoves.memo}</Box>
        </Stack></>
    } else {
        return <StandardWrapper disabled={disabled}>
            <Stack>
                <Typography>Not sure how to describe this</Typography>
                <pre>{JSON.stringify(mapping)}</pre>
            </Stack>
        </StandardWrapper>
    }
}


export const ExistingMoveViewer = (props: { moveId: string, getAccount: (id: string) => AccountInfo | undefined, handleChange: () => void }) => {
    const { moveId, getAccount, handleChange } = props

    const [move, setMove] = useState<FundMove>()

    useEffect(() => {
        getMove(moveId, setMove)
    }, [moveId])

    return (<>
        <Typography>Map to existing move
            {move ? <FundMovePanel move={move} getAccount={getAccount} handleChange={handleChange} /> : <CircularProgress />}
        </Typography>
    </>)
}

export const PlannedMoveViewer = (props: { plannedId: PlannedItemHandle, getAccount: (id: string) => AccountInfo | undefined, handleChange: () => void }) => {
    const { plannedId, getAccount, handleChange } = props

    const [move, setMove] = useState<PlannedFundMove>()

    useEffect(() => {
        getPlanedItem(plannedId, setMove)
    }, [plannedId])

    return (<>
        <Typography>Realize planned item & map to it
            {move ? <PlannedFundMovePanel plannedMove={move} getAccount={getAccount} handleChange={handleChange} /> : <CircularProgress />}
        </Typography>
    </>)
}

