import React, { CSSProperties, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'underscore'
import util, { averageTimesPerMonth, combineTotals, describeFrequencey, describeSchedule, getTenantId, mapRecord } from './util'
import Table, { TableRowData } from './TableNG'
import dataCache, { Data } from './data'
import AnalysisWindowControls, { AnalysisWindow, WindowOption, resolveWindowOption } from './AnalysisWindowControls'
import { AccountInfo, PDate, Flow, MonthDate, Monthly, TemplateInfoListItem, Weekly, Yearly, BoxedFrequency, PlannedFundMove, FundsFlow, PlannedItemHandle, FundMove, HistoricalPlanItemAnalysis, UnplannedAnalysis, MonthlyRatesForCurrency, QuantitiesInOut, BalancesByTick, Organization, SplitPattern, Frequency } from './api'
import { Alert, Box, Breadcrumbs, Button, CircularProgress, DialogContent, IconButton, Stack, Typography } from '@mui/material'
import { doDeleteWithConfirmation, fetchChartData, fetchMonthlyRatesByEntity, fetchPlannedStats, fetchUnplannedStats, getAccounts, getAccountsPromise, getFlowsPromise, getPlanItem, getTenantOrganization, getTenantOrganizationPromise } from './api-actions'
import { DialogsContext } from './Dialogs'
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom'
import MarkdownContent from './MarkdownContent'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import FloatingActionBar from './FloatingActionBar'
import PlanChart from './PlanChart'
import { AccountLink, accountUrl } from './Links'
import { formatDateIfDefined } from './DateAndTime'
import { urlForPlanElementPage } from './PlanItemPage'
import { flowsInChronOrderBySequence, isOld } from './flows'
import { AccountRecurringMonthlyBudgetAnalysis, FlowFilter, analyzeMonthlyBudgetForAccount, makeIntraEntityFilter } from './budget'

export const makeNewFlowTemplate = (fromAccount: string | undefined): Flow => {
    const schedule: Monthly = {
        "type": "monthly",
        "skip": 1,
        "first": {
            "year": (new Date().getFullYear()),
            "month": (new Date().getMonth() + 1),
            "day": (new Date().getDate())
        },
        "last": {
            "year": 2030,
            "month": 1,
            "day": 1
        },
        "daysOfMonth": []
    }

    var template: Flow = {
        "enabled": true,
        "name": "",
        "notes": "",
        "amount": -1000,
        "currency": "USD",
        "toFromAccount": fromAccount ?? "Foo Bar",
        "accountOrTemplate": "generic-income-expense",
        "schedule": schedule,
        "duration": {
            type: "instantaneous"
        },
        template: undefined,
    }
    return template
}

function renderPlanScreenRow(flowsStats: Record<string, HistoricalPlanItemAnalysis> | undefined,
    sequenceName: string, data: Data, flow: Flow, onChange: () => void) {


    function formatDate(date: PDate) {
        function pad2(i: number) {
            var str = i.toString()
            if (str.length < 2) {
                return "0" + str
            } else {
                return str
            }
        }
        return pad2(date.year) + "-" + pad2(date.month) + "-" + pad2(date.day);
    }


    

    const onDeletePressed = () => {
        flow.id && doDeleteWithConfirmation(flow.id, onChange)
    }

    const makeUnsavedDuplicate = (orig: Flow): Flow => {
        var copy: Flow = JSON.parse(JSON.stringify(orig))
        delete (copy as any).id
        copy.name = "Copy of " + orig.name
        return copy
    }

    let extraClasses = isOld(flow) ? "old-flow-date" : ""

    let stats = flowsStats ? flowsStats[flow.id!!] : undefined

    let statsItemsBreakdown = stats ? stats.actual.numItems + "/" + stats.expected.numItems : ""
    let statsAmountsBreakdown = stats ? mapRecord(stats.actual.averageAmountsByCurrency, (currency, amt) => util.formatAmount(amt, currency)) : ""

    return [<span className={extraClasses}><Link target="_blank" to={`./${flow.id}`}>{sequenceName}</Link></span>,
    <span className={extraClasses}>{util.formatAmount(flow.amount, flow.currency)}</span>,
    <span className={extraClasses}>
        <div>{statsAmountsBreakdown}</div>
    </span>,
    <span className={extraClasses}>
        <div>{statsItemsBreakdown}</div>
    </span>,
    <span className={extraClasses}>{flow.amount > 0 ? "to" : "from"}</span>,
    <span className={extraClasses}>{describeSchedule(flow)}</span>,
    <span className={extraClasses}>{formatDateIfDefined((flow.schedule as BoxedFrequency).first)}</span>,
    <span className={extraClasses}>{formatDateIfDefined((flow.schedule as BoxedFrequency).last)}</span>,
    <div className={extraClasses}>{flow.matchPattern}</div>,
    <DialogsContext.Consumer>{dialogs =>
        <span className="plan-item-actions">
            <IconButton onClick={() => dialogs.showFlow({ value: flow, onChange: onChange })}><EditIcon /></IconButton>
            <IconButton onClick={onDeletePressed} ><DeleteIcon /></IconButton>
            <IconButton onClick={() => dialogs.showFlow({ value: makeUnsavedDuplicate(flow), onChange: onChange })}><CopyIcon /></IconButton>
        </span>
    }</DialogsContext.Consumer>]
}

export const urlForPlanConfigPage = () => `/app/${getTenantId()}/plan/plan`

export default ()=>{
    const [data, setData] = React.useState(dataCache())
    const [showInactive, setShowInactive] = React.useState(false)
    const [isFetchingPlan, setFetchingPlan] = React.useState(false)
    const [accounts, setAccounts] = React.useState<AccountInfo[]>([])
    const [templates, setTemplates] = React.useState<TemplateInfoListItem[]>([])
    const [analysisWindow, setAnalysisWindow] = React.useState<AnalysisWindow>(resolveWindowOption(WindowOption.CONTEXTUAL_12_MONTHS))
    const [organization, setOrganization] = useState<Organization>()
    const [unplannedStats, setUnplannedStats] = React.useState<UnplannedAnalysis>()
    const [monthlyRatesByEntity, setMonthlyRatesByEntity] = React.useState<Record<string, MonthlyRatesForCurrency[]>>()
    const [flows, setFlows] = React.useState<Flow[]>()
    const [statsByPlannedItemId, setStatsByPlannedItemId] = React.useState<Record<string, HistoricalPlanItemAnalysis>>()

    const [isFetchingStats, setFetchingStats] = React.useState<boolean>()

    const [chartData, setChartData] = useState<BalancesByTick>()

    const filter:FlowFilter = makeIntraEntityFilter(organization, accounts)

    const getChartData = () => {
        fetchChartData({to:analysisWindow.to, from:analysisWindow.from})
            .then(setChartData)
            .catch(alert)
    }
    useEffect(getChartData, [analysisWindow.to])

    

    function fetchStats():Promise<any> {
        setFetchingStats(true)

        const dataFetches = Promise.all([
            getTenantOrganizationPromise(getTenantId()).then(setOrganization),
            fetchUnplannedStats(analysisWindow).then(setUnplannedStats),
            fetchMonthlyRatesByEntity(analysisWindow).then(setMonthlyRatesByEntity),
            fetchPlannedStats(analysisWindow).then(setStatsByPlannedItemId),
        ])

        return dataFetches
            .then(()=>setFetchingStats(false))
            .catch(alert)
    }

    const triggerPlanFetch = ():Promise<any>=>{
        setFetchingPlan(true)

        const dataFetches =  Promise.all([
            getFlowsPromise(getTenantId()).then(setFlows),
            data.accountsPromise().then(setAccounts),
            data.templatesPromise().then(setTemplates),
        ])
        
        return dataFetches
            .then(()=>setFetchingPlan(false))
            .catch(alert)
    }

    function refresh() {
        getChartData()
        triggerPlanFetch()
        fetchStats()
    }

    React.useEffect(refresh, [])

    const header = ["Name", "Amount", "Actual", "Realized", "Dir", "Repetition", "Start", "End", "Match Pattern", "Actions"]

    interface Foo {
        id: string
        name: string
        kind: string | undefined
        purpose: string | undefined
        category: string | undefined
    }
    const aa: Foo[] = accounts.map(a => { return { id: a.id, name: a.name, kind: a.kind, purpose: a.purpose, category: a.category } })
    const tt: Foo[] = templates.map(a => { return { id: a.id, name: a.name, kind: undefined, purpose: undefined, category: undefined } })
    const allAccountsAndTemplates: Foo[] = aa.concat(tt)
    const accountsAndTemplatesInNameOrder = _.sortBy(allAccountsAndTemplates, (aOrT) => (aOrT.kind || "AAAAAA-template") + "________" + (aOrT.category || "AAAAA-uncategorized") + "_____" + aOrT.name)
    const allFlowsEverywhere = flows;


    const stuff = accountsAndTemplatesInNameOrder.flatMap(function (accountOrTemplate, idx) {
        const account = _.find(data.accounts(), (a) => a.id == accountOrTemplate.id)
        const template = _.find(data.templates(), (t) => t.id == accountOrTemplate.id)

        const categoryName = account ? ((account.category && account.category !== "null") ? account.category + ": " : "") + account.name : (template ? template.name : "Unknown")

        

        let plannedView: ReactNode | undefined;
        const stuff = flowsInChronOrderBySequence(accountOrTemplate.id, flows ?? [])
        if (stuff) {

            let items = _.flatten(_.map(stuff, function (flowsInChronOrder, sequenceName) {
                const flowWidgets = _.map(flowsInChronOrder, function (flow) {
                    // console.log(sequenceName, flow);
                    return renderPlanScreenRow(statsByPlannedItemId, sequenceName, data, flow, refresh)
                });

                return flowWidgets
            }), true)

            plannedView = <>
                <div className="plan-category-subheader"><h4>The Plan</h4></div>
                <Table
                    header={header}
                    rows={items} />
            </>
        }

        let unplannedView: ReactNode | undefined;
        if (unplannedStats && account) {
            let accountId = account.id
            let statsForThisAccount = _.filter(unplannedStats.accountFlows, ([x, y]) => {
                return accountId == x.fromFundId || accountId == x.toFundId
            })
            if (statsForThisAccount.length > 0) {
                let unplannedRows = _.map(statsForThisAccount, ([x, y]) => {
                    let direction = accountId == x.fromFundId ? "to" : "from"
                    let otherAccountId = accountId == x.fromFundId ? x.toFundId : x.fromFundId
                    let otherAccount = _.find(data.accounts(), (account) => account.id == otherAccountId)
                    return [util.formatAmount(y.total, x.currency), direction, otherAccount ? otherAccount.name : "Unknown", y.numItems]
                })
                unplannedView = <>
                    <div className="plan-category-subheader"><h4>Unplanned</h4></div>
                    <Table
                        header={["Amount", "Direction", "Account", "Count"]}
                        rows={unplannedRows} />
                </>
            }
        }

        const MarkdownNote = (props:{children:string|undefined})=>{
            const {children} = props

            if(children && children.trim().length > 0){
                return <Alert color="info" style={{maxWidth:"500px"}}><MarkdownContent>{children}</MarkdownContent></Alert>
            }else{
                return <></>
            }
        }

        if (plannedView || unplannedView) {
            return [<div key={`foobar ${idx}`} className="plan-category">
                <div className="plan-category-header">
                    <h3 ><Link to={accountUrl(accountOrTemplate.id)}>{categoryName} {accountOrTemplate.kind != "Account" ? "[" + (accountOrTemplate.kind || "Template") + "]" : ""}</Link></h3>
                    <MarkdownNote>{accountOrTemplate.purpose}</MarkdownNote>
                </div>

                <PlanChart
                    focusAccountId={accountOrTemplate.id}
                    standardData={data}
                    showDetails={true}
                    data={chartData} />
                {plannedView}
                {unplannedView}
            </div>]
        } else {
            return []
        }
    });


    let unplannedStatsHeader = ["Entity", "Amount", "Direction", "Stub", "Count"]
    let sortedUnplannedStats = _.sortBy(unplannedStats ? unplannedStats.entityFlows : [], ([x, y]) => x.entityName)
    let unplannedStatsRows = _.map(sortedUnplannedStats, ([x, y]) => {
        return [x.entityName, util.formatAmount(y.total, x.currency), x.direction, x.stubId, y.numItems]
    })

    let monthlyStatsView: ReactNode;
    if (monthlyRatesByEntity) {

        monthlyStatsView = Object.keys(monthlyRatesByEntity).flatMap((entityId, idx) => {
            const stats = monthlyRatesByEntity[entityId]
            if (stats && stats.length > 0) {

                let amountsFor = (reverse: boolean, propertyAccessor: (q: MonthlyRatesForCurrency) => number): ReactNode | string => {
                    return util.joinReact(stats.map((stat, idx) => {
                        let value = propertyAccessor(stat)
                        return <span key={`stat${idx}`}>
                            {value && util.formattedColorAmount(reverse ? -1 * value : value, stat.currency)}
                        </span>
                    }), k => <span key={k}>", "</span>)
                }

                let header = ["", "In", "Out", "Net"]

                let makeRow = (name: string, statBucketAccessor: (f: MonthlyRatesForCurrency) => QuantitiesInOut) => {
                    return [
                        name,
                        amountsFor(false, (stat) => statBucketAccessor(stat).in),
                        amountsFor(true, (stat) => statBucketAccessor(stat).out),
                        amountsFor(false, (stat) => statBucketAccessor(stat).net)
                    ]
                }

                let monthlyStatsRows = [
                    makeRow("Planned", (stat) => stat.planned),
                    makeRow("Unplanned", (stat) => stat.unplanned),
                    makeRow("Optimistic", (stat) => stat.optimistic),
                    makeRow("Pessimistic", (stat) => stat.pessimistic)
                ]

                return [<Grid sm={4}>
                    <div key={`entity${entityId}-${idx}`} className="entity-trajectory-panel">
                    <h3>{entityId}</h3>
                    <Table
                        header={header}
                        rows={monthlyStatsRows} />

                </div>
                </Grid>]
            } else {
                return []
            }

        })
    }


    let patchAnalysisWindow = (patch: AnalysisWindow) => {
        setAnalysisWindow({
            ...analysisWindow,
            ...patch
        })
    }

    let categoryHeaderStyles:CSSProperties = {
        marginTop:"30px",
        paddingBottom:"10px",
        borderBottom:"1px solid lightgray",
        position:"sticky",
        top:"0px",
        background: "white",
    }

    const isRefreshing = isFetchingPlan || isFetchingStats

        return <>
            <FloatingActionBar noFloat={true} isRefreshing={isRefreshing} onRefresh={refresh}>
                <AnalysisWindowControls onChange={patchAnalysisWindow} window={analysisWindow} doUpdate={fetchStats} isFetching={isFetchingStats} />
                <Button onClick={() => setShowInactive(!showInactive)}>{showInactive ? "Hide Inactive" : "Show Inactive"}</Button>            
            </FloatingActionBar>

            <Box style={{ background:"white"}}>
                <PlanChart
                focusAccountId={undefined}
                standardData={data}
                showDetails={true}
                data={chartData} />
            </Box> 

            <Typography >
                <div style={categoryHeaderStyles}>
                    <h2>Monthly Trajectory</h2>
                    <p>Projections based on the monthly averages of planned & unplanned activity during the analysis window</p>
                </div>
                <Grid container spacing={2}>
                    {monthlyStatsView}
                </Grid>
                <div style={categoryHeaderStyles}>
                    <h2>Unplanned Overview</h2>
                    <p>Moves that have not been accounted for in the plan</p>
                    {unplannedStats ? (unplannedStats.numMoves + " moves, " + unplannedStats.numFlows + " flows") : ""}

                </div>
                <Table header={unplannedStatsHeader} rows={unplannedStatsRows} />
                <div style={categoryHeaderStyles}>
                    <h2>Accounts Overview</h2>
                    <p>Summary analysis of each account</p>
                </div>

                {(allFlowsEverywhere && statsByPlannedItemId && organization) ? 
                    <BudgetView 
                        accounts={accounts}
                        filter={filter}
                        flows={allFlowsEverywhere}
                        />: <CircularProgress/>}
                

                <div style={categoryHeaderStyles}>
                    <h2>Accounts</h2>
                    <p>Detailed analysis of each account</p>
                    
                </div>
                <div className="rows">{_.flatten(stuff, true)}</div>
            </Typography>
        </>
   

}

export const BudgetView = (props:{flows:Flow[], accounts:AccountInfo[], filter:FlowFilter})=>{
    const {flows, accounts, filter} = props

 
    return (<>
    <Table header={["Type", "Account", "Recurring Income", "Recurring Expenses", "Recurring Net"]} rows={_.sortBy(accounts, a=>`${a.kind} - ${a.name}`).map(account=>{
        const analysis = analyzeMonthlyBudgetForAccount(account.id, flows ?? [], new Date().getTime(), filter)
        const {net, incomes, expenses}  = analysis

        return [
            account.kind, 
            <AccountLink target="_blank" account={account}/>, 
            util.formattedTotals(incomes),
            util.formattedTotals(expenses),
            util.formattedTotals(net),
            <MonthlyStaticAmountView analysis={analysis} />,
        ]
        })} />
    </>)
}

export const AccountRecurringMonthlyBudgetAnalysisTable = (props:{analysis:AccountRecurringMonthlyBudgetAnalysis})=>{
    const {analysis} = props
    const {changes} = analysis
    const totalsRow:TableRowData = ["", util.formattedTotals(analysis.incomes), util.formattedTotals(analysis.expenses)]
    const dataRows = changes.map(({flow, amount, amountPerInstance, change})=>{                
        return [
            <Link to={urlForPlanElementPage(flow.id ?? "??")} target="_blank">{flow.name}</Link>,
            (amount >= 0) && util.formattedColorAmount(amount, flow.currency),
            (amount < 0) && util.formattedColorAmount(amount, flow.currency), 
            describeFrequencey(flow.schedule), 
            <>{round2Places(averageTimesPerMonth(flow.schedule))}</>,
            <>{util.formattedColorAmount(amountPerInstance, flow.currency)}</>,
            <>{util.formattedColorAmount(flow.amount, flow.currency)}</>]
    })
    return (
        <Stack spacing={1}>
            <Table
            header={["flow", "income", "expenses", "schedule", "avg times/mo", "amt to account", "plan item total",]}
            rows={dataRows.concat([totalsRow])} />    
            <Box>Net: {util.formattedTotals(analysis.net)}</Box>
        </Stack>
    )
}
const round2Places = (num:number) => Math.round((num + Number.EPSILON) * 100) / 100
const MonthlyStaticAmountView = (props:{analysis: AccountRecurringMonthlyBudgetAnalysis})=>{

    const [showDetails, setShowDetails] = useState(false)

    const analysis = props.analysis
    const {net, incomes, expenses} = analysis

   return (<>
    {/* <>
        {util.formattedTotals(expenses)}
        {util.formattedTotals(incomes)}
        {util.formattedTotals(net)}
    </> */}
    <Button onClick={()=>setShowDetails(!showDetails)}>explain</Button>
        {showDetails && <>
            <AccountRecurringMonthlyBudgetAnalysisTable analysis={analysis} />        
        </>}
   </>)
}