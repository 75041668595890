
export interface Organization {
    name: string
}

export interface TenantInfo {
    tenantId: string
    organization: Organization | undefined
}

export interface AccountInfo {
    id: string,
    category: string | undefined,
    name: string,
    purpose: string,
    notes: string | undefined,
    kind: string,
    balances: Record<string, number>,
    latestMeterValue?: number | undefined
}

export interface TemplateInfoListItem {
    id: string
    name: string
    link: string
    pattern: string
}

export const dateIsAfter = (d: PDate, reference: PDate): boolean => {
    return (
        d.year > reference.year
        ||
        (d.year == reference.year && d.month > reference.month)
        ||
        (d.year == reference.year && d.month == reference.month && d.day > reference.day)
    )
}

export interface PDate {
    year: number
    month: number
    day: number
}
export interface MonthDate {
    month: number
    day: number
}
export interface Frequency {
    type: "once" | "weekly" | "monthly" | "yearly"
}

export interface BoxedFrequency extends Frequency {
    first: PDate
    last: PDate
}

export interface Once extends Frequency {
    when: PDate
}
export interface Weekly extends Frequency, BoxedFrequency {
    skip: number
    daysOfWeek: string[]
}
export interface Monthly extends Frequency, BoxedFrequency {
    skip: number
    daysOfMonth: number[]
}
export interface Yearly extends Frequency, BoxedFrequency {
    skip: number
    dates: MonthDate[] | undefined
}

export interface Flow {
    id?: string
    name: string,
    notes: string,
    enabled: boolean,
    amount: number,
    currency: string,
    toFromAccount: string,
    accountOrTemplate: string,
    schedule: Frequency,
    duration: FlowDurationSpec | undefined,
    matchPattern?: string | undefined
    template: EmbeddedSplitPattern | undefined
}


export type FlowDurationSpecType = "instantaneous" | "days" | "until-next"
export interface FlowDurationSpec {
    type: FlowDurationSpecType
}

export interface UntilNext extends FlowDurationSpec {
    type: "until-next"
}

export interface Instantaneous extends FlowDurationSpec {
    type: "instantaneous"
}

export interface DaysSpec extends FlowDurationSpec {
    type: "days"
    days: number
}

export interface UserInfoDto {
    userId: string
}

export interface PhysicalAccountInfoDto {
    id?: string
    name: string,
    config: PhysicalAccountConfiguration | undefined
}

export interface PhysicalAccountSummaryDto {
    id?: string
    name: string,
    config: PhysicalAccountConfiguration | undefined
    balances: Record<string, number>
}

export interface AccountListingInfo {
    description: string
    specifics: AccountListingInfoSpecifics | undefined
}
export interface AccountListingInfoSpecifics {
    accountDetails: AccountListingInfoDetails | undefined
}
export interface AccountListingInfoDetails {
    bankId: string | undefined,
    accountNumber: string | undefined,
    routingNumber: string | undefined,
    accountType: string | undefined
}

export interface ConnectionConfigDto {
    name: string,
    id: string,
    remote: ConnectionSpecificationDto | undefined,
    accounts: AccountSyncConfig[]
}

export interface AccountSyncConfig {
    isEnabled: boolean,
    physicalAccountId: string,
    accountNumber: string,
    whenToStartRecordingRelative: number | undefined,
    whenToStartRecordingAbsolute: number | undefined,
    whenToStopRecording: number | undefined,
    memo: string | undefined
}


export interface ConnectionSpecificationDto {
    type: "ofx" | "plaid"
}


export interface OfxConnectionSpecificationDto extends ConnectionSpecificationDto {
    type: "ofx"
    url: string
    organization: string | undefined
    financialInstitutionId: string
    clientId: string | undefined
    username: string
    password: string | undefined
}

export interface PlaidConnectionSpecificationDto extends ConnectionSpecificationDto {
    type: "plaid"

    item_id: string,
    public_token: string | undefined
}




// export type ConnectionSpecificationDto = OfxConnectionSpecificationDto | PlaidConnectionSpecificationDto


// export interface OfxConnectionSpecificationDto {
//     type:"ofx"
//     url:string,
//     organization:string|undefined
//     financialInstitutionId:string
//     clientId:string|undefined
//     username:string
//     password:string|undefined
// }

// export interface PlaidConnectionSpecificationDto {
//     type:"plaid"
//     item_id:string,
//     public_token:string|undefined
// }


export interface AccountSyncConfig{
     isEnabled:boolean,
     physicalAccountId:string
     accountNumber:string
     whenToStartRecordingRelative:number|undefined
     whenToStartRecordingAbsolute:number|undefined
     whenToStopRecording:number|undefined
     memo:string|undefined
}

export interface ConnectionConfigDto{
     name:string,
     id:string,
     remote:ConnectionSpecificationDto|undefined,
     accounts:AccountSyncConfig[]
}


export interface FundMoveToPhysicalItemMapping {
    physicalAccountId: string,
    physicalItemId: string,
    amount: number
}

export interface PlannedItemHandle {
    sequenceId: string
    instanceId: string
}
export interface FundsFlow {
    fromFundId: string
    toFundId: string
    amount: number
}

export interface WarningDismissal {
    tagId: string
    warningText: string
    rationale: string
    whenDismissed: number
}

export interface FundMove {
    id: string | undefined, // hack for ui to edit things
    memo: string,
    when: number,
    flows: FundsFlow[],
    currency: string,
    physicalMappings: FundMoveToPhysicalItemMapping[]
    plannedItemId: PlannedItemHandle | undefined
    warningDismissals: WarningDismissal[] | undefined

    whenEnds: number | undefined

    //oldFromFundId old !!  -- don't use
    fromFundId?: string | undefined,
    //oldToFundId old !!  -- don't use
    toFundId?: string | undefined,
    //oldAmount old !!  -- don't use
    amount?: number | undefined
}

export interface PhysicalItemToFundMoveAllocation {
    fundMoveId: string
    amount: number
}
export interface PhysicalItemToFundMoveAllocations {
    memo: string,
    allocations?: PhysicalItemToFundMoveAllocation[] | undefined,
    // old !!  -- don't use
    split?: Record<string, number> | undefined
}

export interface ResolvedAsNonMaterial {
    reason: string
}
export interface ItemSourceInfo {
    importId: string,
    whenImported: number,
    sourceId: string
}
export interface PhysicalItem {

    id: string,
    source: ItemSourceInfo | undefined,
    ref: string,
    whenPosted: number,
    amount: number,
    currency: string,

    // Memos
    memo: string,
    structuredMemo: Record<string, string>,
    externalData: any | undefined,

    // Mapping
    mappingMemo: string | undefined,
    nonMaterialResolution: ResolvedAsNonMaterial | undefined,
    mappings: PhysicalItemToFundMoveAllocations | undefined,
    otherSideOfTransfer: string | undefined,
    otherAccount: string | undefined    
    pendingTransferId:  string | undefined    
}
export interface ItemInfo {
    handle: PhysicalItemHandle
    item: PhysicalItem
    moves: FundMove[]
}
export interface PhysicalAccountDto {
    id: string
    name: string
    items: ItemInfo[]
    config: PhysicalAccountConfiguration | undefined
}

export interface PlannedFundActualization {
    isComplete: boolean
    actuals: FundMove[]
}
export interface PlannedFundMove {
    id: PlannedItemHandle
    planned: FundMove
    actualization: PlannedFundActualization | undefined
}

// export interface MappingDto {
//     memo: string
//     splits?: PhysicalItemToFundMoveAllocations | undefined
//     otherSideOfTransfer?: string | undefined
//     otherAccountId?: string | undefined
//     nonMaterialResolution?: ResolvedAsNonMaterial | undefined,
//     existingMoveId?: string | undefined  
//     pendingTransferId?: string | undefined 
// }

export interface TransferOptionDto {
    item: PhysicalItem
    accountId: string
    accountName: string
}
export interface Bucket {
    id: string
    category: string | undefined
    name: string
    purpose: string
    kind: string
    meterUnits: string | undefined
    chargeCurrency: string | undefined
    chargeRate: number | undefined
}



export interface BucketState {
    id: string
    balances: Record<string, number>
    history: BalanceAdjustment[]
}

export interface BalanceAdjustmentPlanAlignment {
    plannedItemId: PlannedItemHandle
    description: string
    isComplete: boolean
}
export interface BalanceAdjustmentPhysicalAlignment {
    amount: number
    currency: string
    description: string
    whenPosted: number
    handle: PhysicalItemHandle
}
export interface BalanceAdjustment {

    itemId: string,
    memo: string,
    when: number,
    qty: number,
    currency: string,
    otherAccountId: string,
    physicalAlignment: BalanceAdjustmentPhysicalAlignment[],
    planAlignment: BalanceAdjustmentPlanAlignment | undefined
}


export interface StatementInfo {
    id: string,
    accountId: string,
    name: string,
}

export interface Statement {
    id: string,
    accountId: string,
    name: string,
    notes: string | undefined,
    itemIds: string[],
    itemIdsWithCategory: Record<string, string> | undefined
}

export interface StatementStats {
    totals: Record<string, number>
    totalsIn: Record<string, number>
    totalsOut: Record<string, number>
    totalsByCategory: Record<string, Record<string, number>>
    countsByCategory: Record<string, Record<string, number>>
}

export interface StatementWithStats {
    statement: Statement,
    stats: StatementStats,
}

export interface SplitPattern {
    split: Record<string, number>
    remainder?: string | undefined
}
export interface EmbeddedSplitPattern extends SplitPattern {

}

export interface Template extends SplitPattern {
    id: string,
    name: string,
    pattern: string,
    kind: string,
}

export interface AnalysisTag {
    tagId: string | undefined
    level: string
    note: string
}

export interface FullPlannedMove{
     planned:PlannedFundMove,
     unActualization:FullMoveDetails|undefined,
     actualizations:FullMoveDetails[]|undefined,
}

export interface FullPhysicalItemDetails {
    accountId: string,
    accountName: string,
    item: PhysicalItem
}
export interface FullPlannedMoveDetails {
    plannedFundMove: PlannedFundMove,
    plannedTotal: number,
    isComplete: boolean
}

export interface FullMoveDetails {
    when: number,
    whenEnds: number | undefined,
    totalPhysical: number,
    total: number,
    currency: string,
    actual: FundMove | undefined,
    planned: FullPlannedMoveDetails | undefined,
    entityIds: [string, string][],
    physicalItems: FullPhysicalItemDetails[],
    pendingPhysicalItems: PendingTransfer[],
    analysis: AnalysisTag[]
}
export interface FullMoveDetailsPage {

    totalPhysicalAmountMissing: number,
    items: FullMoveDetails[],
    pageSize: number,
    pageNum: number,
    numMoves: number
}

export interface MappingOption {
    planned: PlannedFundMove | undefined
    move: FundMove | undefined
    items: FullPhysicalItemDetails[] 
}
export interface PhysicalItemHandle {
    accountId: string,
    itemId: string
}

// export interface MappingSelection {
//     memo: string
//     toMove: MoveReference | undefined
//     mapping: MappingSpec | undefined
//     spendId: string | undefined
// }

export interface UserInfoDto {
    userId: string
}
export interface CurrencyDirection {
    currency: string
    amount: number
}
export interface DirectionResponse {
    annual: CurrencyDirection[]
}
export interface ConnectionCredentials {
    username: string
    password: string
}
export interface MappingRecommendation {
    templateId: string
    mappings: [string, number][]
}
export interface Recommendation {    
    item:PhysicalItemHandle
    mapping:MappingSpec
}

export interface Measurement {
    qty: number
    units: string
}
export interface UtilityChargeDto {
    id: string,
    accountId: string,
    when: number,
    memo: string,
    previousReading: number | undefined
    meterReading: number,
    meterUnits: string,
    charge: Measurement | undefined
    mappings: PhysicalItemToFundMoveAllocations | undefined
}

export interface ConnectionsListItem {
    id: string
    name: string
}

export interface PhysicalAccountItemsPatch {
    itemIdsToDelete: String[]
}

export type PhysicalConfigType = "line-of-credit"
export interface PhysicalAccountConfiguration {
    type: PhysicalConfigType
}

export interface LineOfCredit extends PhysicalAccountConfiguration {
    type: "line-of-credit"
    currency: string
    creditAmount: number
}

export interface MoveReference {
    planned: PlannedItemHandle | undefined
    existing: string | undefined
}

export interface PendingTransferDto {
    ref: string
    whenPosted: number
    amount: number
    currency: string
}

export interface PendingTransfer{
    who:string|undefined
    id:string
    moveId: string
    moveAllocations: Record<string, Number> | undefined
    memo:string|undefined
    actualTransferId:string|undefined
    physicalAccountId:string|undefined
    ref: string
    whenPosted: number
    amount: number
    currency: string
}
export interface ContextualizedPendingTransfer{
    pending:PendingTransfer
    moves:PendingTransferFundMoveAllocation[]
}
export interface PendingTransferFundMoveAllocation{
    move:FundMove,
    amount:number,
}
export interface SpendDto {
    memo: String  
    mapping: MappingSpec | undefined,
    physicalAccountId: string | undefined
    transfer: PendingTransferDto
}

export interface Template {
    id: string,
    name: string,
    pattern: string,
    kind: string,
    split: Record<string, number>,
    remainder: string | undefined
}




export interface MappingState {
    type: "transfer" | "non-material" | "existing-move"
    memo: string,
}

export interface MappedAsTransfer extends MappingState {
    type: "transfer"
    otherSideOfTransfer: string,
    otherAccountId: string,
}

export interface MappedAsNonMaterial extends MappingState {
    type: "non-material"
    nonMaterialResolution: ResolvedAsNonMaterial,
}

export interface MappedToExistingMove extends MappingState {
    type: "existing-move"
    pendingTransferId: string | undefined,
    splits: PhysicalItemToFundMoveAllocations,
}



// TODO: update to match the new shape of this
export interface MappingSpec {
    type: "new-move" | "transfer" | "non-material" | "to-moves" | "pending-transfer"
    memo: string
}

// export interface MapToNewMove extends MappingSpec {
//     type: "new-move"
//     splits: PhysicalItemToFundMoveAllocations,
// }

export interface MapAsTransfer extends MappingSpec {
    type: "transfer"
    otherSideOfTransfer: string,
    otherAccountId: string,
}

export interface MapAsNonMaterial extends MappingSpec {
    type: "non-material"
    nonMaterialResolution: ResolvedAsNonMaterial,
}

// export interface FooMapToSingleExistingMove extends MappingSpec {
//     type: "foo-existing-move"
//     existingMoveId: string,
// }

export interface MapToMoves extends MappingSpec {
    type: "to-moves",
    allocations: MoveMappingAllocation[],
}

export interface MoveMappingAllocation {  
    move:MoveSpec,
    amount:number,
}
export interface MapToPendingTransfer extends MappingSpec {
    type: "pending-transfer"
    pendingTransferId: string,
}

export const asMapToPendingTransfer = (mapping:MappingSpec)=> mapping.type == "pending-transfer" ? mapping as MapToPendingTransfer : undefined
export const asTransfer = (mapping:MappingSpec)=> mapping.type == "transfer" ? mapping as MapAsTransfer : undefined
export const asNonMaterial = (mapping:MappingSpec)=> mapping.type == "non-material" ? mapping as MapAsNonMaterial : undefined
// export const asMapToNewMove = (mapping:MappingSpec) => mapping.type == "new-move" ? mapping as MapToNewMove : undefined
// export const asMapToExistingMoves = (mapping:MappingSpec) => mapping.type == "foo-existing-move" ? mapping as FooMapToSingleExistingMove : undefined
export const asMapToMoves = (mapping:MappingSpec) => mapping.type ==  "to-moves" ? mapping as MapToMoves : undefined



export interface MoveSpec {
    type:"new-move"|"existing-move"|"plan-item"
}
  
export interface NewMoveSpec extends MoveSpec {
    type: "new-move",
    newMove: FundMove,
}

export interface ExistingMoveRef extends MoveSpec {
    type: "existing-move",
    existingId: string
}

export interface PlanItemRef extends MoveSpec {
    type: "plan-item",
    handle: PlannedItemHandle
}

export const asNewMoveSpec = (mapping:MoveSpec) => mapping.type ==  "new-move" ? mapping as NewMoveSpec : undefined
export const asExistingMoveRef = (mapping:MoveSpec) => mapping.type ==  "existing-move" ? mapping as ExistingMoveRef : undefined
export const asPlanItemRef = (mapping:MoveSpec) => mapping.type ==  "plan-item" ? mapping as PlanItemRef : undefined


export interface Affordability {
    isAffordable:Boolean, 
    analysis:String
}
export interface MoveChangeResult {
    move:FundMove, 
    affordability:Affordability
}

export interface CheckpointInfo {
    id: string,
    name: string | undefined,
    who: string | undefined,
    permanent: boolean,
    previousVersion: string | undefined,
    timestamp: number | undefined,
    isCurrent: boolean,
    reason: string | undefined
}

export interface TimeRange{
    from:number
    until:number
}

export interface Amount{
    currency:string
    quantity:number
}

export interface ProfitAndLossPeriod{
    name:String,
    timeRange:TimeRange,
    income:Amount[],
    expense:Amount[],
    liabilities:Amount[],
    promotionalCredits:Amount[],
    external:Amount[],
    other:Amount[]
}

export interface ProfitAndLossReport {
    periods: ProfitAndLossPeriod[]
}


export interface FlowForMove{
    f:FundsFlow, 
    m:FundMove
}

export interface CurrencyBetweenEntities{
    entityName:string
    currency:string
    direction:string
    stubId:string
}
export interface CurrencyBetweenAccounts{
    fromFundId:string
    toFundId:string
    currency:string
}
export interface FlowSetStats{
    numItems:number
    total:number
}

export interface UnplannedAnalysis{
    numMoves:number
    numFlows:number
    entityFlows:[CurrencyBetweenEntities, FlowSetStats][]
    accountFlows:[CurrencyBetweenAccounts, FlowSetStats][]
}


export interface QuantitiesInOut { in: number, out: number, net: number }
export interface MonthlyRatesForCurrency {
    currency: string,
    planned: QuantitiesInOut,
    unplanned: QuantitiesInOut,
    pessimistic: QuantitiesInOut,
    optimistic: QuantitiesInOut
}


export interface MovesStats {
    numItems: number
    averageAmountsByCurrency: Record<string, number>
}
export interface HistoricalPlanItemAnalysis {
    planItemId: string
    expected: MovesStats
    actual: MovesStats
}


export interface AccountBalances {
    accountId: string,
    balances: Record<string, number>
}
export interface Tick {
    name: string
    startInclusive: number
    endExclusive: number
}

export interface AccountBalancesForTick {
    tick: Tick
    balances: AccountBalances[]
}
export type BalancesByTick = AccountBalancesForTick[]


export interface PhysicalItemPatch{
    mappingMemo:string|undefined
}
