import React, { useEffect, useState } from 'react'
import util, { getTenantId } from './util'
import http, { HttpMethodName } from './cj/HttpClient'
import AccountSelect from './AccountSelect'
import FrequencyWidget from './FrequencyWidget'
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { AccountInfo, DaysSpec, Flow, FlowDurationSpec, FlowDurationSpecType, Frequency, Instantaneous, SplitPattern, UntilNext } from './api'
import CurrencyAmountSelector from './CurrencyAmountSelector'
import SplitPatternWidget from './SplitPatternWidget'
import { Label } from '@mui/icons-material'
import MarkdownEditor from './MarkdownEditor'


function getJson<T>(url: string, onResult: (result: T) => void) {
    http({
        url: url,
        method: "GET",
        onResponse: function (response) {
            if (response.status == 200) {
                onResult(JSON.parse(response.body))
            } else {
                alert("Error!  Server said:" + response.body);
            }
        }
    },
        { async: true });
}

export default (props: { flow: Flow, mode?: string, onComplete: (f: Flow) => void }) => {
    const { onComplete } = props
    const [accounts, setAccounts] = React.useState<AccountInfo[]>()
    const [isWorking, setIsWorking] = React.useState<boolean>(false)
    const [flow, setFlow] = React.useState<Flow>(props.flow)
    const [schedule, setSchedule] = useState<Frequency | undefined>(props.flow.schedule)

    const loadData = () => {

        getJson<AccountInfo[]>(`/api/tenants/${getTenantId()}/accounts`, (accounts) => {
            setAccounts(accounts)
        })
    }

    React.useEffect(loadData, [])

    const readyForSave = flow && schedule

    function onSubmit() {
        setIsWorking(true)


        if (readyForSave) {

            const newFlow: Flow = {
                ...flow,
                schedule: schedule
            }

            var method: HttpMethodName, url: string;

            if (newFlow.id) {
                method = "PUT";
                url = `/api/tenants/${getTenantId()}/plan/elements/` + newFlow.id;
            } else {
                method = "POST";
                url = `/api/tenants/${getTenantId()}/plan/elements/`;
            }

            http({
                url: url,
                method: method,
                data: JSON.stringify(newFlow),
                onResponse: (response) => {
                    setIsWorking(false)
                    if (response.status == 200) {
                        console.log(`FLOW: did`, newFlow)
                        onComplete(newFlow)
                    } else {
                        alert("Error!  Server said:" + response.body);
                    }
                }
            })
        }
    }
    console.log("Showing splits for flow", flow)

    const splits = Object.values(flow.template?.split ?? {})
    const total = splits.reduce((accum, next) => (accum ?? 0) + next, 0)

    if (!accounts) {
        return <CircularProgress />
    } else {
        return (<div className="flow-projection-editor">
            <div>
                <Stack spacing={2}>
                    {/* <FormControlLabel control={<Checkbox checked={flow.enabled} onChange={e => setFlow({ ...flow, enabled: e.target.checked })} />} label="Enabled" /> */}
                    <TextField variant="outlined" label="name" value={flow.name} onChange={e => setFlow({ ...flow, name: e.target.value })} />
                    <MarkdownEditor
                        editable={true}
                        label="Notes"
                        value={flow.notes}
                        onChange={v => setFlow({ ...flow, notes: v})}
                    />
                    <CurrencyAmountSelector value={flow} onChange={v => setFlow({ ...flow, ...v })} />
                    <FormControl>
                        <FormLabel id="from-label">From</FormLabel>
                        <Box style={{ marginTop: "10px" }}>
                            <AccountSelect label="account" accounts={accounts} selection={flow.toFromAccount} onChange={e => setFlow({ ...flow, toFromAccount: e.target.value })} />
                        </Box>
                    </FormControl>

                    <FormControl>
                        <FormLabel id="to-label">To  {splits.length > 1 && <>({util.formattedColorAmount(flow.amount - total, flow.currency)} remaining)</>}</FormLabel>
                        <Stack direction="row">
                            <SplitPatternWidget
                                value={resolveSplits(flow.accountOrTemplate, flow.template, accounts)}
                                disabled={false}
                                accounts={accounts}
                                currency={flow.currency}
                                onChange={v => {
                                    setFlow({
                                        ...flow,
                                        accountOrTemplate: "template",
                                        template: v
                                    })
                                }} />
                        </Stack>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Schedule</FormLabel>
                        <FrequencyWidget spec={flow.schedule} onChange={(e) => setSchedule(e)} />
                    </FormControl>


                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Duration</FormLabel>
                        <DurationSelect value={flow.duration} onChange={v => setFlow({ ...flow, duration: v })} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Automation</FormLabel>
                        <TextField label="Match Pattern" value={flow.matchPattern} onChange={e => setFlow({ ...flow, matchPattern: e.target.value })} />
                    </FormControl>
                    <Stack direction="row-reverse">
                        <Button variant="contained" onClick={onSubmit} disabled={isWorking || !readyForSave}>Submit</Button>
                    </Stack>
                </Stack>
            </div>

        </div>)
    }
}

const daysOrNone = (v: FlowDurationSpec) => (v.type == "days") ? v as DaysSpec : undefined

const DurationSelect = (props: { value: FlowDurationSpec | undefined, onChange: (newValue: FlowDurationSpec | undefined) => void }) => {
    const { value, onChange } = props
    const [type, setType] = React.useState<FlowDurationSpecType>(value?.type ?? "instantaneous")
    const [days, setDays] = React.useState(value && daysOrNone(value)?.days)

    const readValue = (): FlowDurationSpec | undefined => {
        if (type == "instantaneous") {
            return {
                type: "instantaneous"
            } as Instantaneous
        } else if (type == "days") {
            return {
                type: "days",
                days: days
            } as DaysSpec
        } else if (type == "until-next") {
            return {
                type: "until-next"
            } as UntilNext
        } else {
            return undefined
        }
    }

    const sendUpdate = () => {
        const v = readValue()
        if (JSON.stringify(v) != JSON.stringify(value)) {
            console.log("Frequency changed")
            onChange(v)
        }
    }

    useEffect(sendUpdate, [type, days])

    return (<>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={type}
            onChange={e => {
                setType(e.target.value as any)
            }}
            name="radio-buttons-group"
        >
            {/* <FormControlLabel value="instantaneous" control={<Radio />} label="Instantaneous" /> */}

            <Stack direction="row" spacing={2}><Radio value="instantaneous" /><Typography variant='caption'>Instantaneous</Typography> </Stack>
            <Stack direction="row" spacing={2}><Radio value="until-next" /><Typography variant='caption'>Until Next Instance</Typography> </Stack>
            <Stack direction="row" spacing={2}><Radio value="days" /><TextField value={days} onChange={e => {
                setDays(parseInt(e.target.value))
            }} disabled={type != "days"} type="number" label="days" /></Stack>

            {/* <FormControlLabel value="days" control={<Radio/>} label="Days" /> {type=="days"  && <TextField type="number" />} */}
            {/* <FormControlLabel value="days" control={<Stack direction="row" spacing={1} style={{marginRight:"10px"}}><Radio /><TextField type="number" /></Stack>} label="Days" /> */}
        </RadioGroup>
        {/* <FrequencyWidget spec={flow.schedule} onChange={(e: Frequency) => setFlow({ ...flow, schedule: e })} /> */}
    </>
    )
}

const defaultSplits = (accounts: AccountInfo[]): SplitPattern => {
    const firstAccount = accounts[0]

    return {
        split: {
            [firstAccount.id]: 0
        },
        remainder: firstAccount.id
    }
}

const fixedIfEmptyOrMissing = (input: SplitPattern | undefined, accounts: AccountInfo[]): SplitPattern => {
    const value = (input?.split && Object.keys(input.split).length > 0) ? input : defaultSplits(accounts)
    return value
}

const resolveSplits = (accountId: string | undefined, input: SplitPattern | undefined, accounts: AccountInfo[]): SplitPattern => {
    if (!input && accountId) {
        return {
            split: { [accountId]: 0 },
            remainder: accountId
        }
    } else {
        return fixedIfEmptyOrMissing(input, accounts)
    }
}
