
export const KIND_STUB = "Stub"
export const KIND_UTILITY = "Utility"
export const KIND_ALIEN = "Alien"
export const KIND_FUND = "Account"

const kindLabels:Record<string, string> = { 
    [KIND_FUND]: "Funds", 
    [KIND_ALIEN]: "Aliens", 
    [KIND_UTILITY]: "Utilities", 
    [KIND_STUB]: "Stubs" 
}
export default {
    accountKindDescription: function (kind:string):string {
        console.log("kindLabels, ", kindLabels)
        return kindLabels[kind] || kind
    }
}
