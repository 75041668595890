import React from 'react'
import $ from 'jquery'
import _ from 'underscore'
import { AccountInfo } from './api'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { typeLabeForAccount } from './util'

export default (props: { label: string, accounts: AccountInfo[], selection: string, disabled?: boolean, onChange: (event: SelectChangeEvent<string>) => void }) => {
    const { label, disabled, onChange } = props
    const [selection, setSelection] = React.useState(props.selection)

    let makeLabel = (account: AccountInfo) => {



        var categoryPrefix = account.category ? (account.category + " -- ") : "";
        return '[' + typeLabeForAccount(account) + "] " + categoryPrefix + account.name;
    }

    var accountsByCategory = _.sortBy(props.accounts, function (account) {
        var prefix = (account.category == null) ? "1" : ""; // put the uncategorized items at the top
        return prefix + makeLabel(account);
    });
    var accountsInKindAndCategoryOrder = _.flatten(_.values(_.groupBy(accountsByCategory, "kind")).reverse());

    console.log("Account selected is ", selection, "disabled?", disabled)

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelection(event.target.value);
        onChange(event);
    }

    return (<>
        <FormControl fullWidth>
            <InputLabel >{label}</InputLabel>
            <Select label={label}
                value={selection}
                disabled={disabled === true}
                onChange={handleChange}
            >
                {_.map(accountsInKindAndCategoryOrder, (account) => <MenuItem value={account.id}>{makeLabel(account)}</MenuItem>)}
            </Select>
        </FormControl>
    </>)
}


