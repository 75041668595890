import FlowsMapper from "./FlowsMapper"
import { AccountInfo, FundMove, FundsFlow, MapToMoves, MappingSpec, MoveReference, NewMoveSpec, PhysicalAccountSummaryDto, PhysicalItem, PlannedFundMove, PlannedItemHandle, SpendDto, UserInfoDto, asMapToMoves } from "./api"
import { addSpend, asNewFundMove, getAccounts, getPhysicalAccountSummaries, getPlanedItem, getPlanedItemPromise } from "./api-actions"
import { useEffect, useState } from "react"
import CurrencyAmountSelector from "./CurrencyAmountSelector"
import { CurrencyAmount } from "./CurrencyAmountField"
import { Alert, Box, Button, CircularProgress, Divider, FormControl, FormLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tab, Typography } from "@mui/material"
import FundMovePanel from "./FundMovePanel"
import data from "./data"
import Tabs from '@mui/material/Tabs';
import MarkdownEditor from "./MarkdownEditor"
import LiveSplitFlowsEditor from "./LiveSplitFlowsEditor"
import StandardFlowsEditor from "./StandardFlowsEditor"
import PhysicalAccountSelect from "./PhysicalAccountSelect"
import MoveSelector, { MoveSelection } from "./MoveSelector"
import MappingEditor from "./MappingEditor"
import { mapToExistingMove, mapToPlannedMove } from "./mappingLogic"
import { useUrlParams } from "./util"
import PlannedFundMovePanel, { PlannedFundMoveViewer } from "./PlannedFundMovePanel"

export default (props: { user: UserInfoDto }) => {
    const [success, setSuccess] = useState(false)

    return <>
        <Stack spacing={3} style={{ padding: "20px" }}>
            <Typography variant="h4">Safe Spend</Typography>
            {!success && <SpendMaker onSuccess={() => setSuccess(true)} />}
            {success && <>
                <Alert color="success">Spend Accepted!</Alert>
                <Stack direction="row-reverse"><Button variant="outlined" onClick={() => setSuccess(false)}>Record Another</Button></Stack>
            </>
            }
        </Stack>
    </>
}

const SpendMappingSelector = (props:{accountId:string|undefined, memo:string, amount:CurrencyAmount, accounts:AccountInfo[], onResults:(mappingFactory:(allocatedAmount:CurrencyAmount)=>MappingSpec)=>void})=>{
    const {accountId, memo, amount, accounts, onResults} = props
    const [expected, setExpected] = useState<MoveSelection>()
    const [unexpected, setUnexpected] = useState<FundsFlow[]>()
    const [d, setData] = useState(data())
    const [path, setPath] = useState<"expected" | "unexpected">("expected")
    const [tabIdx, setTabIdx] = useState(0)

    const handleUpdate = ()=>{

        if(expected || unexpected){
            onResults((amount)=>{
                if (unexpected) {
                    const move:NewMoveSpec = {
                        type: "new-move",
                        newMove: asNewFundMove({
                            memo: "",
                            when: new Date().getTime(), // TODO: this needs to be defined? Or, is ~now ok?
                            currency: amount.currency,
                            flows: unexpected,
                        })
                    }
                    return {
                        type: "to-moves",
                        memo: memo,
                        allocations: [{
                            move:move,
                            amount:amount.amount
                        }],
                    }
                } else if(expected){
                    if(expected.move){
                        return mapToExistingMove(expected.move, amount)
                    }else if(expected?.planned){
                        return mapToPlannedMove(expected.planned.id, amount)
                    }else{
                        throw "Not sure what this means .. shouldn't get here"
                    }
                }else{
                    throw "Not sure what this means .. shouldn't get here"
                }
            })
        }

    }

    useEffect(handleUpdate, [amount, expected, unexpected])


    const fakePhysicalItem: PhysicalItem = {
        id: "new",
        source: undefined,
        ref: "whatever",
        whenPosted: 0,
        amount: amount.amount,
        currency: amount.currency,

        memo: "",
        structuredMemo: {},
        externalData: undefined,

        mappingMemo: undefined,
        nonMaterialResolution: undefined,
        mappings: undefined,
        otherSideOfTransfer: undefined,
        otherAccount: undefined,
        pendingTransferId: undefined,
    }

    const tabs = {
        "Moves": (accountId: string) => <StandardFlowsEditor
            currency={amount.currency}
            accounts={accounts}
            flows={unexpected || [{
                fromFundId: accounts.filter(a => a.kind == 'Account' && !a.name.startsWith("_"))[0]?.id,
                toFundId: "generic-income-expense",
                amount: amount.amount
            }]}
            onChange={setUnexpected} />,
        "Quick": (accountId: string) => <LiveSplitFlowsEditor
            accounts={accounts}
            flows={unexpected || []}
            currency={amount.currency}
            onChange={setUnexpected} />
    }

    return (<>
        {!expected && <Stack spacing={2}>
            {!accountId && <Alert color="info">Select an account to see options</Alert>}

            {(accountId && path == "expected") && <>

                <MoveSelector
                    foreignAccountId={accountId}
                    item={fakePhysicalItem}
                    accounts={accounts}
                    onUnexpectedSelected={() => setPath("unexpected")}
                    onSelection={v => {
                        setExpected(v)
                        setUnexpected(undefined)
                    }} />
            </>
            }

            {(accountId && path == "unexpected") && <>
                <Tabs
                    value={tabIdx}
                    onChange={(e, idx) => { setTabIdx(idx) }}>
                    {Object.keys(tabs).map(name => <Tab key={name} label={name} />)}
                </Tabs>
                <Box style={{ margin: "15px" }}>
                    {Object.values(tabs)[tabIdx](accountId)}
                </Box>
            </>
            }


        </Stack>
        }
        {(expected) && <FundMovePanel move={expected.planned?.planned || expected.move!!} getAccount={d.account} handleChange={() => { }} />}
    </>)

}

export const SpendMaker = (props: { onSuccess: () => void }) => {
    const { onSuccess } = props
    const [physicalAccounts, setPhysicalAccounts] = useState<PhysicalAccountSummaryDto[]>()
    const [accounts, setAccounts] = useState<AccountInfo[]>()
    const [accountId, setAccountId] = useState<string>()
    const [memo, setMemo] = useState("")
    const [amount, setAmount] = useState<CurrencyAmount>({
        currency: "USD",
        amount: 0
    })
    const [error, setError] = useState<string>()

    const [plannedItemFromUrl, setPlannedItemFromUrl] = useState<PlannedFundMove>()

    const [{plannedSequenceId, plannedInstanceId}] = useUrlParams()

    const plannedItemHandle:PlannedItemHandle | undefined = (plannedInstanceId && plannedSequenceId) ? {
        sequenceId: plannedSequenceId,
        instanceId: plannedInstanceId,
    } : undefined

    useEffect(()=>{
        plannedItemHandle && getPlanedItemPromise(plannedItemHandle).then(setPlannedItemFromUrl)
    }, [plannedItemHandle])

    useEffect(() => {
        getPhysicalAccountSummaries(accounts => {
            setPhysicalAccounts(accounts)
        })
        getAccounts(setAccounts)
    }, [])

    console.log("Account id is ", accountId)
    if (!physicalAccounts || !accounts) {
        return <CircularProgress />
    }


    const doIt = (spec:MappingSpec) => {

        const spend: SpendDto = {
            memo: memo,
            mapping: spec,
            physicalAccountId: accountId,
            transfer: {
                ref: "",
                whenPosted: new Date().getTime(),
                amount: amount.amount,
                currency: amount.currency,
            }
        }

        const resultPromise = new Promise<String>((resolve, reject) => {
            
            addSpend(spend, (move, error) => {
                console.log("RESULT: move ", move, "error", error)
                if (error) {
                    setError(error)
                    reject()
                } else {
                    onSuccess()
                    resolve("it worked")
                }
            })

          });

        return resultPromise

    }
    const getAccount = (id:string)=>accounts.find(a=>a.id == id)
    
    console.log("Rendering SpendView")
    return (<>
        <Typography variant="h5">What is it?</Typography>

        <MarkdownEditor
            label="Description"
            value={memo}
            editable={true}
            onChange={setMemo} />

        <CurrencyAmountSelector value={amount} onChange={setAmount} />
        <PhysicalAccountSelect label="account" accounts={physicalAccounts} value={accountId} onChange={a => setAccountId(a?.id)} />

        <Typography variant="h5">Where is it in the Plan?</Typography>
        {(plannedItemFromUrl) && <Alert color="info">
            <Stack spacing={2}>
                <Typography variant="h6">Here is what you need to find and enter:</Typography>
                <Divider />
                <PlannedFundMoveViewer plannedMove={plannedItemFromUrl} getAccount={getAccount} />
            </Stack>    
        </Alert>}
        {error && <Alert color="error">{error}</Alert>}
        <MappingEditor 
            makeEditor={(isAppending, f) => <SpendMappingSelector 
                accountId={accountId} 
                amount={amount} 
                accounts={accounts} 
                memo={memo} 
                onResults={f} />} 
            itemAmount={amount} 
            getAccount={getAccount} 
            onSubmit={doIt} 
            handleChange={()=>{}}        
            />
    </>)
}



