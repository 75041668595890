import _ from "underscore";
import { formatDate } from "./DateAndTime";
import { BoxedFrequency, Flow, PDate } from "./api";


export let isOld = (flow: Flow) => {
    let date = (flow.schedule as BoxedFrequency).last || (flow.schedule as any).when
    // console.log(flow.schedule.last, flow.schedule.when)
    // console.log("last is ", date, "for", JSON.stringify(flow))
    let lastFlowMillis = new Date(date.year, date.month - 1, date.day).getTime()
    let now = new Date().getTime()
    let isOld = lastFlowMillis < now
    if (isOld) {
        // console.log("this is old: ", date, lastFlowMillis, now)
    }
    return isOld
}

const sortFlowsByChronologicalOrder = (flows:Flow[]):Flow[] => {
    return _.sortBy(flows, function (flow) {
        const when: PDate | undefined = (flow.schedule as any).when
        return formatDate(when ? when : (flow.schedule as BoxedFrequency).first)
    });
}

export const findAllFlowsForAccount = (accountId:string, allFlowsEverywhere:Flow[], showInactive:boolean):Flow[]=>{

    let allFlowsForCategoryIgnoringActive = (allFlowsEverywhere ?? []).filter((flow) => flow.accountOrTemplate == accountId || flow.toFromAccount == accountId)

    let allFlowsForCategory = allFlowsForCategoryIgnoringActive.filter((flow => {

        if (!showInactive && isOld(flow)) {
            return false
        } else {
            return true
        }
    }))

    return allFlowsForCategory

}


export const flowsInChronOrderBySequence = (accountId:string, flows:Flow[]):Record<string, Flow[]>|undefined =>{
    const allFlowsForCategory = findAllFlowsForAccount(accountId, flows, false)
    if (allFlowsForCategory.length > 0) {

        const flowsBySequence = _.groupBy(_.sortBy(allFlowsForCategory, (f) => f.name.toLowerCase()), "name")
        let flowsInChronOrderBySequence:Record<string, Flow[]> = {}
        _.map(flowsBySequence, function (flows, sequenceName) {
            flowsInChronOrderBySequence[sequenceName] = sortFlowsByChronologicalOrder(flows);
        })
        return flowsInChronOrderBySequence
    }else{
        return undefined
    }
}