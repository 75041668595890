import React, { useState } from 'react'
import _ from 'underscore'
import Chart, { ChartData, ChartDataset } from 'chart.js/auto';
import makeStandardData, { Data } from './data'
import CurrencyHandlers from './CurrencyHandlers';
import { AccountBalances, BalancesByTick } from './api';


export default (props: { focusAccountId: string | undefined, showDetails?: boolean, data: BalancesByTick|undefined, lastUpdated?: number | undefined, standardData?:Data }) => {

    let { focusAccountId, showDetails, lastUpdated, data } = props
    const [chart, setChart] = React.useState<any>()
    const [standardData, setStandardData] = useState(props.standardData || makeStandardData())

    const chartElement = React.useRef<HTMLCanvasElement>(null)

    React.useEffect(renderData, [chartElement, data, showDetails, lastUpdated, focusAccountId])

    function renderData() {
        console.log("chart renderData ")
        if (chartElement.current && data) {
            console.log("chart renderData for chart")
            showData(data)
        } else {
            console.log("chart renderData: not eligible")
        }
    }

    function showData(data: BalancesByTick) {
        let chartCanvas = chartElement.current;


        setChart((chart: any) => {

            console.log("s Creating chart.  existing is ", chart)
            if (chart) {
                console.log("Destroying chart")
                chart.destroy()
            } else {
                console.log("No existing chart")
            }

            const { ticks, allDatasets } = makeData(focusAccountId, showDetails, data, standardData)

            let newChart: any
            try {
                newChart = new Chart(chartCanvas!!.getContext('2d') as any, {
                    type: 'line',
                    data: {
                        labels: ticks,
                        datasets: allDatasets
                    },
                    options: {
                        responsive: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                } as any)
                console.log("Done creating chart")
            } catch (e) {
                console.log("There was an error creating chart", e)
            }

            console.log(`Setting chart: ${newChart}`)
            return newChart
        })


    }

    // this should be ignored by react once initially rendered, as it has no reason to update it
    return (<canvas
        className="projectionsChart"
        ref={chartElement}
        style={{
            width: "100%",
            maxHeight: "400px",
            display: "inline-block"
        }} />)
}



interface SeriesKey {
    name: string
    accountId: string
    currency: string
}
function makeData(focusAccountId: string | undefined, showDetails: boolean | undefined, data: BalancesByTick, standardData: Data): { ticks: any, allDatasets: any } {

    console.log("Preparing chart data from ", data)

    function flatMap<T, R>(list: T[], fn: (t: T) => R[]): R[] {
        return list.flatMap(key => fn(key))
    }

    function flatMapProperties<T, R>(data: Record<string, T>, fn: (key: string, value: T) => R[]): R[] {
        return Object.keys(data).flatMap(key => fn(key, data[key]))
    }
    function mapProperties<T, R>(data: Record<string, T>, fn: (key: string, value: T) => R): R[] {
        return Object.keys(data).flatMap(key => fn(key, data[key]))
    }


    let accountBalances = flatMap(data, r => r.balances)
    let chartKeysForRecord = (r: AccountBalances) => _.map(_.keys(r.balances), (currency) => ({
        name: standardData.account(r.accountId)?.name + " " + currency,
        accountId: r.accountId,
        currency: currency
    }))

    let seriesKeysFromRecords = _.filter(flatMap(accountBalances, chartKeysForRecord), (chartKey) => standardData.account(chartKey.accountId)?.kind == "Account")
    let seriesKeys = _.uniq(seriesKeysFromRecords, (key) => key.accountId + "____" + key.currency)
    let seriesKeysToShow: SeriesKey[]
    if (focusAccountId) {
        seriesKeysToShow = _.filter(seriesKeys, (key) => key.accountId == focusAccountId)
    } else {
        seriesKeysToShow = seriesKeys
    }
    console.log("Filtering on account ", focusAccountId, "showing", seriesKeysToShow, "vs", seriesKeys)
    // seriesKeysToShow = []

    let ticks = _.uniq(data.map(r => r.tick.name))

    // console.log("Chart seriesKeys:", JSON.stringify(seriesKeys, null, 4))

    let colors = ["blue", "green", "red", "orange", "brown", "pink", "darkgrey", "purple"]

    let colorForIndex = (idx: number) => {
        let color;
        while (!color) {
            if (idx >= colors.length) {
                idx = idx - colors.length
            } else {
                color = colors[idx]
            }
        }

        return color
    }

    var datasets = !showDetails ? [] : _.map(seriesKeysToShow, (seriesKey, seriesIdx) => {
        // console.log("Working on series", seriesKey)

        let chartData = data.map(r => {
            let record = _.find(r.balances, (record, t) => record.accountId == seriesKey.accountId)
            let currency = seriesKey.currency
            let v = record?.balances[currency] || 0

            // console.log(seriesKey, "value is", v, " on ", key)
            return {
                x: r.tick.name,
                y: CurrencyHandlers(currency).humanNumber(v)
            }
        })

        return {
            label: seriesKey.name,
            data: chartData,
            fill: false,
            borderColor: [
                colorForIndex(seriesIdx)
            ],
            borderWidth: 1
        }
    })


    let allCurrencies = _.uniq(data.flatMap(r => flatMap(r.balances, (record) => _.keys(record.balances))))
    // console.log("allCurrencies", allCurrencies)
    var totalsDatasets = focusAccountId ? [] : _.map(allCurrencies, (currency, currencyIdx) => {
        let label = "Net " + currency
        let chartData = data.map(r => {
            let entityRecords = _.filter(r.balances, (record, t) => {
                let account = standardData.account(record.accountId)
                return account?.kind == "Account"
            })

            let amount = 0;

            _.each(entityRecords, (record) => {
                let v = record.balances[currency] || 0
                amount += v
            })
            // console.log(label, "value is", amount, " on ", key)
            return {
                x: r.tick.name,
                y: CurrencyHandlers(currency).humanNumber(amount)
            }
        })

        return {
            label: label,
            data: chartData,
            fill: false,
            borderColor: [
                colorForIndex(currencyIdx)
            ],
            borderWidth: 1
        }
    })
    //  = 

    console.log("Chart data", datasets)

    let allDatasets = totalsDatasets.concat(datasets)

    return {
        ticks: ticks,
        allDatasets: allDatasets
    }
}