import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import { marked } from 'marked'
import http from './cj/HttpClient'
import MarkdownContent from './MarkdownContent';
import { CircularProgress } from '@mui/material';

export default () => {
    const [content, setContent] = useState<string>()

    useEffect(() => {
        http({
            url: "/principles-of-operation.md",
            method: "GET",
            onResponse: (response) => {
                if (response.status == 200) {
                    setContent(response.body)
                } else {
                    alert("Error!  Server said:" + response.body);
                }
            }
        });
    }, [])

    if (content) {
        return <MarkdownContent>{content}</MarkdownContent>
    } else {
        return <CircularProgress />
    }
}