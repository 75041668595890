import React from 'react'
import { Button, TextField } from '@mui/material'
import { MapAsNonMaterial, MappingSpec } from '../api'

export default (props: { onMappingsChange: (newSelection: MappingSpec) => void }) => {
    const { onMappingsChange } = props
    const [reason, setReason] = React.useState("")

    function applyMappings() {

        const f:MapAsNonMaterial = {
            type: "non-material",
            memo: "",
            nonMaterialResolution: { reason: reason } 
        }
        onMappingsChange(f)
    }

    return <div>
        <TextField
            fullWidth
            multiline
            minRows={2}
            placeholder="reason this doesn't matter"
            value={reason}
            onChange={e => setReason(e.target.value)} />
        <Button
            style={{ float: "right" }}
            variant='contained'
            onClick={applyMappings}
        >Next</Button>
    </div>

}