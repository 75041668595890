import { Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material"
import { AccountInfo, Flow, FundMove, PlannedFundMove } from "./api"
import { AccountLink } from "./Links"
import util, { formatDateTimeRange } from "./util"
import { DialogsContext } from "./Dialogs"
import { getMove, getPlanItem, getPlanedItem } from "./api-actions"
import React from "react"
import MarkdownContent from "./MarkdownContent"

export default (props: { move: FundMove, getAccount: (id: string) => AccountInfo | undefined, handleChange: () => void }) => {
    const { getAccount, handleChange } = props
    const [move, setMove] = React.useState(props.move)
    const [plannedMove, setPlannedMove] = React.useState<PlannedFundMove>()

    const planSequenceId = move.plannedItemId?.sequenceId

    const getPlannedMove = () => {
        if (move.plannedItemId) getPlanedItem(move.plannedItemId, setPlannedMove)
    }

    React.useEffect(getPlannedMove, [move])


    const respondToChange = (update?: FundMove) => {
        if (update?.id) getMove(update.id, setMove)
        getPlannedMove()
        handleChange()
    }
    console.log(JSON.stringify(move))

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Stack>
                    <Typography variant="h5" component="div">Move {move.id?.substring(0, 5)}</Typography>
                    {(plannedMove && (plannedMove?.planned.memo != move.memo)) && <Typography>Plan: {plannedMove.planned.memo}</Typography>}
                    {(plannedMove && (plannedMove.planned.when != move.when)) && <Typography>Plan: {formatDateTimeRange(plannedMove.planned.when, plannedMove.planned.whenEnds)}</Typography>}

                    <Typography>{formatDateTimeRange(move.when, move.whenEnds)}</Typography>
                    <Typography><MarkdownContent>{move.memo}</MarkdownContent></Typography>
                    {move.flows.map(flow => {
                        const from = getAccount(flow.fromFundId)
                        const to = getAccount(flow.toFundId)
                        return <>
                            <Typography>{flow.amount && util.formattedColorAmount(flow.amount, move.currency)}</Typography>
                            <Typography>From: <AccountLink target="_blank" account={from} /></Typography>
                            <Typography>To: <AccountLink target="_blank" account={to} /></Typography>
                        </>
                    })}
                </Stack>
            </CardContent>
            <CardActions>
                <DialogsContext.Consumer>{dialogs => <>
                    {move && <Button onClick={() => move && dialogs.showMove({ value: move, onChange: respondToChange })}>Edit Move</Button>}
                    {planSequenceId && <Button onClick={() => planSequenceId && dialogs.showFlowById(planSequenceId, (n) => respondToChange())}>Edit Plan</Button>}
                </>}</DialogsContext.Consumer>
            </CardActions>
        </Card>
    )
}
