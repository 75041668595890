import React from 'react'
import util from './util'
import { Stack, TextField } from '@mui/material'
import CurrencyHandlers from './CurrencyHandlers'

export interface CurrencyAmount {
    amount: number
    currency: string
}

export default (props: { label: string, disabled?: boolean, fullWidth?: boolean, value: CurrencyAmount, onChange: (v: number) => void }) => {
    const { value, label, disabled, fullWidth, onChange } = props
    const handler = CurrencyHandlers(value.currency)
    const [amount, setAmount] = React.useState(handler.normalized(value.amount))

    const handleChange = (str: string) => {
        const v = handler.parse(str)
        console.log("Value is ", v)
        setAmount(str)
        onChange(v)
    }

    const handleBlur = () => {
        const amt = handler.normalized(handler.parse(amount))
        console.log("Blur: ", amount, "to", amt)
        setAmount((amt))
    }

    console.log("Amount is ", amount)
    console.log("Handler for", value.currency, "is", handler)

    return (
        <TextField
            onBlur={handleBlur}
            fullWidth={fullWidth}
            label={label}
            disabled={disabled}
            type="number"
            inputProps={{ step: "0.01" }}
            value={amount}
            onChange={e => handleChange(e.target.value)} />
    )
}
