import { ReactNode, useEffect, useState } from "react";
import { MovesSearchParameters, fetchFullMoveDetailsPage, fetchPages, getFlows, getTenantOrganization } from "./api-actions";
import AnalysisWindowControls, { AnalysisWindow, previousMonth } from "./AnalysisWindowControls";
import { AccountInfo, Flow, FullMoveDetails, FullMoveDetailsPage, FundMove, FundsFlow, Organization } from "./api";
import { Box, Button, Card, CardContent, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import util, { distinct, entityName as entityNameFull, formatDateTimeRange, formattedPercentages, formattedPercentagesWithSigns, formattedTotals, getTenantId, sortedBy } from "./util";
import MarkdownContent from "./MarkdownContent";
import Table from "./Table2";
import makeData from "./data";
import { KIND_ALIEN, KIND_STUB } from "./accounts";
import FloatingActionBar from "./FloatingActionBar";

/*
 * TODO:
 *   - This needs to show actuals - seems to be showing something else
 *   - Needs drill-down
 *      - All Entities: Shows high-level breakdown of each entity
 *      - One Entity: Shows high-level breakdown of accounts for the entity
 *      - One Account: Shows plan-series level breakdown of moves for the account
 *      - One Plan Series (or the unplanned stuff) - Shows every planned/realized/unplanned move for the account + series
 * 
 *  - figure-out how to handle internal transfers (e.g. caruso distribution)
 *  - sort by most aggregious
 */

interface FooFlow {
    fromFundId: string
    toFundId: string
    planned: FundsFlow | undefined
    actual: FundsFlow | undefined
}
interface FlowInMove {
    flow:FooFlow
    // flow:FundsFlow
    move:FundMove
    full:FullMoveDetails
    // TODO: Should have some indication of what the original planned item was, not the current planned item
}

interface FilterCriteria {
    entity:string|undefined,
    accountId:string|undefined,
    planElement:string|undefined,
}

const blank:FilterCriteria = {
    entity:undefined,
    accountId:undefined,
    planElement:undefined,
}
export default ()=>{
    const [data, setData] = useState(makeData())
    const [analysisWindow, setAnalysisWindow] = useState<AnalysisWindow>(previousMonth())
    const [pages, setPages] = useState<FullMoveDetailsPage[]>([])
    const [plan, setPlan] = useState<Flow[]>()
    const [onlyUsed, setOnlyUsed] = useState(true)
    const [showSimple, setShowSimple] = useState(true)
    const [filter, setFilter] = useState<FilterCriteria>(blank)
    const [isLoading, setLoading] = useState(false)

    const [organization, setOrganization] = useState<Organization>()
    const [error, setError] = useState<string>()

    const tenantId = getTenantId()!!

    useEffect(() => {
        getTenantOrganization(
            tenantId, 
            setOrganization, 
            response=>setError(response.body))
    }, [])


    useEffect(()=>{
        getFlows(
            tenantId, 
            flows => {
                setPlan(flows)
            }, 
            response=>{
                setPlan(undefined)
            })
    }, [])

    const searchParams = (): MovesSearchParameters => {
        return {
            planSequenceId: undefined,
            onlyInstantaneous: false,
            onlyFlagged: false,
            before: analysisWindow.to
        }
    }

    const refreshEverything = ()=>{
        setLoading(true)
        fetchPages(
            searchParams(), 
            pages=> pages.length > 100,
            pages=> {
                setPages(pages)
                setLoading(false)
            })
    }

    useEffect(refreshEverything, [])

    const matchesWindow = (it:FullMoveDetails):boolean => {
        const matchesStart = it.when >= analysisWindow.from
        const matchesEnd = !it.whenEnds || it.whenEnds <= analysisWindow.to
        return matchesStart && matchesEnd
    }

    const fullMoves = pages?.flatMap(it => it.items)
    const entityName = (a:AccountInfo) => entityNameFull(a, organization)
    const entityNames = (fim:FlowInMove):Set<string>=>{
        const from = data.account(fim.flow.fromFundId)
        const to = data.account(fim.flow.toFundId)

        const entities:Set<string> = new Set()
        
        from && entities.add(entityName(from))
        to && entities.add(entityName(to))

        return entities
    }

    const items:FlowInMove[] = fullMoves.filter(matchesWindow).flatMap(item=> {
        const i:FundMove = item.actual ?? item.planned?.plannedFundMove.planned !!
        
        let flows:FooFlow[] = []

        const update = (from:string, to:String, fn:(f:FooFlow)=>FooFlow) => {
            const isIt = (it:FooFlow) => it.fromFundId == from && it.toFundId == to
            const existing = flows.find(isIt) 
            
            const updated = fn(existing ?? ({
                fromFundId:from,
                toFundId:to,
                actual:undefined,
                planned:undefined,
            } as FooFlow))

            
            flows = flows.map(it => 
                isIt(it) ? updated : it    
            ).concat(existing ? [] : [updated])
        }

        const actual = item.actual
        if(actual){
            actual.flows.forEach(flow=>{
                update(flow.fromFundId, flow.toFundId, f => {
                    return {
                        ... f,
                        actual:flow
                    }
                })
            })
        }
        const plannedMove = item.planned?.plannedFundMove.planned

        if(plannedMove){
            plannedMove.flows.forEach(flow=>{
                update(flow.fromFundId, flow.toFundId, f => {
                    return {
                        ... f,
                        planned:flow
                    }
                })
            })
        }
        return flows.map(f=> {
            return {
                flow:f,
                move:i,
                full:item
            }
        })
    })


    const entities = distinct(items.flatMap(i => Array.from(entityNames(i))))

    const flowAffectsEntity = (f:FundsFlow|FooFlow, entity:string):boolean => {
        const from = entityName(data.account(f.fromFundId)!!)
        const to = entityName(data.account(f.toFundId)!!)
        const affects =  from == entity || to == entity
        if(affects){
            console.log(`affects ${entity}: ${from} -> ${to}`)
        }
        return affects
    }

    const isFlowOutFromEntity = (f:FundsFlow, entity:string):boolean => {
        const from = entityName(data.account(f.fromFundId)!!)
        const to = entityName(data.account(f.toFundId)!!)
        if(f.amount >= 0){
            return from == entity && to != entity
        }else{
            return to == entity && from !=entity
        }
    }

    interface BalanceAccum {
        add:(amount:number, currency:string)=>void
        balances:()=>Record<string, number>
    }

    const makeAccum = ():BalanceAccum => {
        const totals:Record<string, number> = {}
        return {
            add:(n, c) => {
                totals[c] = (totals[c] ?? 0) + n
            },
            balances:()=>{return {... totals}}
        }
    }

    const amountRelativeTo= (flow:FundsFlow, entity:string):number => {
        const fromA = data.account(flow.fromFundId)!!
        const from = entityName(fromA)
        const toA = data.account(flow.toFundId)!!
        const to = entityName(toA)
        let amountIn:number = 0
        if(from == entity && to != entity){
            amountIn = (flow.amount * -1)
        }else if(from != entity && to == entity){
            amountIn = flow.amount
        }
        return amountIn
    }
    interface EntityTotals{
        allOut:Record<string, number>
        allIn:Record<string, number>
        plannedOut:Record<string, number>
        plannedIn:Record<string, number>
        plannedActualOut:Record<string, number>
        plannedActualIn:Record<string, number>
        unplannedOut:Record<string, number>
        unplannedIn:Record<string, number>
    }
    const planElementForMove = (fim:FlowInMove):string => {
        return fim.full.planned?.plannedFundMove?.id.sequenceId ?? "Unplanned"
    }
    const relativeTotals = (toEntity:string, toAccountId:string|undefined, items:FlowInMove[]):EntityTotals=>{
        const entity = toEntity
        
        const allOut = makeAccum()
        const allIn = makeAccum()
        const plannedOut = makeAccum()
        const plannedIn = makeAccum()
        const plannedActualOut = makeAccum()
        const plannedActualIn = makeAccum()
        const unplannedOut = makeAccum()
        const unplannedIn = makeAccum()

        // need 2 sets - one relative to the entity, another relative to the account

        items.forEach(fim => {
            if(fim.flow.actual){
                let actualAmountIn = amountRelativeTo(fim.flow.actual, entity)
                if(actualAmountIn < 0){
                    allOut.add(actualAmountIn, fim.move.currency)
                }else{
                    allIn.add(actualAmountIn, fim.move.currency)
                }
                if(fim.full.planned){
                    if(actualAmountIn < 0){
                        plannedActualOut.add(actualAmountIn, fim.move.currency)
                    }else{
                        plannedActualIn.add(actualAmountIn, fim.move.currency)
                    }
                }else{
                    if(actualAmountIn < 0){
                        unplannedOut.add(actualAmountIn, fim.move.currency)
                    }else{
                        unplannedIn.add(actualAmountIn, fim.move.currency)
                    }
                }
            }
            if(fim.flow.planned){
                let plannedAmountIn = amountRelativeTo(fim.flow.planned, entity)
                if(plannedAmountIn < 0){
                    plannedOut.add(plannedAmountIn, fim.move.currency)
                }else{
                    plannedIn.add(plannedAmountIn, fim.move.currency)
                }
            }
            
        })

        return {
            allOut:allOut.balances(),
            allIn:allIn.balances(),
            plannedOut:plannedOut.balances(),
            plannedIn:plannedIn.balances(),
            plannedActualOut:plannedActualOut.balances(),
            plannedActualIn:plannedActualIn.balances(),
            unplannedOut:unplannedOut.balances(),
            unplannedIn:unplannedIn.balances(),
        }

    }
    const mergeBalances = (a: Record<string, number>, b: Record<string, number>, fn:(a:number, b:number, currency:string)=>number|undefined): Record<string, number> => {
        const result: Record<string, number> = {}
        
        const currencies = distinct(Object.keys(a).concat(Object.keys(b)))

        currencies.forEach(currency => {
            const val = fn((a[currency] ?? 0), (b[currency] ?? 0), currency)
            if(val != undefined){
                result[currency] = val
            }
        })

        return result
    }
    const minusBalances = (balances: Record<string, number>, minus: Record<string, number>) => mergeBalances(balances, minus, (a, b)=>a-b)
    const addBalances = (balances: Record<string, number>, minus: Record<string, number>) => mergeBalances(balances, minus, (a, b)=>a+b)

    const safeDivide = (a:number, b:number):number|undefined => {
        if(a && b){
            return a/b
        }else{
            return undefined
        }
    }

    const safeDivideF = (a:number, b:number):number|undefined => {
        if(a && b){
            return (a/b) - 1
        }else{
            return undefined
        }
    }

    // formatPercent

    const standardColumns = (balances:EntityTotals):Record<string, ReactNode> => {
        const netActual = addBalances(balances.allIn, balances.allOut)
        const netPlanned = addBalances(balances.plannedIn, balances.plannedOut)
        const netPlannedActual = addBalances(balances.plannedActualIn, balances.plannedActualOut)
        const netUnplanned = addBalances(balances.unplannedIn, balances.unplannedOut)

        const details:Record<string, ReactNode> = {
            "planned (out)":util.formattedTotals(balances.plannedOut), 
            "planned actual (out)":util.formattedTotals(balances.plannedActualOut), 
            "planned delta (out)":util.formattedTotals(minusBalances(balances.plannedOut, balances.plannedActualOut)), 
            "planned delta % (out)":formattedPercentagesWithSigns(mergeBalances(balances.plannedActualOut, balances.plannedOut, safeDivideF)), 
            "unplanned actual (out)":util.formattedTotals(balances.unplannedOut), 
            "all actual (out)":util.formattedTotals(balances.allOut), 
            "total planning delta (out)":formattedTotals(minusBalances(balances.allOut, balances.plannedOut)), 

            "planned (in)":util.formattedTotals(balances.plannedIn), 
            "planned actual (in)":util.formattedTotals(balances.plannedActualIn), 
            "planned delta (in)":util.formattedTotals(minusBalances(balances.plannedActualIn, balances.plannedIn)), 
            "planned delta % (in)":formattedPercentagesWithSigns(mergeBalances(balances.plannedActualIn, balances.plannedIn, safeDivideF)), 
            "unplanned actual (in)":util.formattedTotals(balances.unplannedIn), 
            "all actual (in)":util.formattedTotals(balances.allIn), 
            "total planning delta (in)":formattedTotals(minusBalances(balances.allIn, balances.plannedIn)),  

        }

        return {
            ... (showSimple ? {} : details),
            "net planned ":formattedTotals(netPlanned), 
            "net planned actual ":formattedTotals(netPlannedActual), 
            "net un-planned ":formattedTotals(netUnplanned), 
            "net actual ":formattedTotals(netActual), 
            "net planning delta ":formattedTotals(minusBalances(netActual, netPlanned)), 
            "total planning delta % (in)":formattedPercentagesWithSigns(mergeBalances(balances.allIn, balances.plannedIn, safeDivideF)),
            "total planning delta % (out)":formattedPercentagesWithSigns(mergeBalances(balances.allOut, balances.plannedOut, safeDivideF)), 
            "net planning delta % ":formattedPercentagesWithSigns(mergeBalances(netActual, netPlanned, safeDivideF)), 
        }
    }
    const flowAffectsAccount = (i:FlowInMove, accountId:string):boolean => i.flow.fromFundId == accountId || i.flow.toFundId == accountId
    const matchesFilter = (fim:FlowInMove):boolean => {
        return (
            (!filter.entity || flowAffectsEntity(fim.flow, filter.entity))
            &&
            (!filter.accountId || flowAffectsAccount(fim, filter.accountId))
            && 
            (!filter.planElement || planElementForMove(fim) == filter.planElement)
        )
    }

    const accountAffectedByPlan = (f:Flow, accountId:string):boolean => {
        const matchesSimple =  (f.toFromAccount == accountId || f.accountOrTemplate == accountId)

        const embeddedTemplateHasIt = f.template?.remainder == accountId || (new Set(Object.keys(f.template?.split ?? {}))).has(accountId)

        const matches = matchesSimple || embeddedTemplateHasIt

        if(matches){
            console.log("Plan match: ", f, accountId)
        }
        return matches
    }

    const accountMatchesFilter = (a:AccountInfo):boolean => {
        const planElement = filter.planElement ? plan?.find(p => p.id == filter.planElement) : undefined

        return (
            (!filter.entity || entityName(a) == filter.entity)
            &&
            (!filter.accountId || filter.accountId == a.id)
            && 
            (!filter.planElement || filter.planElement == "Unplanned" || (planElement ? accountAffectedByPlan(planElement, a.id) : false))
        )
    }
    const planElementMatchesFilter = (a:Flow):boolean => {
        const x = data.account(a.toFromAccount)
        const y = data.account(a.accountOrTemplate)

        const xName = x && entityName(x)
        const yName = y && entityName(y)

        const matchesEntity = (!filter.entity || (xName == filter.entity || yName == filter.entity))

        const matches =  (
            matchesEntity
            &&
            (!filter.accountId || accountAffectedByPlan(a,  filter.accountId))
            && 
            (!filter.planElement || a.id == filter.planElement)
        )

        if(matches){
            console.log("Plan match: ", a, `matchesEntity? ${matchesEntity}`)
        }

        return matches
    }

    const standardHeader = Object.keys(standardColumns(relativeTotals("", undefined, [])))

    const filteredItems = items.filter(matchesFilter)

    const useAccountIds = new Set(filteredItems.flatMap(i => [i.flow.fromFundId, i.flow.toFundId]))
    const accountsForEntity = sortedBy(data.accounts().filter(accountMatchesFilter).filter(a=> !onlyUsed || useAccountIds.has(a.id)), a=>a.name)

    const usedPlanIds = new Set(filteredItems.flatMap(i => i.full.planned ? [i.full.planned.plannedFundMove.id.sequenceId] : []))

    const planThings = plan && sortedBy(plan.filter(planElementMatchesFilter).filter(p => !onlyUsed || usedPlanIds.has(p.id??"whatever-not-real")), p=>p.name)
    const planElementsForEntity = planThings && distinct(planThings.map(it => it.id!!).concat("Unplanned"))
    

    const planNameForPlanId = (id:string):string => {
        if(id == "Unplanned"){
            return id
        }else{
            return plan?.find(p => p.id == id)?.name ?? "unknown"
        }
    }

    return (<>
        <FloatingActionBar noFloat={true} isRefreshing={isLoading} onRefresh={refreshEverything}>
            <AnalysisWindowControls onChange={setAnalysisWindow} window={analysisWindow} doUpdate={refreshEverything} isFetching={isLoading} />
            {filter.entity && <Chip label={filter.entity} onDelete={()=>setFilter(blank)}/>}
            {filter.planElement && <Chip label={planNameForPlanId(filter.planElement)} onDelete={()=>{
                setFilter({
                    ... filter,
                    planElement:undefined
                })
            }}/>}
            {filter.accountId && <Chip label={data.account(filter.accountId)?.name} onDelete={()=>{
                setFilter({
                    ... filter,
                    accountId:undefined
                })
            }}/>}
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={onlyUsed} onChange={(e, v)=>setOnlyUsed(v)}/>} label="OnlyShowUsed" />
                <FormControlLabel control={<Checkbox checked={showSimple} onChange={(e, v)=>setShowSimple(v)}/>} label="Simple" />
            </FormGroup>
        </FloatingActionBar>
        
        <Stack spacing={2}>
            {isLoading && <CircularProgress/>}
            
            <Typography variant="h3">Entities</Typography>
            {<Table
                header={["name"].concat(standardHeader)}
                rows={Array.from(entities).filter(e => !filter.entity || filter.entity == e).map(n => {
                    const balances = relativeTotals(n, filter.accountId, filteredItems)
                    const ff:ReactNode[] = Object.values(standardColumns(balances))
                    const aa:ReactNode[] =[
                        <Button style={{textAlign:"left"}} onClick={()=>setFilter({... filter, entity:n})}>{n}</Button>,
                    ]
                    return {
                        key:n,
                        cells:aa.concat(ff)
                    }
                })}/>}

            {(filter.entity && accountsForEntity)&& <><Typography variant="h3">Accounts</Typography>
            {<Table
                header={[ "Plan Element",].concat(standardHeader)}
                rows={accountsForEntity.flatMap(account => {

                    const matchingItems = filteredItems.filter(i => flowAffectsAccount(i, account.id))
                    
                    const balances = relativeTotals(filter.entity!!, filter.accountId, matchingItems)
                    const ff:ReactNode[] = Object.values(standardColumns(balances))
                    const aa:ReactNode[] = [
                        <Button style={{textAlign:"left"}} onClick={i=>setFilter({... filter, accountId:account.id})}>{account.name}</Button>,
                    ]
                    return {
                        key: `account-${account.id}-${JSON.stringify(filter)}`,
                        cells:aa.concat(ff)
                    }
                })}/>}</>}

            {(filter.entity && planElementsForEntity)&& <><Typography variant="h3">Plan Elements</Typography>
            {<Table
                header={[ "Plan Element",].concat(standardHeader)}
                rows={planElementsForEntity.flatMap(planElementId => {

                    const matchingItems = filteredItems.filter(i => planElementForMove(i) == planElementId)
                    
                    const balances = relativeTotals(filter.entity!!, filter.accountId, matchingItems)
                    const ff:ReactNode[] = Object.values(standardColumns(balances))
                    const aa:ReactNode[] = [
                        <Button style={{textAlign:"left"}} onClick={i=>setFilter({... filter, planElement:planElementId})}>{planNameForPlanId(planElementId)}</Button>,
                    ]
                    const key = `plan-${planElementId}-${JSON.stringify(filter)}`
                    console.log(`Using key ${key}`)
                    return {
                        key: key,
                        cells:aa.concat(ff)
                    }
                })}/>}</>}

            {(filter.entity && (filter.planElement || filter.accountId)) && <><Typography variant="h3"> {(filter.planElement && planNameForPlanId(filter.planElement)) ?? ""} -- {(filter.accountId && data.account(filter.accountId)?.name) ?? ""}</Typography>
            {<Table
                header={[ "Plan Element", "when", "memo", "amount", "planned", "from", "to",].concat(standardHeader)}
                rows={filteredItems.map(fim => {
                    const {flow, move, full} = fim
                    const balances = relativeTotals(filter.entity!!, filter.accountId, [fim])
                    const from = data.account(flow.fromFundId)!!
                    const to = data.account(flow.toFundId)!!!
                    const planId = planElementForMove(fim)
                    const ff:ReactNode[] = Object.values(standardColumns(balances))
                    const aa:ReactNode[] = [
                        planNameForPlanId(planId),
                        formatDateTimeRange(move.when, move.whenEnds),
                        <MarkdownContent>{move.memo}</MarkdownContent>,
                        util.formattedColorAmount(flow.actual?.amount ?? 0, move.currency),
                        util.formattedColorAmount(flow.planned?.amount ?? 0, move.currency),
                        `${entityName(from)} ${from.name}`,
                        `${entityName(to)} ${to.name}`,
                    ]
                    return {
                        key: `item-${JSON.stringify(fim)}-${JSON.stringify(filter)}`,
                        cells:aa.concat(ff)
                    }
                })}/>}</>}
        </Stack>
    </>)
}