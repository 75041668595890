import { Alert, Box, Button, Card, CardContent, Chip, CircularProgress, Divider, Link, Stack, Typography, useMediaQuery } from "@mui/material"
import { AccountInfo, Flow, FundsFlow, MappingOption, MappingSpec, PhysicalItem, PhysicalItemHandle  } from "./api"
import React, { CSSProperties, ReactNode, useEffect } from "react"
import http from './cj/HttpClient'
import util, { describeState, formatDateTimeRange, formatPercent, getTenantId, typeLabelForAccountKind } from "./util"
import Table from "./TableNG"
import AccountingEntryDialog from "./AccountingEntryDialog"
import PhysicalItemsPanel from "./PhysicalItemsPanel"
import Grid from '@mui/material/Grid';
import MarkdownContent from "./MarkdownContent"
import ClearableTextField from "./ClearableTextField"
import { getFlows } from "./api-actions"
import { totalFlows, totalPhysicalMappings, totalPhysicalMappingsForFullPhysicalItemDetails } from "./moveUtils"
import { optionToMappingSpec } from "./itemEditor"
import { CurrencyAmount } from "./CurrencyAmountField"

const totalPhysical = (option:MappingOption):number => {

    const firstIfDefined = <T,>(items:T[]|undefined):T|undefined => {
        return (items && items.length > 0 )? items[0] : undefined
    }
    
    const firstItem = firstIfDefined(option.items)
    const used = (firstItem ? totalPhysicalMappingsForFullPhysicalItemDetails(option.items) : 0) * -1

    return used
}

const fetchOptions = (handle: PhysicalItemHandle, filter: string, setMappingOptions: (options: MappingOption[]) => void, setError: (err: string) => void) => {
    console.log("Query: starting" + filter);
    http({
        url: `/api/tenants/${getTenantId()}/mappingOptions/${handle.accountId}/${handle.itemId}?filter=${filter}&limit=100`,
        method: "GET",
        onResponse: function (response) {
            console.log("Query: done");
            if (response.status === 200) {
                setMappingOptions(JSON.parse(response.body));
            } else {
                setError(`Error ${response.status}: ${response.body}`)
            }
        }
    });
}


export default (props: { foreignAccountId: string, item: PhysicalItem, accounts: AccountInfo[], onMappingsChange: (mappingFactory:(allocatedAmount:CurrencyAmount)=>MappingSpec) => void }) => {
    const {item} = props

    return <Foo
        foreignAccountId={props.foreignAccountId}
        item={props.item}
        accounts={props.accounts}
        showUnexpectedOption={false}
        onUnexpectedSelected={() => { }}
        onMappingsChange={option => props.onMappingsChange(allocatedAmount=>optionToMappingSpec(option, allocatedAmount))}
    />
}

interface SearchRequest {
    id: string,
    whenEntered: number
    filtter: string
    status: "active" | "finished" | "queued"
}

interface ResultSet {
    source: "search" | "drill-down"
    filter: string | undefined
    options: MappingOption[]
}
export const Foo = (props: { foreignAccountId: string, item: PhysicalItem, accounts: AccountInfo[], showUnexpectedOption: boolean, onUnexpectedSelected: () => void, onMappingsChange: (m: MappingOption) => void }) => {
    const { foreignAccountId, item, accounts, showUnexpectedOption, onMappingsChange } = props
    const [filterText, setFilterText] = React.useState<string>("")
    const [searchQueue, setSearchQueue] = React.useState<SearchRequest[]>([])
    const [results, setResults] = React.useState<ResultSet>()
    const [error, setError] = React.useState<string>()
    const [isSearching, setSearching] = React.useState(false)
    const [optionToShow, setOptionToShow] = React.useState<MappingOption>()
    const isLarge = useMediaQuery('(min-width:800px)');


    const last = <T,>(list: T[]): T | undefined => (list.length > 0) ? list[list.length - 1] : undefined

    const handle: PhysicalItemHandle = {
        accountId: foreignAccountId,
        itemId: item.id
    }

    console.log("Rendering for ", handle, item)

    const tryStuff = () => {
        const now = new Date().getTime()
        const activeSearch = searchQueue.find(e => e.status == "active")
        const latestQueued = last(searchQueue.filter(e => e.status == "queued"))

        console.log("Query: Maybe search?", activeSearch, latestQueued)

        if (!activeSearch && latestQueued) {
            const entry: SearchRequest = {
                ...latestQueued,
                status: "active",
            }

            if ((entry.filtter.trim().length < 3)) {
                console.log("Too small", entry)
                setResults(undefined)
                setError(undefined)
            } else if ((entry.whenEntered + 800) > now) {
                console.log("Too soon", entry)
                setTimeout(tryStuff, 500);
            } else {

                console.log("Query: yes, definitely search", entry)

                setSearchQueue([entry])
                setSearching(true)

                const markComplete = () => {
                    console.log("Query: marking complete: ", entry)

                    setSearching(false)
                    setSearchQueue(searchQueue => searchQueue.map(e => {
                        if (e.id == entry.id) {
                            return {
                                ...e,
                                status: "finished"
                            }
                        } else {
                            return e
                        }
                    }))
                }

                fetchOptions(
                    handle,
                    entry.filtter,
                    (result: MappingOption[]) => {
                        setResults({
                            source: "search",
                            filter:entry.filtter,
                            options: result
                        })
                        markComplete()
                    },
                    (error: string) => {
                        setError(error)
                        markComplete()
                    })
            }

        }
    }

    React.useEffect(tryStuff, [searchQueue])


    const accountById = (id: string) => accounts.find((account) => account.id == id)
    const accountName = (id: string) => accountById(id)?.name

    const maxResults = 500

    const isAcceptableOptionToShowToUser = (option:MappingOption):boolean => {
        let isPlanned = option.planned ? true : false
        let isPartiallyMappedUnplannedMove:boolean
        
        if(!option.planned && option.move){
            let mapped = totalPhysical(option)
            let size = totalFlows(option.move)
            let isFullyMapped = Math.abs(mapped) == Math.abs(size)
            if(!isFullyMapped){
                console.log("Not fully mapped:", mapped, size, option)
            }
            isPartiallyMappedUnplannedMove = !isFullyMapped
        }else{
            isPartiallyMappedUnplannedMove = false
        }

        return isPlanned || isPartiallyMappedUnplannedMove
    }
    
    const visibleOptions = results?.options?.filter(isAcceptableOptionToShowToUser).slice(0, maxResults)

    const optionCurrency = (option:MappingOption) => (option.move ?? option.planned?.planned)?.currency ?? "??"

    const optionStuff = (option:MappingOption, flow:FundsFlow) => {
        const move = option.move ?? option.planned?.planned

        const used = totalPhysical(option)

        const remaining = flow.amount - used

        const currency = optionCurrency(option)
        return {
            usedPercentFormatted:formatPercent(used/flow.amount),
            remainingFormatted:util.formatAmount(remaining, currency),
            remaining:remaining,
            used:used,
            usedFormatted:<>
                {/*totalPhysicalMappings*/}
                {option.move &&  util.formattedColorAmount(totalPhysicalMappings(option.move), currency)}
                {util.formattedColorAmount(used, currency)} ({formatPercent(used/flow.amount)})
            </>,
            flowAmountFormatted:util.formatAmount(flow.amount, currency)
        }
    }

    const rows = visibleOptions?.map(option => {
        const move = option.move ?? option.planned?.planned
        const flows = move?.flows ?? []

        let state = describeState(option)

        return flows.flatMap((flow, idx) => {
            const {used, usedFormatted, usedPercentFormatted, flowAmountFormatted, remainingFormatted} = optionStuff(option, flow)
            const select = () => {
                onMappingsChange(option)
            }
            return [
                state,
                move && formatDateTimeRange(move.when, move.whenEnds),
                move &&
                <>
                    <Link onClick={() => {
                        setOptionToShow(option)
                    }}>{move.memo}</Link>
                </>,
                `${idx + 1}/${flows.length}`,
                move && <>{flowAmountFormatted}
                </>
                ,
                accountName(flow.fromFundId),
                accountName(flow.toFundId),
                ,

                (move && option.items && option.items.length > 0) && <Stack>
                    <Table header={[]} rows={[
                        // ["Amount", flowAmountFormatted], 
                        ["Used", usedFormatted], 
                        ["Remaining", remainingFormatted]]}/>
                    <PhysicalItemsPanel move={move} items={option.items} />    
                </Stack>,
                <Button variant="contained" onClick={select}>Select</Button>
            ]
        })
    })

    const smallThings = visibleOptions?.map(option => {
        const move = option.move ?? option.planned?.planned

        const title = move?.memo.split("\n")[0] ?? "unknown"

        const flows = move?.flows ?? []

        let state = describeState(option)

        return flows.map((flow, idx) => {
            const select = () => {
                onMappingsChange(option)
            }
            const {usedFormatted, flowAmountFormatted, remainingFormatted} = optionStuff(option, flow)
            return <Card>
                <CardContent>
                    <Stack spacing={1}>
                        <Stack spacing={1} direction="row-reverse">
                            <Button variant="contained" onClick={select}>Select</Button>
                        </Stack>
                        <Box>{state}</Box>
                        <Stack spacing={1} direction="row">

                            {move &&
                                <Typography variant="h6">
                                    <Link onClick={() => {
                                        setOptionToShow(option)
                                    }}>{title} @ {formatDateTimeRange(move.when, move.whenEnds)}</Link>

                                </Typography>}
                        </Stack>
                        <Box>From: {accountName(flow.fromFundId)}</Box>
                        <Box>To: {accountName(flow.toFundId)}</Box>
                        {flows.length > 1 && <Box>Flow:{`${idx + 1}/${flows.length}`}</Box>}
                        {(move && title != move.memo) && <>
                            <Divider />
                            <MarkdownContent>{move.memo}</MarkdownContent>
                        </>}



                        <Divider />
                        <Box>{move && <>Amount: {flowAmountFormatted}</>}</Box>
                        <Box>{move && <>Used: {usedFormatted}</>}</Box>
                        <Box>{move && <>Remaining: {remainingFormatted}</>}</Box>
                        {(move && option?.items?.length > 0) && <>
                            
                            <PhysicalItemsPanel move={move} items={option.items} />
                        </>}
                    </Stack>
                </CardContent>
            </Card>
        })
    })
    console.log("is large?", isLarge)
    console.log("isSearching?", isSearching)

    const appendSearch = (filter: string) => {
        const now = new Date().getTime()
        setSearchQueue(searchQueue => searchQueue.concat([{
            id: `${now}-${Math.random()}-${Math.random()}-${Math.random()}`,
            whenEntered: now,
            filtter: filter,
            status: "queued"
        }]))
    }

    useEffect(() => appendSearch(filterText), [filterText])

    return <>
        {optionToShow && <AccountingEntryDialog option={optionToShow} onClose={() => setOptionToShow(undefined)} />}
        {error && <Alert color="error">{error}</Alert>}

        <Grid container spacing={2}>
            <Grid item sm={3} >
                <Stack direction="row" spacing={2}>
                    <ClearableTextField
                        label="search"
                        variant="outlined"
                        value={filterText}
                        onSearch={v => setFilterText(v.replaceAll("’", "'"))}
                    />
                    {isSearching && <CircularProgress />}
                </Stack>
            </Grid>
            <Grid item sm={9} >
                {(!results || results.source == "drill-down") &&
                    <Box style={{ margin: "20px" }}>
                        <DrillDownSelector
                            handle={handle}
                            accounts={accounts}
                            showUnexpectedOption={props.showUnexpectedOption}
                            onUnexpectedSelected={props.onUnexpectedSelected}
                            onSelect={(r) => setResults(r && {
                                source: "drill-down",
                                filter:undefined,
                                options: r
                            })}
                        />
                    </Box>
                }

                {/* {(!isSearching) && } */}
            </Grid>
        </Grid>
        {rows && <>
            {/* {results && <>Showing {results.options.length} options from {results.source} '{results.filter ?? ""}'</>} */}

            {rows.length > 0 && <>
                {isLarge && <Table
                    header={["Status", "When", "Move", "Flow #", "Amount", "From", "To", "Mappings", "Action"]}
                    rows={rows}
                />}
                {!isLarge && smallThings}
            </>
            }


            {rows.length == 0 && <Box>
                No results
            </Box>}

            {(results && visibleOptions && results.options?.length > visibleOptions?.length) && <Box>Results are truncated - try a narrower search</Box>}
        </>}

    </>
}

interface MenuArrayItem {
    name: string,
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    onSelect: () => void
}
const breakableText = (text: string): string => {
    const parts = text.split(' ')
    return parts.map(p => {
        if (p.length > 10) {
            return p.replaceAll("/", " / ").replaceAll("-", " - ")
        } else {
            return p
        }
    }).join(" ")
}
const MenuArray = (props: { items: MenuArrayItem[] | undefined, emptyLabel?: string, style?: CSSProperties }) => {
    const { items, emptyLabel } = props
    const isEmpty = (items ?? []).length == 0
    return (<>
        {isEmpty && <Box style={{ ...props.style, textAlign: "center" }}>
            <Button variant="text" disabled={true} >{breakableText(emptyLabel ?? "No Options")}</Button>

        </Box>}
        {!isEmpty && <Grid container spacing={2} style={props.style}>
            {props.items?.sort((a, b) => a.name.localeCompare(b.name))?.map(a => {

                return <Grid item xs={6} sm={6} lg={4} xl={3}>
                    <Button key={a.name} color={a.color} variant="outlined" style={{ textAlign: "left" }} onClick={() => a.onSelect()}>{breakableText(a.name)}</Button>
                </Grid>
            })}
        </Grid>}
    </>)
}

const AccountSelector = (props: { accounts: AccountInfo[], showUnexpectedOption: boolean, onUnexpectedSelected: () => void, onSelect: (a: AccountInfo) => void }) => {
    const { accounts, onSelect } = props
    const kinds = Object.keys(util.groupArray(accounts, "kind")).sort()

    return (<Stack spacing={3}>
        {kinds.map(kind => {
            const accountsForKind = accounts.filter(a => a.kind == kind)
            return <Box key={kind} >
                <Typography>{typeLabelForAccountKind(kind)}</Typography>
                <MenuArray
                    style={{ marginTop: "10px" }}
                    items={accountsForKind.filter(a => !a.name.startsWith("_")).sort().map(a => {
                        return {
                            onSelect: () => onSelect(a),
                            name: a.name
                        }
                    })}
                />
            </Box>
        })}

        {props.showUnexpectedOption && <Box>
            {/* <Button color="warning" onClick={props.onUnexpectedSelected}>This wasn't part of the plan</Button> */}
            <Typography>Other</Typography>
            <MenuArray
                style={{ marginTop: "10px" }}
                items={[{
                    name: "This wasn't part of the plan",
                    color: "warning",
                    onSelect: props.onUnexpectedSelected,
                }]}
            />
        </Box>}
    </Stack>)
}



const DrillDownSelector = (props: { handle: PhysicalItemHandle, accounts: AccountInfo[], showUnexpectedOption: boolean, onUnexpectedSelected: () => void, onSelect: (s: MappingOption[] | undefined) => void }) => {
    const { handle, accounts, showUnexpectedOption, onSelect } = props
    const [flows, setFlows] = React.useState<Flow[]>()

    const [account, setAccount] = React.useState<AccountInfo>()
    const [flow, setFlow] = React.useState<Flow>()

    const [error, setError] = React.useState<string>()
    const [isFetching, setFetching] = React.useState(false)

    const getTheFlows = ()=>{
        setFetching(true)
        getFlows(
            getTenantId(), 
            flows => {
                setFlows(flows)
                setFetching(false)
            }, 
            response=>{
                setError(`Error ${response.status}: ${response.body}`)
                setFetching(false)
            })
    }

    const getOptions = () => {
        if (account && flow) {
            setFetching(true)
            http({
                url: `/api/tenants/${getTenantId()}/mappingOptions/${handle.accountId}/${handle.itemId}?filter=${flow.name}&limit=100`,
                method: "GET",
                onResponse: function (response) {
                    console.log("done fetching");
                    setFetching(false)
                    if (response.status === 200) {
                        onSelect(JSON.parse(response.body));
                    } else {
                        setError(`Error ${response.status}: ${response.body}`)
                    }
                }
            });
        }
    }

    React.useEffect(getOptions, [account, flow])
    React.useEffect(getTheFlows, [])

    let selector: ReactNode;
    if (!account) {
        selector = <>
            <AccountSelector
                accounts={accounts}
                showUnexpectedOption={props.showUnexpectedOption}
                onUnexpectedSelected={props.onUnexpectedSelected}
                onSelect={setAccount} />
        </>
    } else if (!flow) {
        selector = <>
            <MenuArray
                items={
                    flows?.filter(flow => flow.accountOrTemplate == account.id || flow.toFromAccount == account.id)?.map(flow => {
                        return {
                            onSelect: () => setFlow(flow),
                            name: flow.name
                        }
                    })} />
        </>
    } else if (isFetching) {
        selector = <>
            <CircularProgress />
        </>
    } else {
        selector = <></>
    }
    <Chip />
    return <>
        <Stack spacing={1}>
            {account && <Box><Chip label={account.name} onDelete={() => {
                setAccount(undefined)
                setFlow(undefined)
                onSelect(undefined)
            }} /></Box>}
            {flow && <Box><Chip label={flow.name} onDelete={() => {
                setFlow(undefined)
                onSelect(undefined)
            }}></Chip></Box>}
            {error && <Alert color="error">{error}</Alert>}
            {selector}
        </Stack>

    </>
}