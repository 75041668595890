import React, { ReactNode } from 'react'

export default (props: { isLoading: boolean, children?: ReactNode | string | undefined }) => {
    const { isLoading, children } = props

    console.log("isLoading?", isLoading)
    console.log("children are", children)

    return (<>
        {isLoading &&
            <table style={{ width: "100%", height: "80%" }}>
                <tbody>
                    <tr style={{ height: "50%" }}></tr>
                    <tr><td style={{ width: "50%" }}></td><td><img className="throbber" src="/loader.gif"></img></td><td style={{ width: "50%" }}></td></tr>
                    <tr style={{ height: "50%" }}></tr>
                </tbody>
            </table>}
        {(!isLoading) && children}
    </>);
}