import React from 'react'
import $ from 'jquery'
import _ from 'underscore'
import { TemplateInfoListItem } from './api'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'

export default (props: { templates: TemplateInfoListItem[], disabled: boolean, selection: string | undefined, onChange: (e: SelectChangeEvent<string>) => void }) => {
    let { templates, selection, disabled, onChange } = props

    return <Select value={selection} disabled={disabled} onChange={e => onChange(e)}>
        {_.sortBy(templates, "name").map((template) => <MenuItem value={template.id} >{template.name}</MenuItem>)}
    </Select>
}