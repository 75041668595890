import { Button } from "@mui/material"
import React, { CSSProperties } from "react"

export default (props: { style?: CSSProperties, children?: string, maxCharsPerWord?: number }) => {
    const maxCharsPerWord = props.maxCharsPerWord ?? 100
    const maxLines = 10
    const { children, style } = props
    let formattedChildren: string
    try {
        formattedChildren = JSON.stringify(children ?? "", null, 4)
    } catch (e) {
        formattedChildren = ""
    }
    const [expanded, setExpanded] = React.useState(false)
    const [showAllLines, setShowAllLines] = React.useState(false)

    const toggleExpand = () => setExpanded(!expanded)
    const toggleShowAllLines = () => setShowAllLines(!showAllLines)

    const trimmedLines = (formattedChildren?.split("\n") ?? []).map(line => {
        const words = line.split(' ')

        const displayWords = words.map(word => {
            if (word.length > maxCharsPerWord) {
                return <span>{word.substring(0, maxCharsPerWord)}...<Button onClick={toggleExpand}>Truncated</Button></span>
            } else {
                return word
            }
        })

        return (<span>
            {displayWords.map((word, idx) => {
                return <>
                    {idx > 0 && " "}
                    {word}
                </>
            })}
        </span>)
    })

    const lines = expanded ? (formattedChildren?.split("\n") ?? []) : trimmedLines
    const linesToShow = showAllLines ? lines : lines.slice(0, maxLines)
    return (<span style={style}>
        {linesToShow.map(line => <>{line}<br /></>)}
        {(lines.length >= maxLines && !showAllLines) && <Button onClick={toggleShowAllLines}>More lines...</Button>}
    </span>)
}