import { Button, Stack, Typography } from "@mui/material"
import { ReactNode, useState } from "react"

export default (props: { pages: Record<string, () => ReactNode>, onSubmit: () => void }) => {
    const { pages, onSubmit } = props
    const [idx, setIdx] = useState(0)

    const pageNames = Object.keys(pages)
    const isLastPage = idx == (pageNames.length - 1)
    const isFirstPage = idx == 0

    return (<>

        <Stack spacing={4}>
            <Typography variant='subtitle1'>{pageNames[idx]}</Typography>
            {pages[pageNames[idx]]()}
            <Stack direction="row" spacing={2} style={{ textAlign: "right" }} alignItems={"flex-end"} justifyContent={"flex-end"}>
                {(!isFirstPage) && <Button variant="outlined" onClick={() => setIdx(idx - 1)}>Back</Button>}
                {isLastPage ? <Button variant="contained" onClick={onSubmit}>Submit</Button> : <Button variant="outlined" onClick={() => setIdx(idx + 1)}>Next</Button>}
            </Stack>
        </Stack>
    </>)
}