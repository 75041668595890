import React from 'react'
import _ from 'underscore'
import http, { HttpMethodName } from './cj/HttpClient'
import { getTenantId } from './util'
import { LineOfCredit, PhysicalAccountInfoDto, PhysicalConfigType } from './api'
import { Alert, Button, Dialog, DialogActions, DialogTitle, MenuItem, Stack, TextField, useMediaQuery, useTheme } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CurrencyAmount } from './CurrencyAmountField'
import CurrencyAmountSelector from './CurrencyAmountSelector'
import LabeledSelect from './LabeledSelect'

const defaultLineOfCredit: LineOfCredit = {
    type: "line-of-credit",
    currency: "USD",
    creditAmount: 0
}
export default (props: { existing: PhysicalAccountInfoDto | undefined, onComplete: (change: PhysicalAccountInfoDto | undefined) => void }) => {
    const { existing, onComplete } = props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [name, setName] = React.useState(existing?.name ?? "")
    const [error, setError] = React.useState<string>()
    const [config, setConfig] = React.useState(existing?.config)

    const typesAvailable: (PhysicalConfigType | undefined)[] = [undefined, "line-of-credit"]

    const doClose = () => {
        onComplete(undefined)
    }

    const doSave = () => {
        if (name && name.length > 0) {
            setError(undefined)
            var method: HttpMethodName = existing ? "PUT" : "POST";
            var url = existing ? `/api/tenants/${getTenantId()}/physicalAccounts/` + existing.id : `/api/tenants/${getTenantId()}/physicalAccounts`

            var update: PhysicalAccountInfoDto = {
                name: name,
                config: config,
            }
            http({
                url: url,
                method: method,
                data: JSON.stringify(update),
                onResponse: function (response) {
                    if (http.is2xxResponse(response)) {
                        onComplete(update)
                    } else {
                        setError("Error!  Server said:" + response.body);
                    }
                }
            });
        } else {

            setError("Missing a name")
        }
    }

    const changeType = (type: PhysicalConfigType | undefined) => {
        if (type !== config?.type) {
            switch (type) {
                case undefined:
                    setConfig(undefined)
                    break
                case "line-of-credit":
                    setConfig(defaultLineOfCredit)
                    break
            }
        }
    }

    return <Dialog
        open={true}
        onClose={doClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>{existing ? "Edit" : "Create"} Physical Account<IconButton
            aria-label="close"
            onClick={doClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton></DialogTitle>
        <Stack spacing={2} style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
            <TextField label="Name" value={name} onChange={e => setName(e.target.value)} />
            <LabeledSelect
                labelId="demo-simple-select-label"
                label="Configuration"
                value={config?.type}
                onChange={(e) => changeType(e.target.value as PhysicalConfigType | undefined)}>
                {typesAvailable.map(typeName => <MenuItem value={typeName}>{typeName || "None"}</MenuItem>)}
            </LabeledSelect>
            {(config?.type == "line-of-credit" && <LineOfCreditConfigPanel config={config?.type == "line-of-credit" ? config as LineOfCredit : undefined} setConfig={setConfig} />)}
        </Stack>
        <DialogActions>
            <Button variant="contained" onClick={doSave}>Save</Button>
        </DialogActions>
        {error && <Stack direction="row-reverse" style={{ margin: "20px" }}><Alert color="error">{error}</Alert></Stack>}

    </Dialog>
}


const LineOfCreditConfigPanel = (props: { config: LineOfCredit | undefined, setConfig: (c: LineOfCredit) => void }) => {
    const { setConfig } = props

    const config: LineOfCredit = props.config || defaultLineOfCredit

    const amount: CurrencyAmount = {
        currency: config.currency,
        amount: config.creditAmount
    }

    const setAmount = (amount: CurrencyAmount) => {
        setConfig({
            ...config,
            creditAmount: amount.amount,
            currency: amount.currency
        })
    }

    return (
        <>
            <CurrencyAmountSelector value={amount} onChange={setAmount} />
        </>
    )
}