import React, { ReactNode } from 'react'
import $ from 'jquery'
import http from './cj/HttpClient'
import { formattedTotals, getTenantId } from './util'
import Table from './TableNG'
import { AccountInfo, ItemInfo, PhysicalAccountDto, PhysicalAccountInfoDto, Statement, StatementWithStats } from './api'
import { Alert, Button, CircularProgress, IconButton, } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { getStatements } from './api-actions'
import StatementStatsPrintout from './StatementStatsPrintout'
import StatementScreen from './StatementScreen'

interface StatementsScreenProps {
    source: PhysicalAccountDto,
    items: ItemInfo[],
    accounts: AccountInfo[],
    sources: PhysicalAccountInfoDto[],
    handleUpdate: () => void
}
export default (props: StatementsScreenProps) => {
    const { source, items, accounts, sources, handleUpdate } = props
    const [allStatements, setAllStatements] = React.useState<StatementWithStats[]>()
    const [selection, setSelection] = React.useState<string>()
    const [error, setError] = React.useState<string>()

    const statements = allStatements && allStatements.filter(it => it.statement.accountId == source.id)

    const reload = () => {
        handleUpdate()
        getStatements()
            .then(setAllStatements)
            .catch(setError)
    }

    React.useEffect(reload, [])

    const handleAdd = () => {
        http({
            url: `/api/tenants/${getTenantId()}/statements`,
            data: JSON.stringify({
                accountId: source.id
            }),
            method: "POST",
            options: { async: false },
            onResponse: function onResponse(response) {
                if (response.status !== 200) {
                    setError(`${response.status}:\n${response.body}`)
                } else {
                    const statement: Statement = JSON.parse(response.body)
                    setSelection(statement.id)
                }
            }
        });
    }

    if (error) {
        return <Alert color="error">{error}</Alert>
    } else if (!statements) {
        return <CircularProgress />
    } else {
        const statementsInOrder = statements.sort((a, b) => a.statement.name.localeCompare(b.statement.name))


        const addTotals = (a: Record<string, number>, b: Record<string, number>): Record<string, number> => {
            const n = {
                ...a
            }

            Object.keys(b).forEach(key => {
                n[key] = b[key] + (a[key] ?? 0)
            })

            return n
        }

        let runningTotals: Record<string, number> = {}
        const rows = statementsInOrder.map(ss => {
            runningTotals = addTotals(runningTotals, ss.stats.totals)
            const s = ss.statement
            // const categoryKeys = Object.keys(ss.stats.totalsByCategory)
            return [
                s.id,
                s.name,
                s.itemIds.length,
                formattedTotals(runningTotals),
                formattedTotals(ss.stats.totals),
                formattedTotals(ss.stats.totalsIn),
                formattedTotals(ss.stats.totalsOut),
                <StatementStatsPrintout stats={ss.stats} />,
                <Button onClick={() => setSelection(s.id)}>Edit</Button>]
        })
        return <>
            {selection && <StatementScreen
                statementId={selection}
                source={source}
                items={items}
                accounts={accounts}
                sources={sources}
                onComplete={() => {
                    setSelection(undefined)
                    reload()
                }}
                handleUpdate={reload}
            />}
            {!selection && <>
                <IconButton onClick={e => { e.preventDefault(); handleAdd() }}><AddIcon /></IconButton>
                <Table
                    header={["ID", "Name", "Count", "Running", "Net", "In", "Out", "Breakdown", ""]}
                    rows={rows} />
            </>}
        </>
    }
}
