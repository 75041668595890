import React from 'react'
import AccountSelect from './AccountSelect'
import { IconButton, Paper, SelectChangeEvent, Stack } from '@mui/material'
import { AccountInfo, FundsFlow } from './api'
import CurrencyAmountField from './CurrencyAmountField'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const FlowEditor = (props: { currency: string, flow: FundsFlow, accounts: AccountInfo[], onDelete: () => void, onChange: (n: FundsFlow) => void }) => {

    const { currency, flow, onChange, onDelete, accounts } = props

    console.log("FlowEditor props", props)
    let handleAmountChange = (amount: number) => {
        onChange({
            ...flow,
            amount: amount
        })
    }

    let handleFromChange = (event: SelectChangeEvent<string>) => {
        console.log("from Changed to ", event)
        onChange({
            ...flow,
            fromFundId: event.target.value
        })
    }

    let handleToChange = (event: SelectChangeEvent<string>) => {
        console.log("to Changed to ", event)
        onChange({
            ...flow,
            toFundId: event.target.value
        })
    }


    return (
        <Stack spacing={2} style={{ marginTop: "10px" }}>
            <div>

                <IconButton onClick={onDelete} style={{ float: "right" }}><CloseIcon /></IconButton>
                <CurrencyAmountField label="amount" value={{ amount: flow.amount, currency: currency }} onChange={handleAmountChange} />
            </div>
            <div>
                <AccountSelect
                    label="from"
                    accounts={accounts}
                    selection={flow.fromFundId}
                    onChange={handleFromChange} />
            </div>
            <div>
                <AccountSelect
                    label="to"
                    accounts={accounts}
                    selection={flow.toFundId}
                    onChange={handleToChange} />
            </div>
        </Stack>
    )
}

export default (props: { currency: string, flows: FundsFlow[] | undefined, accounts: AccountInfo[], onChange: (n: FundsFlow[]) => void }) => {
    const { currency, accounts, onChange } = props
    console.log("Rendering StandardFlowsEditor with props:", props)

    const flows = props.flows || []

    let handleAddFlow = () => {
        console.log("Adding flow")
        let updatedFlows = [...flows]
        updatedFlows.push({ amount: 0, fromFundId: "generic-income-expense", toFundId: "generic-income-expense" })
        onChange(updatedFlows)
    }

    let flowUpdateHandler = (idx: number) => {
        return (newFlow: FundsFlow) => {
            console.log("flow ", idx, "updated to ", newFlow)
            let newFlows = flows.slice(0)
            newFlows[idx] = newFlow
            console.log("new flows are ", newFlows)
            onChange(newFlows)
        }
    }
    let flowDeleteHandler = (idx: number) => {
        return () => {
            console.log("flow ", idx, "deleted")
            let newFlows = flows.slice(0)
            newFlows.splice(idx, 1);
            console.log("new flows are ", newFlows)
            onChange(newFlows)
        }
    }

    console.log(`there are ${flows.length} flows`)
    return (
        <Stack>
            <div><IconButton onClick={handleAddFlow} ><AddIcon /></IconButton></div>
            {flows.map((flow, idx) => {
                return <Paper style={{ padding: "20px" }}>
                    <FlowEditor currency={currency} flow={flow} accounts={accounts} onChange={flowUpdateHandler(idx)} onDelete={flowDeleteHandler(idx)} />
                </Paper>
            })}
        </Stack>
    )
}
