import React, { useState } from 'react'
import _ from 'underscore'
import { AccountInfo, Template, TemplateInfoListItem } from './api'
import LoadingThrobber from './LoadingThrobber'
import { TemplateEditorDialog } from './TemplateEditorDialog';
import { getAccountsPromise, getTemplate, getTemplates, updateTemplate } from './api-actions';
import FloatingActionBar from './FloatingActionBar';
import { AccordionExpandToggle } from './UncategorizedScreenEntry';
import { Button, Typography } from '@mui/material';

const TemplateDump = (props:{fullTemplate:Template, accountById:(id:string)=>AccountInfo|undefined})=>{
    const {fullTemplate, accountById} = props

    const split = fullTemplate.split
    const splitAccounts = Object.keys(fullTemplate.split)
    return  <>
        {<Typography>Default: {(fullTemplate.remainder && accountById(fullTemplate.remainder)?.name) ?? "none"}</Typography>}
        {splitAccounts.map(accountId => {
            const foo = split[accountId]
            var account = accountById(accountId)
            return <tr key={accountId}><td>{account?.name}</td><td>{foo}</td></tr>
        })}
    </>
}

const TemplateRow = (props:{listItem:TemplateInfoListItem, accountById:(id:string)=>AccountInfo|undefined, onEdit:()=>void})=>{
    const {accountById} = props
    const item = props.listItem

    const [detailTemplate, setDetailTemplate] = React.useState<Template>()

    var name = item.name, link = item.link;

    const handleDetailsLink = () => {
        console.log("this1 is ", item)

        getTemplate(item.id).then(setDetailTemplate)
    }
    
    const editButtonPressed = (templateInfo: TemplateInfoListItem) => props.onEdit()


    let fullTemplate = (detailTemplate?.id == item.id) ? detailTemplate : undefined

    console.log("detailsView for ", item.id, " is ", fullTemplate, "(", detailTemplate?.id, "vs", item.id, detailTemplate?.id == item.id)


    return <div key={item.id} style={{
        margin: "5px",
        padding:"10px",
        border:"1px solid white",
        clear:"both"
    }}>
        <Typography>
        <div>
            <span>{item.id} - {name}</span>
            <div style={{display:"inline-block", float:"right"}}>
                <Button onClick={() => editButtonPressed(item)}>edit</Button>
                <AccordionExpandToggle isShowing={detailTemplate ? true : false} onToggle={()=>detailTemplate ? setDetailTemplate(undefined): handleDetailsLink()}/>
            </div>
        </div>
        <table className="details"><tbody>{fullTemplate && <TemplateDump fullTemplate={fullTemplate} accountById={accountById}/>}</tbody></table>
        </Typography>
    </div>
}

export default (props: {}) => {
    const [templates, setTemplates] = React.useState<TemplateInfoListItem[]>([])
    const [accounts, setAccounts] = React.useState<AccountInfo[]>()
    const [template, setTemplate] = React.useState<Template>()
    const [isLoading, setLoading] = useState(false)

    React.useEffect(reload, [])

    function reload() {
        setLoading(true)
        getTemplates()
            .then(results=> setTemplates(_.sortBy(results, "name")))
            .then(getAccountsPromise)
            .then(setAccounts)
            .catch(err=> alert("Error: " + err))
            .finally(()=>setLoading(false))
    }

    if ( isLoading) {
        return <LoadingThrobber isLoading={true} />
    }
    console.log("Rendering templates", templates)

    const saveTemplate = (template: Template) => {
        updateTemplate(template).then(()=>{
            reload()
            setTemplate(undefined)
        })
    }


    return (<>
        {template && <TemplateEditorDialog
            template={template}
            onComplete={(t) => saveTemplate(t)}
            doClose={() => setTemplate(undefined)} />}
        <div >
            <FloatingActionBar noFloat={true} onRefresh={reload}/>
            {templates.map((item, idx) => <>
                <TemplateRow 
                    listItem={item}
                    accountById={accountId=>accounts?.find(a => { return a.id == accountId; })}
                    onEdit={()=>getTemplate(item.id).then(setTemplate)}
                />
            </>)}
        </div>
    </>)
}
