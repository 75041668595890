import React, { ReactNode } from 'react'
import MappingLogic from './mappingLogic'
import UncategorizedScreen from './UncategorizedScreen'
import TransactionsScreen from './TransactionsScreen'
import ConnectionsScreen from './ConnectionsScreen'
import RoutedSubNavigation from './RoutedSubNavigation'
import { Route, Routes } from 'react-router-dom'
import PhysicalItemPage from './PhysicalItemPage'
import PhysicalAccountPage from './PhysicalAccountPage'

export default () => {
    const [mapper, setMapper] = React.useState(MappingLogic())

    return <div>
        <RoutedSubNavigation 
            propertyName="subtab" 
            tabs={{
                "incoming": () => <UncategorizedScreen mapper={mapper} />,
                "accounts": () => <TransactionsScreen />,
                "connections": () => <ConnectionsScreen />
            }}
            extraRoutes={<>
                <Route path="accounts/:accountId/items/:itemId" element={<PhysicalItemPage />} />
                <Route path="accounts/:accountId" element={<PhysicalAccountPage />} />
            </>
            } />
        
    </div>
}
