import React from 'react'
import _ from 'underscore'
import util from '../util'
import dataCache, { Data } from '../data'
import QuarterSelect from '../QuarterSelect'
import { Amount, ProfitAndLossPeriod, ProfitAndLossReport } from '../api'
import { QuarterRange, getProfitAndLoss } from '../api-actions'

interface ProfitAndLossReportProps {
}

interface ProfitAndLossReportState {
    range: QuarterRange
    data: Data
    pnlData?: ProfitAndLossReport
    pnlGenerationDate?: Date
}

export default class ProfitAndLossReportUI extends React.Component<ProfitAndLossReportProps, ProfitAndLossReportState> {
    constructor(props: ProfitAndLossReportProps) {
        super(props);
        this.state = {
            data: dataCache(),
            range: {
                from: { year: 2019, quarter: 1 },
                to: { year: 2020, quarter: 1 }
            }
        }
        this.fetchPlan()
    }

    fetchPlan() {
        let getState = (() => this.state).bind(this)
        let setState = this.setState.bind(this)

        getProfitAndLoss(this.state.range)
            .then(pnlData => {
                setState({
                    ...getState(),
                    pnlData: pnlData,
                    pnlGenerationDate: new Date()
                })
            })
            .catch(alert)
    }

    render() {
        let { pnlData, pnlGenerationDate, range } = this.state

        console.log("Rendering pnlData", pnlData)
        try {
            if (pnlData) {

                let selectors: Record<string, (p: ProfitAndLossPeriod) => Amount[]> = {
                    "income": p => p.income,
                    "expense": p => p.income,
                    "liabilities": p => p.income,
                    "promotionalCredits": p => p.income
                }
                let handleRangeUpdate = (range: QuarterRange) => {
                    this.setState({
                        ... this.state,
                        range: range
                    }, this.fetchPlan)
                }

                return <div className="reports-tab">
                    <QuarterSelect value={range.from} onChange={(v) => handleRangeUpdate({ ...range, from: v })} /> to <QuarterSelect value={range.to} onChange={(v) => handleRangeUpdate({ ...range, to: v })} />

                    <h1>Profit and Loss</h1>
                    {(pnlData && pnlGenerationDate) && <>

                        <div className="header-data-band">For period {pnlData.periods[0].name} to {pnlData.periods[pnlData.periods.length - 1].name}</div>
                        <div className="header-data-band">Generated {pnlGenerationDate.toString()}</div>
                        <table className="pnl-table">
                            <tr><th>Quarter</th>{_.map(selectors, (getter, categoryName) => <th>{categoryName}</th>)}</tr>
                            {_.map(pnlData.periods, (quarterInfo) => {
                                let quarterAmounts = quarterInfo;
                                console.log("amounts are ", quarterAmounts)
                                console.log("income is ", quarterAmounts["income"])

                                let foos = _.map(selectors, (getter, categoryName) => {
                                    let categoryAmounts = getter(quarterAmounts)
                                    console.log("categoryAmounts for ", categoryName, " are ", categoryAmounts)
                                    let amountDivs = _.map(categoryAmounts, (amount) => <div>{util.formatAmount(amount.quantity, amount.currency)}&nbsp;</div>)
                                    return <td>{amountDivs}</td>
                                })

                                console.log(foos)
                                return <tr><td>{quarterInfo.name}</td>{foos}</tr>;
                            })}
                        </table>

                    </>}
                </div>
            } else {
                return <div style={{ textAlign: 'center' }}><img style={{ marginTop: '40%' }} className="throbber" src="/loader.gif"></img></div>
            }
        } catch (err) {
            console.log("Error in render: ", err)
        }
    }
}