import { useEffect, useState } from 'react'
import { AccountInfo, FullPhysicalItemDetails, PhysicalAccountInfoDto, PhysicalItem } from './api'
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ItemEditor from './itemEditor'
import makeMapper from './mappingLogic'
import makeData, { Data } from './data'
import PhysicalItemPanel from './PhysicalItemPanel';
import { ResultThing, getPhysicalAccountInfos } from './api-actions';

export interface ContextualPhysicalItem {
    item: PhysicalItem,
    foreignAccountId: string,
}

export interface ItemEditorDialogProps {
    item: ContextualPhysicalItem,
    accounts?: AccountInfo[], 
    // mapper: Mapper, 
    onSuccess: (result: ResultThing) => void,
    handleChange: () => void ,
    onClose: () => void
}
export default (props: ItemEditorDialogProps) => {
    const {onSuccess, handleChange, onClose } = props
    const {item, foreignAccountId} = props.item
    const [data, setData] = useState<Data>(makeData())
    const [mapper, setMapper] = useState(makeMapper())
    const [accounts, setAccounts] = useState<AccountInfo[]>(props.accounts || data.accounts())
    const [physAccounts, setPhysAccounts] = useState<PhysicalAccountInfoDto[]>()
    const physAccount = physAccounts?.find(p => p.id == foreignAccountId)

    useEffect(()=>getPhysicalAccountInfos(setPhysAccounts), [])


    const fullItem:FullPhysicalItemDetails = {
        accountId:foreignAccountId,
        accountName:physAccount?.name ?? "????",
        item:item  
    }

    return <Dialog
        open={true}
        onClose={() => onClose()}
        // fullScreen={true}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>Item Mapping<IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                <PhysicalItemPanel item={fullItem}></PhysicalItemPanel>
                <ItemEditor 
                    item = {item}
                    foreignAccountId = {foreignAccountId}
                    accounts = {accounts}
                    mapper={mapper}
                    handleChange={handleChange}
                    onSuccess={onSuccess}
                    />
            </Stack>
        </DialogContent>
    </Dialog>
}