import { Alert, Box } from "@mui/material"

export interface Problem {
    description:string,
    details:string
}

export default (props:{error:Problem})=>{
    const {error} = props
    return (<Alert color="error">
    {error.description}
    <Box>Details:</Box>
    <div style={{whiteSpace:"pre-wrap", fontFamily:"monospace"}}>{error.details}</div>
  </Alert>)
}